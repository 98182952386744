import { useFetch } from "../../hooks/useFetch";
import { message } from "antd";

// const sampleApi = {
//     message: "Successfuly Retrieved Ads",
//     data: {
//         total: 10834,
//         perPage: 10,
//         page: 1,
//         lastPage: 1084,
//         data: [
//             {
//                 id: 1,
//                 title: " A0C968 Toyota Vios E",
//                 slug: "a0c968",
//                 description:
//                     " A0C968 Toyota Vios E Gasoline Automatic Libis  QC",
//                 category_id: null,
//                 buy_now_price: null,
//                 sub_category_id: null,
//                 brand_id: null,
//                 type: null,
//                 ad_condition: null,
//                 model: null,
//                 buy_now_type: null,
//                 price: 400000,
//                 price_order: 400000,
//                 is_negotiable: null,
//                 is_featured: 0,
//                 seller_name: null,
//                 seller_email: null,
//                 seller_phone: null,
//                 warehouse_id: 1,
//                 new_warehouse_id: null,
//                 country_id: 1,
//                 state_id: 1,
//                 city_id: 1,
//                 address: null,
//                 video_url: null,
//                 category_type: null,
//                 status: "1",
//                 price_plan: null,
//                 mark_ad_agent: null,
//                 view: 288,
//                 max_impression: null,
//                 user_id: null,
//                 relist: 0,
//                 is_closed: 1,
//                 winner_id: null,
//                 winner_payment: null,
//                 latitude: null,
//                 longitude: null,
//                 purchased_at: null,
//                 expired_at: "2019-11-06T01:00:58.000Z",
//                 created_at: "2019-10-09 12:09:29",
//                 updated_at: "2020-01-03 13:02:45",
//                 categories: null,
//                 warehouses: {
//                     id: 1,
//                     viewing_address: "Automart Mercury Ave. Cor C5 Ave",
//                     landmark: "Near Eastwood City",
//                     location: "Libis",
//                     direction_here: "",
//                     city_id: 3,
//                     country_id: 1,
//                     days_open: "M-F Whole Day; Sat-Half Day; By Appt Only",
//                     days_avail: "Mon,Tue,Wed,Thu,Fri,Sat.5",
//                     hours_open: "10AM-5PM",
//                     slots: "10am,11am,1pm,2pm,3pm,4pm",
//                     bank_id: 3,
//                     created_at: "2019-08-02 11:10:52",
//                     updated_at: null,
//                 },
//                 state: null,
//                 city: {
//                     id: 1,
//                     city_name: "Cebu",
//                     alternative_name: "cebu",
//                     state_id: 1,
//                     created_at: null,
//                     updated_at: null,
//                 },
//                 user: null,
//                 medias: [
//                     {
//                         id: 1,
//                         user_id: 1,
//                         ad_id: 1,
//                         post_id: null,
//                         media_name: "a0c968-1.jpg",
//                         url: "https://automart-dev.sgp1.digitaloceanspaces.com/a0c968/a0c968-1.jpg",
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:29",
//                         updated_at: "2019-10-09 12:40:04",
//                     },
//                     {
//                         id: 2,
//                         user_id: 1,
//                         ad_id: 1,
//                         post_id: null,
//                         media_name: "a0c968-2.jpg",
//                         url: "https://automart-dev.sgp1.digitaloceanspaces.com/a0c968/a0c968-2.jpg",
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:29",
//                         updated_at: "2019-10-09 12:40:04",
//                     },
//                     {
//                         id: 3,
//                         user_id: 1,
//                         ad_id: 1,
//                         post_id: null,
//                         media_name: "a0c968-3.jpg",
//                         url: "https://automart-dev.sgp1.digitaloceanspaces.com/a0c968/a0c968-3.jpg",
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:29",
//                         updated_at: "2019-10-09 12:40:04",
//                     },
//                     {
//                         id: 4,
//                         user_id: 1,
//                         ad_id: 1,
//                         post_id: null,
//                         media_name: "a0c968-4.jpg",
//                         url: null,
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:29",
//                         updated_at: "2019-10-09 12:09:29",
//                     },
//                     {
//                         id: 5,
//                         user_id: 1,
//                         ad_id: 1,
//                         post_id: null,
//                         media_name: "a0c968-5.jpg",
//                         url: null,
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:29",
//                         updated_at: "2019-10-09 12:09:29",
//                     },
//                 ],
//             },
//             {
//                 id: 2,
//                 title: " A0K430 Toyota Vios E",
//                 slug: "a0k430",
//                 description: " A0K430 Toyota Vios E Gasoline Manual Libis  QC",
//                 category_id: null,
//                 buy_now_price: null,
//                 sub_category_id: null,
//                 brand_id: null,
//                 type: null,
//                 ad_condition: null,
//                 model: null,
//                 buy_now_type: null,
//                 price: 365500,
//                 price_order: 365500,
//                 is_negotiable: null,
//                 is_featured: 0,
//                 seller_name: null,
//                 seller_email: null,
//                 seller_phone: null,
//                 warehouse_id: 1,
//                 new_warehouse_id: null,
//                 country_id: 1,
//                 state_id: 1,
//                 city_id: 1,
//                 address: null,
//                 video_url: null,
//                 category_type: null,
//                 status: "1",
//                 price_plan: null,
//                 mark_ad_agent: null,
//                 view: 470,
//                 max_impression: null,
//                 user_id: null,
//                 relist: 0,
//                 is_closed: 1,
//                 winner_id: null,
//                 winner_payment: null,
//                 latitude: null,
//                 longitude: null,
//                 purchased_at: null,
//                 expired_at: "2019-11-06T01:00:58.000Z",
//                 created_at: "2019-10-09 12:09:30",
//                 updated_at: "2020-01-03 13:02:45",
//                 categories: null,
//                 warehouses: {
//                     id: 1,
//                     viewing_address: "Automart Mercury Ave. Cor C5 Ave",
//                     landmark: "Near Eastwood City",
//                     location: "Libis",
//                     direction_here: "",
//                     city_id: 3,
//                     country_id: 1,
//                     days_open: "M-F Whole Day; Sat-Half Day; By Appt Only",
//                     days_avail: "Mon,Tue,Wed,Thu,Fri,Sat.5",
//                     hours_open: "10AM-5PM",
//                     slots: "10am,11am,1pm,2pm,3pm,4pm",
//                     bank_id: 3,
//                     created_at: "2019-08-02 11:10:52",
//                     updated_at: null,
//                 },
//                 state: null,
//                 city: {
//                     id: 1,
//                     city_name: "Cebu",
//                     alternative_name: "cebu",
//                     state_id: 1,
//                     created_at: null,
//                     updated_at: null,
//                 },
//                 user: null,
//                 medias: [
//                     {
//                         id: 6,
//                         user_id: 1,
//                         ad_id: 2,
//                         post_id: null,
//                         media_name: "a0k430-1.jpg",
//                         url: "https://automart-dev.sgp1.digitaloceanspaces.com/a0k430/a0k430-1.jpg",
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:40:04",
//                     },
//                     {
//                         id: 7,
//                         user_id: 1,
//                         ad_id: 2,
//                         post_id: null,
//                         media_name: "a0k430-2.jpg",
//                         url: "https://automart-dev.sgp1.digitaloceanspaces.com/a0k430/a0k430-2.jpg",
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:40:04",
//                     },
//                     {
//                         id: 8,
//                         user_id: 1,
//                         ad_id: 2,
//                         post_id: null,
//                         media_name: "a0k430-3.jpg",
//                         url: "https://automart-dev.sgp1.digitaloceanspaces.com/a0k430/a0k430-3.jpg",
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:40:04",
//                     },
//                     {
//                         id: 9,
//                         user_id: 1,
//                         ad_id: 2,
//                         post_id: null,
//                         media_name: "a0k430-4.jpg",
//                         url: null,
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:09:30",
//                     },
//                     {
//                         id: 10,
//                         user_id: 1,
//                         ad_id: 2,
//                         post_id: null,
//                         media_name: "a0k430-5.jpg",
//                         url: null,
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:09:30",
//                     },
//                 ],
//             },
//             {
//                 id: 3,
//                 title: "2017 Toyota Vios E 1.3",
//                 slug: "a0z711",
//                 description:
//                     " A0Z711 2017 Toyota Vios E Sedan Gasoline Manual Sanford  Paranaque",
//                 category_id: null,
//                 buy_now_price: null,
//                 sub_category_id: null,
//                 brand_id: null,
//                 type: null,
//                 ad_condition: null,
//                 model: null,
//                 buy_now_type: null,
//                 price: 307450,
//                 price_order: 307450,
//                 is_negotiable: "0",
//                 is_featured: 0,
//                 seller_name: null,
//                 seller_email: null,
//                 seller_phone: null,
//                 warehouse_id: 10,
//                 new_warehouse_id: null,
//                 country_id: 1,
//                 state_id: 1,
//                 city_id: 1,
//                 address: null,
//                 video_url: "",
//                 category_type: null,
//                 status: "1",
//                 price_plan: null,
//                 mark_ad_agent: null,
//                 view: 7637,
//                 max_impression: null,
//                 user_id: null,
//                 relist: 0,
//                 is_closed: 1,
//                 winner_id: null,
//                 winner_payment: null,
//                 latitude: null,
//                 longitude: null,
//                 purchased_at: null,
//                 expired_at: "2020-01-15T01:00:00.000Z",
//                 created_at: "2019-10-09 12:09:30",
//                 updated_at: "2020-01-10 11:23:50",
//                 categories: null,
//                 warehouses: {
//                     id: 10,
//                     viewing_address:
//                         "Robinson's Supermarket Merville (meet-up pt.)",
//                     landmark: "N/A",
//                     location: "Merville",
//                     direction_here: "https://goo.gl/maps/XocTCBbeX15g458w6",
//                     city_id: 2,
//                     country_id: 1,
//                     days_open: "Mon - Tues",
//                     days_avail: "Mon,Tue,Sat",
//                     hours_open: "10AM-5PM",
//                     slots: "10am,11am,1pm,2pm,3pm,4pm",
//                     bank_id: 3,
//                     created_at: "2019-09-20 05:46:18",
//                     updated_at: "2020-08-27 17:56:00",
//                 },
//                 state: null,
//                 city: {
//                     id: 1,
//                     city_name: "Cebu",
//                     alternative_name: "cebu",
//                     state_id: 1,
//                     created_at: null,
//                     updated_at: null,
//                 },
//                 user: null,
//                 medias: [
//                     {
//                         id: 11,
//                         user_id: 1,
//                         ad_id: 3,
//                         post_id: null,
//                         media_name: "a0z711-1.jpg",
//                         url: "https://automart-dev.sgp1.digitaloceanspaces.com/a0z711/a0z711-1.jpg",
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:40:05",
//                     },
//                     {
//                         id: 12,
//                         user_id: 1,
//                         ad_id: 3,
//                         post_id: null,
//                         media_name: "a0z711-2.jpg",
//                         url: "https://automart-dev.sgp1.digitaloceanspaces.com/a0z711/a0z711-2.jpg",
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:40:05",
//                     },
//                     {
//                         id: 13,
//                         user_id: 1,
//                         ad_id: 3,
//                         post_id: null,
//                         media_name: "a0z711-3.jpg",
//                         url: "https://automart-dev.sgp1.digitaloceanspaces.com/a0z711/a0z711-3.jpg",
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:40:05",
//                     },
//                     {
//                         id: 14,
//                         user_id: 1,
//                         ad_id: 3,
//                         post_id: null,
//                         media_name: "a0z711-4.jpg",
//                         url: "https://automart-dev.sgp1.digitaloceanspaces.com/a0z711/a0z711-4.jpg",
//                         type: "image",
//                         is_feature: "1",
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-11-28 10:18:56",
//                     },
//                     {
//                         id: 15,
//                         user_id: 1,
//                         ad_id: 3,
//                         post_id: null,
//                         media_name: "a0z711-5.jpg",
//                         url: null,
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:09:30",
//                     },
//                 ],
//             },
//             {
//                 id: 4,
//                 title: null,
//                 slug: "a1c680",
//                 description: null,
//                 category_id: null,
//                 buy_now_price: null,
//                 sub_category_id: null,
//                 brand_id: null,
//                 type: null,
//                 ad_condition: null,
//                 model: null,
//                 buy_now_type: null,
//                 price: 468000,
//                 price_order: 468000,
//                 is_negotiable: null,
//                 is_featured: 0,
//                 seller_name: null,
//                 seller_email: null,
//                 seller_phone: null,
//                 warehouse_id: 1,
//                 new_warehouse_id: null,
//                 country_id: 1,
//                 state_id: 1,
//                 city_id: 1,
//                 address: null,
//                 video_url: null,
//                 category_type: null,
//                 status: "1",
//                 price_plan: null,
//                 mark_ad_agent: null,
//                 view: 3,
//                 max_impression: null,
//                 user_id: null,
//                 relist: 0,
//                 is_closed: 1,
//                 winner_id: null,
//                 winner_payment: null,
//                 latitude: null,
//                 longitude: null,
//                 purchased_at: null,
//                 expired_at: "2019-10-15T16:00:00.000Z",
//                 created_at: "2019-10-09 12:09:30",
//                 updated_at: "2020-01-03 13:02:45",
//                 categories: null,
//                 warehouses: {
//                     id: 1,
//                     viewing_address: "Automart Mercury Ave. Cor C5 Ave",
//                     landmark: "Near Eastwood City",
//                     location: "Libis",
//                     direction_here: "",
//                     city_id: 3,
//                     country_id: 1,
//                     days_open: "M-F Whole Day; Sat-Half Day; By Appt Only",
//                     days_avail: "Mon,Tue,Wed,Thu,Fri,Sat.5",
//                     hours_open: "10AM-5PM",
//                     slots: "10am,11am,1pm,2pm,3pm,4pm",
//                     bank_id: 3,
//                     created_at: "2019-08-02 11:10:52",
//                     updated_at: null,
//                 },
//                 state: null,
//                 city: {
//                     id: 1,
//                     city_name: "Cebu",
//                     alternative_name: "cebu",
//                     state_id: 1,
//                     created_at: null,
//                     updated_at: null,
//                 },
//                 user: null,
//                 medias: [
//                     {
//                         id: 16,
//                         user_id: 1,
//                         ad_id: 4,
//                         post_id: null,
//                         media_name: "a1c680-1.jpg",
//                         url: null,
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:09:30",
//                     },
//                     {
//                         id: 17,
//                         user_id: 1,
//                         ad_id: 4,
//                         post_id: null,
//                         media_name: "a1c680-2.jpg",
//                         url: null,
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:09:30",
//                     },
//                     {
//                         id: 18,
//                         user_id: 1,
//                         ad_id: 4,
//                         post_id: null,
//                         media_name: "a1c680-3.jpg",
//                         url: null,
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:09:30",
//                     },
//                     {
//                         id: 19,
//                         user_id: 1,
//                         ad_id: 4,
//                         post_id: null,
//                         media_name: "a1c680-4.jpg",
//                         url: null,
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:09:30",
//                     },
//                     {
//                         id: 20,
//                         user_id: 1,
//                         ad_id: 4,
//                         post_id: null,
//                         media_name: "a1c680-5.jpg",
//                         url: null,
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:09:30",
//                     },
//                 ],
//             },
//             {
//                 id: 5,
//                 title: " A1J256 Toyota Vios E",
//                 slug: "a1j256",
//                 description:
//                     " A1J256 Toyota Vios E Gasoline Automatic Balintawak  QC",
//                 category_id: null,
//                 buy_now_price: null,
//                 sub_category_id: null,
//                 brand_id: null,
//                 type: null,
//                 ad_condition: null,
//                 model: null,
//                 buy_now_type: null,
//                 price: 475000,
//                 price_order: 475000,
//                 is_negotiable: null,
//                 is_featured: 0,
//                 seller_name: null,
//                 seller_email: null,
//                 seller_phone: null,
//                 warehouse_id: 9,
//                 new_warehouse_id: null,
//                 country_id: 1,
//                 state_id: 1,
//                 city_id: 1,
//                 address: null,
//                 video_url: null,
//                 category_type: null,
//                 status: "1",
//                 price_plan: null,
//                 mark_ad_agent: null,
//                 view: 8,
//                 max_impression: null,
//                 user_id: null,
//                 relist: 0,
//                 is_closed: 1,
//                 winner_id: null,
//                 winner_payment: null,
//                 latitude: null,
//                 longitude: null,
//                 purchased_at: null,
//                 expired_at: "2019-11-06T01:00:58.000Z",
//                 created_at: "2019-10-09 12:09:30",
//                 updated_at: "2020-01-03 13:02:45",
//                 categories: null,
//                 warehouses: {
//                     id: 9,
//                     viewing_address: "Balintawak Showroom",
//                     landmark: "N/A",
//                     location: "Balintawak",
//                     direction_here: "",
//                     city_id: 3,
//                     country_id: 1,
//                     days_open: "Mon, Tue, Wed",
//                     days_avail: "Mon,Tue,Wed",
//                     hours_open: "8AM - 12NN",
//                     slots: "10am,11am,1pm,2pm,3pm,4pm",
//                     bank_id: 3,
//                     created_at: "2019-09-18 04:01:43",
//                     updated_at: "2020-08-26 06:09:40",
//                 },
//                 state: null,
//                 city: {
//                     id: 1,
//                     city_name: "Cebu",
//                     alternative_name: "cebu",
//                     state_id: 1,
//                     created_at: null,
//                     updated_at: null,
//                 },
//                 user: null,
//                 medias: [
//                     {
//                         id: 21,
//                         user_id: 1,
//                         ad_id: 5,
//                         post_id: null,
//                         media_name: "a1j256-1.jpg",
//                         url: null,
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:09:30",
//                     },
//                     {
//                         id: 22,
//                         user_id: 1,
//                         ad_id: 5,
//                         post_id: null,
//                         media_name: "a1j256-2.jpg",
//                         url: null,
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:09:30",
//                     },
//                     {
//                         id: 23,
//                         user_id: 1,
//                         ad_id: 5,
//                         post_id: null,
//                         media_name: "a1j256-3.jpg",
//                         url: null,
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:09:30",
//                     },
//                     {
//                         id: 24,
//                         user_id: 1,
//                         ad_id: 5,
//                         post_id: null,
//                         media_name: "a1j256-4.jpg",
//                         url: null,
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:09:30",
//                     },
//                     {
//                         id: 25,
//                         user_id: 1,
//                         ad_id: 5,
//                         post_id: null,
//                         media_name: "a1j256-5.jpg",
//                         url: null,
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:09:30",
//                     },
//                 ],
//             },
//             {
//                 id: 6,
//                 title: "2018 Toyota Vios E 1.3",
//                 slug: "a2o631",
//                 description:
//                     " A2O631 2018 Toyota Vios E Gasoline Automatic Sanford  Paranaque",
//                 category_id: null,
//                 buy_now_price: 444000,
//                 sub_category_id: null,
//                 brand_id: null,
//                 type: null,
//                 ad_condition: null,
//                 model: null,
//                 buy_now_type: null,
//                 price: null,
//                 price_order: 444000,
//                 is_negotiable: null,
//                 is_featured: 0,
//                 seller_name: null,
//                 seller_email: null,
//                 seller_phone: null,
//                 warehouse_id: 10,
//                 new_warehouse_id: null,
//                 country_id: 1,
//                 state_id: 1,
//                 city_id: 1,
//                 address: null,
//                 video_url: null,
//                 category_type: null,
//                 status: "1",
//                 price_plan: null,
//                 mark_ad_agent: null,
//                 view: 198,
//                 max_impression: null,
//                 user_id: null,
//                 relist: 0,
//                 is_closed: 1,
//                 winner_id: 5327,
//                 winner_payment: "financing",
//                 latitude: null,
//                 longitude: null,
//                 purchased_at: null,
//                 expired_at: "2020-01-25T01:00:00.000Z",
//                 created_at: "2019-10-09 12:09:30",
//                 updated_at: "2020-01-06 21:39:30",
//                 categories: null,
//                 warehouses: {
//                     id: 10,
//                     viewing_address:
//                         "Robinson's Supermarket Merville (meet-up pt.)",
//                     landmark: "N/A",
//                     location: "Merville",
//                     direction_here: "https://goo.gl/maps/XocTCBbeX15g458w6",
//                     city_id: 2,
//                     country_id: 1,
//                     days_open: "Mon - Tues",
//                     days_avail: "Mon,Tue,Sat",
//                     hours_open: "10AM-5PM",
//                     slots: "10am,11am,1pm,2pm,3pm,4pm",
//                     bank_id: 3,
//                     created_at: "2019-09-20 05:46:18",
//                     updated_at: "2020-08-27 17:56:00",
//                 },
//                 state: null,
//                 city: {
//                     id: 1,
//                     city_name: "Cebu",
//                     alternative_name: "cebu",
//                     state_id: 1,
//                     created_at: null,
//                     updated_at: null,
//                 },
//                 user: {
//                     id: 5327,
//                     first_name: "Loise Ann Dayao",
//                     middle_name: "Loise Ann",
//                     last_name: "Dayao",
//                     user_name: null,
//                     email: "loiseanndayao@gmail.com",
//                     password:
//                         "$2y$10$3yuZLPIdpX89OAUcs5.azOxhvzYltStmEhafqnCfI5i8ChpKAtHzm",
//                     interest: null,
//                     country_id: null,
//                     mobile: "09975663036",
//                     gender: null,
//                     address: null,
//                     website: null,
//                     phone: null,
//                     photo: null,
//                     photo_storage: null,
//                     user_type: "user",
//                     has_deposited: 0,
//                     active_status: "1",
//                     is_email_verified: null,
//                     activation_code: null,
//                     is_online: null,
//                     last_login: null,
//                     remember_token:
//                         "byEAfJuUabnU9y0jVQsjFDGDDJPxPiJHPYgmVNAqaE7Db3M1CQEAN3AoSpPE",
//                     created_at: "2019-12-30 11:59:48",
//                     updated_at: "2020-01-22 12:07:39",
//                 },
//                 medias: [
//                     {
//                         id: 26,
//                         user_id: 1,
//                         ad_id: 6,
//                         post_id: null,
//                         media_name: "a2o631-1.jpg",
//                         url: "https://automart-dev.sgp1.digitaloceanspaces.com/a2o631/a2o631-1.jpg",
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:40:07",
//                     },
//                     {
//                         id: 27,
//                         user_id: 1,
//                         ad_id: 6,
//                         post_id: null,
//                         media_name: "a2o631-2.jpg",
//                         url: "https://automart-dev.sgp1.digitaloceanspaces.com/a2o631/a2o631-2.jpg",
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:40:07",
//                     },
//                     {
//                         id: 28,
//                         user_id: 1,
//                         ad_id: 6,
//                         post_id: null,
//                         media_name: "a2o631-3.jpg",
//                         url: "https://automart-dev.sgp1.digitaloceanspaces.com/a2o631/a2o631-3.jpg",
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:40:07",
//                     },
//                     {
//                         id: 29,
//                         user_id: 1,
//                         ad_id: 6,
//                         post_id: null,
//                         media_name: "a2o631-4.jpg",
//                         url: "https://automart-dev.sgp1.digitaloceanspaces.com/a2o631/a2o631-4.jpg",
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:40:07",
//                     },
//                     {
//                         id: 30,
//                         user_id: 1,
//                         ad_id: 6,
//                         post_id: null,
//                         media_name: "a2o631-5.jpg",
//                         url: null,
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:09:30",
//                     },
//                 ],
//             },
//             {
//                 id: 7,
//                 title: " A2S361 Toyota Vios G",
//                 slug: "a2s361",
//                 description:
//                     " A2S361 Toyota Vios G Gasoline Automatic Libis  QC",
//                 category_id: null,
//                 buy_now_price: null,
//                 sub_category_id: null,
//                 brand_id: null,
//                 type: null,
//                 ad_condition: null,
//                 model: null,
//                 buy_now_type: null,
//                 price: 516000,
//                 price_order: 516000,
//                 is_negotiable: "0",
//                 is_featured: 0,
//                 seller_name: null,
//                 seller_email: null,
//                 seller_phone: null,
//                 warehouse_id: 1,
//                 new_warehouse_id: null,
//                 country_id: 1,
//                 state_id: 1,
//                 city_id: 1,
//                 address: null,
//                 video_url: "",
//                 category_type: null,
//                 status: "1",
//                 price_plan: null,
//                 mark_ad_agent: null,
//                 view: 242,
//                 max_impression: null,
//                 user_id: null,
//                 relist: 0,
//                 is_closed: 1,
//                 winner_id: null,
//                 winner_payment: null,
//                 latitude: null,
//                 longitude: null,
//                 purchased_at: null,
//                 expired_at: "2019-11-06T01:00:58.000Z",
//                 created_at: "2019-10-09 12:09:30",
//                 updated_at: "2020-01-03 13:02:45",
//                 categories: null,
//                 warehouses: {
//                     id: 1,
//                     viewing_address: "Automart Mercury Ave. Cor C5 Ave",
//                     landmark: "Near Eastwood City",
//                     location: "Libis",
//                     direction_here: "",
//                     city_id: 3,
//                     country_id: 1,
//                     days_open: "M-F Whole Day; Sat-Half Day; By Appt Only",
//                     days_avail: "Mon,Tue,Wed,Thu,Fri,Sat.5",
//                     hours_open: "10AM-5PM",
//                     slots: "10am,11am,1pm,2pm,3pm,4pm",
//                     bank_id: 3,
//                     created_at: "2019-08-02 11:10:52",
//                     updated_at: null,
//                 },
//                 state: null,
//                 city: {
//                     id: 1,
//                     city_name: "Cebu",
//                     alternative_name: "cebu",
//                     state_id: 1,
//                     created_at: null,
//                     updated_at: null,
//                 },
//                 user: null,
//                 medias: [
//                     {
//                         id: 31,
//                         user_id: 1,
//                         ad_id: 7,
//                         post_id: null,
//                         media_name: "a2s361-1.jpg",
//                         url: "https://automart-dev.sgp1.digitaloceanspaces.com/a2s361/a2s361-1.jpg",
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:40:07",
//                     },
//                     {
//                         id: 32,
//                         user_id: 1,
//                         ad_id: 7,
//                         post_id: null,
//                         media_name: "a2s361-2.jpg",
//                         url: "https://automart-dev.sgp1.digitaloceanspaces.com/a2s361/a2s361-2.jpg",
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:40:07",
//                     },
//                     {
//                         id: 33,
//                         user_id: 1,
//                         ad_id: 7,
//                         post_id: null,
//                         media_name: "a2s361-3.jpg",
//                         url: "https://automart-dev.sgp1.digitaloceanspaces.com/a2s361/a2s361-3.jpg",
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:40:07",
//                     },
//                     {
//                         id: 34,
//                         user_id: 1,
//                         ad_id: 7,
//                         post_id: null,
//                         media_name: "a2s361-4.jpg",
//                         url: "https://automart-dev.sgp1.digitaloceanspaces.com/a2s361/a2s361-4.jpg",
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:40:07",
//                     },
//                     {
//                         id: 35,
//                         user_id: 1,
//                         ad_id: 7,
//                         post_id: null,
//                         media_name: "a2s361-5.jpg",
//                         url: null,
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:09:30",
//                     },
//                 ],
//             },
//             {
//                 id: 8,
//                 title: null,
//                 slug: "a3r767",
//                 description: null,
//                 category_id: null,
//                 buy_now_price: null,
//                 sub_category_id: null,
//                 brand_id: null,
//                 type: null,
//                 ad_condition: null,
//                 model: null,
//                 buy_now_type: null,
//                 price: 501750,
//                 price_order: 501750,
//                 is_negotiable: null,
//                 is_featured: 0,
//                 seller_name: null,
//                 seller_email: null,
//                 seller_phone: null,
//                 warehouse_id: 1,
//                 new_warehouse_id: null,
//                 country_id: 1,
//                 state_id: 1,
//                 city_id: 1,
//                 address: null,
//                 video_url: null,
//                 category_type: null,
//                 status: "1",
//                 price_plan: null,
//                 mark_ad_agent: null,
//                 view: null,
//                 max_impression: null,
//                 user_id: null,
//                 relist: 0,
//                 is_closed: 1,
//                 winner_id: null,
//                 winner_payment: null,
//                 latitude: null,
//                 longitude: null,
//                 purchased_at: null,
//                 expired_at: "2019-10-15T16:00:00.000Z",
//                 created_at: "2019-10-09 12:09:30",
//                 updated_at: "2020-01-03 13:02:45",
//                 categories: null,
//                 warehouses: {
//                     id: 1,
//                     viewing_address: "Automart Mercury Ave. Cor C5 Ave",
//                     landmark: "Near Eastwood City",
//                     location: "Libis",
//                     direction_here: "",
//                     city_id: 3,
//                     country_id: 1,
//                     days_open: "M-F Whole Day; Sat-Half Day; By Appt Only",
//                     days_avail: "Mon,Tue,Wed,Thu,Fri,Sat.5",
//                     hours_open: "10AM-5PM",
//                     slots: "10am,11am,1pm,2pm,3pm,4pm",
//                     bank_id: 3,
//                     created_at: "2019-08-02 11:10:52",
//                     updated_at: null,
//                 },
//                 state: null,
//                 city: {
//                     id: 1,
//                     city_name: "Cebu",
//                     alternative_name: "cebu",
//                     state_id: 1,
//                     created_at: null,
//                     updated_at: null,
//                 },
//                 user: null,
//                 medias: [
//                     {
//                         id: 36,
//                         user_id: 1,
//                         ad_id: 8,
//                         post_id: null,
//                         media_name: "a3r767-1.jpg",
//                         url: null,
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:09:30",
//                     },
//                     {
//                         id: 37,
//                         user_id: 1,
//                         ad_id: 8,
//                         post_id: null,
//                         media_name: "a3r767-2.jpg",
//                         url: null,
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:09:30",
//                     },
//                     {
//                         id: 38,
//                         user_id: 1,
//                         ad_id: 8,
//                         post_id: null,
//                         media_name: "a3r767-3.jpg",
//                         url: null,
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:09:30",
//                     },
//                     {
//                         id: 39,
//                         user_id: 1,
//                         ad_id: 8,
//                         post_id: null,
//                         media_name: "a3r767-4.jpg",
//                         url: null,
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:09:30",
//                     },
//                     {
//                         id: 40,
//                         user_id: 1,
//                         ad_id: 8,
//                         post_id: null,
//                         media_name: "a3r767-5.jpg",
//                         url: null,
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:09:30",
//                     },
//                 ],
//             },
//             {
//                 id: 9,
//                 title: " A4F405 Toyota Hiace Commuter",
//                 slug: "a4f405",
//                 description:
//                     " A4F405 Toyota Hiace Commuter Diesel Manual Lasang  Davao",
//                 category_id: null,
//                 buy_now_price: null,
//                 sub_category_id: null,
//                 brand_id: null,
//                 type: null,
//                 ad_condition: null,
//                 model: null,
//                 buy_now_type: null,
//                 price: 834000,
//                 price_order: 834000,
//                 is_negotiable: null,
//                 is_featured: 0,
//                 seller_name: null,
//                 seller_email: null,
//                 seller_phone: null,
//                 warehouse_id: 2,
//                 new_warehouse_id: null,
//                 country_id: 1,
//                 state_id: 1,
//                 city_id: 1,
//                 address: null,
//                 video_url: null,
//                 category_type: null,
//                 status: "1",
//                 price_plan: null,
//                 mark_ad_agent: null,
//                 view: 41,
//                 max_impression: null,
//                 user_id: null,
//                 relist: 0,
//                 is_closed: 1,
//                 winner_id: null,
//                 winner_payment: null,
//                 latitude: null,
//                 longitude: null,
//                 purchased_at: null,
//                 expired_at: "2019-11-06T01:00:58.000Z",
//                 created_at: "2019-10-09 12:09:30",
//                 updated_at: "2020-01-03 13:02:45",
//                 categories: null,
//                 warehouses: {
//                     id: 2,
//                     viewing_address:
//                         "Davao Starplus Near Task Force  Licanan, Bunawan, Davao City",
//                     landmark: "N/A",
//                     location: "Lasang",
//                     direction_here: "https://goo.gl/maps/1MUDz4msGL4yZsPB6",
//                     city_id: 5,
//                     country_id: 1,
//                     days_open: "Mon, Wed, Fri",
//                     days_avail: "Mon,Wed,Fri",
//                     hours_open: "9AM - 5PM",
//                     slots: "9am,10am,11am,1pm,2pm,3pm,4pm",
//                     bank_id: 3,
//                     created_at: "2019-09-02 06:22:52",
//                     updated_at: "2019-12-17 16:53:58",
//                 },
//                 state: null,
//                 city: {
//                     id: 1,
//                     city_name: "Cebu",
//                     alternative_name: "cebu",
//                     state_id: 1,
//                     created_at: null,
//                     updated_at: null,
//                 },
//                 user: null,
//                 medias: [
//                     {
//                         id: 41,
//                         user_id: 1,
//                         ad_id: 9,
//                         post_id: null,
//                         media_name: "a4f405-1.jpg",
//                         url: "https://automart-dev.sgp1.digitaloceanspaces.com/a4f405/a4f405-1.jpg",
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:40:08",
//                     },
//                     {
//                         id: 42,
//                         user_id: 1,
//                         ad_id: 9,
//                         post_id: null,
//                         media_name: "a4f405-2.jpg",
//                         url: "https://automart-dev.sgp1.digitaloceanspaces.com/a4f405/a4f405-2.jpg",
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:40:08",
//                     },
//                     {
//                         id: 43,
//                         user_id: 1,
//                         ad_id: 9,
//                         post_id: null,
//                         media_name: "a4f405-3.jpg",
//                         url: "https://automart-dev.sgp1.digitaloceanspaces.com/a4f405/a4f405-3.jpg",
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:40:08",
//                     },
//                     {
//                         id: 44,
//                         user_id: 1,
//                         ad_id: 9,
//                         post_id: null,
//                         media_name: "a4f405-4.jpg",
//                         url: null,
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:09:30",
//                     },
//                     {
//                         id: 45,
//                         user_id: 1,
//                         ad_id: 9,
//                         post_id: null,
//                         media_name: "a4f405-5.jpg",
//                         url: null,
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:09:30",
//                     },
//                 ],
//             },
//             {
//                 id: 10,
//                 title: " A4H691 Toyota Hiace Commuter",
//                 slug: "a4h691",
//                 description:
//                     " A4H691 Toyota Hiace Commuter Diesel Manual Libis  QC",
//                 category_id: null,
//                 buy_now_price: null,
//                 sub_category_id: null,
//                 brand_id: null,
//                 type: null,
//                 ad_condition: null,
//                 model: null,
//                 buy_now_type: null,
//                 price: 990000,
//                 price_order: 990000,
//                 is_negotiable: null,
//                 is_featured: 0,
//                 seller_name: null,
//                 seller_email: null,
//                 seller_phone: null,
//                 warehouse_id: 1,
//                 new_warehouse_id: null,
//                 country_id: 1,
//                 state_id: 1,
//                 city_id: 1,
//                 address: null,
//                 video_url: null,
//                 category_type: null,
//                 status: "1",
//                 price_plan: null,
//                 mark_ad_agent: null,
//                 view: 154,
//                 max_impression: null,
//                 user_id: null,
//                 relist: 0,
//                 is_closed: 1,
//                 winner_id: null,
//                 winner_payment: null,
//                 latitude: null,
//                 longitude: null,
//                 purchased_at: null,
//                 expired_at: "2019-11-06T01:00:58.000Z",
//                 created_at: "2019-10-09 12:09:30",
//                 updated_at: "2020-01-03 13:02:45",
//                 categories: null,
//                 warehouses: {
//                     id: 1,
//                     viewing_address: "Automart Mercury Ave. Cor C5 Ave",
//                     landmark: "Near Eastwood City",
//                     location: "Libis",
//                     direction_here: "",
//                     city_id: 3,
//                     country_id: 1,
//                     days_open: "M-F Whole Day; Sat-Half Day; By Appt Only",
//                     days_avail: "Mon,Tue,Wed,Thu,Fri,Sat.5",
//                     hours_open: "10AM-5PM",
//                     slots: "10am,11am,1pm,2pm,3pm,4pm",
//                     bank_id: 3,
//                     created_at: "2019-08-02 11:10:52",
//                     updated_at: null,
//                 },
//                 state: null,
//                 city: {
//                     id: 1,
//                     city_name: "Cebu",
//                     alternative_name: "cebu",
//                     state_id: 1,
//                     created_at: null,
//                     updated_at: null,
//                 },
//                 user: null,
//                 medias: [
//                     {
//                         id: 46,
//                         user_id: 1,
//                         ad_id: 10,
//                         post_id: null,
//                         media_name: "a4h691-1.jpg",
//                         url: "https://automart-dev.sgp1.digitaloceanspaces.com/a4h691/a4h691-1.jpg",
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:40:08",
//                     },
//                     {
//                         id: 47,
//                         user_id: 1,
//                         ad_id: 10,
//                         post_id: null,
//                         media_name: "a4h691-2.jpg",
//                         url: "https://automart-dev.sgp1.digitaloceanspaces.com/a4h691/a4h691-2.jpg",
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:40:08",
//                     },
//                     {
//                         id: 48,
//                         user_id: 1,
//                         ad_id: 10,
//                         post_id: null,
//                         media_name: "a4h691-3.jpg",
//                         url: "https://automart-dev.sgp1.digitaloceanspaces.com/a4h691/a4h691-3.jpg",
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:40:08",
//                     },
//                     {
//                         id: 49,
//                         user_id: 1,
//                         ad_id: 10,
//                         post_id: null,
//                         media_name: "a4h691-4.jpg",
//                         url: null,
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:09:30",
//                     },
//                     {
//                         id: 50,
//                         user_id: 1,
//                         ad_id: 10,
//                         post_id: null,
//                         media_name: "a4h691-5.jpg",
//                         url: null,
//                         type: "image",
//                         is_feature: null,
//                         storage: "do_spaces",
//                         ref: "ad",
//                         created_at: "2019-10-09 12:09:30",
//                         updated_at: "2019-10-09 12:09:30",
//                     },
//                 ],
//             },
//         ],
//     },
// };

export const FetchList = async (query) => {
    // const {page, limit} = query;
    console.log("Fetching List..");
    try {
        // const data = await useFetch(
        //     `${process.env.REACT_APP_API_URL}/ads?page=${page}&limit=${limit}`
        // );
        // NOTE: changed this to have a dynamic param on query
        const data = await useFetch(
            `${process.env.REACT_APP_API_URL}/ads`,
            query
        );
        console.log("Done Fetching List");
        message.success(data.message);
        data.data.data = data.data.data.map((x, i) => ({ ...x, key: i }));
        return data.data;
    } catch (err) {
        console.log("ERROR: ", err.stack);
        message.error(err.message);
        return null;
    }
};
