import { useFetch } from "../../hooks/useFetch";

export const SearchAndFilterUser = async (query) => {
  try {
    const data = await useFetch(
      `${process.env.REACT_APP_API_URL}/users/search`,
      query
    );
    for (const item of data.data.data) {
      item["key"] = item.id;
    }

    return data.data;
  } catch (err) {
    console.log("ERROR: ", err.stack);
    return null;
  }
};
