import React from "react";
import { ModulesTableComponent } from "../../ModulesTableComponent";
import useGetRemarksIndexListColumns from "../../../hooks/remarks_index/useGetRemarksIndexListColumns";
import useColumnFilter from "../../../hooks/useColumnFilter";

export const RemarksIndexListComponent = (props) => {
  const {
    data = { data: [], total: 0 },
    onPaginationChange,
    currentPagination,
    onSelection,
    selectedRowKey,
    onSortChange,
    columnFilter,
    setColumnFilter,
    tableLoading,
    onClickRestoreRemarksIndexButton,
    rolesRestriction,
  } = props;

  const reviewsColumn = useGetRemarksIndexListColumns(
    onClickRestoreRemarksIndexButton,
    rolesRestriction
  );

  const { getColumnFilterProps } = useColumnFilter(
    columnFilter,
    setColumnFilter
  );

  let newCols = [];

  for (let i = 0, n = reviewsColumn.length; i < n; i++) {
    if (reviewsColumn[i].title !== "Actions") {
      newCols.push({
        ...reviewsColumn[i],
        sorter: true,
        ...getColumnFilterProps(reviewsColumn[i].dataIndex),
      });
    }
  }

  return (
    <div>
      <ModulesTableComponent
        data={data}
        onPaginationChange={onPaginationChange}
        onSelection={onSelection}
        selectedRowKey={selectedRowKey}
        tableColumns={newCols || []}
        currentPagination={currentPagination}
        columnFilter={columnFilter}
        setColumnFilter={setColumnFilter}
        onSortChange={onSortChange}
        tableLoading={tableLoading}
      />
    </div>
  );
};
