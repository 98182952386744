import { Tabs, message, Divider, PageHeader } from "antd";
import { useState, useEffect, useRef } from "react";
import ViewList from "../sub_components/edit_ads/ViewList";
import GeneralData from "../sub_components/edit_ads/GeneralData";
import ImageData from "../sub_components/edit_ads/ImageData";
import AdsNavBar from "../sub_components/edit_ads/AdsNavBar";
import { SearchSlug } from "../../api/edit_ads/SearchSlug";
import { FetchList } from "../../api/edit_ads/FetchList";
import { useParams } from "react-router-dom";
import { useComponentDidUpdate } from "../../hooks/useComponentDidUpdate";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import "../../dist/style/ads_editor.css";
import { ModulesSelectAndColumnTagsComponent } from "../ModulesSelectAndColumnTagsComponent";

const { TabPane } = Tabs;

export const EditAdsComponent = () => {
  let { slug } = useParams();
  //text input to search bar
  const [searchInput, setSearchInput] = useState("");
  //slug to search upon click on search button (mainly used for searched-data pagination)
  const [textToSearch, setTextToSearch] = useState("");
  //searched data based on search slug input
  const [searchedData, setSearchedData] = useState({});
  const [pageData, setPageData] = useState({
    total: 100,
    page: 1,
    perPage: 10,
    data: [],
  });
  //the selected data to edit
  const [toEditData, setToEditData] = useState({});

  const [mediaApi, setMediaApi] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [activeKey, setActiveKey] = useState({
    parent: "1",
    editAds: "2",
  });
  const [columnFilter, setColumnFilter] = useState([]);
  const sortColumn = useRef(null);
  const sortType = useRef("asc");

  const [isShowColumnFilter, setIsShowColumnFilter] = useState(false);
  const [columnFilterTags, setColumnFilterTags] = useState([]);

  const setSortColumn = (value) => {
    sortColumn.current = value;
  };

  const setSortType = (value) => {
    if (value === "descend") {
      sortType.current = "desc";
    } else if (value === "ascend") {
      sortType.current = "asc";
    } else {
      sortType.current = "none";
    }
  };

  const onSortChange = async (sortCol, sortType) => {
    setSortColumn(sortCol);
    setSortType(sortType);

    await fetchListData(pageData.page, pageData.perPage);
  };

  ///////////////////////////////
  // for column filter tags
  const onCloseColumnFilterTag = (id) => {
    setColumnFilterTags((prevState) => {
      return prevState.filter((item) => item.id !== id);
    });

    setColumnFilter((prevState) => {
      let newData = prevState.filter((item) => {
        return Array.isArray(item.id)
          ? JSON.stringify(item.id) != id
          : item.id != id;
      });

      return newData;
    });

    if (!columnFilterTags.length) {
      setIsShowColumnFilter(false);
    }
  };

  const onLoadColumnFilterTags = () => {
    if (columnFilter.length) {
      let filterTags = columnFilter.map((e) => {
        let [label, tagId] = Array(2).fill(e.id);

        if (Array.isArray(e.id)) {
          tagId = JSON.stringify(tagId);
          label = "";

          for (let i = 0, n = e.id.length; i < n; i++) {
            label += `${e.id[i]} `;
          }
        }

        return {
          color: "processing",
          closable: true,
          selection_label: label,
          value: e.value,
          id: tagId,
          onClose: onCloseColumnFilterTag,
        };
      });

      setColumnFilterTags(filterTags);
      setIsShowColumnFilter(true);
    } else {
      // if columnFilter is just blank, then remove the tags
      setColumnFilterTags([]);
      setIsShowColumnFilter(false);
    }
  };

  const onClearColumnFilters = () => {
    setColumnFilterTags([]);
    setIsShowColumnFilter(false);
    setColumnFilter([]);
  };

  useEffect(() => {
    if (typeof slug !== "undefined") {
      onSearch(slug, true);
    }
    fetchListData(1, 10);
  }, []);

  useComponentDidUpdate(() => {
    fetchListData(1, pageData.perPage);
    onLoadColumnFilterTags();
  }, [columnFilter]);

  async function onSearch(inputToSearch, directEdit) {
    if (inputToSearch.length < 3)
      return message.error(`Search input must be minimum of 3 characters`, 5);
    setLoadingList(true);
    const query = { page: 1, limit: 10, slug: inputToSearch };

    if (sortColumn) {
      query["sortColumn"] = sortColumn.current;
    }

    if (sortType) {
      query["sortType"] = sortType.current;
    }

    if (columnFilter && columnFilter.length) {
      query["columnFilter"] = columnFilter;
    }

    const data = await SearchSlug(query);
    if (data.data) {
      setSearchedData(data);
      setTextToSearch(inputToSearch);
      setSearchInput(inputToSearch);
      directEdit && data.data.length == 1 && setToEditData(data.data[0]);
      directEdit &&
        data.data.length == 1 &&
        setActiveKey((prevState) => ({ ...prevState, parent: "2" }));
    }
    setLoadingList(false);
  }

  async function fetchListData(page, limit) {
    //set loading state to true
    setLoadingList(true);
    //define the function to use for displaying data; if textToSearch is present, use slug search function, else, use fetch all function
    const fetchFunction = textToSearch ? SearchSlug : FetchList;
    const dataSetterFunction = textToSearch ? setSearchedData : setPageData;
    const queries = textToSearch
      ? { page, limit, slug: textToSearch }
      : { page, limit };

    if (sortColumn) {
      queries["sortColumn"] = sortColumn.current;
    }

    if (sortType) {
      queries["sortType"] = sortType.current;
    }

    if (columnFilter && columnFilter.length) {
      queries["columnFilter"] = columnFilter;
    }

    //now do the work..
    const data = await fetchFunction(queries);

    pageData.page = page;

    dataSetterFunction(data);
    setLoadingList(false);
  }
  const toDisplayData = textToSearch ? searchedData : pageData;
  const paginationProps = {
    current: toDisplayData.page,
    pageSize: toDisplayData.perPage,
    total: toDisplayData.total,
    position: ["bottomCenter"],
    onChange: (page, limit) => {
      fetchListData(page, limit);
    },
  };
  return (
    <>
      <PageHeader
        title="Ads"
        subTitle="ADS MANAGEMENT"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["Ads"]} />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <Tabs
          tabPosition="top"
          activeKey={activeKey.parent}
          onTabClick={(key) => {
            setActiveKey((prevState) => ({ ...prevState, parent: key }));
          }}
          size="small"
        >
          <TabPane tab="View List" key="1">
            <AdsNavBar
              hasPageData={pageData.data.length > 0}
              textToSearch={textToSearch}
              searchInput={searchInput}
              onSearch={onSearch}
              setSearchInput={setSearchInput}
              setTextToSearch={setTextToSearch}
              fetchListData={fetchListData}
              loadingList={loadingList}
            />
            <ModulesSelectAndColumnTagsComponent
              onClearColumnFilters={onClearColumnFilters}
              isShowColumnFilterTags={isShowColumnFilter}
              columnFilterTags={columnFilterTags}
            />
            <br />
            <ViewList
              setActiveKey={setActiveKey}
              loadingList={loadingList}
              setToEditData={setToEditData}
              paginationProps={paginationProps}
              data={toDisplayData.data}
              onSortChange={onSortChange}
              columnFilter={columnFilter}
              setColumnFilter={setColumnFilter}
            />
          </TabPane>
          <TabPane tab="Edit Item" key="2">
            <Tabs
              activeKey={activeKey.editAds}
              onTabClick={(key) => {
                setActiveKey((prevState) => ({
                  ...prevState,
                  editAds: key,
                }));
              }}
            >
              <TabPane tab="General Info" key="1">
                <GeneralData
                  toEditData={toEditData}
                  onSearch={onSearch}
                  loadingList={loadingList}
                />
              </TabPane>
              <TabPane tab="Images" key="2">
                <ImageData
                  toEditData={toEditData}
                  mediaApi={mediaApi}
                  setMediaApi={setMediaApi}
                  onSearch={onSearch}
                  setActiveKey={setActiveKey}
                  loadingList={loadingList}
                />
              </TabPane>
            </Tabs>
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};
