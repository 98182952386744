import React from "react";
import { Button } from "antd";
import { FormOutlined } from "@ant-design/icons";

export const PlatformFunctionalitiesComponent = (props) => {
  const { createPlatformOnClick, rolesRestrictions = {} } = props;
  const { CREATE_PLATFORM = 0 } = rolesRestrictions;
  return (
    <div className="btn-layer-div">
      <Button
        type="primary"
        size="middle"
        icon={<FormOutlined />}
        onClick={createPlatformOnClick}
        hidden={CREATE_PLATFORM === 0 ? true : false}
      >
        Create Platform
      </Button>
    </div>
  );
};
