import React from "react";
import { Form, Select, Input, Divider, Row, Col } from "antd";
import { SelectOptionFooterExtraButtonComponent } from "../../SelectOptionFooterExtraButtonComponent";
import { FormRules } from "../../../dist/functions/formRules";

export const WarehouseGenralInformationFormComponent = ({
  banks = { data: [], total: 0 },
  specialists = { data: [], total: 0 },
  cities = [],
  barangays = [],
  provinces = [],
  providers = [],
  onClickShowMoreBanks,
  onClickShowMoreAdviser,
  isProvinceFetchingLoading,
  isCityFetchingLoading,
  onChangeProvince,
  onChangeCity,
  onChangeBarangay,
  oldCities = [], //old city data
}) => {
  const {
    REQUIRED_FULL_ADDRESS,
    REQUIRED_LANDMARK,
    REQUIRED_BANK,
    REQUIRED_ADVISERS,
  } = FormRules;

  const { Option } = Select;
  return (
    <Row gutter={[16, 8]} align={"top"}>
      <Col span={12}>
        <Form.Item
          name="viewing_address"
          label="Street / Warehouse Name"
          rules={[REQUIRED_FULL_ADDRESS]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Address" style={{ width: "100%" }}>
          <Input.Group compact style={{ width: "100%" }}>
            <Form.Item
              name={["address", "province_id"]}
              label="Province"
              rules={[{ required: true, message: "Province is required" }]}
              style={{ width: "33.3%" }}
            >
              <Select
                placeholder="Select province"
                disabled={isProvinceFetchingLoading}
                onChange={(province_id) =>
                  onChangeProvince(province_id, provinces)
                }
                style={{ width: "100%" }}
              >
                {provinces.map((item) => (
                  <Option value={item.province_id}>
                    {item?.province_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name={["address", "city__id"]}
              label="City"
              rules={[{ required: true, message: "City is required" }]}
              style={{ width: "33.3%" }}
            >
              <Select
                placeholder="Select city"
                disabled={isProvinceFetchingLoading || isCityFetchingLoading}
                onChange={(id) => onChangeCity(id, cities)}
                style={{ width: "100%" }}
              >
                {cities.map((item) => (
                  <Option value={item.id}>{item?.city_name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name={["address", "barangay_id"]}
              label="Barangay"
              rules={[{ required: true, message: "Barangay is required" }]}
              style={{ width: "33.3%" }}
            >
              <Select
                placeholder="Select Barangay"
                style={{ width: "100%" }}
                onChange={(id) => onChangeBarangay(id, barangays)}
              >
                {barangays.map((item) => (
                  <Option value={item.barangay_id}>
                    {item?.barangay_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Input.Group>
          <Input.Group compact style={{ width: "100%" }}>
            <Form.Item
              name={["address", "location"]}
              label="Location"
              help="Note: This field is to be depreciated on the next version"
              style={{ width: "50%" }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={["address", "city_id"]}
              label="City"
              help="Note: This field is to be depreciated on the next version"
              rules={[{ required: true, message: "City is required" }]}
              style={{ width: "50%" }}
            >
              <Select placeholder="Select City" style={{ width: "100%" }}>
                {oldCities.map((item) => (
                  <Option value={item.id}>{item?.city_name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Input.Group>
          <br />
          <Form.Item
            label="Additional Location Information"
            style={{ width: "100%" }}
          >
            <Input.Group compact style={{ width: "100%" }}>
              <Form.Item
                name="landmark"
                label="Nearest Landmark"
                rules={[REQUIRED_LANDMARK]}
                style={{ width: "50%" }}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="direction_here"
                label="Google Map Url"
                style={{ width: "50%" }}
              >
                <Input />
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Input.Group compact style={{ width: "100%" }}>
          <Form.Item
            name="bank_id"
            label="Bank"
            rules={[REQUIRED_BANK]}
            style={{ width: "50%" }}
          >
            <Select
              placeholder="Select Bank"
              virtual={true}
              dropdownRender={(menu) => (
                <SelectOptionFooterExtraButtonComponent
                  key="show_more"
                  menu={menu}
                  onClickShowMoreList={onClickShowMoreBanks}
                  isClickShowMoreDisabled={
                    banks.data.length < banks.total ? false : true
                  }
                />
              )}
              style={{ width: "100%" }}
            >
              {banks.data.map((item) => (
                <Option id={item.id} key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            key="specialists"
            name="specialists"
            label="Adviser(s)"
            rules={[REQUIRED_ADVISERS]}
            style={{ width: "50%" }}
          >
            <Select
              mode="multiple"
              placeholder="Select Adviser(s)"
              virtual={true}
              dropdownRender={(menu) => (
                <SelectOptionFooterExtraButtonComponent
                  key="show_more"
                  menu={menu}
                  onClickShowMoreList={onClickShowMoreAdviser}
                  isClickShowMoreDisabled={
                    specialists.data.length < specialists.total ? false : true
                  }
                />
              )}
              style={{ width: "100%" }}
            >
              {specialists.data.map((item) => (
                <Option
                  id={item.id}
                  key={item.id}
                  value={item.id}
                >{`${item.first_name} ${item.last_name}`}</Option>
              ))}
            </Select>
          </Form.Item>
        </Input.Group>
        <Form.Item
          name="provider_id"
          label="Provider"
          rules={[{ required: true, message: "provider is required" }]}
          style={{ width: "50%" }}
        >
          <Select placeholder="Select Provider" style={{ width: "100%" }}>
            {providers.map((item) => (
              <Option
                id={item.provider_id}
                key={item.provider_id}
                value={item.provider_id}
              >
                {item.provider_name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Divider />
        <Form.Item label="Schedule Information" style={{ width: "100%" }}>
          <Input.Group compact style={{ width: "100%" }}>
            <Form.Item
              name="days_avail"
              label="Days Avail"
              style={{ width: "70%" }}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="hours_open"
              label="Hours Open"
              style={{ width: "30%" }}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          </Input.Group>
        </Form.Item>
      </Col>
    </Row>
  );
};
