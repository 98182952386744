import { useState } from "react";
import axios from "axios";
import { message } from "antd";

const useUpdateWarehouse = () => {
  const [loading, setLoading] = useState(false);

  const updateWarehouse = async (form, warehouseId) => {
    try {
      setLoading(true);
      const payload = await form.validateFields();
      const { address } = payload;

      const _payload = {
        bank_id: payload.bank_id,
        direction_here: payload.direction_here,
        landmark: payload.landmark,
        days_avail: payload.days_avail,
        days_open: payload.days_avail,
        hours_open: payload.hours_open,
        viewing_address: payload.viewing_address,
        ...address,
      };

      const resp = await axios.put(
        `${process.env.REACT_APP_API_URL}/warehouses/${warehouseId}`,
        _payload,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      form.resetFields();
      setLoading(false);

      if (resp.status === 200) {
        message.success("Successfuly Updated Warehouse General Information", 3);
        return true;
      }

      message.error("Something went wrong, please try again later", 2);
      return false;
    } catch (err) {
      console.log("error:", err.stack);
    }
  };

  return { updateWarehouse, warehouseUpdateLoading: loading };
};

export default useUpdateWarehouse;
