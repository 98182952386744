export const validateData = (value, defaultReturnValue) => {
  return typeof value !== "undefined" ? value : defaultReturnValue;
};

export const returnBuyNowWinnersPermissions = async () => {
  const restrictions = {};
  const permissions = JSON.parse(localStorage.getItem("permissions"));

  const GENERATE_BUY_NOW_WINNERS_REPORT = permissions.find(
    (item) => item.name === "GENERATE_BUY_NOW_WINNERS_REPORT"
  );

  const UPDATE_BUY_NOW_WINNERS_STATUS = permissions.find(
    (item) => item.name === "UPDATE_BUY_NOW_WINNERS_STATUS"
  );

  restrictions["GENERATE_BUY_NOW_WINNERS_REPORT"] =
    typeof GENERATE_BUY_NOW_WINNERS_REPORT === "undefined"
      ? 0
      : GENERATE_BUY_NOW_WINNERS_REPORT.has_access;

  restrictions["UPDATE_BUY_NOW_WINNERS_STATUS"] =
    typeof UPDATE_BUY_NOW_WINNERS_STATUS === "undefined"
      ? 0
      : UPDATE_BUY_NOW_WINNERS_STATUS.has_access;

  return restrictions;
};
