import React from "react";
import { Form, Modal, Input, InputNumber, Select, Radio } from "antd";
import { SaveOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { SelectOptionFooterExtraButtonComponent } from "./SelectOptionFooterExtraButtonComponent";

const { TextArea } = Input;
const { Option } = Select;
export const ModalwithFormComponent = (props) => {
  const {
    title,
    form,
    formLayout,
    formInputsComponent,
    onOk,
    onCancel,
    destroyOnClose,
    okText,
    cancelText,
    visible,
    readOnly = false,
  } = props;

  return (
    <>
      <Modal
        title={title}
        onOk={onOk}
        onCancel={onCancel}
        destroyOnClose={destroyOnClose}
        visible={visible}
        okText={okText}
        cancelText={cancelText}
        okButtonProps={{
          icon: <SaveOutlined />,
        }}
        cancelButtonProps={{
          icon: <ArrowLeftOutlined />,
        }}
      >
        <Form form={form} layout={formLayout}>
          {formInputsComponent
            ? formInputsComponent.map((item, index) => (
                <Form.Item
                  key={item.name}
                  name={item.name}
                  label={item.label}
                  rules={item.configs.rules}
                >
                  {item.configs.type === "input" ? (
                    <Input disabled={readOnly} key={index} />
                  ) : item.configs.type === "input_password" ? (
                    <Input disabled={readOnly} type="password" key={index} />
                  ) : item.configs.type === "input_number" ? (
                    <InputNumber disabled={readOnly} key={index} />
                  ) : item.configs.type === "text_area" ? (
                    <TextArea
                      disabled={readOnly}
                      key={index}
                      rows={item.configs.text_area_row}
                    />
                  ) : item.configs.type === "select" ? (
                    <Select
                      disabled={readOnly}
                      key={index}
                      placeholder={item.configs.placeholder}
                      dropdownRender={(menu) => (
                        <SelectOptionFooterExtraButtonComponent
                          key="show_more"
                          menu={menu}
                          onClickShowMoreList={item.onClickShowMoreList}
                          isClickShowMoreDisabled={
                            item.configs.list_value.length < item.configs.total
                              ? false
                              : true
                          }
                        />
                      )}
                    >
                      {item.configs.list_value.map((item) => (
                        <Option id={item.id} key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  ) : item.configs.type === "radio" ? (
                    <Radio.Group>
                      {item.configs.list_value.map((item, index) => (
                        <Radio key={index} id={item.id} value={item.id}>
                          {item.name}
                        </Radio>
                      ))}
                    </Radio.Group>
                  ) : null}
                </Form.Item>
              ))
            : null}
        </Form>
      </Modal>
    </>
  );
};
