import { CreateFAQ } from "../../api/faq/CreateFAQ";
import { message } from "antd";

const useCreateFAQ = async (payload) => {
  const data = { data: payload };
  const response = await CreateFAQ(data);
  if (response.status === 201) {
    await message.success("Successfuly Created FAQs", 2);
    return true;
  }

  await message.error("Error Creating FAQ", 2);
  return false;
};

export default useCreateFAQ;
