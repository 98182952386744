import {
  Card,
  Button,
  Popconfirm,
  Modal,
  Image,
  Spin,
  message,
  Switch,
  Typography,
  Space,
} from "antd";
import {
  DeleteOutlined,
  LoadingOutlined,
  StarOutlined,
  StarFilled,
  ExclamationCircleOutlined,
  CloudUploadOutlined,
  FileAddOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useState, useEffect, useRef } from "react";
import { UpdateFeature } from "../../../api/edit_ads/UpdateFeature";
import { UpdateGeneralInfo } from "../../../api/edit_ads/UpdateGeneralInfo";
import { DeleteImage } from "../../../api/edit_ads/DeleteImage";
import { ImageBatchUpload } from "../../../api/edit_ads/ImageBatchUpload";
import MetaDataFields from "./MetaDataFields";

//custom hook
import useClassName from "../../../hooks/useClassName";

//for entries without image url, use placeholder
const noImage = "/no_image_available.svg";

const platform = process.env.REACT_APP_PLATFORM;
const { Title } = Typography;
const vehicle = platform == "automart" ? "car" : "motor";

//confirmation window
function confirm(onOk, content) {
  Modal.confirm({
    title: "Confirm",
    icon: <ExclamationCircleOutlined />,
    content,
    onOk,
  });
}

//helpers
const generateMediaApiOnSearch = (toEditData, setMediaApi) => {
  console.log("Setting mediaApi on search...");

  const initialMediaApi = toEditData.medias.map((d) => {
    //test if filename is old or new format
    //if new, just return it
    //if old, reconstruct it to new
    const splitMediaName = d.media_name.split("-");
    const file_name =
      splitMediaName.length > 2
        ? d.media_name
        : `used-${toEditData[vehicle].model.make.name}-${toEditData[vehicle].model.name}-${d.media_name}`;
    return {
      id: d.id,
      slug: toEditData.slug,
      preview: d.url || noImage,
      file_name,
      transaction_type: null,
      is_feature: d.is_feature || "0",
      metadata:
        d.meta != "No Metadata"
          ? Object.entries(d.meta.Metadata).map((x) => ({
              name: x[0],
              value: x[1],
            }))
          : [],
      cloudState: {
        preview: d.url,
      },
    };
  });
  setMediaApi(() => initialMediaApi);
};
const getBase64Array = (files) => {
  //convert files to base64
  const promises = files.map((file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => reject(error);
    });
  });
  //resolve all and return
  return Promise.all(promises);
};

function PopConfirmDynamic({ children, title, onConfirm, disabled }) {
  return (
    <Popconfirm
      title={title}
      onConfirm={onConfirm}
      okText="Yes"
      cancelText="No"
      disabled={disabled}
    >
      {children}
    </Popconfirm>
  );
}
export default function ImageData({
  toEditData,
  onSearch,
  mediaApi,
  setMediaApi,
  loadingList,
}) {
  //react hooks___________________________________
  const [toUpdateImageIndex, setToUpdateImageIndex] = useState();
  const [progressInfo, setProgressInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const inputFileSingle = useRef(null);
  const inputFileMultiple = useRef(null);
  useEffect(() => {
    console.log("New Data searched, resetting the image values...");

    //guard clause || return if no data found | error handling
    if (!toEditData || !toEditData[vehicle])
      return console.log("No data found for toEditData[vehicle]");

    setMediaApi(() => []);
    toEditData &&
      toEditData.medias &&
      generateMediaApiOnSearch(toEditData, setMediaApi);
  }, [toEditData]);
  //custom hooks
  const getClassName = useClassName();

  //guard clause || default return if no data found
  if (!toEditData || !toEditData[vehicle])
    return (
      <>
        <h2>Images Information Section</h2>
        <p>Vehicle's media images will show here.</p>
      </>
    );

  const ProgressDialog = () => {
    const checkIfAllDone = () => progressInfo.every((x) => x.done);
    const successCount = progressInfo.filter(
      (x) => x.status == "success"
    ).length;
    const failedCount = progressInfo.filter((x) => x.status == "failed").length;
    const textColors = {
      success: "green",
      processing: "yellow",
      failed: "red",
      pending: "blue",
    };
    return (
      <div className="progress-dialog-background">
        <div className="progress-dialog-foreground">
          <div>
            <div
              style={{
                color: successCount > 0 ? textColors.success : "black",
              }}
            >
              Success: {successCount} / {progressInfo.length}
            </div>
            <div
              style={{
                color: failedCount > 0 ? textColors.failed : textColors.success,
              }}
            >
              Failed: {failedCount}
            </div>
          </div>
          <div className="progress-items-container">
            {progressInfo.map(({ base64Preview, percent, status }, i) => {
              return (
                <div
                  className={getClassName("progress-item")}
                  key={i}
                  style={{ color: textColors[status] }}
                >
                  <img src={base64Preview} />
                  {/* <div> */}
                  {/* <p>{percent}%</p> */}
                  {/* <p> */}
                  {status == "success"
                    ? "Success"
                    : status == "processing"
                    ? "Processing.."
                    : status == "failed"
                    ? "Failed"
                    : "Pending"}
                  {/* </p> */}
                  {/* </div> */}
                </div>
              );
            })}
          </div>
          <Button
            disabled={!checkIfAllDone()}
            block
            onClick={() => {
              setProgressInfo([]);
              onSearch(toEditData.slug, true);
            }}
          >
            Done
          </Button>
        </div>
      </div>
    );
  };

  //action calls
  const handleSetDefault = async (id, toUpdateDefaultItemIndex) => {
    setLoading(true);
    //if this item is not FOR_NEW_UPLOADING..
    if (
      mediaApi[toUpdateDefaultItemIndex].transaction_type != "FOR_NEW_UPLOADING"
    ) {
      //get same id from mediaApi and clone it using spread
      const newDataForFeature = {
        ...mediaApi.filter((x) => x.id == id)[0],
        is_feature: "1",
        ad_id: toEditData.id,
        transaction_type: "FOR_UPDATE_FEATURE",
      };

      const status = await UpdateFeature(newDataForFeature);

      if (status == 200) {
        setMediaApi((prevState) =>
          prevState.map((m) => {
            //change is_feature to "1" if same id
            if (m.id == id) {
              const newDataPreviousTransactionType = {
                ...m,
                is_feature: "1",
              };
              return newDataPreviousTransactionType;
            }
            //default: return with is_feature set to 0
            return { ...m, is_feature: "0" };
          })
        );
      }
    } else {
      setMediaApi((prevState) => {
        const newState = [...prevState];
        newState.map((entry, i) => {
          entry.is_feature = "0";
        });
        newState[toUpdateDefaultItemIndex].is_feature = "1";

        return newState;
      });
    }

    setLoading(false);
  };

  const handleDelete = async (index) => {
    setLoading(true);
    const { transaction_type } = mediaApi[index];
    if (transaction_type == "FOR_NEW_UPLOADING") {
      //if for new uploading, just delete the entry
      setMediaApi((prevState) => {
        return prevState.filter((m, i) => i != index);
      });
    } else {
      const { id, slug, file_name } = mediaApi[index];
      const payload = {
        id,
        slug,
        file_name,
        transaction_type: "FOR_DELETE",
        is_feature: "0",
      };
      const status = await DeleteImage(payload);
      //check here first if delete api is successful
      if (status == 200) {
        setMediaApi((prevState) => {
          //avoid mutation
          //remove the indexed item
          return [...prevState].filter((x, i) => i != index);
        });
      }
    }

    setLoading(false);
  };

  const handleSelectedImage = async (e, isNew) => {
    const files = [...e.target.files];

    const arrayOfBase64 = await getBase64Array(files);

    setMediaApi((prevState) => {
      const newState = [...prevState];
      //get all empty slots from newState
      const emptySlotsIndices = [];
      newState.map((x, i) => {
        x.preview == noImage && emptySlotsIndices.push(i);
      });

      if (isNew) {
        //construct api from array of files
        const newMediaApis = files.map((file, i) => {
          //if current index is inside the length of empty slots
          //then format as for_updating
          if (i < emptySlotsIndices.length) {
            return {
              ...newState[emptySlotsIndices[i]],
              file,
              preview: arrayOfBase64[i],
              transaction_type: "FOR_UPDATING",
              metadata: [
                {
                  name: "car-view",
                  value: "front/rear/left/right",
                },
              ],
            };
          } else {
            //else, format as for_new_uploading
            return {
              slug: toEditData.slug,
              file,
              file_name: `used-${toEditData[vehicle].model.make.name}-${toEditData[vehicle].model.name}`,
              preview: arrayOfBase64[i],
              transaction_type: "FOR_NEW_UPLOADING",
              is_feature: "0",
              metadata: [
                {
                  name: "car-view",
                  value: "front/rear/left/right",
                },
              ],
              cloudState: {},
            };
          }
        });

        newMediaApis.map((newMediaApi, i) => {
          if (i < emptySlotsIndices.length) {
            //for_updating
            newState[emptySlotsIndices[i]] = newMediaApi;
          } else {
            //new entries or for_new_uploading
            newState.push(newMediaApi);
          }
        });
        return newState;
      }
      //if not for_new_uploading then set it to for_updating, else leave it as is
      newState[toUpdateImageIndex].transaction_type =
        newState[toUpdateImageIndex].transaction_type != "FOR_NEW_UPLOADING"
          ? "FOR_UPDATING"
          : newState[toUpdateImageIndex].transaction_type;

      newState[toUpdateImageIndex].file = files[0];
      newState[toUpdateImageIndex].preview = arrayOfBase64[0];
      newState[toUpdateImageIndex].metadata = [
        { name: "car-view", value: "front/rear/left/right" },
      ];

      return newState;
    });

    /*  BUG FIX: reset the value so that the onChange
            will trigger upon new search
            when the same image is selected */
    e.target.value = "";
  };
  const handleBatchUpload = async () => {
    //for processing metadata
    const forms = [...document.querySelectorAll(".metadata-forms")];

    let metadataArray = [];

    const toMetadataArray = (form) => {
      const formData = new FormData(form);
      const inputValueArray = [];
      const returnObj = {};
      for (const pair of formData.entries()) {
        inputValueArray.push(pair[1]);
      }
      inputValueArray.map((v, i) => {
        if (i % 2 == 0) {
          returnObj[v] = inputValueArray[i + 1];
        }
      });
      metadataArray.push(returnObj);
    };

    //filter the cards
    const filteredMediaApi = mediaApi.filter((x, i) => {
      if (
        x.transaction_type == "FOR_NEW_UPLOADING" ||
        x.transaction_type == "FOR_UPDATING"
      ) {
        toMetadataArray(forms[i]);
        return true;
      }
    });

    const featuredImageCount = filteredMediaApi.filter(
      (item) => item.is_feature === "1"
    ).length;

    if (featuredImageCount === 0) {
      await message.warning("Please select featured image before uploading", 3);
      return;
    }

    //convert mediaApi to FormData
    const toFormData = filteredMediaApi.map((m, i) => {
      let formData = new FormData();
      formData.append("file", m.file);
      m.transaction_type == "FOR_UPDATING" && formData.append("id", m.id);
      formData.append("slug", m.slug);
      formData.append("file_name", m.file_name);
      formData.append("transaction_type", m.transaction_type);
      formData.append("is_feature", m.is_feature);

      formData.append("metadata", JSON.stringify(metadataArray[i]));

      return formData;
    });
    //show progress dialog
    setProgressInfo(
      filteredMediaApi.map((x) => ({
        base64Preview: x.preview,
        percent: 0,
        status: "pending",
        done: false,
      }))
    );

    ImageBatchUpload(toFormData, setProgressInfo);
  };

  //check if mediaApi has entries that are pending for upload
  const isUploadButtonEnabled = mediaApi.some(
    (m) =>
      m.transaction_type == "FOR_UPDATING" ||
      m.transaction_type == "FOR_NEW_UPLOADING"
  );
  const makeCards = () => {
    return mediaApi.map((m, i) => (
      <Card.Grid
        key={i}
        className={`${getClassName("card-grid")}${
          m.transaction_type == "FOR_NEW_UPLOADING"
            ? " card-grid-new"
            : m.transaction_type == "FOR_UPDATING"
            ? " card-grid-update"
            : ""
        }`}
      >
        <div className={getClassName("left-side-card")}>
          <div
            className="card-img-container"
            onClick={() => {
              setToUpdateImageIndex(() => i);
              inputFileSingle.current.click();
            }}
          >
            <Image
              className="card-img"
              src={m.preview}
              preview={false}
              placeholder={
                <Spin
                  size="large"
                  style={{
                    background: "white",
                    width: "100%",
                    height: "100%",
                  }}
                />
              }
            />
          </div>
          <div className="controls-style">
            {loading ? (
              <LoadingOutlined className="input-style" />
            ) : m.is_feature == "1" ? (
              <StarFilled className="input-style" style={{ color: "green" }} />
            ) : (
              <StarOutlined
                className="input-style"
                onClick={() => {
                  handleSetDefault(m.id, i);
                }}
              />
            )}
            <PopConfirmDynamic
              onConfirm={() => handleDelete(i)}
              title="Delete this image?"
              disabled={m.cloudState.preview === null}
            >
              {loading ? (
                <LoadingOutlined className="input-style" />
              ) : (
                <DeleteOutlined
                  style={{
                    color: m.cloudState.preview === null ? "#ddd" : "red",
                  }}
                  className="input-style"
                />
              )}
            </PopConfirmDynamic>
          </div>
        </div>
        <div className="right-side-card">
          <MetaDataFields
            metadata={m.metadata}
            cardIndex={i}
            setMediaApi={setMediaApi}
          />
        </div>
      </Card.Grid>
    ));
  };

  const LoadingCards = () => (
    <>
      <Card loading={true} className="card-loading" />
      <Card loading={true} className="card-loading" />
    </>
  );

  const showUpdateWreckConfirmation = (value) => {
    Modal.confirm({
      title: `Are you sure, you want to tag this unit as ${
        value ? "wrecked" : "not wrecked"
      }`,
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        console.log("OK", toEditData.id);
        if (!toEditData.id) {
          return;
        }

        await UpdateGeneralInfo(toEditData.id, { is_wrecked: value ? 1 : 0 });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  console.log(toEditData.is_wrecked, "is wreck");

  return (
    <>
      <h2>
        {toEditData[vehicle].year_model} {toEditData[vehicle].model.make.name}{" "}
        {toEditData[vehicle].model.name} {toEditData[vehicle].model.trim}{" "}
        {toEditData[vehicle].model.engine_displacement} | {toEditData.slug}
      </h2>
      <hr />

      <div className="image-data-cards-container">
        <Space size={"small"} align="start">
          <Title level={5}>Tag this unit as wrecked? : </Title>
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={toEditData.is_wrecked === 1 ? true : false}
            onChange={(e) => showUpdateWreckConfirmation(e)}
          />
        </Space>
        <input
          type="file"
          id="file-single"
          ref={inputFileSingle}
          accept="image/*"
          style={{ display: "none" }}
          onChange={(e) => handleSelectedImage(e)}
        />
        <input
          type="file"
          id="file-multiple"
          multiple
          ref={inputFileMultiple}
          accept="image/*"
          style={{ display: "none" }}
          onChange={(e) => handleSelectedImage(e, true)}
        />
        {loadingList ? <LoadingCards /> : makeCards()}
        {loadingList ? (
          <LoadingCards />
        ) : (
          mediaApi && (
            <div className={getClassName("image-upload-button")}>
              <div>
                <Button
                  type="dashed"
                  className="upload-new-image"
                  onClick={() => {
                    inputFileMultiple.current.click();
                  }}
                >
                  <FileAddOutlined />
                </Button>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "10px",
                  }}
                >
                  Add
                  <br />
                  Images
                </p>
              </div>
              <div>
                <Button
                  disabled={!isUploadButtonEnabled}
                  type="dashed"
                  className="upload-new-image"
                  onClick={() =>
                    confirm(handleBatchUpload, "Start uploading the updates?")
                  }
                >
                  <CloudUploadOutlined />
                </Button>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "10px",
                  }}
                >
                  Upload
                  <br />
                  Now
                </p>
              </div>
            </div>
          )
        )}
      </div>
      {progressInfo.length > 0 && <ProgressDialog />}
    </>
  );
}
