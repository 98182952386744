import axios from "axios";

export const useUpdate = async (url, payload = {}) => {
  try {
    const header = {
      headers: { Authorization: localStorage.getItem("token") },
    };
    const dataRes = await axios.put(url, payload, header);
    return dataRes;
  } catch (err) {
    return null;
  }
};
