import React from "react";
import { Row, Col, Form, Modal, Button } from "antd";
import { CarsGeneralFormCreationComponent } from "./CarsGeneralFormCreationComponent";
import { CarsSubInformationComponent } from "./CarsSubInformationFormCreationComponent";

export const CarsCreationComponent = (props) => {
  const {
    form,
    visible,
    currentStep,
    models = [],
    specialists = [],
    nextStep,
    prevStep,
    handleCarCreationModalVisibility,
    handleSubmit,
  } = props;

  const returnFooter = () => {
    return currentStep === 0
      ? [
          <Button key="close" onClick={handleCarCreationModalVisibility}>
            Return
          </Button>,
          <Button key="submit" type="primary" onClick={nextStep}>
            Next
          </Button>,
        ]
      : [
          <Button key="back" onClick={prevStep}>
            Back
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit}>
            Submit
          </Button>,
        ];
  };

  return (
    <>
      <Modal title="Car Creation" visible={visible} footer={returnFooter()}>
        <Form form={form} layout="vertical" name="cars">
          <Row>
            <Col span={24}>
              <div style={{ display: currentStep > 0 ? "none" : null }}>
                <CarsGeneralFormCreationComponent models={models} />
              </div>
              <div style={{ display: currentStep < 1 ? "none" : null }}>
                <CarsSubInformationComponent specialists={specialists} />
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
