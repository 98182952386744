import React, { useState, useEffect, useRef } from "react";
import { PageHeader, Divider, Form, message } from "antd";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { MakesFunctionalitiesComponent } from "../sub_components/makes/MakesFunctionalitiesComponent";
import { MakesTableComponent } from "../sub_components/makes/MakesTableComponent";
import { MakesFiltersAndSelectionComponent } from "../sub_components/makes/MakesFiltersAndSelectionComponent";
import { MakeCreationModalComponent } from "../sub_components/makes/MakeCreationModalComponent";
import { MakeUpdateModalComponent } from "../sub_components/makes/MakeUpdateModalComponent";
import { FetchMakes } from "../../api/makes/FetchMakes";
import { CreateMake } from "../../api/makes/CreateMake";
import { UpdateMake } from "../../api/makes/UpdateMake";
import { useComponentDidUpdate } from "../../hooks/useComponentDidUpdate";
import { ModulesSelectAndColumnTagsComponent } from "../ModulesSelectAndColumnTagsComponent";

const initialRolesState = {
  CREATE_MAKES: 0,
  UPDATE_MAKES: 0,
};

export const ItemMakesManagementComponent = () => {
  const [createForm] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [data, setData] = useState({ data: [], total: 0 });
  const [selectedRowKeys, setSelectedRowKey] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showCreationModal, setShowCreationModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [rolesRestriction, setRolesRestrictions] = useState(initialRolesState);
  const [currentPagination, setCurrentPagination] = useState(1);
  const [currentCount, setCurrentCount] = useState(10);

  const [isShowColumnFilter, setIsShowColumnFilter] = useState(false);
  const [columnFilterTags, setColumnFilterTags] = useState([]);

  const [columnFilter, setColumnFilter] = useState([]);
  const sortColumn = useRef(null);
  const sortType = useRef("asc");

  const setSortColumn = (value) => {
    sortColumn.current = value;
  }

  const setSortType = (value) => {
    if (value === "descend") {
      sortType.current = "desc";
    } else if (value === "ascend") {
      sortType.current = "asc";
    } else {
      sortType.current = "none";
    }
  }

  const onSortChange = async (sortCol, sortType) => {

    setSortColumn(sortCol);
    setSortType(sortType);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    await FetchMakesList(currentPagination, currentCount);

  };

  const onRowSelectionChange = (selectedRowKeys, selectedRows) => {
    setSelectedRow(selectedRows[0]);
    setSelectedRowKey(selectedRowKeys[0]);
  };

  const onClearSearcheAndSelection = () => {
    setSelectedRowKey(null);
    setSelectedRow(null);
  };

  ///////////////////////////////
  // for column filter tags
  const onCloseColumnFilterTag = (id) => {
    setColumnFilterTags((prevState) => {
      return prevState.filter(
        (item) => item.id !== id
      );
    });

    setColumnFilter((prevState) => {
      let newData = prevState.filter(
        (item) => {

          return Array.isArray(item.id) ? JSON.stringify(item.id) != id : item.id != id;
        }
      );

      return newData;
    });

    if (!columnFilterTags.length) {
      setIsShowColumnFilter(false);
    }
  };

  const onLoadColumnFilterTags = () => {

    if (columnFilter.length) {
      let filterTags = columnFilter.map((e) => {
        let [label, tagId] = Array(2).fill(e.id);

        if (Array.isArray(e.id)) {
          tagId = JSON.stringify(tagId);
          label = "";

          for (let i = 0, n = e.id.length; i < n; i++) {
            label += `${e.id[i]} `;
          }
        }

        return {
          color: "processing",
          closable: true,
          selection_label: label,
          value: e.value,
          id: tagId,
          onClose: onCloseColumnFilterTag
        }
      })

      setColumnFilterTags(filterTags);
      setIsShowColumnFilter(true);
    } else {
      // if columnFilter is just blank, then remove the tags
      setColumnFilterTags([]);
      setIsShowColumnFilter(false);
    }
  };

  const onClearColumnFilters = () => {
    setColumnFilterTags([]);
    setIsShowColumnFilter(false);
    setColumnFilter([]);

  };

  useEffect(() => {
    FetchMakesList();
  }, []);

  useComponentDidUpdate(() => {
    FetchMakesList(1, currentCount);
    onLoadColumnFilterTags();
  }, [columnFilter]);

  const onPaginationChange = (page, limit) => {
    setCurrentPagination(page);
    setCurrentCount(limit);
    FetchMakesList(page, limit);
  };

  const FetchMakesList = async (page = 1, limit = 10) => {
    setCurrentPagination(page);

    const query = {
      page: page,
      limit: limit,
      sortColumn: sortColumn.current,
      sortType: sortType.current,
      columnFilter: columnFilter
    };

    const data = await FetchMakes(query);
    setData(data);
  };

  const updateCreationModalStatus = () => {
    setShowCreationModal(!showCreationModal);
  };

  const updateUpdateModalStatus = () => {
    setShowUpdateModal(!showUpdateModal);
    updateForm.setFieldsValue(selectedRow);
  };

  const CreateMakeData = async () => {
    const values = await createForm.validateFields();
    await message.loading("Creating Make...");
    const payload = { data: values };
    const response = await CreateMake(payload);
    if (response.status === 201) {
      message.success("Successfuly Created Make");
      setSelectedRow(null);
      setSelectedRowKey(null);
      updateCreationModalStatus();
      FetchMakesList();
      setCurrentPagination(1);
      createForm.resetFields();
    }

    if (response.status === 400) {
      for (const item of response.data) {
        message.error(item.message, "2");
      }
    }
  };

  const UpdateMakeData = async () => {
    const values = await updateForm.validateFields();
    await message.loading("Updating Make...");
    const payload = { data: values };
    const response = await UpdateMake(selectedRowKeys, payload);
    if (response.status === 200) {
      message.success("Successfuly Updated Make");
      setSelectedRow(null);
      setSelectedRowKey(null);
      setShowUpdateModal(false);
      FetchMakesList();
      setCurrentPagination(1);
      updateForm.resetFields();
    }
  };

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const rolesRestriction = {};

    const CREATE_MAKES = permissions.find(
      (item) => item.name === "CREATE_MAKES"
    );

    rolesRestriction["CREATE_MAKES"] =
      typeof CREATE_MAKES === "undefined" ? 0 : CREATE_MAKES.has_access;

    const UPDATE_MAKES = permissions.find(
      (item) => item.name === "UPDATE_MAKES"
    );

    rolesRestriction["UPDATE_MAKES"] =
      typeof UPDATE_MAKES === "undefined" ? 0 : UPDATE_MAKES.has_access;

    setRolesRestrictions(rolesRestriction);
  }, []);

  return (
    <>
      <PageHeader
        title="MAKES"
        subTitle="VEHICLE BRANDS"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["Items", "Make"]} />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <MakesFunctionalitiesComponent
          onClickCreateMake={updateCreationModalStatus}
          onClickUpdateMake={updateUpdateModalStatus}
          selectedRowKeys={selectedRowKeys}
          rolesRestriction={rolesRestriction}
        />

        <ModulesSelectAndColumnTagsComponent
          onClearColumnFilters={onClearColumnFilters}
          isShowColumnFilterTags={isShowColumnFilter}
          columnFilterTags={columnFilterTags}
          isShowSelection={!!selectedRow}
          onClearSearcheAndSelection={onClearSearcheAndSelection}
          tags={[
            {
              color: "processing",
              selection_label: "Selected Color",
              value: selectedRow
                ? `${selectedRow.name}`
                : null,
            },
          ]}
        />
        <MakesTableComponent
          data={data}
          selectedRowKeys={selectedRowKeys}
          onRowSelectionChange={onRowSelectionChange}
          onPaginationChange={onPaginationChange}
          currentPagination={currentPagination}
          columnFilter={columnFilter}
          setColumnFilter={setColumnFilter}
          onSortChange={onSortChange}
        />
      </div>
      <MakeCreationModalComponent
        visible={showCreationModal}
        form={createForm}
        onCancel={updateCreationModalStatus}
        onOk={CreateMakeData}
      />
      <MakeUpdateModalComponent
        visible={showUpdateModal}
        form={updateForm}
        onCancel={updateUpdateModalStatus}
        onOk={UpdateMakeData}
      />
    </>
  );
};
