export const FormRules = {
  REQUIRED_CONTACT: {
    required: true,
    message: "Please input your Contact!",
  },
  CONTACT_CHARACTER_COUNT: {
    min: 9,
    message: "Contact Number must be minimum of 9 characters.",
  },
  NUMBER_FORMAT_ONLY: {
    pattern: new RegExp(/\d+/g),
    message: "This field is acccepting only a number",
  },
  REQUIRED_LANDLINE: {
    required: true,
    message: "Please input your Landline!",
  },
  LANDLINE_CHARACTER_COUNT: {
    min: 7,
    message: "Landline must be minimum of 7 characters.",
  },
  EMAIL_FORMAT: {
    type: "email",
    message: "The input is not valid E mail Address!",
  },
  REQUIRED_EMAIL: {
    required: true,
    message: "Please input your E-mail!",
  },
  REQUIRED_ADDRESS: {
    required: true,
    message: "Please Fillup Address Information",
  },
  REQUIRED_FIRSTNAME: {
    required: true,
    message: "Please input firstname",
  },
  REQUIRED_MIDDLENAME: {
    required: true,
    message: "Please input middlename",
  },
  REQUIRED_LASTNAME: {
    required: true,
    message: "Please input lastname",
  },
  REQUIRED_GENDER: {
    required: true,
    message: "Please select gender",
  },
  REQUIRED_USERNAME: {
    required: true,
    message: "Please input username",
  },
  REQUIRED_PASSWORD: {
    required: true,
    message: "Please input password",
  },
  REQUIRED_USER_TYPE: {
    required: true,
    message: "Please select User Type",
  },
  REQUIRED_USER_ATTACHED_ROLES: {
    required: false,
    message:
      "Please select atleast 1 role to proceed, when Admin is Selected as User Type",
  },
  REQUIRED_FUEL_TYPE: {
    required: true,
    message: "Please input Fuel Type",
  },
  REQUIRED_MAKE_ID: {
    required: true,
    message: "Please select Vehicle Brand",
  },
  REQUIRED_TRANSMISSION_TYPE: {
    required: true,
    message: "Please select Transmission Type",
  },
  REQUIRED_VEHICLE_TYPE: {
    required: true,
    message: "Please select Vehicle Type",
  },
  REQUIRED_MODEL_NAME: {
    required: true,
    message: "Please input Model Name",
  },
  REQUIRED_GENERAL_NAME: {
    required: true,
    message: "Please input General Name",
  },
  REQUIRED_SPECIFIC_NAME: {
    required: true,
    message: "Please input Specific Name",
  },
  REQUIRED_CANCELLATION_REASON: {
    required: true,
    message: "Please input reason for cancellation",
  },
  REQUIRED_ARCHIVING_REASON: {
    required: true,
    message: "Please input reason for archiving",
  },
  REQUIRED_TITLE: {
    required: true,
    message: "Please input title",
  },
  REQUIRED_SUB_TITLE: {
    required: true,
    message: "Please input sub title",
  },
  REQUIRED_SLUG: {
    required: true,
    message: "Please input slug",
  },
  REQUIRED_STATUS: {
    required: true,
    message: "Please select status",
  },
  REQUIRED_FULL_ADDRESS: {
    required: true,
    message: "Pleae input the full Address",
  },
  REQUIRED_LOCATION: {
    required: true,
    message: "Pleae input the Location",
  },
  REQUIRED_LANDMARK: {
    required: true,
    message: "Pleae input the Nearest Landmark",
  },
  REQUIRED_COUNTRY: {
    required: true,
    message: "Pleae Select Country",
  },
  REQUIRED_BANK: {
    required: true,
    message: "Pleae Select Bank",
  },
  REQUIRED_ADVISERS: {
    required: true,
    message: "Please select advisers!",
    type: "array",
  },
  REQUIRED_CITY: {
    required: true,
    message: "Please select city",
  },
  REQUIRED_FULL_NAME: {
    required: true,
    message: "Please Input Full Name",
  },
  REQUIRED_RATINGS: {
    required: true,
    message: "Please Input Ratings",
  },
  REQUIRED_REVIEW_DATE: {
    required: true,
    message: "Please Input Review Date",
  },
  REQUIRED_COMMENT: {
    required: true,
    message: "Please Input Comment",
  },
  REQUIRED_CHANNEL: {
    required: true,
    message: "Please Input Comment",
  },
  REQUIRED_REVIEW_TYPE: {
    required: true,
    message: "Please Review Type",
  },
  REQUIRED_REMARKS_CONTENT: {
    required: true,
    message: "Please input Content",
  },
  REQUIRED_REMARKS_CLASSIFICATION: {
    required: true,
    message: "Please select Remarks Classification",
  },
};
