import React, { Fragment, useState } from "react";
import { Modal, Form, Button, Row, Col, Select } from "antd";
import { UserPermissionsFormComponent } from "./UserPermissionsFormComponent";

const { Option } = Select;

export const UpdateRolesFormComponent = ({
  isModalVisible,
  roles,
  handleModalVisibleOnChange,
  permissions,
  form,
  isDefaultPermission,
  handleDefaultPermissionChecked,
  HandleUpdateRolesFormComponentSubmit,
}) => {
  const [confirmLoading] = useState(false);
  const [, setSelectedRole] = useState(null);

  const rolesNameOnChange = (e) => {
    setSelectedRole(e);
  };

  return (
    <Fragment>
      <Modal
        forceRender
        visible={isModalVisible}
        title="Update Roles"
        onOk={HandleUpdateRolesFormComponentSubmit}
        onCancel={handleModalVisibleOnChange}
        width={700}
        centered={true}
        footer={[
          <Button key="back" onClick={handleModalVisibleOnChange}>
            Back
          </Button>,
          <Button
            type="primary"
            onClick={HandleUpdateRolesFormComponentSubmit}
            loading={confirmLoading}
          >
            Submit
          </Button>,
        ]}
        destroyOnClose={true}
        confirmLoading={confirmLoading}
        maskClosable={false}
        keyboard={true}
      >
        <Form form={form} layout="vertical">
          <Row>
            <Col span={24}>
              <div className="no-step-modal-body-div-layout">
                <Form.Item key="roles_id" name="roles_id" label="Roles">
                  <Select
                    placeholder="Select Roles"
                    onChange={rolesNameOnChange}
                  >
                    {roles.map((item, index) => (
                      <Option key={index} value={item.key}>
                        {item.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <UserPermissionsFormComponent
                  isHidden={false}
                  roles={permissions}
                  type={"Update"}
                  isDefaultChecked={isDefaultPermission}
                  onChangeDefaultPermission={handleDefaultPermissionChecked}
                />
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Fragment>
  );
};
