import { useDelete } from "../../hooks/useDelete";

export const DeleteWarehouse = async (warehouse_id) => {
  try {
    const data = await useDelete(
      `${process.env.REACT_APP_API_URL}/warehouses/${warehouse_id}`
    );
    return data.data;
  } catch (err) {
    return null;
  }
};
