import { useFetch } from "../../hooks/useFetch";

export const FetchBuyNowList = async (params) => {
  try {
    const data = await useFetch(
      `${process.env.REACT_APP_API_URL}/buys`,
      params
    );

    for (const i of data.data) {
      i["key"] = i.id;
    }
    return data.data;
  } catch (err) {
    console.log("ERROR: ", err);
    return null;
  }
};
