import React from "react";
import { Row, Col, Table } from "antd";
import { AdsListSubInformationComponent } from "./AdsListSubInformationComponent";

const { Column } = Table;

export const AdsListComponent = (props) => {
  const {
    data,
    warehouseList = [],
    onChange,
    selectedRow,
    currentTableSpan,
  } = props;
  return (
    <>
      <Row>
        <Col span={currentTableSpan}>
          <Table
            size="small"
            dataSource={data}
            bordered
            rowSelection={{
              onChange: onChange,
              type: "radio",
            }}
          >
            <Column title="Id" dataIndex="id" key="id" responsive={["md"]} />
            <Column
              title="Title"
              dataIndex="title"
              key="title"
              responsive={["md"]}
            />
            <Column
              title="Description"
              dataIndex="description"
              key="description"
              responsive={["md"]}
            />
            <Column
              title="Category"
              dataIndex={["categories", "category_name"]}
              key={["categories", "category_name"]}
              responsive={["md"]}
            />
            <Column
              title="Price Order"
              dataIndex="price_order"
              key="price_order"
              responsive={["md"]}
            />
            <Column
              title="Type"
              dataIndex="type"
              key="type"
              responsive={["md"]}
            />
            <Column
              title="Date Expiration"
              dataIndex="expired_at"
              key="expired_at"
              responsive={["md"]}
            />
            <Column
              title="Date Created"
              dataIndex="created_at"
              key="created_at"
              responsive={["md"]}
            />
          </Table>
        </Col>
        {selectedRow ? (
          <Col span={6} className="sub-information-col-div">
            <AdsListSubInformationComponent
              sub_data={selectedRow}
              warehouseList={warehouseList}
            />
          </Col>
        ) : null}
      </Row>
    </>
  );
};
