import { useDelete } from "../../hooks/useDelete";

export const DeleteMedia = async (payload = {}) => {
  console.log(payload, "api");
  try {
    return await useDelete(
      `${process.env.REACT_APP_API_URL}/medias/banner_image`,
      payload
    );
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};
