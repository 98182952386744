import React from "react";
import { Space, Button, Input, Divider } from "antd";
import {
  UserAddOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import useIsMobile from "../../../hooks/useIsMobile";

const { Search } = Input;
export const SpecialistsFunctionalityComponent = (props) => {
  const {
    handleModalSpecialistCreationOnClick,
    selectedSpecialistId,
    handleUpdateSpecialistInfo,
    handleDeleteSpecialist,
    userPermissions,
    onSearch,
  } = props;

  const { CREATE_ADVISER, DELETE_ADVISER } = userPermissions;
  const isMobile = useIsMobile();
  return (
    <div style={{ width: "100%", textAlign: isMobile ? "center" : "right" }}>
      <Space size={"small"} direction={isMobile ? "vertical" : "horizontal"}>
        <Search
          id="adviser-search"
          onSearch={onSearch}
          placeholder="search adviser"
          size="default"
          enterButton
          width={2000}
        />
        <Divider type={isMobile ? "horizontal" : "vertical"} />
        <Button
          hidden={CREATE_ADVISER === 1 ? false : true}
          type="primary"
          icon={<UserAddOutlined />}
          onClick={handleModalSpecialistCreationOnClick}
          block={isMobile ? true : false}
        >
          Create Adviser
        </Button>
        {selectedSpecialistId ? (
          <>
            <Button
              type="default"
              icon={<EditOutlined />}
              onClick={handleUpdateSpecialistInfo}
              block={isMobile ? true : false}
            >
              Update Adviser
            </Button>
            <Button
              hidden={DELETE_ADVISER === 1 ? false : true}
              type="primary"
              icon={<DeleteOutlined />}
              onClick={handleDeleteSpecialist}
              block={isMobile ? true : false}
              hidden={true}
              danger
            >
              Delete Adviser
            </Button>
          </>
        ) : null}
      </Space>
    </div>
  );
};
