import React, { createRef } from "react";
import { Table, Divider, Progress, Typography, Space } from "antd";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";

const { Column } = Table;
const { Title } = Typography;
export const BulkUploaderTableComponent = (props) => {
  const {
    setHighlighted,
    isFileUploaded,
    handleUploaderOnChange,
    data,
    header,
    uploadPercentage = 0,
    loading = false,
    isFiltered,
    filteredData,
    handleClickUploaderOnChange,
  } = props;

  const csvFileButton = createRef();
  return (
    <>
      {uploadPercentage > 0 && uploadPercentage < 100 ? (
        <div className="upload-progress-div-layout">
          <Title level={5}>
            Validating Information
            {"      "}
            <LoadingOutlined />
          </Title>
          <Progress
            percent={uploadPercentage}
            status={uploadPercentage === 100 ? "completed" : "active"}
          />
        </div>
      ) : uploadPercentage === 0 && !isFileUploaded ? (
        <div>
          <Divider />
          <div
            className="upload-div-area-layout"
            onDragStart={() => {
              setHighlighted(true);
            }}
            onDragExit={() => {
              setHighlighted(false);
            }}
            onDrop={handleUploaderOnChange}
            onDragOver={(e) => {
              e.preventDefault();
            }}
          >
            <Space size="small" direction="vertical" align="center">
              <UploadOutlined style={{ fontSize: "40px", color: "darkgray" }} />
              <Title level={5} type="secondary">
                DROP FILE HERE
              </Title>
              <div>OR</div>
              <input
                type="file"
                id="csvFile"
                ref={csvFileButton}
                onChange={handleClickUploaderOnChange}
              />
            </Space>
          </div>
        </div>
      ) : null}
      <div style={{ display: isFileUploaded ? null : "none" }}>
        <Table
          size="small"
          bordered={true}
          dataSource={isFiltered ? filteredData : data}
          scroll={{ x: 5000 }}
          loading={loading}
        >
          {header.map((item) => (
            <Column
              title={item}
              dataIndex={item.toLowerCase()}
              key={item.toLowerCase()}
              render={(text) => (item === "Vehicle_comments" ? "...." : text)}
            />
          ))}
        </Table>
      </div>
    </>
  );
};
