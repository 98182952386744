import React from "react";
import { Button } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

export const PermissionIndexFunctionalityComponent = (props) => {
  const { handleShowModalPermission, rolesRestriction } = props;
  const { ADD_PERMISSION_INDEX = 0 } = rolesRestriction;

  return (
    <div style={{ textAlign: "right", marginBottom: "2%" }}>
      <Button
        type="primary"
        onClick={handleShowModalPermission}
        icon={<PlusCircleOutlined />}
        hidden={ADD_PERMISSION_INDEX === 0 ? true : false}
      >
        Create Permission
      </Button>
    </div>
  );
};
