import React from "react";
import { Select, Form } from "antd";

const { Option } = Select;
export const PageCreationSubInformationFormComponent = () => {
  return (
    <>
      <Form.Item
        name="show_in_header_menu"
        label="Show In Header?"
        initialValue="Select Option"
      >
        <Select style={{ width: "100%" }}>
          <Option value={0}>Yes</Option>
          <Option value={1}>No</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="show_in_footer_menu"
        label="Show In Footer?"
        initialValue="Select Option"
      >
        <Select style={{ width: "100%" }}>
          <Option value={0}>Yes</Option>
          <Option value={1}>No</Option>
        </Select>
      </Form.Item>
    </>
  );
};
