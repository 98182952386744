import {useUpdate} from "../../hooks/useUpdate";
import {message} from "antd";

export const UpdateGeneralInfo = async (ads_id, payload) => {
    try {
        const data = await useUpdate(
            `${process.env.REACT_APP_API_URL}/ads/main_info/${ads_id}`,
            payload
        );
        if (data.status == 200) {
            message.success("Successfully updated general information.", 2);
            console.log("Successfully updated general information.");
        } else {
            message.error(
                "Failed to update general information, please try again.",
                3
            );
            console.log(
                "Failed to update general information, please try again.",
                data
            );
        }
        return data.status;
    } catch (err) {
        message.error(
            "Failed to update general information, please try again.",
            3
        );
        console.log("ERROR: ", err.stack);
        return null;
    }
};
