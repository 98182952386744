import { useEffect, useState } from "react";
import { Button, Space, Tag } from "antd";
import { FormOutlined, RedoOutlined } from "@ant-design/icons";

const columns = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Channel",
    dataIndex: "channel",
    key: "channel",
  },
  {
    title: "Review Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Comment",
    dataIndex: "comment",
    key: "comment",
  },
  {
    title: "Rating",
    dataIndex: "rating",
    key: "rating",
  },
  {
    title: "Reviewed Date",
    dataIndex: "reviewed_date",
    key: "reviewed_date",
  },
  {
    title: "Status",
    dataIndex: "is_show",
    key: "is_show",
    render: (is_show) =>
      is_show || is_show === null ? (
        <Tag color="green">Enable</Tag>
      ) : (
        <Tag color="orange">Archived</Tag>
      ),
  },
  {
    title: "Last Modified Date",
    dataIndex: "updated_at",
    key: "updated_at",
  },
];

export default function useGetReviewListColumns(
  onClickEditReviews,
  onClickRestoreReview,
  rolesRestriction
) {
  const [tableColumns, setColumns] = useState(columns);
  const { REVIEW_INDEX_UPDATE, REVIEW_INDEX_DELETE, REVIEW_INDEX_RESTORE } =
    rolesRestriction;

  const returnHasEditFuntionality = () => {
    if (REVIEW_INDEX_UPDATE === 0 && REVIEW_INDEX_DELETE === 0) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    setColumns([
      ...columns,
      {
        dataIndex: "id",
        key: "id",
        title: "Actions",
        render: (id, row) => (
          <Space direction="horizontal" size={0}>
            <Button
              type="link"
              icon={<FormOutlined />}
              size="small"
              disabled={!returnHasEditFuntionality()}
              onClick={() => onClickEditReviews(id, row)}
            />
            <Button
              type="link"
              icon={<RedoOutlined />}
              size="small"
              disabled={
                REVIEW_INDEX_RESTORE === 0
                  ? true
                  : row.is_show || row.is_show === null
                  ? true
                  : false
              }
              onClick={() => onClickRestoreReview(id, row)}
              danger
            />
          </Space>
        ),
      },
    ]);
  }, [rolesRestriction]);

  return tableColumns;
}
