import { useCallback } from "react";
import axios from "axios";
import { message } from "antd";

const useUpdateBid = () => {
  const updateBid = useCallback(async (bidding_id, payload) => {
    if (!bidding_id || !payload) {
      message.error("Missing required parameters (bidding_id, payload)");
      return null;
    }

    await message.loading("Updating bidding status...", 2);

    try {
      const url = `${process.env.REACT_APP_API_URL}/biddings/${bidding_id}`;
      const token = localStorage.getItem("token");

      const response = await axios.put(url, payload, {
        headers: token ? { Authorization: token } : {},
      });

      if (response?.status === 200) {
        message.success("Bidding status updated successfully");
        return response.data;
      } else {
        throw new Error("Failed to update bidding status");
      }
    } catch (err) {
      message.error(
        err.response?.data?.message || "An error occurred while updating bidding status"
      );
      console.error("ERROR updating bidding status:", err);
      return null;
    }
  }, []);

  return { updateBid };
};

export default useUpdateBid;
