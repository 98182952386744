import React from "react";
import { Modal, Input, Button, Form, Divider } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";

export const UpdatePermissionListComponent = (props) => {
  const { visible, form, OnFinish, onCancel } = props;
  return (
    <Modal
      title="Add Permissions"
      onOk={OnFinish}
      onCancel={onCancel}
      width={800}
      destroyOnClose={true}
      visible={visible}
    >
      <Form form={form} layout="vertical">
        <Form.List name="permission_indeces">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <>
                  <Form.Item
                    {...field}
                    key={`name-${index}`}
                    name={[field.name, "name"]}
                    fieldKey={[field.fieldKey, "name"]}
                    rules={[
                      {
                        required: true,
                        message: "Missing permission name",
                      },
                    ]}
                  >
                    <Input
                      key={`field-name_${index}`}
                      placeholder="Permission Name"
                    />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    key={`description-${index}`}
                    name={[field.name, "description"]}
                    fieldKey={[field.fieldKey, "description"]}
                  >
                    <Input.TextArea
                      key={`field-description_${index}`}
                      placeholder="Permission Description"
                    />
                  </Form.Item>
                  <Button
                    key={`btn-remove-${index}`}
                    type="default"
                    size="small"
                    icon={<DeleteOutlined />}
                    onClick={() => remove(field.name)}
                    block
                    danger
                  >
                    Remove Entry
                  </Button>
                  <Divider />
                </>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Permission Entry
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};
