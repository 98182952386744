import { CreateUserAttachedRole } from "../../api/admin/CreateUserAttachedRole";
import { message } from "antd";

const useCreateUserAttachedRoles = async (payload) => {
  await message.loading("Updating User Information...", 2);

  const response = await CreateUserAttachedRole(payload);

  if (response.status === 201) {
    await message.success("Successfuly Added Role to Specific User", 2);
    return true;
  }

  await message.error("Error attaching roles to user", 2);
  return false;
};

export default useCreateUserAttachedRoles;
