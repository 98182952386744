import React from "react";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";

export const BuyNowReportsComponent = ({ dataSet }) => {
  const generateRow = (item) => {
    const { ads, users, payment_method, created_at } = item;
    const { title, slug, warehouses, buy_now_price, buy_now_type } = ads;
    const { first_name, last_name, email, mobile } = users;

    const model = title.split(" ")[0];
    const trans_type = ads?.car?.model?.transmission_type;
    const new_adviser = ads?.car?.new_adviser || ads?.car?.adviser;

    let _title = title;

    if (trans_type) {
      _title += ` ${trans_type}`;
    }
    if (model) {
      _title = _title.replace(model, "");
    }

    const adviserName = new_adviser
      ? `${new_adviser.first_name || ""} ${new_adviser.last_name || ""}`
      : "";

    return [
      created_at,
      model,
      _title,
      ads?.car?.conduction_sticker,
      slug,
      first_name || "",
      last_name || "",
      buy_now_price || "",
      payment_method || "",
      adviserName,
      warehouses?.location || "",
      buy_now_type,
      mobile,
      email,
      users?.referrer || "AutomartPH",
      ads?.car?.color?.specific_name || "",
    ];
  };

  const generateData = (rows, platform) => {
    const header = [
      "Date Purchased",
      "Year Model",
      "Car Title",
      "Conduction Sticker",
      "Plate Number",
      "Winner Firstname",
      "Winner Lastname",
      "Price",
      "Payment Method",
      "Seller Name",
      "Location",
      "Buy Now Type",
      "Winner Phone",
      "Winner Email",
      "Referrer",
      "Unit Color",
    ];

    return [header, ...rows.map((item) => generateRow(item))];
  };

  const exportToExcel = (rows) => {
    const data = generateData(rows);
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(
      wb,
      `buy_now${new Date()
        .toLocaleString()
        .replace("/", "")
        .replace("/", "")
        .replace(":", "")
        .replace(":", "")}.xlsx`
    );
  };

  return (
    <Button icon={<DownloadOutlined />} onClick={() => exportToExcel(dataSet)}>
      Generate Report
    </Button>
  );
};
