import { useFetch } from "../../hooks/useFetch";

export const GetAdsWithoutPhoto = async (warehouse_id) => {
  try {
    const data = await useFetch(
      `${process.env.REACT_APP_API_URL}/ads/no-photo/${warehouse_id}`
    );

    for (const i of data.data) {
      i["key"] = i.id;
    }

    return data.data;
  } catch (err) {
    console.log("ERROR: ", err);
    return [];
  }
};
