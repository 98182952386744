// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.site-description-item-profile-wrapper {
  margin-bottom: 7px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
}

[data-theme="compact"] .site-description-item-profile-wrapper {
  font-size: 12px;
  line-height: 1.66667;
}

.ant-drawer-body p.site-description-item-profile-p {
  display: block;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 1.5715;
}

[data-theme="compact"] .ant-drawer-body p.site-description-item-profile-p {
  font-size: 14px;
  line-height: 1.66667;
}

.site-description-item-profile-p-label {
  display: inline-block;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.85);
}

.content-pane-transaction-div-style {
  padding: 0% 2% 0% 1% !important;
}

.main-component-default-style {
  padding: 1% !important;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,0BAA0B;EAC1B,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,0BAA0B;EAC1B,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,0BAA0B;AAC5B;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".site-description-item-profile-wrapper {\n  margin-bottom: 7px;\n  color: rgba(0, 0, 0, 0.65);\n  font-size: 14px;\n  line-height: 1.5715;\n}\n\n[data-theme=\"compact\"] .site-description-item-profile-wrapper {\n  font-size: 12px;\n  line-height: 1.66667;\n}\n\n.ant-drawer-body p.site-description-item-profile-p {\n  display: block;\n  margin-bottom: 16px;\n  color: rgba(0, 0, 0, 0.85);\n  font-size: 16px;\n  line-height: 1.5715;\n}\n\n[data-theme=\"compact\"] .ant-drawer-body p.site-description-item-profile-p {\n  font-size: 14px;\n  line-height: 1.66667;\n}\n\n.site-description-item-profile-p-label {\n  display: inline-block;\n  margin-right: 8px;\n  color: rgba(0, 0, 0, 0.85);\n}\n\n.content-pane-transaction-div-style {\n  padding: 0% 2% 0% 1% !important;\n}\n\n.main-component-default-style {\n  padding: 1% !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
