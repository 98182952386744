import React from "react";
import { Table, Tag } from "antd";
import useColumnFilter from "../../../hooks/useColumnFilter";

const { Column } = Table;
export const MakesTableComponent = (props) => {
  const {
    data = { data: [], total: 0 },
    onRowSelectionChange,
    selectedRowKeys,
    onPaginationChange,
    currentPagination,
    onSortChange,
    columnFilter,
    setColumnFilter
  } = props;

  const { getColumnFilterProps, getColumnDynamicBoolFilter } = useColumnFilter(columnFilter, setColumnFilter);

  return (
    <>
      <Table
        size="small"
        dataSource={data.data}
        bordered={true}
        scroll={{ x: 1550 }}
        rowSelection={{
          type: "radio",
          onChange: onRowSelectionChange,
          selectedRowKeys: [selectedRowKeys],
        }}
        pagination={{
          total: data.total,
          onChange: onPaginationChange,
          current: currentPagination,
        }}
        onChange={(pagination, filters, sorter, action) => {
          if (action.action == "sort") {
            onSortChange(sorter.field, sorter.order);
          }

        }}
      >
        <Column title="Id" key="id" dataIndex="id" sorter={true} {...getColumnFilterProps('id')} />
        <Column title="Name" key="name" dataIndex="name" sorter={true} {...getColumnFilterProps('name')} />
        <Column title="Label" key="label" dataIndex="label" sorter={true} {...getColumnFilterProps('label')} />
        <Column
          title="Status"
          key="status"
          dataIndex="status"
          render={(item) => (
            <Tag color={item > 0 ? "green" : "default"}>
              {item > 0 ? "ACTIVE" : "NOT ACTIVE"}
            </Tag>
          )}
          sorter={true}
          {...getColumnDynamicBoolFilter('status', "Active", "Not Active")}
        />
        <Column
          title="Sequence Order"
          key="sequence_order"
          dataIndex="sequence_order"
          sorter={true} {...getColumnFilterProps('sequence_order')}
        />
        <Column title="Created At" key="created_at" dataIndex="created_at" sorter={true} {...getColumnFilterProps('created_at')} />
        <Column title="Updated At" key="updated_at" dataIndex="updated_at" sorter={true} {...getColumnFilterProps('updated_at')} />
      </Table>
    </>
  );
};
