import React, { useState, useEffect } from "react";
import { PageHeader, Divider, message, Form, Modal } from "antd";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { BulkUploaderFunctionalityComponent } from "../sub_components/bulk_uploader/BulkUploaderFunctionalityComponent";
import { BulkUploadValidationFeedBacksComponent } from "../sub_components/bulk_uploader/BulkUploadValidationFeedBacksComponent";
import { BulkUploaderTableComponent } from "../sub_components/bulk_uploader/BulkUploaderTableComponent";
import { BulkUploaderFiltersAndSelectionComponent } from "../sub_components/bulk_uploader/BulkUploaderFiltersAndSelectionComponent";
import { BulkUploaderSyncingProcessComponent } from "../sub_components/bulk_uploader/BulkUploaderSyncingProcessComponent";
import { parse } from "papaparse";
import { FetchVehicleTypes } from "../../api/vehicle_type/FetchVehicleTypes";
import { QueryVehicleType } from "../../api/vehicle_type/QueryVehicleType";
import { FetchMakes } from "../../api/makes/FetchMakes";
import { QueryMake } from "../../api/makes/QueryMake";
import { CreateModel } from "../../api/models/CreateModel";
import { ValidateBulkUploaderData } from "../../api/bulk_uploader/ValidateBulkUploaderData";
import { InsertBulkUploaderData } from "../../api/bulk_uploader/InsertBulkUploaderData";
import { UpdateBulkUploaderData } from "../../api/bulk_uploader/UpdateBulkUploaderData";
import { UpdateAdsMeta } from "../../api/ads_meta/UpdateAdsMeta";
import { returnBulkUploaderColumnNamesList } from "../../dist/functions/returnBulkUploaderColumnNamesList";
import { ModalwithFormComponent } from "../ModalwithFormComponent";
import { FormRules } from "../../dist/functions/formRules";
import useBulkRelistAds from "../../hooks/bulk_uploader/useBulkRelistAds";

import "../../dist/style/bulk_uploader.css";

const initialRolesState = {
  UPLOAD_BULK_UPLOADER_DATA: 0,
};

const { info } = Modal;

export const BulkUploaderManagementComponent = () => {
  const {
    REQUIRED_FUEL_TYPE,
    REQUIRED_MODEL_NAME,
    REQUIRED_TRANSMISSION_TYPE,
    REQUIRED_VEHICLE_TYPE,
    REQUIRED_MAKE_ID,
  } = FormRules;

  const [createForm] = Form.useForm();
  const [data, setData] = useState([]);
  const [dataHeader, setDataHeader] = useState([]);
  const [reportValidationStatus, setReportValidationStatus] =
    useState("default");
  const [validationInformation, setValidationInformation] = useState(null);
  const [highlighted, setHighlighted] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [searchValue, setSearchValue] = useState(null);
  const [isSyncingModalVisible, setIsSyncingModalVisible] = useState(false);
  const [fileSyncingStatus, setFileSyncingStatus] = useState("Pending");
  const [rolesRestriction, setRolesRestrictions] = useState(initialRolesState);
  const [showCreateModelModal, setShowCreateModelModal] = useState(false);
  const [makesList, setMakes] = useState({ data: [], total: 0 });
  const [vehicleTypesList, setVehicleTypes] = useState({
    data: [],
    total: 0,
  });

  const handleUploaderOnChange = (e) => {
    e.preventDefault();
    Array.from(e.dataTransfer.files)
      .filter(
        (file) =>
          file.type === "text/csv" || file.type === "application/vnd.ms-excel"
      )
      .forEach(async (file) => {
        const text = await file.text();
        const header = parse(text).data[2];

        if (!header) {
          message.error(
            "Validation error, Invalid file format make sure it is the bulk uploder file"
          );

          return;
        }

        const headerListOriginal = returnBulkUploaderColumnNamesList();

        for (const item of header) {
          if (!headerListOriginal.includes(item)) {
            if (item === "") {
              message.error("Validation error, Found an empty column name");
              return;
            }

            message.error(
              "Validation error, Invalid Column Name/Format please check the input file"
            );

            message.info(
              `Cannot found column on the original ${item} Row 3, Column ${
                parseInt(header.indexOf(item)) + 1
              }`,
              4
            );

            return;
          }
        }

        setDataHeader(header);
        const result = parse(text);
        const excelRows = [];
        setTableLoading(true);
        result.data.forEach((element, index, array) => {
          if (index > 2) {
            const rowItem = {};
            element.forEach((element, index) => {
              rowItem[header[index].toLowerCase()] = element;
            });

            rowItem["key"] = index;
            excelRows.push(rowItem);
          }
        });

        setData((existing) => [...existing, ...excelRows]);
        setTableLoading(false);
      });
  };

  const handleClickUploaderOnChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (
        file.type === "text/csv" ||
        file.type === "application/vnd.ms-excel"
      ) {
        const fileContent = await file.text();
        const header = parse(fileContent).data[2];
        if (!header) {
          message.error(
            "Validation error, Invalid file format make sure it is the bulk uploder file"
          );

          return;
        }

        const headerListOriginal = returnBulkUploaderColumnNamesList();

        for (const item of header) {
          if (!headerListOriginal.includes(item)) {
            message.error(
              "Validation error, Invalid Column Name/Format please check the input file"
            );

            return;
          }
        }

        setDataHeader(header);
        const result = parse(fileContent);
        const excelRows = [];
        setTableLoading(true);
        result.data.forEach((element, index, array) => {
          if (index > 2) {
            const rowItem = {};
            element.forEach((element, index) => {
              rowItem[header[index].toLowerCase()] = element;
            });

            rowItem["key"] = index;
            excelRows.push(rowItem);
          }
        });

        setData((existing) => [...existing, ...excelRows]);
        setTableLoading(false);
        return;
      }

      message.error(`Invalid File Type ${file.type}`, 3);
      return;
    }

    message.error("Error loading file");
    return;
  };

  const ValidateBulkData = async (data) => {
    return await ValidateBulkUploaderData(data);
  };

  const QueryMakesInformation = async (query) => {
    return await QueryMake(query);
  };

  const OnValidateData = async () => {
    const payload = { data: data };
    const data = await ValidateBulkData(payload);
  };

  const showConfirm = async (content) => {
    info({
      title: "Information",
      width: 900,
      content: <div>{content}</div>,
      onOk() {},
    });
  };

  const OnUploadData = async () => {
    // Update all the status of items to "Syncing"
    for (const sourceData of data) {
      sourceData["sync_status"] = "Syncing";
    }

    // Create Payload for Sending
    const payload = { data: validationInformation };
    return await ExecuteUploadAds(payload);
  };

  const ExecuteUploadAds = async (payload) => {
    const { bulk_info } = validationInformation;
    const { forItemCreation, forItemUpdating } = bulk_info;
    setFileSyncingStatus("Syncing");

    message.loading("Syncing Bulk Uploader Sheet...", 5).then(async () => {
      const insertResp =
        forItemCreation.length > 0
          ? await InsertBulkUploaderData(payload)
          : null;

      if (insertResp) {
        for (const sourceData of data) {
          for (const rowInserted of insertResp.data.data) {
            if (rowInserted.key === sourceData.key) {
              const { status } = rowInserted;
              sourceData["status"] = status;
              sourceData["sync_status"] = status.includes("Rejected")
                ? "Rejected"
                : "Successfuly Inserted";
            }
          }
        }
      }

      const updateResp =
        forItemUpdating.length > 0
          ? await UpdateBulkUploaderData(payload)
          : null;

      if (updateResp) {
        for (const sourceData of data) {
          for (const rowInserted of updateResp.data.data) {
            if (rowInserted.key === sourceData.key) {
              sourceData["sync_status"] = "Successfuly Updated";
            }
          }
        }
      }

      setFileSyncingStatus("Completed");

      var brands = [];
      var brand_types = [];
      var brand_models = [];

      for (const item of forItemCreation) {
        brands.push(item["vehicle_brand"]);
        brand_types.push(item["sub category"]);
        brand_models.push(item["vehicle_model"]);
      }

      for (const item of forItemUpdating) {
        brands.push(item["vehicle_brand"]);
        brand_types.push(item["sub category"]);
        brand_models.push(item["vehicle_model"]);
      }

      brands = [...new Set(brands)];
      brand_types = [...new Set(brand_types)];
      brand_models = [...new Set(brand_models)];

      const ads_meta_payload = {
        trigger: "selected",
        brands,
        brand_types,
        brand_models,
      };

      UpdateAdsMeta(ads_meta_payload);

      message.success(
        "Successfuly Inserted/Updated Items from the bulk uploader sheet",
        10
      );
    });
  };

  const onReset = () => {
    setReportValidationStatus(false);
    setValidationInformation(null);
    setIsFileUploaded(false);
    setReportValidationStatus("default");
    setData([]);
    setDataHeader([]);
    setUploadPercentage(0);
    setFileSyncingStatus("Pending");
    onResetSearch();
  };

  const onResetSearch = () => {
    setSearchValue(null);
    setIsFiltered(false);
    setFilteredData([]);
  };

  const onSearch = (value) => {
    setSearchValue(value);
    const filteredData = data.filter(
      (item) => item.plate_number === value || item.conduction_sticker === value
    );

    setFilteredData(filteredData);
    setIsFiltered(true);
  };

  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  useEffect(() => {
    const onFileUploaded = async () => {
      var initialCSVdata = [];
      let initialPercentage = 0;
      if (data.length > 0) {
        setUploadPercentage(initialPercentage);
        initialCSVdata = data;
        setTableLoading(true);

        const totalItems = data.length;
        const itemsPerSubArray = 10;
        const numChunks = Math.ceil(totalItems / itemsPerSubArray);
        const dividedArray = chunkArray(data, itemsPerSubArray);

        // accumulated list response
        let totalForItemUpdating = [];
        let totalForItemCreation = [];
        let totalRejectedItemInfo = [];
        let totalDuplicateItemInfo = [];
        let totalHasWinnerIdAttachedCount = 0;

        message.loading(`processing ${numChunks} batches`, 2);

        for (const [index, dividedItems] of dividedArray.entries()) {
          const response = await ValidateBulkData({ data: dividedItems });
          const { data } = response.data;
          const { bulk_info, hasWinnerIdAttachedCount } = data;
          const {
            forItemUpdating,
            forItemCreation,
            rejectedItemInfo,
            duplicateItemInfo,
          } = bulk_info;

          totalForItemCreation.push(...forItemCreation);
          totalForItemUpdating.push(...forItemUpdating);
          totalRejectedItemInfo.push(...rejectedItemInfo);
          totalDuplicateItemInfo.push(...duplicateItemInfo);

          totalHasWinnerIdAttachedCount =
            totalHasWinnerIdAttachedCount + hasWinnerIdAttachedCount;

          initialPercentage += Math.ceil(99 / dividedItems.length);

          if (initialPercentage < 90) {
            setUploadPercentage(initialPercentage);
          }
        }

        const _data = {
          bulk_info: {
            forItemCreation: totalForItemCreation,
            forItemUpdating: totalForItemUpdating,
            rejectedItemInfo: totalRejectedItemInfo,
            duplicateItemInfo: totalDuplicateItemInfo,
          },
          duplicateItems: totalDuplicateItemInfo.length,
          hasDuplicate: totalDuplicateItemInfo.length > 0 ? true : false,
          hasErrors: totalRejectedItemInfo.length > 0 ? true : false,
          hasWinnerIdAttachedCount: totalHasWinnerIdAttachedCount,
          itemErrorsCount: totalRejectedItemInfo.length,
          totalItems,
          totalItemsForCreation: totalForItemCreation.length,
          totalItemsForUpdating: totalForItemUpdating.length,
        };

        if (totalHasWinnerIdAttachedCount > 0) {
          const message = `System found a number of items to be uploaded has a recent winner, if
                              you wish to proceed with removing previous winner see below
                              instruction to do so`;
          showConfirm(message);
        }

        setReportValidationStatus(_data.hasErrors);
        setValidationInformation(_data);
        setUploadPercentage(100);
        setIsFileUploaded(true);

        for (const updatingItems of _data.bulk_info.forItemUpdating) {
          const { key, status, _slug } = updatingItems;
          for (const sourceData of initialCSVdata) {
            if (key === sourceData.key) {
              sourceData["_slug"] = _slug;
              sourceData.status = status;
            }
          }
        }

        for (const creationItems of _data.bulk_info.forItemCreation) {
          const { key, status, _slug } = creationItems;
          for (const sourceData of initialCSVdata) {
            if (key === sourceData.key) {
              sourceData["_slug"] = _slug;
              sourceData.status = status;
            }
          }
        }

        for (const rejectedItems of _data.bulk_info.rejectedItemInfo) {
          const { key, status, _slug } = rejectedItems;
          for (const sourceData of initialCSVdata) {
            if (key === sourceData.key) {
              sourceData["_slug"] = _slug;
              sourceData.status = status;
            }
          }
        }

        for (const duplicateItems of _data.bulk_info.duplicateItemInfo) {
          console.log(duplicateItems, "duplicate items");

          const { key, status, _slug } = duplicateItems;
          for (const sourceData of initialCSVdata) {
            if (key === sourceData.key) {
              sourceData["_slug"] = _slug;
              sourceData.status = status;
            }
          }
        }

        setData(initialCSVdata);
        setTableLoading(false);
      }
    };

    onFileUploaded();
  }, [data]);

  const handleSyncingModalVisibility = () => {
    setIsSyncingModalVisible(!isSyncingModalVisible);
  };

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const UPLOAD_BULK_UPLOADER_DATA = permissions.find(
      (item) => item.name === "UPLOAD_BULK_UPLOADER_DATA"
    );
    const rolesRestriction = {};
    rolesRestriction["UPLOAD_BULK_UPLOADER_DATA"] =
      typeof UPLOAD_BULK_UPLOADER_DATA === "undefined"
        ? 0
        : UPLOAD_BULK_UPLOADER_DATA.has_access;

    setRolesRestrictions(rolesRestriction);
  }, []);

  const FetchVehicleTypesList = async (page, limit) => {
    const query = { page: page, limit: limit };
    const data = await FetchVehicleTypes(query);
    setVehicleTypes(data);
  };

  const FetchMakeList = async (page, limit) => {
    const query = { page: page, limit: limit };
    const data = await FetchMakes(query);
    setMakes(data);
  };

  const onClickShowMoreVehicleBrand = async (id) => {
    const { page, lastPage } = makesList;
    if (page < lastPage) {
      const query = { page: page, limit: parseInt(id) + 10 };
      const data = await FetchMakes(query);
      const oldMakes = makesList.data;
      for (const item of data.data) {
        oldMakes.push(item);
      }

      const newState = {
        ...data,
        data: oldMakes,
      };

      setMakes(newState);
      return;
    }
  };

  const onClickShowMoreVehicleType = async (id) => {
    const { page, lastPage } = vehicleTypesList;
    if (page < lastPage) {
      const query = { page: page, limit: parseInt(id) + 10 };
      const data = await FetchVehicleTypesList(query);
      const oldVehicleType = vehicleTypesList.data;
      for (const item of data.data) {
        oldVehicleType.push(item);
      }

      const newState = {
        ...data,
        data: oldVehicleType,
      };

      setVehicleTypes(newState);
      return;
    }
  };

  useEffect(() => {
    FetchVehicleTypesList(1, 50);
    FetchMakeList(1, 50);
  }, []);

  const onClickCreateModal = () => {
    setShowCreateModelModal(!showCreateModelModal);
  };

  const onCreateNewModel = async (data) => {
    const {
      transmission_type,
      vehicle_model,
      vehicle_trim,
      vehicle_brand,
      displacement,
      fuel_type,
    } = data;

    const modelData = {
      engine_displacement: displacement !== "" ? displacement : null,
      fuel_type: fuel_type,
      name: vehicle_model,
      transmission_type: transmission_type,
      trim: vehicle_trim,
    };

    let isFoundVehicleType = false;
    for (const item of vehicleTypesList.data) {
      if (item.name === data["sub category"]) {
        modelData["vehicle_type_id"] = item.id;
        isFoundVehicleType = true;
      }
    }

    if (!isFoundVehicleType) {
      const vehicle_types = await QueryVehicleType({
        name: data["sub category"],
      });
      if (vehicle_types.length > 0) {
        modelData["vehicle_type_id"] = vehicle_types[0].id;
        onClickShowMoreVehicleType(vehicle_types[0].id);
      }
    }

    let isFoundVehicleBrand = false;
    for (const item of makesList.data) {
      if (item.name === vehicle_brand) {
        modelData["make_id"] = item.id;
        isFoundVehicleBrand = true;
      }
    }

    if (!isFoundVehicleBrand) {
      const makes = await QueryMakesInformation({ name: vehicle_brand });
      if (makes.length > 0) {
        modelData["make_id"] = makes[0].id;
        onClickShowMoreVehicleBrand(makes[0].id);
      }
    }

    createForm.setFieldsValue(modelData);
    onClickCreateModal();
  };

  const onFinishCreate = async () => {
    const values = await createForm.validateFields();

    for (const key in values) {
      values[key] = values[key] ? values[key] : null;
    }

    await message.loading("Creating Models....");
    const payload = { data: values };
    const response = await CreateModel(payload);
    if (response.status === 201) {
      message.success("Successfuly Created Model");
      message.info("Please re-run validation to see the changes");
      onClickCreateModal();
      createForm.resetFields();
      return;
    }

    if (response.status === 400) {
      const errorMessages = JSON.parse(response.response);
      for (const item of errorMessages) {
        await message.error(item.message, 2);
      }
      return;
    }
  };

  const formInputsComponent = [
    {
      name: "engine_displacement",
      label: "Engine Displacement",
      default_value: "",
      configs: {
        type: "input",
        rules: [],
        list_value: "",
        place_holder: "",
      },
    },
    {
      name: "fuel_type",
      label: "Fuel Type",
      default_value: "",
      configs: {
        type: "select",
        rules: [REQUIRED_FUEL_TYPE],
        list_value: [
          { id: "Gas", name: "Gas" },
          { id: "Diesel", name: "Diesel" },
          { id: "Hybrid", name: "Hybrid" },
          { id: "Electric", name: "Electric" },
        ],
      },
    },
    {
      name: "name",
      label: "Model Name",
      default_value: "",
      configs: {
        type: "input",
        rules: [REQUIRED_MODEL_NAME],
      },
    },
    {
      name: "transmission_type",
      label: "Transmission Type",
      default_value: "",
      configs: {
        type: "select",
        rules: [REQUIRED_TRANSMISSION_TYPE],
        list_value: [
          { id: "AT", name: "AT" },
          { id: "MT", name: "MT" },
          { id: "UNK", name: "UNK" },
          { id: "Unknown", name: "Unknown" },
        ],
        total: 0,
        onClickShowMoreList: () => {},
        place_holder: "Select Transmission Type",
      },
    },
    {
      name: "trim",
      label: "Trim",
      default_value: "",
      configs: {
        type: "input",
        rules: [],
      },
    },
  ];

  const ExecuteRelistSlug = async (slug) => {
    const payload = { slugs: slug };
    return await useBulkRelistAds(payload);
  };

  return (
    <>
      <PageHeader
        title="BULK UPLOADER"
        subTitle="BULK ITEMS UPLOAD MANAGEMENT"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["Bulk Uploader"]} />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <BulkUploaderFunctionalityComponent
          OnValidateData={OnValidateData}
          hasErrors={reportValidationStatus}
          OnUploadData={OnUploadData}
          hasData={data.length > 0 ? true : false}
          onSearch={onSearch}
          fileSyncingStatus={fileSyncingStatus}
          rolesRestriction={rolesRestriction}
        />
        <BulkUploaderFiltersAndSelectionComponent
          searchValue={searchValue}
          onRemoveAllSearches={onResetSearch}
        />
        <BulkUploadValidationFeedBacksComponent
          hasErrors={reportValidationStatus}
          validationLogs={validationInformation}
          onReset={onReset}
          handleSyncingModalVisibility={handleSyncingModalVisibility}
          fileSyncingStatus={fileSyncingStatus}
          onCreateNewModel={onCreateNewModel}
          ExecuteRelistSlug={ExecuteRelistSlug}
          showConfirm={showConfirm}
        />
        <BulkUploaderTableComponent
          handleUploaderOnChange={handleUploaderOnChange}
          header={dataHeader}
          data={data}
          highlighted={highlighted}
          setHighlighted={setHighlighted}
          isFileUploaded={isFileUploaded}
          uploadPercentage={uploadPercentage}
          validationLogs={validationInformation}
          loading={tableLoading}
          isFiltered={isFiltered}
          filteredData={filteredData}
          handleClickUploaderOnChange={handleClickUploaderOnChange}
        />
      </div>
      <BulkUploaderSyncingProcessComponent
        data={data}
        isVisible={isSyncingModalVisible}
        onCancel={handleSyncingModalVisibility}
      />
      <ModalwithFormComponent
        title={"Create Model"}
        form={createForm}
        formLayout={"vertical"}
        readOnly={true}
        formInputsComponent={[
          ...formInputsComponent,
          {
            name: "vehicle_type_id",
            label: "Vehicle Type",
            default_value: "",
            configs: {
              type: "select",
              rules: [REQUIRED_VEHICLE_TYPE],
              list_value: vehicleTypesList.data,
              total: vehicleTypesList.total,
              onClickShowMoreList: onClickShowMoreVehicleType,
              place_holder: "Select Vehicle Type",
            },
          },
          {
            name: "make_id",
            label: "Vehicle Brand",
            default_value: "",
            configs: {
              type: "select",
              rules: [REQUIRED_MAKE_ID],
              list_value: makesList.data,
              place_holder: "Select Vehicle Brand",
              total: makesList.total,
              onClickShowMoreList: onClickShowMoreVehicleBrand,
            },
          },
        ]}
        onOk={onFinishCreate}
        onCancel={onClickCreateModal}
        destroyOnClose={true}
        okText={"Save Model"}
        okCancel={"Cancel"}
        visible={showCreateModelModal}
      />
    </>
  );
};
