import React from "react";
import { Table } from "antd";
import { SpecialistTableExpandedWarehouseComponent } from "./SpecialistTableExpandedWarehouseComponent";
import useColumnFilter from "../../../hooks/useColumnFilter";

const { Column } = Table;
export const SpecialistsTableComponent = (props) => {
  const {
    specialists,
    specialistUpdateModal,
    handleModalSpecialistUpdateOnClick,
    HandleClearWarehouse,
    HandleUpdateWarehousesOnFinish,
    form,
    warehouses,
    tableLoading,
    rowSelection,
    userPermissions,
    isFiltered,
    filteredData,
    selectedSpecialistId,
    onPaginationChange,
    currentPagination,
    onClickShowMoreList,
    isClickShowMoreDisabled,
    onSortChange,
    columnFilter,
    setColumnFilter
  } = props;

  const { getColumnFilterProps, getColumnBooleanFilter } = useColumnFilter(columnFilter, setColumnFilter);

  return (
    <>
      <Table
        scroll={{ x: 1550 }}
        size="small"
        loading={tableLoading}
        dataSource={isFiltered ? filteredData.data : specialists.data}
        rowSelection={{
          selectedRowKeys: selectedSpecialistId,
          type: "radio",
          ...rowSelection,
        }}
        onChange={(pagination, filters, sorter, action) => {
          if (action.action == "sort") {
            onSortChange(sorter.field, sorter.order);
          }

        }}
        pagination={{
          total: isFiltered ? filteredData.total : specialists.total,
          onChange: onPaginationChange,
          current: currentPagination,
        }}
        expandable={{
          expandedRowRender: (record) => (
            <SpecialistTableExpandedWarehouseComponent
              record={record}
              specialistUpdateModal={specialistUpdateModal}
              handleModalSpecialistUpdateOnClick={
                handleModalSpecialistUpdateOnClick
              }
              HandleUpdateWarehousesOnFinish={() =>
                HandleUpdateWarehousesOnFinish(record)
              }
              HandleClearWarehouse={() => HandleClearWarehouse(record)}
              form={form}
              warehouses={warehouses ? warehouses.data : []}
              userPermissions={userPermissions}
              onClickShowMoreList={onClickShowMoreList}
              isClickShowMoreDisabled={isClickShowMoreDisabled}
            />
          ),
        }}
        bordered
      >
        <Column title="Id" dataIndex="id" key="id" sorter={true}  {...getColumnFilterProps('id')} />
        <Column title="Firstname" dataIndex="first_name" key="first_name" sorter={true}  {...getColumnFilterProps('first_name')} />
        <Column title="Lastname" dataIndex="last_name" key="last_name" sorter={true}  {...getColumnFilterProps('last_name')} />
        <Column title="Email Address" dataIndex="email" key="email" sorter={true}  {...getColumnFilterProps('email')} />
        <Column title="Globe" dataIndex="mobile_globe" key="mobile_globe" sorter={true}  {...getColumnFilterProps('mobile_globe')} />
        <Column title="Smart" dataIndex="mobile_smart" key="mobile_smart" sorter={true}  {...getColumnFilterProps('mobile_smart')} />
        <Column title="Landline" dataIndex="landline" key="landline" sorter={true}  {...getColumnFilterProps('landline')} />
      </Table>
    </>
  );
};
