import React from "react";
import { Button, Divider, Space, Input } from "antd";
import { PlusCircleOutlined, SyncOutlined } from "@ant-design/icons";

const { Search } = Input;
export const RolesAccessFunctionalitiesComponent = (props) => {
  const {
    handleModalVisibleOnClick,
    rolesRestrictions,
    GetRolesList,
    loading,
  } = props;

  const { CREATE_ROLES_ACCESS = 0 } = rolesRestrictions;
  return (
    <div style={{ textAlign: "right" }}>
      <Space size="small" direction={"horizontal"} split={<Divider />}>
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={handleModalVisibleOnClick}
          hidden={CREATE_ROLES_ACCESS === 0 ? true : false}
        >
          Create Roles
        </Button>
        <Divider type={"vertical"} />
        <Button
          loading={loading}
          type="default"
          icon={<SyncOutlined />}
          onClick={GetRolesList}
        >
          Load Roles
        </Button>
      </Space>
    </div>
  );
};
