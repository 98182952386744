import React from "react";
import { Form, DatePicker, Input, Radio, Checkbox } from "antd";

const { RangePicker } = DatePicker;

export const AuctionSearchInputsComponent = ({ is_bidding_module = false }) => {
  const dateFormat = "YYYY-MM-DD hh:mm:ss";

  return (
    <>
      {/* Date Range Selection */}
      <Form.Item
        name="report_date_range"
        label={`${is_bidding_module ? "Bid" : "Buynow"} Date Range`}
      >
        <RangePicker format={dateFormat} style={{ width: "100%" }} />
      </Form.Item>

      {/* Include Previous Bids Checkbox */}
      {is_bidding_module && (
        <Form.Item
          name="isIncludePreviousBids"
          label="Include Previous Bids?"
          valuePropName="checked"
          initialValue={true}
        >
          <Checkbox />
        </Form.Item>
      )}

      {/* Type Selection */}
      <Form.Item name="type" label="Type" initialValue="plate_number">
        <Radio.Group>
          <Radio value="winner">Winner</Radio>
          <Radio value="plate_number">Plate Number</Radio>
        </Radio.Group>
      </Form.Item>

      {/* Keyword Input */}
      <Form.Item name="keyword">
        <Input placeholder="Input Keyword" allowClear />
      </Form.Item>
    </>
  );
};
