import React, { useState, useEffect, useCallback } from "react";
import { Divider, PageHeader, Tabs, Form } from "antd";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { ReviewsGroupListComponent } from "../sub_components/reviews_group/ReviewsGroupListComponent";
import { ReviewsTransactionComponent } from "../sub_components/reviews_group/ReviewsTransactionComponent";
import { ReviewsFunctionalityComponent } from "../sub_components/reviews_group/ReviewsFunctionalityComponent";
import useFetchReviewsGroup from "../../hooks/reviews_group/useFetchReviewsGroup";
import useFetchReviews from "../../hooks/reviews/useFetchReviews";
import useUpdateReviewsGroup from "../../hooks/reviews_group/useUpdateReviewsGroup";
import useCreateReviewsGroup from "../../hooks/reviews_group/useCreateReviewsGroup";
import useFetchPermissionSets from "../../hooks/useFetchPermissions";

const { TabPane } = Tabs;
const initialTableState = { data: [], total: 0 };

const initialRolesRestriction = {
  REVIEW_GROUP_CREATE: 0,
  REVIEW_GROUP_UPDATE: 0,
  REVIEW_GROUP_DELETE: 0,
  REVIEW_GROUP_RESTORE: 0,
};

const permissionSet = [
  "REVIEW_GROUP_CREATE",
  "REVIEW_GROUP_UPDATE",
  "REVIEW_GROUP_DELETE",
  "REVIEW_GROUP_RESTORE",
];

export const ReviewsGroupManagementComponent = () => {
  const [createReviewGroupForm] = Form.useForm();
  const [reviewGroups, setReviewGroups] = useState(initialTableState);
  const [reviews, setReviews] = useState([]);
  const [selectedReviews, setSelectedReviews] = useState([]);
  const [selectedReviewId, setSelectedReviewId] = useState(null);
  const [currentPagination, setCurrentPagination] = useState(1);
  const [currentTab, setCurrenTab] = useState("1");
  const [transactionType, setTransactionType] = useState("for_creation");
  const [searchTags, setSearchTags] = useState([]);
  const [isShowSelection, setIsShowSelection] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [searchParameter, setSearchParameters] = useState({
    page: 1,
    limit: 10,
    filter: "",
  });

  // Query restriction using hook
  // Then hook, to get local storage
  const rolesRestriction = useFetchPermissionSets(
    permissionSet,
    initialRolesRestriction
  );

  const ExecuteFetchReviewsGroupHook = async (
    page = 1,
    limit = 10,
    filter = ""
  ) => {
    setTableLoading(true);

    const query = { page: page, limit: limit, filter: filter };
    const data = await useFetchReviewsGroup(true, query);

    setTimeout(() => {
      setTableLoading(false);
      setReviewGroups(data);
    }, 1300);
  };

  const onPaginationChange = (page, limit) => {
    setCurrentPagination(page);
    setSearchParameters((prevState) => {
      return { ...prevState, page: page, limit: limit };
    });
  };

  useEffect(() => {
    ExecuteFetchReviewsGroupHook();
  }, []);

  useEffect(() => {
    const { page, limit, filter } = searchParameter;
    ExecuteFetchReviewsGroupHook(page, limit, filter);
  }, [searchParameter]);

  const reset = useCallback(() => {
    setSelectedReviews([]);
    setSearchParameters(() => {
      return { page: 1, limit: 10, filter: "" };
    });
    createReviewGroupForm.resetFields();
  }, [createReviewGroupForm]);

  useEffect(() => {
    const ExecuteFetchReviewsHook = async () => {
      const data = await useFetchReviews(false, {});
      for (const review of data) {
        for (const selectedReview of selectedReviews) {
          if (review.id === selectedReview.id) {
            review["checked"] = true;
          }
        }
      }
      setReviews(data);
    };

    ExecuteFetchReviewsHook();
  }, [selectedReviews]);

  const OnClickCreateReviewGroup = async () => {
    const values = await createReviewGroupForm.validateFields();
    values.indices = selectedReviews;
    const isSuccess = await useCreateReviewsGroup(values);
    if (isSuccess) {
      reset();
    }
  };

  const OnClickUpdateReviewGroup = async () => {
    const values = await createReviewGroupForm.validateFields();
    values.indices = selectedReviews;

    const isSuccess = await useUpdateReviewsGroup(selectedReviewId, values);
    if (isSuccess) {
      reset();
    }
  };

  const OnClickDeleteReviewGroup = async () => {
    const payload = { is_show: false };
    UpdateStatus(selectedReviewId, payload);
  };

  const OnClickRestoreReviewGroup = async (id) => {
    const payload = { is_show: true };
    UpdateStatus(id, payload);
  };

  const UpdateStatus = async (id, payload) => {
    const isSuccess = await useUpdateReviewsGroup(id, payload);
    if (isSuccess) {
      reset();
    }
  };

  const onClickEditReviewGroup = (id, row) => {
    const { reviews_pivot } = row;
    const review_lists = [];

    reviews_pivot.forEach((item) => {
      review_lists.push(item.reviews_index);
    });

    createReviewGroupForm.setFieldsValue({
      name: row.name,
      indices: review_lists,
    });

    setSelectedReviewId(id);
    setSelectedReviews(review_lists);
    setTransactionType("for_update");
    setCurrenTab("2");
  };

  const onClickUnselectButton = (value) => {
    setSelectedReviews((prevState) => {
      return prevState.filter((item) => item.id !== value.id);
    });
  };

  const onClickUnselectAll = () => {
    setSelectedReviews([]);
  };

  const onCloseIncludesFilterSearchTag = () => {
    setSearchTags((prevState) => {
      return prevState.filter(
        (item) => item.selection_label !== "Includes Value"
      );
    });
    setIsShowSelection(false);
  };

  const onSearchReviewsGroup = (value) => {
    setIsShowSelection(true);
    setSearchTags(() => {
      return [
        {
          color: "blue",
          closable: true,
          selection_label: "Includes Value",
          value: value,
          onClose: onCloseIncludesFilterSearchTag,
        },
      ];
    });
    setSearchParameters((prevState) => {
      return { ...prevState, filter: value };
    });
  };

  const onClearSearcheAndSelection = () => {
    setSearchTags([]);
    setIsShowSelection(false);
    setSearchParameters(() => {
      return { page: 1, limit: 10, filter: "" };
    });
  };

  return (
    <>
      <PageHeader
        title="Reviews Group"
        subTitle="Reviews Group Management"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["Reviews", "Group"]} />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <Tabs
          defaultActiveKey="1"
          activeKey={currentTab}
          onChange={(activeKey) => {
            setCurrenTab(activeKey);
          }}
        >
          <TabPane tab="List of Reviews" key="1">
            <ReviewsGroupListComponent
              data={reviewGroups}
              onPaginationChange={onPaginationChange}
              currentPagination={currentPagination}
              onClickEditReviewGroup={onClickEditReviewGroup}
              onClickRestoreReviewGroup={OnClickRestoreReviewGroup}
              isShowSelection={isShowSelection}
              tags={searchTags}
              onClearSearcheAndSelection={onClearSearcheAndSelection}
              onSearchReviewsGroup={onSearchReviewsGroup}
              rolesRestriction={rolesRestriction}
              tableLoading={tableLoading}
            />
          </TabPane>
          <TabPane
            tab="Create/Edit Reviews"
            key="2"
            disabled={rolesRestriction.REVIEW_GROUP_CREATE === 0 ? true : false}
          >
            <ReviewsFunctionalityComponent
              onClickCreateReviewGroup={OnClickCreateReviewGroup}
              onClickUpdateReviewGroup={OnClickUpdateReviewGroup}
              onClickDeleteReviewGroup={OnClickDeleteReviewGroup}
              rolesRestriction={rolesRestriction}
              selectedReviewId={selectedReviewId}
            />
            <ReviewsTransactionComponent
              form={createReviewGroupForm}
              reviews={reviews}
              setSelectedReviews={setSelectedReviews}
              selectedReviews={selectedReviews}
              transactionType={transactionType}
              onClickUnselectButton={onClickUnselectButton}
              onClickUnselectAll={onClickUnselectAll}
            />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};
