import { useFetch } from "../../hooks/useFetch";

export const FetchFAQsIndex = async (query) => {
  try {
    return await useFetch(`${process.env.REACT_APP_API_URL}/faq-index`, query);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};
