import { useState, useEffect } from "react";
import axios from "axios";

const useFetchProviders = () => {
  const [providers, setProviders] = useState([]);
  const [metaDetails, setMetaDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProviders = async () => {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_INSTALLMENT_ADMIN_API_URL}/providers`,
        {
          params: { page: 1, limit: "ALL" },
        }
      );

      if (response.data) {
        const { data, meta } = response.data;
        setMetaDetails(meta);
        setProviders(data);
        setIsLoading(false);
        setError(null);
      }
    };

    fetchProviders();
  }, []);

  return {
    providers,
    isProvidersFetchingLoading: isLoading,
    error,
    metaDetails,
  };
};

export default useFetchProviders;
