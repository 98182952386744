import { useState, useEffect } from "react";
import axios from "axios";

const useFetchCities = (province_id) => {
  const [cities, setCities] = useState([]);
  const [metaDetails, setMetaDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCities = async () => {
      if (!province_id) {
        return;
      }

      setIsLoading(true);

      const response = await axios.get(
        `${process.env.REACT_APP_ASSETMART_ADMIN_API_URL}/cities`,
        {
          params: { province_id, page: 1, limit: "ALL" },
        }
      );

      if (response.data) {
        const { data, ...meta } = response.data.data;
        setMetaDetails(meta);
        setCities(data);
        setIsLoading(false);
        setError(null);
      }
    };

    fetchCities();
  }, [province_id]);

  return {
    cities,
    isCityFetchingLoading: isLoading,
    error,
    metaDetails,
  };
};

export default useFetchCities;
