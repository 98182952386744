import React from "react";
import { List, Avatar, Empty, Rate, Button } from "antd";
import { UserOutlined, RightCircleOutlined } from "@ant-design/icons";

export const SelectedReviewItemListComponent = ({
  listData,
  transactionType,
  onClickUnselectButton,
}) => {
  return (
    <div style={{ overflowY: "auto", height: "400px" }}>
      <List
        itemLayout="vertical"
        size="large"
        dataSource={listData}
        renderItem={(item) => (
          <List.Item
            key={item.title}
            extra={
              <div style={{ textAlign: "center" }}>
                <Button
                  type="default"
                  size="small"
                  icon={<RightCircleOutlined />}
                  danger
                  hidden={transactionType === "for_creation" ? true : false}
                  onClick={() => onClickUnselectButton(item)}
                >
                  Un-Select Review
                </Button>
                <br />
                <br />
                {item.image ? (
                  <img width={272} alt="logo" src={item.image.url} />
                ) : (
                  <Empty description={"No Image"} />
                )}
              </div>
            }
          >
            <List.Item.Meta
              avatar={<Avatar icon={<UserOutlined />} />}
              title={item.name}
              description={
                <div>
                  <div>{item.channel || "N/A"}</div>
                  <Rate value={item.rating || 0} />
                </div>
              }
            />
            {item.comment}
          </List.Item>
        )}
      />
    </div>
  );
};
