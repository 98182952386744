import React, { useRef, useState } from "react";
import { Col, Row, Divider, message, Form, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { CreatePost } from "../../../api/cms/CreatePost";
import { UpdatePostWithMetalInformation } from "../../../api/cms/UpdatePostWithMetaInformation";
import { PostCreationFunctionalitiesComponent } from "./PostCreationFunctionalitiesComponent";
import { returnScriptingSchema_ } from "../../../dist/functions/returnSchemaScripts";
import { PostCreationGeneralInformationComponent } from "./PostCreationGeneralInformationComponent";
import { PostCreateionMetaDataFormComponent } from "./PostCreationMetaDataFormComponent";
import { postCreationPayload } from "../../../dist/functions/formatters_/postCreationPayload";
import { PluginsModalFormComponent } from "./PluginsModalFormComponent";

const { confirm } = Modal;
export const PostsCreationComponent = (props) => {
  const {
    form,
    isForUpdate = false,
    forUpdateIds,
    setForUpdateIds,
    setIsForUpdate,
    rolesRestriction,
    editorJSHeroContent = {},
    editorJSPostContent = {},
    type = "editorjs",
    setMediaIds,
    mediaIds = [],
    tags,
    setTags,
    mediaForm,
    imageSet,
    faqs,
    selectedFAQs,
    onChangeFAQSelection,
    reviewGroups = [],
    selectedReviews,
    onChangeReviewSelection,
    formInitialValues,
    onChangePostType,
    remarksGroup,
    onChangeAdvantageRemarksGroup,
    onChangeDisadvantageRemarksGroup,
    selectedAdvantageRemarks,
    selectedDisadvantageRemarks,
    setSelectedFAQs,
    setSelectedReviews,
    setDisadvantageRemarksItem,
    setAdvantageRemarksItem,
  } = props;

  const postEditor = useRef(null);
  const heroEditor = useRef(null);
  const [postContent, setPostContent] = useState("");
  const [heroContent, setHeroContent] = useState("");
  const [pluginsModalVisible, setPluginsModalVisible] = useState(false);
  const [imageUploadsModal, setImageUploadsModal] = useState(false);

  const postContentOnBlur = (newContent) => {
    if (!newContent) {
      setPostContent(newContent.target.innerHTML);
    }
  };

  const heroContentOnBlur = (newContent) => {
    if (!newContent) {
      setHeroContent(newContent.target.innerHTML);
    }
  };

  const handlePluginsModalVisible = () => {
    setPluginsModalVisible(!pluginsModalVisible);
  };

  const HandleOnSaveAsHTML = async () => {
    var script_tag = `<script type="application/ld+json">${JSON.stringify(
      returnScriptingSchema_
    )}</script>`;

    script_tag += postContent;

    const element = document.createElement("a");
    const file = new Blob([script_tag], { type: "text/html" });
    element.href = URL.createObjectURL(file);
    element.download = "myFile.html";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const uploadProps = {
    name: "feature_image",
    action: `${process.env.REACT_APP_API_URL}/uploads/post/feature_image`,
    onChange(info) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        console.log(info.file, "file");
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const onClickUploadButton = () => {
    setImageUploadsModal(!imageUploadsModal);
  };

  const HandleSave = async (status) => {
    const values = await form.validateFields();
    const payload = postCreationPayload(values, status);

    delete payload["show_in_footer_menu"];
    delete payload["show_in_header_menu"];

    const response = await CreatePost({
      ...payload,
      editor: null,
      plugins: values.plugins,
      media_ids: mediaIds,
      tags: tags,
    });

    if (response.status === 201) {
      await message.success("Successfuly Created New Post", 2);
      resetFields();
    }
    if (response.status === 400) {
      for (const item of response.data) {
        message.error(item.message, 2);
      }
      return;
    }

    return;
  };

  const HandleUpdatePostWithMetaInformation = async () => {
    const values = await form.validateFields();
    await message.loading("Updating Post...", 2);
    const payload = postCreationPayload(values);
    delete payload["show_in_footer_menu"];
    delete payload["show_in_header_menu"];
    const { post_id, meta_id } = forUpdateIds;
    const response = await UpdatePostWithMetalInformation(post_id, meta_id, {
      ...payload,
      plugins: values.plugins,
      media_ids: mediaIds,
      tags: tags,
    });

    if (response.status === 200) {
      await message.success("Successfuly Updated Post", 2);
      setForUpdateIds({ post_id: null, meta_id: null });
      setMediaIds([]);
      resetFields();
      setIsForUpdate(false);
    }

    if (response.status === 400) {
      for (const item of response.data) {
        message.error(item.message, 2);
      }
      return;
    }
  };

  const HandleCreateNewCopyAndSave = async () => {
    const values = await form.validateFields();

    const payload = postCreationPayload(values, "0");

    const post_content = (await values.post_content.saver)
      ? await values.post_content.saver.save()
      : values.post_content;
    const hero_content = (await values.hero_content.saver)
      ? await values.hero_content.saver.save()
      : values.hero_content;

    payload.post_content = JSON.stringify({ blocks: post_content.blocks });
    payload.hero_content = JSON.stringify({ blocks: hero_content.blocks });

    delete payload["show_in_footer_menu"];
    delete payload["show_in_header_menu"];

    const response = await CreatePost({ ...payload, plugins: values.plugins });
    if (response.status === 201) {
      await message.success("Successfuly Created New Post", 2);
      resetFields();
      setIsForUpdate(false);
    }

    if (response.status === 400) {
      for (const item of JSON.parse(response.response)) {
        message.error(item.message, 2);
      }
      return;
    }
  };

  const OnReset = async () => {
    confirm({
      title: "Are you sure, you want to create a new post?",
      icon: <ExclamationCircleOutlined />,
      content: "All of the unsave data, will be discarded",
      async onOk() {
        resetFields();
      },
      onCancel() {},
    });
  };

  const resetFields = () => {
    form.resetFields();
    mediaForm.resetFields();
    setTags([]);
    setSelectedFAQs([]);
    setSelectedReviews([]);
    setDisadvantageRemarksItem([]);
    setAdvantageRemarksItem([]);
  };

  const onTagsPressEnter = (e) => {
    if (tags.includes(e.target.value)) {
      message.info("Tags already included on the list");
      return;
    }

    setTags((prevState) => {
      return [...prevState, e.target.value];
    });

    form.setFieldsValue({ tags: "" });
  };

  return (
    <>
      <Row>
        <PostCreationFunctionalitiesComponent
          handleSaveAndPublish={() => HandleSave("1")}
          handleSaveAsHTML={HandleOnSaveAsHTML}
          handleSaveasDraft={() => HandleSave("0")}
          handleCreateNew={OnReset}
          isForUpdate={isForUpdate}
          HandleCreateNewCopyAndSave={HandleCreateNewCopyAndSave}
          HandleUpdatePostWithMetaInformation={
            HandleUpdatePostWithMetaInformation
          }
          CREATE_COPY_AND_SAVE={rolesRestriction.CREATE_POST}
          SAVE_POST={rolesRestriction.CREATE_POST}
          SAVE_AS_DRAFT={rolesRestriction.CREATE_POST}
          UPDATE_POST={rolesRestriction.UPDATE_POST}
        />
      </Row>
      <Divider />
      <Form
        form={form}
        name="post"
        layout="vertical"
        initialValues={formInitialValues}
      >
        <Row>
          <Col span={16} className="post-create-general-info-form">
            <PostCreationGeneralInformationComponent
              heroEditor={heroEditor}
              heroContent={heroContent}
              heroContentOnBlur={heroContentOnBlur}
              postEditor={postEditor}
              postContent={postContent}
              postContentBlur={postContentOnBlur}
              config={{ readonly: false }}
              editorJSHeroContent={editorJSHeroContent}
              editorJSPostContent={editorJSPostContent}
              tags={tags}
              type={type}
              onTagsPressEnter={onTagsPressEnter}
              onChangePostType={onChangePostType}
            />
          </Col>
          <Col span={8}>
            <div className="plugins-div-layout">
              <PostCreateionMetaDataFormComponent
                uploadProps={uploadProps}
                handlePluginsModalVisible={handlePluginsModalVisible}
                setMediaIds={setMediaIds}
                imageUploadsModal={imageUploadsModal}
                onClickUploadButton={onClickUploadButton}
                mediaForm={mediaForm}
                imageSet={imageSet}
                faqs={faqs}
                selectedFAQs={selectedFAQs}
                onChangeFAQSelection={onChangeFAQSelection}
                reviewGroups={reviewGroups}
                selectedReviews={selectedReviews}
                onChangeReviewSelection={onChangeReviewSelection}
                remarksGroup={remarksGroup}
                onChangeDisadvantageRemarksGroup={
                  onChangeDisadvantageRemarksGroup
                }
                onChangeAdvantageRemarksGroup={onChangeAdvantageRemarksGroup}
                selectedAdvantageRemarks={selectedAdvantageRemarks}
                selectedDisadvantageRemarks={selectedDisadvantageRemarks}
              />
            </div>
          </Col>
        </Row>
        <PluginsModalFormComponent
          visible={pluginsModalVisible}
          handlePluginsModalVisible={handlePluginsModalVisible}
        />
      </Form>
    </>
  );
};
