import React from "react";
import { Form, Select, Modal } from "antd";
import { SearchOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { AuctionSearchInputsComponent } from "../auctions/AuctionSearchInputsComponent";
import useIsMobile from "../../../hooks/useIsMobile";

const { Option } = Select;
export const BidsSearchFunctionalityComponent = (props) => {
  const {
    advisers = { data: [], total: 0 },
    banks = { data: [], total: 0 },
    onSubmitSearch,
    form,
    visible,
    onCancel,
  } = props;

  const isMobile = useIsMobile();

  const modalBodyStyle = {
    overflowY: isMobile ? "auto" : "hidden",
    height: isMobile ? "300px" : "100%",
    padding: isMobile ? "2%" : null,
  };

  return (
    <Modal
      visible={visible}
      title="Filter Bids"
      okText="Search Bids"
      okButtonProps={{
        icon: <SearchOutlined />,
        block: isMobile ? true : false,
      }}
      cancelButtonProps={{
        icon: <ArrowLeftOutlined />,
        block: isMobile ? true : false,
      }}
      onOk={() => onSubmitSearch(1, 10)}
      onCancel={onCancel}
    >
      <div style={modalBodyStyle}>
        <Form form={form} layout="vertical">
          <Form.Item
            name="report_type"
            initialValue={"active"}
            label="Filter Type"
          >
            <Select placeholder="Select Bid Status">
              <Option value="all">All</Option>
              <Option value="active">On Going</Option>
              <Option value="completed">Winners</Option>
            </Select>
          </Form.Item>
          <AuctionSearchInputsComponent
            is_bidding_module={true}
            advisers={advisers}
            banks={banks}
          />
        </Form>
      </div>
    </Modal>
  );
};
