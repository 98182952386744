import { useState, useEffect } from "react";
import axios from "axios";

const useFetchProvinces = () => {
  const [provinces, setProvinces] = useState([]);
  const [metaDetails, setMetaDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProvinces = async () => {
      setIsLoading(true);

      const response = await axios.get(
        `${process.env.REACT_APP_ASSETMART_ADMIN_API_URL}/provinces`,
        {
          params: { page: 1, limit: "ALL" },
        }
      );

      if (response.data) {
        const { data, ...meta } = response.data.data;
        setMetaDetails(meta);
        setProvinces(data);
        setIsLoading(false);
        setError(null);
      }
    };

    fetchProvinces();
  }, []);

  return {
    provinces,
    isProvinceFetchingLoading: isLoading,
    error,
    metaDetails,
  };
};

export default useFetchProvinces;
