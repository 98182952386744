import React from "react";
import { Tag, Button, Typography, Divider } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import useIsMobile from "../../../hooks/useIsMobile";

const { Title } = Typography;

export const OptionsFilterAndSelectionComponent = (props) => {
  const { onClearSearcheAndSelection, selectedOption } = props;
  const isMobile = useIsMobile();
  return (
    <>
      {selectedOption ? (
        <div
          style={{ marginTop: "2%", textAlign: isMobile ? "center" : "left" }}
        >
          <Title level={5}>Filters:</Title>
          <Tag color="processing" visible={!selectedOption ? false : true}>
            {`Selected Option: ${
              !selectedOption ? "none" : selectedOption.option_key
            }`}
          </Tag>
          <Button
            type="link"
            icon={<CloseOutlined />}
            size="small"
            onClick={onClearSearcheAndSelection}
          >
            Clear Filters
          </Button>
          <Divider />
        </div>
      ) : null}
    </>
  );
};
