import React from "react";
import { Space, Button, Modal } from "antd";
import {
  ExclamationCircleOutlined,
  RedoOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { BidsReportsComponent } from "./BidsReportsComponent";
import useIsMobile from "../../../hooks/useIsMobile";

const { confirm } = Modal;
export const BidsFunctionalityComponent = (props) => {
  const {
    isUpdateButtonHidden = true,
    OnUpdateStatus,
    data = [],
    isFilteredFrontend,
    filteredDataFrontend,
    GENERATE_WINNING_BIDDERS_REPORT = 0,
    UPDATE_WINNING_BIDDERS_STATUS = 0,
    platform = "car",
    onClickFilterBids,
  } = props;

  const isMobile = useIsMobile();

  const showConfirm = async () => {
    confirm({
      centered: true,
      title: "Update Status",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to update status of this unit?",
      onOk() {
        OnUpdateStatus();
      },
      onCancel() {
        return null;
      },
    });
  };

  const divStyle = {
    marginTop: "2%",
    textAlign: isMobile ? "center" : "right",
  };

  const spaceStyle = {
    direction: isMobile ? "vertical" : "horizontal",
  };

  const cancelBidStyleDiv = {
    display: isUpdateButtonHidden ? "none" : null,
  };

  const buttonBlockStyling = {
    block: isMobile ? true : false,
  };

  return (
    <>
      <div style={divStyle}>
        <Space size="small" direction={spaceStyle}>
          {
            <div style={cancelBidStyleDiv}>
              <Button
                type="primary"
                icon={<RedoOutlined />}
                onClick={showConfirm}
                hidden={UPDATE_WINNING_BIDDERS_STATUS === 1 ? false : true}
                block={buttonBlockStyling}
                danger
              >
                Cancel Bid
              </Button>
            </div>
          }
          <div>
            <Button
              type="primary"
              title="Filter Bids"
              icon={<FilterOutlined />}
              onClick={onClickFilterBids}
              block={buttonBlockStyling}
            >
              Filter Bids
            </Button>
          </div>
          <div
            style={{
              display: GENERATE_WINNING_BIDDERS_REPORT === 1 ? null : "none",
            }}
          >
            <BidsReportsComponent
              dataSet={isFilteredFrontend ? filteredDataFrontend : data}
              platform={platform}
            />
          </div>
        </Space>
      </div>
    </>
  );
};
