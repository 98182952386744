import { useEffect } from "react";
import axios from "axios";
import { logout } from "../../dist/functions/auth";
import { message } from "antd";

const useRevalidateSession = () => {
  const checkSession = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      return;
    }

    const token_data = await axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/authentications/tokens/${localStorage.getItem("token")}`
    );

    if (token_data.status !== 200) {
      await message.loading("validating session", 2);
      await message.warning("your session is expired", 1);
      await message.info("please relogin to continue", 1);
      localStorage.clear();
      logout();
      window.location.replace(`${window.location.origin}/login`);
      window.location.reload();
      return;
    }
  };

  useEffect(() => {
    window.addEventListener("click", checkSession);

    // Cleanup event listeners when the component unmounts
    return () => {
      window.removeEventListener("click", checkSession);
    };
  }, []);

  return {
    checkSession,
  };
};

export default useRevalidateSession;
