// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-layout-sider-children {
  height: 100vh;
}

#components-layout-demo-responsive .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}

#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

.inner-tab-pane-div-button {
  width: 100%;
  text-align: right;
  margin-bottom: 2%;
}

.menu-header-custom-style {
  padding-left: 18px;
  z-index: 100% !important;
  width: 100%;
  text-align: right;
}

.content-style-padding-div {
  padding: 16px !important;
  height: 100%;
}

.content-layout {
  margin: 24px 16px 0;
}

#adminQmarketzLogo {
  width: 78%;
  margin: 10%;
}

.automart-logo {
  width: 100% !important;
  text-align: center !important;
  padding: 15% !important;
}
`, "",{"version":3,"sources":["webpack://./src/MenuComponent.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,oCAAoC;AACtC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,oCAAoC;AACtC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,wBAAwB;EACxB,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,wBAAwB;EACxB,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,sBAAsB;EACtB,6BAA6B;EAC7B,uBAAuB;AACzB","sourcesContent":[".ant-layout-sider-children {\n  height: 100vh;\n}\n\n#components-layout-demo-responsive .logo {\n  height: 32px;\n  margin: 16px;\n  background: rgba(255, 255, 255, 0.2);\n}\n\n.site-layout-sub-header-background {\n  background: #fff;\n}\n\n.site-layout-background {\n  background: #fff;\n}\n\n#components-layout-demo-custom-trigger .trigger {\n  padding: 0 24px;\n  font-size: 18px;\n  line-height: 64px;\n  cursor: pointer;\n  transition: color 0.3s;\n}\n\n#components-layout-demo-custom-trigger .trigger:hover {\n  color: #1890ff;\n}\n\n#components-layout-demo-custom-trigger .logo {\n  height: 32px;\n  margin: 16px;\n  background: rgba(255, 255, 255, 0.3);\n}\n\n.site-layout .site-layout-background {\n  background: #fff;\n}\n\n.inner-tab-pane-div-button {\n  width: 100%;\n  text-align: right;\n  margin-bottom: 2%;\n}\n\n.menu-header-custom-style {\n  padding-left: 18px;\n  z-index: 100% !important;\n  width: 100%;\n  text-align: right;\n}\n\n.content-style-padding-div {\n  padding: 16px !important;\n  height: 100%;\n}\n\n.content-layout {\n  margin: 24px 16px 0;\n}\n\n#adminQmarketzLogo {\n  width: 78%;\n  margin: 10%;\n}\n\n.automart-logo {\n  width: 100% !important;\n  text-align: center !important;\n  padding: 15% !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
