import { Form, Input, Button, Checkbox, Select, Card, message } from "antd";
import { useState, useEffect, createRef } from "react";
import { UpdateGeneralInfo } from "../../../api/edit_ads/UpdateGeneralInfo";
import { FetchDropDownList } from "../../../api/edit_ads/FetchDropDownList";

const platform = process.env.REACT_APP_PLATFORM;
const vehicle = platform == "automart" ? "car" : "motor";

const { Option } = Select;
export default function GeneralData({ toEditData, onSearch, loadingList }) {
  const [dropdowns, setDropdowns] = useState(null);
  const [fetchingDropdowns, setFetchingDropdowns] = useState(false);
  const [sendingData, setSendingData] = useState(false);

  const [initialFieldsValue, setInitialFieldsValue] = useState({});
  const [currentFieldsValue, setCurrentFieldsValue] = useState({});
  const formRef = createRef();

  //on toEditData change
  useEffect(() => {
    //guard clause
    if (!toEditData || !toEditData[vehicle]) return;
    /*__________DATA STRUCTURE__________*\
        ad title
            yearModel = toEditData[vehicle].year_model,
            brand = toEditData[vehicle].model.make.name,
            model = toEditData[vehicle].model.name,
            trim = toEditData[vehicle].model.trim,
            engineDisplacement = toEditData[vehicle].model.engine_displacement,
        ad price
            bidStartPrice = toEditData.price_order,
            buyNowPrice = toEditData.buy_now_price,
        ad details
            transmissionType = toEditData[vehicle].model.transmission_type,
            fuelType = toEditData[vehicle].model.fuel_type,
            vehicleType = toEditData[vehicle].model.vehicleType.name,
            odometerRreading = toEditData[vehicle].odometer_reading,
            isFeatured = toEditData.is_featured,
            isClosed = toEditData.is_closed,
            orAvailable = toEditData[vehicle].or_available,
            crAvailable = toEditData[vehicle].cr_available,
        location info
            locationId = toEditData.warehouses.id,
            carAdviser = toEditData[vehicle].specialist_id,
        \*____________________________________*/

    //destructured from toEditData
    const {
      [vehicle]: {
        specialist_notes: specialistNotes,
        year_model: yearModel,
        odometer_reading: odometerReading,
        or_available: orAvailable,
        cr_available: crAvailable,
        specialist_id: carAdviser,
        model: {
          make_id: brand,
          name: model,
          trim,
          engine_displacement: engineDisplacement,
          transmission_type: transmissionType,
          fuel_type: fuelType,
          vehicle_type_id: vehicleType,
        },
      },
      price: bidStartPrice,
      price_order: priceOrder,
      buy_now_price: buyNowPrice,
      is_featured: isFeatured,
      is_closed: isClosed,
      slug,
    } = toEditData;

    //prioritize new_warehouse_id
    const locationId = toEditData.new_warehouse_id ?? toEditData.warehouse_id;

    //reset states, may do batch states update so we put a function as argument
    setCurrentFieldsValue(() => ({}));
    setDropdowns(() => null);
    //set initial fields state
    setInitialFieldsValue(() => ({
      specialistNotes,
      yearModel,
      odometerReading,
      orAvailable,
      crAvailable,
      carAdviser,
      brand,
      model,
      trim,
      engineDisplacement,
      transmissionType,
      fuelType,
      vehicleType,
      locationId,
      bidStartPrice,
      priceOrder,
      buyNowPrice,
      isFeatured,
      isClosed,
      slug,
    }));

    //increase dropdown item count limit when certain value exceeds 10
    const limitAdder = (param) => (param > 10 ? param - 10 : 0);

    //default dropdown item count is 10
    const queries = [
      {
        param: "makes",
        limit: 10 + limitAdder(brand),
      },
      {
        param: "vehicle-types",
        limit: 10,
      },
      {
        param: "warehouses",
        limit: 10 + limitAdder(locationId),
      },
    ];
    getDropdownsData(queries);
  }, [toEditData]);

  //update form's fields values when initialFieldsValue state has changed
  useEffect(() => {
    //trigger setstate: setCurrentFieldsValue from handleChange
    handleChange("locationId", initialFieldsValue.locationId);

    //set form fields
    toEditData &&
      toEditData[vehicle] &&
      formRef.current.setFieldsValue(initialFieldsValue);
  }, [initialFieldsValue]);

  //_____________________END OF REACT HOOKS___________________\\

  //guard clause || default return if no data found
  if (!toEditData || !toEditData[vehicle])
    return (
      <>
        <h2>General Information Section</h2>
        <p>Vehicle's general information will show here.</p>
      </>
    );

  //dropdown data getter
  async function getDropdownsData(queries) {
    //loading state
    setFetchingDropdowns(true);
    //fetch api
    const dropdownLists = await FetchDropDownList(queries);
    console.log(dropdownLists, "drop down lists");

    //set state
    setDropdowns((prevState) => ({ ...prevState, ...dropdownLists }));
    //loading state
    setFetchingDropdowns(false);
  }

  //before submit event
  const onFinish = async (values) => {
    //convert to int
    const buyNow = +values.buyNowPrice || null;
    const bidStart = +values.bidStartPrice || null;

    //if both have values or not 0
    if (buyNow && bidStart) {
      values.priceOrder = buyNow < bidStart ? buyNow : bidStart;
    } else {
      values.priceOrder = buyNow || bidStart;
    }
    values.buyNowPrice = buyNow;
    values.bidStartPrice = bidStart;

    //track if the value has changed depends on the initialFieldsValue
    const hasChangedFieldsValue = {};
    Object.entries(values).map(([key, value]) => {
      hasChangedFieldsValue[key] = initialFieldsValue[key] != value;
    });

    if (!Object.values(hasChangedFieldsValue).some((x) => x)) {
      message.error("No changes were made.", 3);
      return;
    }
    setSendingData(true);
    const ads_id = toEditData.id;

    const changesChecker = (key) => {
      //explicitly define the values for boolean cases to prevent misinterpretation of undefined/null
      const value =
        values[key] === 1 || values[key] === true
          ? 1
          : values[key] === 0 || values[key] === false
          ? 0
          : values[key];

      values.odometerReading =
        values.odometerReading === "" ? 0 : values.odometerReading;
      //special case for model table
      const modelPropNames = [
        "brand",
        "model",
        "trim",
        "engineDisplacement",
        "transmissionType",
        "fuelType",
        "vehicleType",
      ];
      const {
        brand,
        model,
        trim,
        engineDisplacement,
        transmissionType,
        fuelType,
        vehicleType,
      } = hasChangedFieldsValue;

      const isFromModel = modelPropNames.indexOf(key) >= 0;

      const hasOneChanged = [
        brand,
        model,
        trim,
        engineDisplacement,
        transmissionType,
        fuelType,
        vehicleType,
      ].some((x) => x);
      //return the value if it has changed, or undefined if not
      return hasChangedFieldsValue[key] || (isFromModel && hasOneChanged)
        ? value
        : undefined;
    };
    const rawPayload = {
      [vehicle]: {
        specialist_notes: changesChecker("specialistNotes"),
        year_model: changesChecker("yearModel"),
        odometer_reading: changesChecker("odometerReading"),
        or_available: changesChecker("orAvailable"),
        cr_available: changesChecker("crAvailable"),
        specialist_id: changesChecker("carAdviser"),
        model: {
          make_id: changesChecker("brand"),
          name: changesChecker("model"),
          trim: changesChecker("trim"),
          engine_displacement: changesChecker("engineDisplacement"),
          transmission_type: changesChecker("transmissionType"),
          fuel_type: changesChecker("fuelType"),
          vehicle_type_id: changesChecker("vehicleType"),
        },
      },
      new_warehouse_id: changesChecker("locationId"),
      price: changesChecker("bidStartPrice"),
      price_order: changesChecker("priceOrder"),
      buy_now_price: changesChecker("buyNowPrice"),
      is_featured: changesChecker("isFeatured"),
      is_closed: changesChecker("isClosed"),
    };

    //iterate {payload} through out the deepest level to delete undefined values
    const deleteUndefined = (table) => {
      Object.entries(table).forEach((entry) => {
        //check if the value is an object. if object, reiterate
        //array is also an object, so we check it as well
        if (
          typeof entry[1] == "object" &&
          !Array.isArray(entry[1]) &&
          entry[1] !== null
        ) {
          return deleteUndefined(entry[1]);
        }
        if (typeof entry[1] == "undefined") {
          delete table[entry[0]];
        }
      });
    };

    deleteUndefined(rawPayload);

    //______manually delete empty objects inside rawPayload________\\
    //model
    Object.entries(rawPayload[vehicle].model).length == 0 &&
      delete rawPayload[vehicle].model;
    //car
    Object.entries(rawPayload[vehicle]).length == 0 &&
      delete rawPayload[vehicle];
    //_____________________________________________________________\\
    // console.log("ID: ", ads_id);
    // console.log("VALUES: ", values);
    console.log("Success:", rawPayload);
    const status = await UpdateGeneralInfo(ads_id, rawPayload);
    status == 200 && onSearch(toEditData.slug, true);
    setSendingData(false);
  };

  const onFinishFailed = ({ errorFields }) => {
    console.log("Failed:", errorFields);
    errorFields.map((err) => {
      message.error(`${err.name[0]} - ${err.errors[0]}`);
    });
  };

  // update carAdviser dropdown on locationId change
  function handleChange(fieldId, fieldValue) {
    if (fieldId == "locationId") {
      //dynamic state change.. [fieldId]: fieldValue
      //still only for locationId for now
      setCurrentFieldsValue((prevState) => ({
        ...prevState,
        [fieldId]: fieldValue,
      }));
    }
  }

  //input elements creator
  const makeInput = ({
    type,
    label,
    fieldId,
    data,
    currentDataLength,
    total,
    required = true,
  }) => {
    switch (type) {
      case "text":
        return (
          <Form.Item
            key={fieldId}
            label={label}
            name={fieldId}
            rules={[
              {
                required,
                message: "This field is required",
              },
            ]}
          >
            <Input id={fieldId} />
          </Form.Item>
        );
      case "multiple":
        return (
          <Form.Item key={fieldId} label={label} name={fieldId}>
            <Input.TextArea rows={5} id={fieldId} />
          </Form.Item>
        );
      case "number":
        return (
          <Form.Item
            key={fieldId}
            label={label}
            name={fieldId}
            rules={[
              {
                required,
                message: "This field is required",
              },
            ]}
          >
            <Input type="number" id={fieldId} />
          </Form.Item>
        );
      case "select":
        return (
          <Form.Item
            key={fieldId}
            label={label}
            name={fieldId}
            extra={
              fieldId == "carAdviser" &&
              "Warning: If this field shows a number instead of the adviser's name, it means that the car adviser is not yet registered on the selected warehouse. Please register them first on the warehouse or select a named car adviser."
            }
            rules={[
              {
                required,
                pattern: /^(?!none_selected$)/,
                message: "This field is required",
              },
            ]}
          >
            <Select
              id={fieldId}
              onChange={(value) => {
                handleChange(fieldId, value);
              }}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  {currentDataLength < total && (
                    <Button
                      loading={fetchingDropdowns}
                      onClick={() => {
                        const query = [
                          {
                            param:
                              fieldId == "brand"
                                ? "makes"
                                : fieldId == "locationId"
                                ? "warehouses"
                                : "",
                            limit: currentDataLength + 10,
                          },
                        ];

                        query[0].param && getDropdownsData(query);
                      }}
                    >
                      See more...
                    </Button>
                  )}
                </>
              )}
            >
              {data.map((x, i) => {
                return (
                  <Option
                    key={i}
                    value={x.id || x.specialist_id}
                    disabled={x.id == "none_selected"}
                  >
                    {x.label ||
                      x.location ||
                      (x.user && x.user.first_name) ||
                      "item_not_named"}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        );

      case "checkbox":
        return (
          <Form.Item
            key={fieldId}
            name={fieldId}
            valuePropName={"checked"}
            wrapperCol={{ offset: 8, span: 16 }}
          >
            <Checkbox id={fieldId}>{label}</Checkbox>
          </Form.Item>
        );
      default:
        return;
    }
  };

  //dropdowns values
  const adTitleFields = dropdowns &&
    dropdowns.makes && [
      {
        type: "text",
        label: "Year Model",
        fieldId: "yearModel",
      },
      {
        type: "select",
        label: "Brand",
        fieldId: "brand",
        data: [
          { label: "Select Brand", id: "none_selected" },
          ...dropdowns.makes.data,
        ],
        currentDataLength: dropdowns.makes.data.length,
        total: dropdowns.makes.total,
      },
      {
        type: "text",
        label: "Model",
        fieldId: "model",
      },
      {
        type: "text",
        label: "Trim",
        fieldId: "trim",
        required: false,
      },
      {
        type: "text",
        label: "Engine Displacement",
        fieldId: "engineDisplacement",
        required: false,
      },
    ];
  const adPriceFields = [
    {
      type: "number",
      label: "Bid Start Price",
      fieldId: "bidStartPrice",
      required: false,
    },
    {
      type: "number",
      label: "Buy Now Price",
      fieldId: "buyNowPrice",
      required: false,
    },
  ];
  const adDetailsFields = dropdowns &&
    dropdowns["vehicle-types"] && [
      {
        type: "select",
        label: "Transmission Type",
        fieldId: "transmissionType",
        data: [
          { label: "Select Transmission Type", id: "none_selected" },
          { label: "Manual", id: "MT" },
          { label: "Automatic", id: "AT" },
        ],
      },
      {
        type: "select",
        label: "Fuel Type",
        fieldId: "fuelType",
        data: [
          { label: "Select Fuel Type", id: "none_selected" },
          { label: "Gas", id: "Gas" },
          { label: "Diesel", id: "Diesel" },
          { id: "Hybrid", name: "Hybrid" },
          { id: "Electric", name: "Diesel" },
        ],
      },
      {
        type: "select",
        label: "Vehicle Type",
        fieldId: "vehicleType",
        data: [
          { label: "Select Vehicle Type", id: "none_selected" },
          ...dropdowns["vehicle-types"].data,
        ],
      },
      {
        type: "number",
        label: "Odometer Reading",
        fieldId: "odometerReading",
        required: false,
      },
      {
        type: "checkbox",
        label: "Is Featured",
        fieldId: "isFeatured",
      },
      {
        type: "checkbox",
        label: "Is Closed",
        fieldId: "isClosed",
      },
      {
        type: "checkbox",
        label: "OR Available",
        fieldId: "orAvailable",
      },
      {
        type: "checkbox",
        label: "CR Available",
        fieldId: "crAvailable",
      },
      dropdowns,
    ];

  //validate values and get the warehouse from [dropdowns.warehouses.data] which id == warehouse.id
  const filteredLocationId =
    dropdowns &&
    dropdowns.warehouses &&
    dropdowns.warehouses.data &&
    typeof currentFieldsValue.locationId != "undefined" &&
    currentFieldsValue.locationId != "none_selected" &&
    dropdowns.warehouses.data.filter(
      (warehouse) => warehouse.id == currentFieldsValue.locationId
    )[0];

  //get the [specialists] table from our filteredLocationId above
  const specialists = filteredLocationId ? filteredLocationId.specialists : [];

  //use the [specialists] array for our Car Advisers dropdown
  const locationInfoFields = dropdowns &&
    dropdowns.warehouses && [
      {
        type: "select",
        label: "Location",
        fieldId: "locationId",
        data: [
          { label: "Select Location", id: "none_selected" },
          ...dropdowns.warehouses.data,
        ],
        currentDataLength: dropdowns.warehouses.data.length,
        total: dropdowns?.warehouses?.total || 0,
      },
      {
        type: "select",
        label: "Car Adviser",
        fieldId: "carAdviser",
        data: [
          { label: "Select Car Adviser", id: "none_selected" },
          ,
          ...specialists,
          ,
        ],
      },
    ];

  const LoadingCards = () => (
    <>
      <Card loading={true} className="card-loading" />
    </>
  );
  return (
    <>
      <h2>
        {toEditData[vehicle].year_model} {toEditData[vehicle].model.make.name}{" "}
        {toEditData[vehicle].model.name} {toEditData[vehicle].model.trim}{" "}
        {toEditData[vehicle].model.engine_displacement} | {toEditData.slug}
      </h2>
      <hr />
      <Form
        ref={formRef}
        name="general-info"
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 11 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {[
          {
            title: "Ad Title",
            fields: adTitleFields,
          },
          {
            title: "Ad Price",
            fields: adPriceFields,
          },
          {
            title: "Ad Details",
            fields: adDetailsFields,
          },
          {
            title: "Location Info",
            fields: locationInfoFields,
          },
        ].map(({ title, fields }, i) => {
          return (
            <div key={i}>
              <h4
                style={{
                  background: "#1890ff",
                  paddingLeft: 4,
                }}
              >
                {title}
              </h4>
              <hr />
              {loadingList || !fields ? (
                <LoadingCards />
              ) : (
                fields.map((field) => makeInput(field))
              )}
            </div>
          );
        })}
        <hr />
        {loadingList ? (
          <LoadingCards />
        ) : (
          makeInput({
            type: "multiple",
            label: "Specialist's Notes",
            fieldId: "specialistNotes",
          })
        )}
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            type="primary"
            htmlType="submit"
            onClick={async () => await formRef.current.validateFields()}
            loading={fetchingDropdowns || sendingData}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
