import React from "react";
import { Button, Space } from "antd";
import { SaveOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";

export const PostCreationFunctionalitiesComponent = (props) => {
  const {
    handleSaveAndPublish,
    handleSaveasDraft,
    handleCreateNew,
    isForUpdate = false,
    HandleCreateNewCopyAndSave,
    HandleUpdatePostWithMetaInformation,
    CREATE_COPY_AND_SAVE,
    SAVE_POST,
    SAVE_AS_DRAFT,
    UPDATE_POST,
  } = props;
  return (
    <>
      <div className="post-creation-function-btns-div">
        <Space size="small">
          {!isForUpdate ? (
            <>
              <Button
                type="primary"
                icon={<SaveOutlined />}
                onClick={handleSaveAndPublish}
                hidden={SAVE_POST === 0 ? true : false}
              >
                Save Post
              </Button>
              <Button
                type="default"
                icon={<EditOutlined />}
                onClick={handleSaveasDraft}
                hidden={SAVE_AS_DRAFT === 0 ? true : false}
              >
                Save as Draft
              </Button>
            </>
          ) : (
            <>
              <Button
                type="primary"
                icon={<SaveOutlined />}
                onClick={HandleUpdatePostWithMetaInformation}
                hidden={UPDATE_POST === 0 ? true : false}
              >
                Update Post
              </Button>
              <Button
                type="default"
                icon={<EditOutlined />}
                onClick={HandleCreateNewCopyAndSave}
                hidden={CREATE_COPY_AND_SAVE === 0 ? true : false}
              >
                Create Copy & Save
              </Button>
            </>
          )}
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleCreateNew}
          >
            Create New
          </Button>
        </Space>
      </div>
    </>
  );
};
