import { useEffect, useState } from "react";

const useOnChangeCity = () => {
  const [cityId, setSelectedCityId] = useState(null);
  const [cityName, setSelectedCityName] = useState(null);

  const onChangeCity = (id, cities) => {
    setSelectedCityId(id);

    const city = cities.find((city) => city.id === id);

    if (city) {
      setSelectedCityName(city.city_name);
    }
  };

  useEffect(() => {
    return () => {
      setSelectedCityId(null);
      setSelectedCityName(null);
    };
  }, []);

  return {
    cityId,
    cityName,
    onChangeCity,
  };
};

export default useOnChangeCity;
