import React, { useEffect, useRef, useState } from "react";
import { Divider, PageHeader, message, Form } from "antd";
import { PlusCircleOutlined, EditOutlined } from "@ant-design/icons";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { ModulesTableComponent } from "../ModulesTableComponent";
import { ModelsFilterAndSelectionComponent } from "../sub_components/models/ModelsFilterAndSelectionComponent";
import { FetchModelsList } from "../../api/models/FetchModelsList";
import { FetchMakes } from "../../api/makes/FetchMakes";
import { FetchVehicleTypes } from "../../api/vehicle_type/FetchVehicleTypes";
import { CreateModel } from "../../api/models/CreateModel";
import { UpdateModel } from "../../api/models/UpdateModel";
import { ModalwithFormComponent } from "../../components/ModalwithFormComponent";
import { ModulesFunctionalitiesComponent } from "../../components/ModulesFunctionalitiesComponent";
import { FormRules } from "../../dist/functions/formRules";
import { useComponentDidUpdate } from "../../hooks/useComponentDidUpdate";
import useColumnFilter from "../../hooks/useColumnFilter";
import { ModulesSelectAndColumnTagsComponent } from "../ModulesSelectAndColumnTagsComponent";

const initialRolesState = {
  UPDATE_MODELS: 0,
  CREATE_MODELS: 0,
};

const initialFilteredDataSet = {
  isFiltered: false,
  search_value: null,
  data: { data: [], total: 0 },
};

export const ItemModelsManagementComponent = () => {
  const {
    REQUIRED_FUEL_TYPE,
    REQUIRED_MODEL_NAME,
    REQUIRED_MAKE_ID,
    REQUIRED_TRANSMISSION_TYPE,
    REQUIRED_VEHICLE_TYPE,
  } = FormRules;
  const [createForm] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [data, setData] = useState({ data: [], total: 0 });
  const [makesList, setMakes] = useState({ data: [], total: 0 });
  const [vehicleTypesList, setVehicleTypes] = useState({
    data: [],
    total: 0,
  });
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showCreateModelModal, setShowCreateModelModal] = useState(false);
  const [showUpdateModelModal, setShowUpdateModelModal] = useState(false);
  const [currentPagination, setCurrentPagination] = useState(1);
  const [rolesRestriction, setRolesRestrictions] = useState(initialRolesState);
  const [filteredDataSet, setFilteredDataSet] = useState(
    initialFilteredDataSet
  );

  const [isShowColumnFilter, setIsShowColumnFilter] = useState(false);
  const [columnFilterTags, setColumnFilterTags] = useState([]);

  const [tableLoading, setTableLoading] = useState(false);
  const [currentCount, setCurrentCount] = useState(10);
  const [columnFilter, setColumnFilter] = useState([]);
  const sortColumn = useRef(null);
  const sortType = useRef("asc");

  const setSortColumn = (value) => {
    sortColumn.current = value;
  };

  const setSortType = (value) => {
    if (value === "descend") {
      sortType.current = "desc";
    } else if (value === "ascend") {
      sortType.current = "asc";
    } else {
      sortType.current = "none";
    }
  };

  const onSortChange = async (sortCol, sortType) => {
    setSortColumn(sortCol);
    setSortType(sortType);

    await FetchModels(currentPagination, currentCount);
  };

  const FetchModels = async (page, limit) => {
    setCurrentPagination(page);
    setTableLoading(true);
    const query = {
      page: page,
      limit: limit,
      sortColumn: sortColumn.current,
      sortType: sortType.current,
      columnFilter: columnFilter,
    };
    const data = await FetchModelsList(query);
    setData(data);
    setTableLoading(false);
  };

  const FetchMakeList = async (page, limit) => {
    const query = { page: page, limit: limit };
    const data = await FetchMakes(query);
    setMakes(data);
  };

  const FetchVehicleTypesList = async (page, limit) => {
    const query = { page: page, limit: limit };
    const data = await FetchVehicleTypes(query);
    setVehicleTypes(data);
  };

  ///////////////////////////////
  // for column filter tags
  const onCloseColumnFilterTag = (id) => {
    setColumnFilterTags((prevState) => {
      return prevState.filter((item) => item.id !== id);
    });

    setColumnFilter((prevState) => {
      let newData = prevState.filter((item) => {
        return Array.isArray(item.id)
          ? JSON.stringify(item.id) != id
          : item.id != id;
      });

      return newData;
    });

    if (!columnFilterTags.length) {
      setIsShowColumnFilter(false);
    }
  };

  const onLoadColumnFilterTags = () => {
    if (columnFilter.length) {
      let filterTags = columnFilter.map((e) => {
        let [label, tagId] = Array(2).fill(e.id);

        if (Array.isArray(e.id)) {
          tagId = JSON.stringify(tagId);
          label = "";

          for (let i = 0, n = e.id.length; i < n; i++) {
            label += `${e.id[i]} `;
          }
        }

        return {
          color: "processing",
          closable: true,
          selection_label: label,
          value: e.value,
          id: tagId,
          onClose: onCloseColumnFilterTag,
        };
      });

      setColumnFilterTags(filterTags);
      setIsShowColumnFilter(true);
    } else {
      // if columnFilter is just blank, then remove the tags
      setColumnFilterTags([]);
      setIsShowColumnFilter(false);
    }
  };

  const onClearColumnFilters = () => {
    setColumnFilterTags([]);
    setIsShowColumnFilter(false);
    setColumnFilter([]);
  };

  useEffect(() => {
    FetchModels(1, 10);
    FetchMakeList(1, 50);
    FetchVehicleTypesList(1, 50);
  }, []);

  useComponentDidUpdate(() => {
    FetchModels(1, currentCount);
    onLoadColumnFilterTags();
  }, [columnFilter]);

  const onPaginationChange = (page, limit) => {
    setCurrentPagination(page);
    setCurrentCount(limit);
    FetchModels(page, limit);
  };

  const onRowSelectionChange = (selectedRowKey, selectedRow) => {
    setSelectedRow(selectedRow[0]);
    setSelectedRowKey(selectedRowKey[0]);
  };

  const onClearSearcheAndSelection = () => {
    setSelectedRowKey(null);
    setSelectedRow(null);
  };

  const onClickCreateModal = () => {
    setShowCreateModelModal(!showCreateModelModal);
  };

  const onClickUpdateModal = () => {
    const modalStatus = !showUpdateModelModal;
    if (modalStatus) {
      updateForm.setFieldsValue(selectedRow);
    }

    setShowUpdateModelModal(modalStatus);
  };

  const onFinishCreation = async () => {
    const values = await createForm.validateFields();

    for (const key in values) {
      values[key] = values[key] ? values[key] : null;
    }

    await message.loading("Creating Models....");
    const payload = { data: values };
    const response = await CreateModel(payload);

    if (response.status === 201) {
      message.success("Successfuly Created Model");
      FetchModels(1, 10);
      onClickCreateModal();
      createForm.resetFields();
      setCurrentPagination(1);
      return;
    }

    if (response.status === 400) {
      const errorMessages = JSON.parse(response.response);
      for (const item of errorMessages) {
        await message.error(item.message, 2);
      }
      return;
    }
  };

  const onFinishUpdate = async () => {
    const values = await updateForm.validateFields();

    await message.loading("Updating Model....");
    const payload = { data: values };
    const response = await UpdateModel(selectedRowKey, payload);

    if (response.status === 200) {
      message.success("Successfuly Updated Model");
      FetchModels(1, 10);
      onClickUpdateModal();
      updateForm.resetFields();
      onClearSearcheAndSelection();
      setCurrentPagination(1);
      return;
    }
  };

  const onClickShowMoreVehicleBrand = async () => {
    const { page, lastPage, perPage } = makesList;
    if (page < lastPage) {
      const query = { page: parseInt(page) + 1, limit: perPage };
      const data = await FetchMakes(query);
      const oldMakes = makesList.data;
      for (const item of data.data) {
        oldMakes.push(item);
      }

      const newState = {
        ...data,
        data: oldMakes,
      };

      setMakes(newState);
      return;
    }
  };

  const onClickShowMoreVehicleType = async () => {
    const { page, lastPage, perPage } = vehicleTypesList;
    if (page < lastPage) {
      const query = { page: parseInt(page) + 1, limit: perPage };
      const data = await FetchVehicleTypesList(query);
      const oldVehicleType = vehicleTypesList.data;
      for (const item of data.data) {
        oldVehicleType.push(item);
      }

      const newState = {
        ...data,
        data: oldVehicleType,
      };

      setVehicleTypes(newState);
      return;
    }
  };

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const rolesRestriction = {};

    const CREATE_MODELS = permissions.find(
      (item) => item.name === "CREATE_MODELS"
    );

    rolesRestriction["CREATE_MODELS"] =
      typeof CREATE_MODELS === "undefined" ? 0 : CREATE_MODELS.has_access;

    const UPDATE_MODELS = permissions.find(
      (item) => item.name === "UPDATE_MODELS"
    );

    rolesRestriction["UPDATE_MODELS"] =
      typeof UPDATE_MODELS === "undefined" ? 0 : UPDATE_MODELS.has_access;

    setRolesRestrictions(rolesRestriction);
  }, []);

  const formInputsComponent = [
    {
      name: "engine_displacement",
      label: "Engine Displacement",
      default_value: "",
      configs: {
        type: "input",
        rules: [],
        list_value: "",
        place_holder: "",
      },
    },
    {
      name: "fuel_type",
      label: "Fuel Type",
      default_value: "",
      configs: {
        type: "select",
        rules: [REQUIRED_FUEL_TYPE],
        list_value: [
          { id: "Gas", name: "Gas" },
          { id: "Diesel", name: "Diesel" },
          { id: "Hybrid", name: "Hybrid" },
          { id: "Electric", name: "Diesel" },
        ],
      },
    },
    {
      name: "name",
      label: "Model Name",
      default_value: "",
      configs: {
        type: "input",
        rules: [REQUIRED_MODEL_NAME],
      },
    },
    {
      name: "transmission_type",
      label: "Transmission Type",
      default_value: "",
      configs: {
        type: "select",
        rules: [REQUIRED_TRANSMISSION_TYPE],
        list_value: [
          { id: "AT", name: "AT" },
          { id: "MT", name: "MT" },
          { id: "UNK", name: "UNK" },
          { id: "Unknown", name: "Unknown" },
        ],
        total: 0,
        onClickShowMoreList: () => {},
        place_holder: "Select Transmission Type",
      },
    },
    {
      name: "trim",
      label: "Trim",
      default_value: "",
      configs: {
        type: "input",
        rules: [],
      },
    },
  ];

  const functionalitiesComponent = [
    {
      component_type: "button",
      type: "primary",
      icon: <PlusCircleOutlined />,
      _onClick: onClickCreateModal,
      is_hidden: rolesRestriction.CREATE_MODELS === 0 ? true : false,
      label: "Create Model",
      size: "default",
    },
    {
      component_type: "button",
      type: "default",
      icon: <EditOutlined />,
      _onClick: onClickUpdateModal,
      is_hidden:
        rolesRestriction.UPDATE_MODELS === 0
          ? true
          : selectedRowKey
          ? false
          : true,
      label: "Update Model",
      size: "default",
    },
  ];

  const { getColumnFilterProps, getColumnYesNoFilter } = useColumnFilter(
    columnFilter,
    setColumnFilter
  );

  const tableColumnsComponent = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      sorter: true,
      ...getColumnFilterProps("id"),
    },
    {
      title: "Engine Displacement",
      key: "engine_displacement",
      dataIndex: "engine_displacement",
      sorter: true,
      ...getColumnFilterProps("engine_displacement"),
    },
    {
      title: "Fuel Type",
      dataIndex: "fuel_type",
      key: "fuel_type",
      sorter: true,
      ...getColumnFilterProps("fuel_type"),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      ...getColumnFilterProps("name"),
    },
    {
      title: "Transmission Type",
      dataIndex: "transmission_type",
      key: "transmission_type",
      sorter: true,
      ...getColumnFilterProps("transmission_type"),
    },
    {
      title: "Brand",
      dataIndex: ["make", "name"],
      key: "make",
      sorter: true,
      ...getColumnFilterProps(["make", "name"]),
    },
    {
      title: "Type",
      dataIndex: ["vehicleType", "name"],
      key: "vehicleType",
      sorter: true,
      ...getColumnFilterProps(["vehicleType", "name"]),
    },
    {
      title: "Trim",
      dataIndex: "trim",
      key: "trim",
      sorter: true,
      ...getColumnFilterProps("trim"),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      ...getColumnFilterProps("created_at"),
    },
  ];

  return (
    <>
      <PageHeader
        title="MODELS"
        subTitle="MODELS MANAGEMENT"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["Settings", "Models"]} />
        )}
      />
      <Divider />
      <div className="content-pane-transaction-div-style">
        <ModulesFunctionalitiesComponent
          alignment={"right"}
          functionalitiesComponent={functionalitiesComponent}
          spaceSize={"small"}
        />

        <ModulesSelectAndColumnTagsComponent
          onClearColumnFilters={onClearColumnFilters}
          isShowColumnFilterTags={isShowColumnFilter}
          columnFilterTags={columnFilterTags}
          isShowSelection={!!selectedRow}
          onClearSearcheAndSelection={onClearSearcheAndSelection}
          tags={[
            {
              color: "processing",
              selection_label: "Selected Model",
              value: selectedRow
                ? `${selectedRow.name} - ${selectedRow.trim}`
                : null,
            },
          ]}
        />
        <ModulesTableComponent
          data={data}
          onPaginationChange={onPaginationChange}
          onSelection={onRowSelectionChange}
          selectedRowKey={selectedRowKey}
          size={"small"}
          selectionType={"radio"}
          tableColumns={tableColumnsComponent}
          currentPagination={currentPagination}
          onSortChange={onSortChange}
          tableLoading={tableLoading}
        />
      </div>
      <ModalwithFormComponent
        title={"Create Model"}
        form={createForm}
        formLayout={"vertical"}
        formInputsComponent={[
          ...formInputsComponent,
          {
            name: "vehicle_type_id",
            label: "Vehicle Type",
            default_value: "",
            configs: {
              type: "select",
              rules: [REQUIRED_VEHICLE_TYPE],
              list_value: vehicleTypesList.data,
              total: vehicleTypesList.total,
              onClickShowMoreList: onClickShowMoreVehicleType,
              place_holder: "Select Vehicle Type",
            },
          },
          {
            name: "make_id",
            label: "Vehicle Brand",
            default_value: "",
            configs: {
              type: "select",
              rules: [REQUIRED_MAKE_ID],
              list_value: makesList.data,
              place_holder: "Select Vehicle Brand",
              total: makesList.total,
              onClickShowMoreList: onClickShowMoreVehicleBrand,
            },
          },
        ]}
        onOk={onFinishCreation}
        onCancel={onClickCreateModal}
        destroyOnClose={true}
        okText={"Save Model"}
        okCancel={"Cancel"}
        visible={showCreateModelModal}
      />
      <ModalwithFormComponent
        title={"Update Model"}
        form={updateForm}
        formLayout={"vertical"}
        formInputsComponent={[
          ...formInputsComponent,
          {
            name: "make_id",
            label: "Vehicle Brand",
            default_value: "",
            configs: {
              type: "select",
              rules: [REQUIRED_MAKE_ID],
              list_value: makesList.data,
              place_holder: "Select Vehicle Brand",
              total: makesList.total,
              onClickShowMoreList: onClickShowMoreVehicleBrand,
            },
          },
        ]}
        onOk={onFinishUpdate}
        onCancel={onClickUpdateModal}
        destroyOnClose={true}
        okText={"Save Model"}
        okCancel={"Cancel"}
        visible={showUpdateModelModal}
      />
    </>
  );
};
