import { useFetch } from "../../hooks/useFetch";
import { message } from "antd";

// const sampleApi = {
//     message: "Successfuly Searched Ads",
//     data: {
//         id: 2,
//         title: " A0K430 Toyota Vios E",
//         slug: "a0k430",
//         description: " A0K430 Toyota Vios E Gasoline Manual Libis  QC",
//         category_id: null,
//         buy_now_price: null,
//         sub_category_id: null,
//         brand_id: null,
//         type: null,
//         ad_condition: null,
//         model: null,
//         buy_now_type: null,
//         price: 365500,
//         price_order: 365500,
//         is_negotiable: null,
//         is_featured: 0,
//         seller_name: null,
//         seller_email: null,
//         seller_phone: null,
//         warehouse_id: 1,
//         new_warehouse_id: null,
//         country_id: 1,
//         state_id: 1,
//         city_id: 1,
//         address: null,
//         video_url: null,
//         category_type: null,
//         status: "1",
//         price_plan: null,
//         mark_ad_agent: null,
//         view: 470,
//         max_impression: null,
//         user_id: null,
//         relist: 0,
//         is_closed: 1,
//         winner_id: null,
//         winner_payment: null,
//         latitude: null,
//         longitude: null,
//         purchased_at: null,
//         expired_at: "2019-11-06T01:00:58.000Z",
//         created_at: "2019-10-09 12:09:30",
//         updated_at: "2020-01-03 13:02:45",
//         categories: null,
//         warehouses: {
//             id: 1,
//             viewing_address: "Automart Mercury Ave. Cor C5 Ave",
//             landmark: "Near Eastwood City",
//             location: "Libis",
//             direction_here: "",
//             city_id: 3,
//             country_id: 1,
//             days_open: "M-F Whole Day; Sat-Half Day; By Appt Only",
//             days_avail: "Mon,Tue,Wed,Thu,Fri,Sat.5",
//             hours_open: "10AM-5PM",
//             slots: "10am,11am,1pm,2pm,3pm,4pm",
//             bank_id: 3,
//             created_at: "2019-08-02 11:10:52",
//             updated_at: null,
//         },
//         state: null,
//         city: {
//             id: 1,
//             city_name: "Cebu",
//             alternative_name: "cebu",
//             state_id: 1,
//             created_at: null,
//             updated_at: null,
//         },
//         user: null,
//         medias: [
//             {
//                 id: 6,
//                 user_id: 1,
//                 ad_id: 2,
//                 post_id: null,
//                 media_name: "a0k430-1.jpg",
//                 url: "https://automart-dev.sgp1.digitaloceanspaces.com/a0k430/a0k430-1.jpg",
//                 type: "image",
//                 is_feature: null,
//                 storage: "do_spaces",
//                 ref: "ad",
//                 created_at: "2019-10-09 12:09:30",
//                 updated_at: "2019-10-09 12:40:04",
//             },
//             {
//                 id: 7,
//                 user_id: 1,
//                 ad_id: 2,
//                 post_id: null,
//                 media_name: "a0k430-2.jpg",
//                 url: "https://automart-dev.sgp1.digitaloceanspaces.com/a0k430/a0k430-2.jpg",
//                 type: "image",
//                 is_feature: null,
//                 storage: "do_spaces",
//                 ref: "ad",
//                 created_at: "2019-10-09 12:09:30",
//                 updated_at: "2019-10-09 12:40:04",
//             },
//             {
//                 id: 8,
//                 user_id: 1,
//                 ad_id: 2,
//                 post_id: null,
//                 media_name: "a0k430-3.jpg",
//                 url: "https://automart-dev.sgp1.digitaloceanspaces.com/a0k430/a0k430-3.jpg",
//                 type: "image",
//                 is_feature: null,
//                 storage: "do_spaces",
//                 ref: "ad",
//                 created_at: "2019-10-09 12:09:30",
//                 updated_at: "2019-10-09 12:40:04",
//             },
//             {
//                 id: 9,
//                 user_id: 1,
//                 ad_id: 2,
//                 post_id: null,
//                 media_name: "a0k430-4.jpg",
//                 url: null,
//                 type: "image",
//                 is_feature: null,
//                 storage: "do_spaces",
//                 ref: "ad",
//                 created_at: "2019-10-09 12:09:30",
//                 updated_at: "2019-10-09 12:09:30",
//             },
//             {
//                 id: 10,
//                 user_id: 1,
//                 ad_id: 2,
//                 post_id: null,
//                 media_name: "a0k430-5.jpg",
//                 url: null,
//                 type: "image",
//                 is_feature: null,
//                 storage: "do_spaces",
//                 ref: "ad",
//                 created_at: "2019-10-09 12:09:30",
//                 updated_at: "2019-10-09 12:09:30",
//             },
//         ],
//     },
// };
export const SearchSlug = async (query) => {
    const { page, limit, slug } = query;
    // console.log("query:",query)
    try {
        // const data = await useFetch(
        //     `${process.env.REACT_APP_API_URL}/ads/query?page=${page}&limit=${limit}&slug=${slug}`
        // );
        // NOTE: changed this to have a dynamic param on query
        const data = await useFetch(
            `${process.env.REACT_APP_API_URL}/ads/query`,
            query
        );

        if (data.data) {
            message.success(`Searched data for ${slug}`, 2);
            data.data.data = data.data.data.map((x, i) => ({ ...x, key: i }));
            // console.log("data.data:",data.data)
            // console.log("API: ",`${process.env.REACT_APP_API_URL}/ads/query?page=${page}&limit=${limit}&slug=${slug}`)
            return data.data;
        } else {
            message.error(`No results found for ${slug}`, 3);
            return {};
        }
    } catch (err) {
        message.error(`Failed to search, please try again`, 3);
        console.log("ERROR: ", err.stack);
        return {};
    }
};
