import { CreateRemarksSubGroup } from "../../api/remarks_sub_group/CreateRemarksSubGroup";
import { message } from "antd";

const useCreateRemarksSubGroup = async (payload) => {
  const response = await CreateRemarksSubGroup({ data: payload });

  if (response.status === 201) {
    await message.success(
      response.message || "Successfuly Created Remarks Sub Group",
      2
    );
    return true;
  }

  await message.error("Error Creating Remarks Sub Group", 2);
  return false;
};

export default useCreateRemarksSubGroup;
