import { useEffect, useState } from "react";
import axios from "axios";

const useFetchSessions = () => {
  const [activeSessions, setActiveSessions] = useState([]);

  const fetchSessions = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/sessions`
    );

    if (response.status === 200) {
      const { data } = response;
      const mappedSessions = data.map(({ key, value }, index) => {
        return {
          id: index,
          key,
          value: JSON.parse(value),
        };
      });
      setActiveSessions(mappedSessions);
      return;
    }
  };

  useEffect(() => {
    fetchSessions();
  }, []);

  return {
    activeSessions,
  };
};

export default useFetchSessions;
