// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ads-sub-information-div {
  width: 100%;
  overflow-y: auto;
  height: 450px;
}

.ads-sub-information-div::-webkit-scrollbar {
  display: none;
}

.sub-information-col-div {
  padding-left: 1% !important;
  width: 100% !important;
}

.ads-creation-form-other-information-div {
  width: 100%;
  overflow-y: auto;
  height: 450px;
  padding-left: 2%;
  border-left-color: whitesmoke;
  border-left-style: solid;
  border-left-width: thin;
  margin-left: 2% !important;
  padding-right: 2%;
}

.ads-creation-form-other-information-div::-webkit-scrollbar {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/dist/style/ads.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,2BAA2B;EAC3B,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,aAAa;EACb,gBAAgB;EAChB,6BAA6B;EAC7B,wBAAwB;EACxB,uBAAuB;EACvB,0BAA0B;EAC1B,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".ads-sub-information-div {\n  width: 100%;\n  overflow-y: auto;\n  height: 450px;\n}\n\n.ads-sub-information-div::-webkit-scrollbar {\n  display: none;\n}\n\n.sub-information-col-div {\n  padding-left: 1% !important;\n  width: 100% !important;\n}\n\n.ads-creation-form-other-information-div {\n  width: 100%;\n  overflow-y: auto;\n  height: 450px;\n  padding-left: 2%;\n  border-left-color: whitesmoke;\n  border-left-style: solid;\n  border-left-width: thin;\n  margin-left: 2% !important;\n  padding-right: 2%;\n}\n\n.ads-creation-form-other-information-div::-webkit-scrollbar {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
