import React from "react";
import { Table, Alert, Collapse } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import useColumnFilter from "../../../hooks/useColumnFilter";

const { Column } = Table;
const { Panel } = Collapse;

export const BidsTableComponent = (props) => {
  const {
    data,
    filteredDataFrontend,
    tableLoading,
    onSelectRowChange,
    selectedRowKeys,
    platform = "car",
    onSortChange,
    columnFilter,
    setColumnFilter,
    columnFilterFrontend,
    setColumnFilterFrontend,
    isFilteredFrontend,
  } = props;

  const { getColumnFilterProps, getColumnDynamicFilter } = useColumnFilter(
    columnFilter,
    setColumnFilter
  );

  const filterFrontendObj = useColumnFilter(
    columnFilterFrontend,
    setColumnFilterFrontend
  );

  const getColumnDynamicFilterFrontend =
    filterFrontendObj.getColumnDynamicFilter;

  return (
    <>
      <Collapse>
        <Panel
          showArrow={false}
          header={
            <span>
              <InfoCircleOutlined /> Information
            </span>
          }
        >
          <Alert
            message="Bids Report Filtering/Searching Important Notes"
            description={
              <ul>
                <li>
                  <li>
                    if checkbox is checked, will include all the previous bids
                    on the table and report
                  </li>
                </li>
                <li>
                  if checkbox is not checked, will not included previous bids on
                  the table and report
                </li>
              </ul>
            }
            type="info"
            showIcon
          />
          <br />
          <Alert
            message="Bids Report Additional Notes"
            description={
              <>
                <ul>
                  <li>previous bids = bids to specific unit/item but hidden</li>
                  <li>
                    current bids = bids attached to current unit/item but not
                    hidden
                  </li>
                </ul>
                <span>
                  hidden bids meaning that the unit is relisted or the previous
                  winner was removed from the unit
                </span>
              </>
            }
            type="info"
            showIcon
          />
        </Panel>
      </Collapse>
      <br />
      <Table
        dataSource={isFilteredFrontend ? filteredDataFrontend : data}
        size="small"
        loading={tableLoading}
        scroll={{ x: 2500, y: 500 }}
        rowSelection={{
          type: "radio",
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectRowChange,
        }}
        onChange={(pagination, filters, sorter, action) => {
          if (
            action.action === "sort" &&
            sorter.column &&
            sorter.column.sorter === true
          ) {
            onSortChange(sorter.field, sorter.order);
          }
        }}
        pagination={false}
        bordered
      >
        <Column
          title="Bid Date"
          name="created_at"
          dataIndex="created_at"
          sorter={true}
          {...getColumnFilterProps("created_at")}
          fixed="left"
        />
        <Column
          title="Year Model"
          render={(row) => {
            return row.split(" ")[0];
          }}
          dataIndex={["ads", "title"]}
          sorter={true}
          {...getColumnFilterProps(["ads", "title"])}
        />
        <Column
          title={`${
            platform.charAt(0).toUpperCase() + platform.slice(1)
          } Title`}
          name={["ads", "title"]}
          dataIndex={["ads", "title"]}
          render={(row, record) => {
            let row_ = row;

            const model = row_.split(" ")[0];
            const trans_type = record?.ads?.car?.model?.transmission_type;

            if (trans_type) {
              row_ = `${row} ${trans_type}`;
            }

            if (model) {
              row_ = row_.replace(model, "");
            }

            return row_;
          }}
          sorter={true}
        />
        <Column
          title="Conduction Sticker"
          render={(row) => row.split(" ")[0]}
          dataIndex={["ads", "car", "conduction_sticker"]}
        />
        <Column
          title="Plate Number"
          name={["ads", "slug"]}
          dataIndex={["ads", "slug"]}
          sorter={(a, b) => a[`ads,slug`].localeCompare(b[`ads,slug`])}
          {...getColumnFilterProps(["ads", "slug"])}
          render={(value) => {
            return value.toUpperCase();
          }}
        />
        <Column
          title="Middle Initial"
          render={(row) => {
            var middle_initial = "";

            if (row.users?.middle_name) {
              const lcase_middle_name = row.users.middle_name.toLowerCase();
              if (
                lcase_middle_name === "n/a" ||
                lcase_middle_name === "na" ||
                lcase_middle_name === "n-a"
              ) {
                middle_initial = "";
              } else {
                middle_initial = row.users.middle_name.charAt(0);
              }
            }

            return middle_initial;
          }}
        />
        <Column
          title="Winner First Name"
          name={["users", "first_name"]}
          dataIndex={["users", "first_name"]}
          sorter={(a, b) =>
            a[`users,first_name`].localeCompare(b[`users,first_name`])
          }
          {...getColumnFilterProps(["users", "first_name"])}
        />
        <Column
          title="Winner Last Name"
          name={["users", "last_name"]}
          dataIndex={["users", "last_name"]}
          sorter={(a, b) =>
            a[`users,last_name`].localeCompare(b[`users,last_name`])
          }
          {...getColumnFilterProps(["users", "last_name"])}
        />

        <Column
          title="Bid Amount"
          name="bid_amount"
          dataIndex="bid_amount"
          sorter={(a, b) =>
            a[`bid_amount`].toString().localeCompare(b[`bid_amount`].toString())
          }
          {...getColumnFilterProps("bid_amount")}
        />
        <Column
          title="Payment Method"
          name="payment_method"
          dataIndex="payment_method"
          sorter={(a, b) =>
            a[`payment_method`].localeCompare(b[`payment_method`])
          }
          {...getColumnFilterProps("payment_method")}
        />
        <Column
          title="Seller Location"
          name={["ads", "warehouses", "location"]}
          dataIndex={["ads", "warehouses", "location"]}
          sorter={(a, b) =>
            a[`ads,warehouses,location`].localeCompare(
              b[`ads,warehouses,location`]
            )
          }
          {...getColumnFilterProps(["ads", "warehouses", "location"])}
        />
        <Column
          title="Seller Name"
          render={(row) => {
            const new_adviser = row.ads[`${platform}`].new_adviser;

            if (new_adviser) {
              const { first_name, last_name } = new_adviser;
              return `${first_name || ""} ${last_name || ""}`;
            }

            const adviser = row.ads[`${platform}`].adviser;

            if (adviser) {
              const { first_name, last_name } = adviser;
              return `${first_name || ""} ${last_name || ""}`;
            }
          }}
        />
        <Column
          title="Winner Phone"
          name={["users", "mobile"]}
          dataIndex={["users", "mobile"]}
          sorter={(a, b) => a[`users,mobile`].localeCompare(b[`users,mobile`])}
          {...getColumnFilterProps(["users", "mobile"])}
        />
        <Column
          title="Winner Email"
          name={["users", "email"]}
          dataIndex={["users", "email"]}
          sorter={(a, b) => a[`users,email`].localeCompare(b[`users,email`])}
          {...getColumnFilterProps(["users", "email"])}
        />
        <Column
          title="Unit Color"
          name={["ads", `${platform}`, "color", "specific_name"]}
          dataIndex={["ads", `${platform}`, "color", "specific_name"]}
          sorter={(a, b) =>
            a[`users,${platform},color,specific_name`].localeCompare(
              b[`users,${platform},color,specific_name`]
            )
          }
          {...getColumnFilterProps([
            "ads",
            `${platform}`,
            "color",
            "specific_name",
          ])}
        />
        <Column
          title="Payment Method"
          name="payment_method"
          dataIndex="payment_method"
        />
        <Column
          title="Auction Status"
          name="auction_status"
          dataIndex="auction_status"
          {...getColumnDynamicFilterFrontend("auction_status", [
            {
              text: "Ended",
              value: "Ended",
            },
            {
              text: "On Going",
              value: "On Going",
            },
            {
              text: "Invalid",
              value: "Invalid",
            },
          ])}
          sorter={(a, b) => a.auction_status.localeCompare(b.auction_status)}
          fixed="right"
        />
        <Column
          title="Bid Status"
          name="user_bid_status"
          dataIndex="user_bid_status"
          filterMultiple={false}
          {...getColumnDynamicFilterFrontend("user_bid_status", [
            {
              text: "Outbidded",
              value: "Outbidded",
            },
            {
              text: "Winner",
              value: "Winner",
            },
          ])}
          sorter={(a, b) => a.user_bid_status.localeCompare(b.user_bid_status)}
          fixed="right"
        />
        <Column
          title="Previous Bid?"
          name="is_accepted"
          dataIndex="is_accepted"
          render={(isAccepted) => {
            return isAccepted === 1 ? "No" : "Yes";
          }}
          fixed="right"
        />
        <Column
          title="Referrer"
          name={["users", "referrer"]}
          dataIndex={["users", "referrer"]}
          sorter={(a, b) =>
            a["users,referrer"].localeCompare(b["users,referrer"])
          }
          {...getColumnFilterProps(["users", "referrer"])}
          fixed="right"
        />
      </Table>
    </>
  );
};
