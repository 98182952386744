export const postCreationPayload = (values, status = null, media_ids = []) => {
  var payload = {
    user_id: parseInt(localStorage.getItem("user_id")),
    title: values.title,
    slug: values.slug,
    faq_id: values.faq_id,
    review_id: values.review_id,
    type: "post",
    show_in_header_menu: values.show_in_header_menu,
    show_in_footer_menu: values.show_in_footer_menu,
    use_legacy_content: values.use_legacy_content,
    cms_post_contents: [],
    media_ids: media_ids,
    metas: {
      title: values.meta_title || null,
      description: values.meta_description || null,
      default_share_photo: "https://automart.ph/images/default_share_photo.jpg",
      og_title: values.og_title,
      og_description: values.og_description,
      og_url: "https://automart.ph",
      copyright: "Automart.ph is a registered trademark of Qmarketz Inc.",
    },
  };

  if (values.body.length > 0) {
    for (const [index, item] of values.body.entries()) {
      payload["cms_post_contents"] = [
        ...payload.cms_post_contents,
        {
          id: item.id || null,
          title: item.title,
          description: item.description,
          is_show: item.is_show,
          sequence: index + 1,
          type: "n/a",
        },
      ];
    }
  }

  const remarks = [];

  if (values.advantage_sub_group_id) {
    const advantage_sub_group_body = {
      post_id: null,
      classification: "advantages",
      remarks_pivot: {
        group_id: null,
        sub_group_id: values.advantage_sub_group_id || null,
        classification: "advantages",
      },
    };
    remarks.push(advantage_sub_group_body);
  }

  if (values.disadvantage_sub_group_id) {
    const disadvantage_sub_group_body = {
      post_id: null,
      classification: "disadvantages",
      remarks_pivot: {
        group_id: null,
        sub_group_id: values.disadvantage_sub_group_id || null,
        classification: "disadvantages",
      },
    };
    remarks.push(disadvantage_sub_group_body);
  }

  payload["remarks"] = remarks;

  if (status) {
    payload["status"] = status;
  }

  return payload;
};
