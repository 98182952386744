import { useCreate } from "../../hooks/useCreate";

export const CreateUser = async (data) => {
  try {
    const result = await useCreate(
      `${process.env.REACT_APP_API_URL}/users/create`,
      data
    );
    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    }

    if (error.request) {
      return error.request;
    }

    return error.message;
  }
};
