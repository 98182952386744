import React from "react";
import { Button } from "antd";

export const SelectOptionFooterExtraButtonComponent = (props) => {
  const { menu, onClickShowMoreList, isClickShowMoreDisabled } = props;
  return (
    <div>
      {menu}
      <div style={{ textAlign: "center", margin: "1.5%" }}>
        <Button
          type="link"
          size="small"
          onClick={onClickShowMoreList}
          disabled={isClickShowMoreDisabled}
        >
          Show More
        </Button>
      </div>
    </div>
  );
};
