import { UpdateRemarksSubGroup } from "../../api/remarks_sub_group/UpdateRemarksSubGroup";
import { message } from "antd";

const useUpdateRemarksSubGroup = async (id, payload) => {
  await message.loading("Updating Remarks Sub Group", 2);
  const response = await UpdateRemarksSubGroup(id, payload);
  if (response.status === 200) {
    await message.success("Successfuly Updated Remarks Sub Group", 2);
    return true;
  }
  await message.error("Error Updating Remarks Sub Group", 2);
  return false;
};

export default useUpdateRemarksSubGroup;
