import React, { useEffect, useState } from "react";
import { Divider, PageHeader, Table, Image, Input, Button } from "antd";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { CloseOutlined } from "@ant-design/icons";
import axios from "axios";

const { Search } = Input;

const columns = [
  {
    title: "Image",
    key: "images",
    dataIndex: "images",
    render: (item) => {
      return <Image src={item[0].url} width={150} />;
    },
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Plate Number / Conduction Sticker",
    dataIndex: "vehicle_offer_id",
    render: (item) => {
      return (
        <a href={`ads-editor/${item}`} target="_blank">
          {item}
        </a>
      );
    },
  },
];

export const XMLManagementComponent = () => {
  const [xml, setXml] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [filteredXML, setFilteredXML] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const data = await axios.get(
        `${process.env.REACT_APP_CORE_API_URL}/sitemap`
      );

      if (data.data) {
        setXml(data.data);
      }
    };

    fetch();
  }, []);

  const onSearch = (e) => {
    const filteredXML = xml.filter((item) => item.vehicle_offer_id.includes(e));
    setFilteredXML(filteredXML);
    setIsFiltered(true);
  };

  const onCloseFilter = () => {
    setIsFiltered(!isFiltered);
  };

  return (
    <>
      <PageHeader
        title="XML"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["Xml"]} />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <div style={{ marginBottom: "2%", width: "20%" }}>
          <Search onSearch={onSearch} size="small" />
        </div>
        <div>
          {isFiltered ? (
            <Button
              type="text"
              icon={<CloseOutlined />}
              size="small"
              onClick={onCloseFilter}
            />
          ) : null}
        </div>
        <Table
          columns={columns}
          dataSource={isFiltered ? filteredXML : xml}
          pagination={{
            total: isFiltered ? filteredXML.length : xml.length,
          }}
          size="small"
          bordered={true}
        />
      </div>
    </>
  );
};
