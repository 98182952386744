import { DeleteMedia } from "../../api/cms/DeleteMedia";
import { message } from "antd";

const useDeleteMedia = async (payload) => {
  const response = await DeleteMedia(payload);

  if (response.status === 200) {
    message.success("Deleted Media Successfuly");
    return true;
  }

  return false;
};

export default useDeleteMedia;
