import axios from "axios";

export const useFetch = async (url, params = {}) => {
  const header = {
    headers: { Authorization: localStorage.getItem("token") },
  };

  const data = await axios.get(url, { params, headers: header.headers });
  return data.data;
};
