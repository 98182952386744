import { FetchModules } from "../../api/admin/FetchModules";
import { message } from "antd";

const useGetModulesIndex = async (query, is_for_table) => {
  // await message.loading("Fetching Modules...", 2);
  // NOTE: its faster to make message with key and not await it... just destroy it afterwards
  message.loading({ content: 'Fetching Modules...', key: "useGetModules", duration: 10 });
  const response = await FetchModules(query);

  for (const item of response.data.data) {
    item["key"] = item.id;
  }

  if (is_for_table) {
    message.destroy("useGetModules");
    message.success("Successfuly Fetch Modules", 2);
    return response.data;
  }

  return response.data.data;
};

export default useGetModulesIndex;
