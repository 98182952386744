import React from "react";
import { ModulesFunctionalitiesComponent } from "../../ModulesFunctionalitiesComponent";

export const RemarksSubGroupListFunctionalityComponent = (props) => {
  const { onSearchRemarksSubGroup } = props;

  const functionalitiesComponent = [
    {
      component_type: "search_input",
      type: "default",
      _onSearch: onSearchRemarksSubGroup,
      place_holder: "Search Remarks Sub Group",
    },
  ];

  return (
    <ModulesFunctionalitiesComponent
      functionalitiesComponent={functionalitiesComponent}
      alignment="right"
      spaceSize="small"
    />
  );
};
