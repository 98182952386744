import React from "react";
import { Space, Button } from "antd";
import { SaveOutlined, RedoOutlined } from "@ant-design/icons";

export const AdsFunctionalityComponent = (props) => {
  const { handleSubmitAds, handleRefreshForm } = props;
  return (
    <>
      <Space size="small">
        <Button
          type="primary"
          icon={<SaveOutlined />}
          onClick={handleSubmitAds}
        >
          Submit Ads
        </Button>
        <Button
          type="default"
          icon={<RedoOutlined />}
          onClick={handleRefreshForm}
        >
          Refresh Form
        </Button>
      </Space>
    </>
  );
};
