import React from "react";
import { Tag, Button, Typography, Divider, Space } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import useIsMobile from "../../../hooks/useIsMobile";

const { Title } = Typography;

const FilterTag = ({ label, value, onClose, closable = false }) =>
  value ? (
    <Tag color="processing" closable={closable} onClose={onClose}>
      {`${label}: ${value}`}
    </Tag>
  ) : null;

export const BuyNowFiltersAndSelectionsComponent = ({
  onRemoveAllSearches,
  onRemoveSelection,
  reportDateRange,
  selectedRow,
  searchKeyword,
  keywordType,
  onClearColumnFilters,
  columnFilterTags = [],
}) => {
  const isMobile = useIsMobile();
  if (
    !reportDateRange &&
    !keywordType &&
    !searchKeyword &&
    !selectedRow &&
    columnFilterTags.length === 0
  ) {
    return null;
  }

  return (
    <>
      <Title level={5}>Filters:</Title>
      <Space size="small" direction={isMobile ? "vertical" : "horizontal"}>
        <FilterTag label="Report Date" value={reportDateRange} />
        <FilterTag
          label="Selected Unit"
          value={selectedRow?.ads?.slug}
          onClose={onRemoveSelection}
          closable
        />
        <FilterTag
          label={`Searched By ${keywordType?.toUpperCase() || ""}`}
          value={searchKeyword}
        />
        {columnFilterTags.map(({ id, selection_label, value, closable, onClose }) => (
          <FilterTag
            key={id}
            label={selection_label}
            value={value}
            onClose={closable ? () => onClose(id) : undefined}
            closable={closable}
          />
        ))}
        <Button
          type="link"
          icon={<CloseOutlined />}
          size="small"
          onClick={() => {
            onRemoveAllSearches();
            onClearColumnFilters();
          }}
        >
          Clear Filters
        </Button>
      </Space>
      <Divider />
    </>
  );
};
