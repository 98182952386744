import { useEffect, useState } from "react";

const useOnFormChanges = () => {
  const [warehouseName, setWarehouseName] = useState(null);
  const [formValues, setFormValues] = useState(null);

  const onFormChanges = (form) => {
    const values = form.getFieldsValue();
    setFormValues(values);
    const { viewing_address } = values;
    if (viewing_address) {
      setWarehouseName(viewing_address);
    }
  };

  const resetForm = () => {
    setWarehouseName(null);
    setFormValues(null);
  };

  useEffect(() => {
    return () => {
      setWarehouseName(null);
      setFormValues(null);
    };
  }, []);

  return {
    warehouseName,
    formValues,
    onFormChanges,
    resetForm,
  };
};

export default useOnFormChanges;
