import React from "react";
import { Button } from "antd";
import { SaveOutlined, LeftOutlined } from "@ant-design/icons";

export const RolesModalFooterComponent = (props) => {
  const {
    HandleModalOkClicked,
    confirmLoading,
    handleModalVisibleOnChange,
  } = props;
  return (
    <>
      <Button
        key="back"
        onClick={handleModalVisibleOnChange}
        icon={<LeftOutlined />}
      >
        Back
      </Button>
      <Button
        type="primary"
        onClick={HandleModalOkClicked}
        loading={confirmLoading}
        icon={<SaveOutlined />}
      >
        Submit
      </Button>
    </>
  );
};
