import { UpdateReviews } from "../../api/reviews/UpdateReviews";
import { message } from "antd";

const useUpdateReviews = async (id, payload) => {
  const response = await UpdateReviews(id, payload);
  if (response.status === 200) {
    await message.success("Updated Reviews Successfuly", 2);
    return true;
  }

  await message.error("Error Updating Reviews", 2);
  return false;
};

export default useUpdateReviews;
