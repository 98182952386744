import React from "react";
import { Table } from "antd";
import useColumnFilter from "../../../hooks/useColumnFilter";

const { Column } = Table;
export const PermissionIndexListComponent = (props) => {
  const {
    data = { data: [], total: 0 },
    currentPagination = 1,
    onPaginationChange,
    onSortChange,
    columnFilter,
    setColumnFilter
  } = props;

  const { getColumnFilterProps, getColumnBooleanFilter } = useColumnFilter(columnFilter, setColumnFilter);

  return (
    <Table
      dataSource={data.data}
      pagination={{
        total: data.total,
        current: currentPagination,
        onChange: onPaginationChange,
      }}
      onChange={(pagination, filters, sorter, action) => {
        if (action.action == "sort") {
          onSortChange(sorter.field, sorter.order);
        }

      }}
      size="small"
      bordered={true}
      scroll={{ x: 1200 }}
    >
      <Column title="Id" name="id" key="id" dataIndex="id" sorter={true} {...getColumnFilterProps('id')} />'
      <Column title="Permission Name" name="name" key="name" dataIndex="name" sorter={true} {...getColumnFilterProps('name')} />
      <Column
        title="Module Name"
        key={["modules", "name"]}
        name={["modules", "name"]}
        dataIndex={["modules", "name"]}
        sorter={true} {...getColumnFilterProps(["modules", "name"])}
      />
      <Column
        title="Platform Name"
        key={["modules", "platform", "name"]}
        name={["modules", "platform", "name"]}
        dataIndex={["modules", "platform", "name"]}
        sorter={true} {...getColumnFilterProps(["modules", "platform", "name"])}
      />
      <Column title="Description" name="description" key="description"
        dataIndex="description" sorter={true} {...getColumnFilterProps('description')} />

      <Column title="Date Created" name="created_at" key="created_at"
        dataIndex="created_at" sorter={true}  {...getColumnFilterProps('created_at')} />
    </Table>
  );
};
