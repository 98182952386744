import React from "react";
import { Steps } from "antd";

const { Step } = Steps;
export const StepsComponent = (props) => {
  const { steps, currentStep = 1, percent = 0, direction, size } = props;
  return (
    <Steps
      current={currentStep}
      percent={percent}
      direction={direction}
      size={size}
      responsive={true}
      labelPlacement={"vertical"}
    >
      {steps.map((step, index) => (
        <Step key={index} title={step.title} description={step.description} />
      ))}
    </Steps>
  );
};
