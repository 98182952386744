import React from "react";
import { Modal, Table, Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Column } = Table;
export const WarehouseUnitsWithoutPhotoModalComponent = (props) => {
  const { visible, onClose, data = [], location } = props;
  return (
    <Modal
      title="Units Without Photo"
      visible={visible}
      closable={false}
      centered={true}
      width={800}
      footer={[
        <Button onClick={onClose} icon={<ArrowLeftOutlined />}>
          Close
        </Button>,
      ]}
      onClose={onClose}
    >
      <Table dataSource={data} size="small" bordered pagination={false}>
        <Column title="Id" dataIndex={"id"} />
        <Column title="Plate Number / Conduction Sticker" dataIndex={"slug"} />
        <Column title="Color" dataIndex={["car", "color", "specific_name"]} />
        <Column title="Year" dataIndex={["car", "year_model"]} />
        <Column
          title="Brand & Model"
          render={(row) => {
            if (row.car) {
              return `${row.car.model.name} ${row.car.model.trim}`;
            }
          }}
        />
        <Column
          title="Location"
          render={() => {
            return location;
          }}
        />
      </Table>
    </Modal>
  );
};
