import { useFetch } from "../../hooks/useFetch";

export const FetchActivityLogs = async (query) => {
  try {
    return await useFetch(
      `${process.env.REACT_APP_API_URL}/activity-logs`,
      query
    );
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
  }
};
