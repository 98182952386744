import { useState, useEffect } from "react";
import { FormRules } from "../../dist/functions/formRules";

const { REQUIRED_TITLE, REQUIRED_SUB_TITLE, REQUIRED_SLUG, REQUIRED_STATUS } = FormRules;

const formInputsComponent = [
	{
		name: "title",
		label: "Title",
		default_value: "",
		configs: {
			type: "input",
			rules: [REQUIRED_TITLE],
		},
	},
	{
		name: "slug",
		label: "Slug",
		default_value: "",
		configs: {
			type: "input",
			rules: [REQUIRED_SLUG],
		},
	},
	{
		name: "sub_title",
		label: "Sub Title",
		default_value: "",
		configs: {
			type: "input",
			rules: [REQUIRED_SUB_TITLE],
		},
	},
	{
		name: "status",
		label: "Status",
		default_value: "",
		configs: {
			type: "radio",
			rules: [REQUIRED_STATUS],
			list_value: [
				{ id: "0", name: "Draft" },
				{ id: "1", name: "Saved / Not Publish" },
				{ id: "2", name: "Publish" },
			],
		},
	},
];

export default function useCMSFormComponents() {
	const [_formInputsComponent] = useState(formInputsComponent);
	return _formInputsComponent;
}
