import React from "react";
import { Button } from "antd";
import { RetweetOutlined, SaveOutlined } from "@ant-design/icons";

export const CreationModalFooterComponent = (props) => {
  const {
    currentStep,
    nextPage,
    prevPage,
    HandleModalFinish,
    handleModalSpecialistCreationOnClick,
  } = props;

  return (
    <>
      {currentStep > 0
        ? [
            <Button key="back" type="default" onClick={prevPage}>
              Back
            </Button>,
            <Button
              key="submit"
              type="primary"
              icon={<SaveOutlined />}
              onClick={HandleModalFinish}
            >
              Submit
            </Button>,
          ]
        : [
            <Button
              key="cancel"
              type="default"
              icon={<RetweetOutlined />}
              onClick={handleModalSpecialistCreationOnClick}
            >
              Cancel
            </Button>,
            <Button
              key="next"
              type="primary"
              icon={<SaveOutlined />}
              onClick={nextPage}
            >
              Next
            </Button>,
          ]}
    </>
  );
};
