import React from "react";
import { Input, Form, Button, Select, Modal, Divider } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const { Option } = Select;
export const PluginsModalFormComponent = (props) => {
	const { visible = false, handlePluginsModalVisible } = props;
	return (
		<Modal
			visible={visible}
			onOk={handlePluginsModalVisible}
			onCancel={handlePluginsModalVisible}
			title="Add Plugins"
			forceRender
		>
			<Form.List name="plugins">
				{(fields, { add, remove }) => (
					<>
						{fields.map(({ key, name, fieldKey }) => (
							<>
								<Form.Item
									label="Plugin Type"
									name={[name, "plugin_type"]}
									fieldKey={[fieldKey, "plugin_type"]}
									rules={[{ required: true, message: "Missing Plugin Type" }]}
								>
									<Select key={`${key}-plugin-type`} placeholder="Select Plugin Type">
										<Option key="schema" value="schema">
											Schema
										</Option>
										<Option key="js" value="js">
											Javascript/JS function
										</Option>
										<Option key="related_links" value="related_links">
											Related Links
										</Option>
									</Select>
								</Form.Item>
								<Form.Item
									label="Plugin Name"
									name={[name, "plugin_name"]}
									fieldKey={[fieldKey, "plugin_name"]}
									rules={[{ required: true, message: "Missing Plugin Data" }]}
									tooltip={"Please use Proper Case when making Plugin Name"}
								>
									<Input />
								</Form.Item>
								<Form.Item
									label="Plugin Data"
									name={[name, "plugin_data"]}
									fieldKey={[fieldKey, "plugin_data"]}
									rules={[{ required: true, message: "Missing Plugin Data" }]}
								>
									<Input.TextArea rows={4} />
								</Form.Item>
								<Form.Item
									label="Status"
									name={[name, "is_active"]}
									fieldKey={[fieldKey, "is_active"]}
									rules={[{ required: true, message: "Please Choose Plugin Status" }]}
								>
									<Select key={`${key}-plugin-status`} placeholder="Select Plugin Status">
										<Option key={true} value={1}>
											Active
										</Option>
										<Option key={false} value={0}>
											Inactive
										</Option>
									</Select>
								</Form.Item>
								<Button
									key={`${key}-remove-item-fields`}
									size="small"
									type="default"
									onClick={() => remove(name)}
									icon={<MinusCircleOutlined />}
									danger
									block
								>
									Remove Item
								</Button>
								<Divider key={`${key}-fields-divider`} />
							</>
						))}
						<Form.Item>
							<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
								Add field
							</Button>
						</Form.Item>
					</>
				)}
			</Form.List>
		</Modal>
	);
};
