import React from "react";
import { Button, Divider, Space } from "antd";
import { PlusCircleOutlined, FormOutlined } from "@ant-design/icons";
import useIsMobile from "../../../hooks/useIsMobile";

export const OptionsFunctionalityComponent = (props) => {
  const { onShowModal, onShowUpdateModal, selectedRow, rolesRestriction } =
    props;

  const { CREATE_SETTINGS, UPDATE_SETTINGS } = rolesRestriction;
  const isMobile = useIsMobile();
  return (
    <div style={{ width: "100%", textAlign: isMobile ? "center" : "right" }}>
      <Space size="small" direction={isMobile ? "vertical" : "horizontal"}>
        <Button
          type="primary"
          onClick={onShowModal}
          icon={<PlusCircleOutlined />}
          hidden={CREATE_SETTINGS === 0 ? true : false}
          block={isMobile ? true : false}
        >
          Create Option
        </Button>
        {selectedRow ? (
          <Button
            type="default"
            onClick={onShowUpdateModal}
            icon={<FormOutlined />}
            hidden={UPDATE_SETTINGS === 0 ? true : false}
            block={isMobile ? true : false}
          >
            Update Option
          </Button>
        ) : null}
      </Space>
      <Divider />
    </div>
  );
};
