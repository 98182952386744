import { useEffect, useState } from "react";

const useOnChangeBarangay = () => {
  const [barangayId, setSelectedBarangayId] = useState(null);
  const [barangayName, setSelectedBarangayName] = useState(null);

  const onChangeBarangay = (id, barangays) => {
    setSelectedBarangayId(id);

    const barangay = barangays.find((barangay) => barangay.barangay_id === id);

    if (barangay) {
      setSelectedBarangayName(barangay.barangay_name);
    }
  };

  useEffect(() => {
    return () => {
      setSelectedBarangayId(null);
      setSelectedBarangayName(null);
    };
  }, []);

  return {
    barangayId,
    barangayName,
    onChangeBarangay,
  };
};

export default useOnChangeBarangay;
