import React from "react";
import { Typography, Empty } from "antd";

const { Paragraph } = Typography;
export const NotesDescriptionComponent = (props) => {
  const { other_notes, specialist_notes } = props;
  return (
    <>
      <Paragraph>
        <blockquote>{"Specialist Notes: "}</blockquote>
        {!specialist_notes ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={"No Specialist Notes"}
          />
        ) : (
          <pre>{specialist_notes}</pre>
        )}
      </Paragraph>
      <Paragraph>
        <blockquote>{"Other Notes: "}</blockquote>
        <pre>{other_notes}</pre>
      </Paragraph>
    </>
  );
};
