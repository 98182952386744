import React from "react";
import { Descriptions, Tag } from "antd";

export const ModelsDescriptionComponent = (props) => {
  const {
    engine_displacement,
    fuel_system,
    fuel_type,
    name,
    starter_mechanism,
    transmission_type,
    trim,
    make_name,
    vehicle_type,
  } = props;
  return (
    <Descriptions title="Model Information" size="small" bordered>
      <Descriptions.Item label="Engine Displacement">
        {engine_displacement}
      </Descriptions.Item>
      <Descriptions.Item label="Model Name">{name}</Descriptions.Item>
      <Descriptions.Item label="Fuel System">
        {!fuel_system ? returnNoInformationTag() : fuel_system}
      </Descriptions.Item>
      <Descriptions.Item label="Fuel Type">{fuel_type}</Descriptions.Item>
      <Descriptions.Item label="Starter Mechanism">
        {!starter_mechanism ? returnNoInformationTag() : starter_mechanism}
      </Descriptions.Item>
      <Descriptions.Item label="Transmission Type">
        {transmission_type}
      </Descriptions.Item>
      <Descriptions.Item label="Trim">{trim}</Descriptions.Item>
      <Descriptions.Item label="Type">{vehicle_type}</Descriptions.Item>
      <Descriptions.Item label="Manufacturer">{make_name}</Descriptions.Item>
    </Descriptions>
  );
};

const returnNoInformationTag = () => {
  return <Tag color="default">Not Available</Tag>;
};
