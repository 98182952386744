import React from "react";
import { Table, Tag, Button, Space } from "antd";
import { EyeOutlined, FormOutlined } from "@ant-design/icons";
import { ExpandedRowTableComponent } from "./ExpandedRowTableComponent";
import useColumnFilter from "../../../hooks/useColumnFilter";

const { Column } = Table;
export const PostsListComponent = (props) => {
  const {
    data = { data: [], total: 0 },
    loading,
    onPaginationChange,
    currentPage,
    onSelectionChange,
    selectedRowKey = null,
    handleClickViewPostHTML,
    handleClickViewHeroHtml,
    onClickEditPost,
    filteredDataSet,
    onSortChange,
    columnFilter,
    setColumnFilter
  } = props;

  const { getColumnFilterProps, getColumnDynamicFilter } = useColumnFilter(columnFilter, setColumnFilter);

  const { isFiltered } = filteredDataSet;
  return (
    <div style={{ marginTop: "2%" }}>
      <Table
        size="small"
        loading={loading}
        dataSource={isFiltered ? filteredDataSet.data : data.data}
        rowSelection={{
          type: "radio",
          onChange: onSelectionChange,
          selectedRowKeys: [selectedRowKey],
        }}
        onChange={(pagination, filters, sorter, action) => {
          if (action.action == "sort") {
            onSortChange(sorter.field, sorter.order);
          }

        }}
        pagination={{
          onChange: onPaginationChange,
          total: isFiltered ? filteredDataSet.data.total : data.total,
          current: currentPage,
        }}
        bordered
        expandable={{
          expandedRowRender: (record) => (
            <ExpandedRowTableComponent record={record} />
          ),
        }}
      >
        <Column
          title=""
          render={(row) => (
            <Button
              type="link"
              size="small"
              icon={<FormOutlined />}
              onClick={() => onClickEditPost(row)}
            />
          )}
        />
        <Column title="Id" dataIndex="id" key="id" sorter={true} {...getColumnFilterProps('id')} />
        <Column title="Slug" dataIndex="slug" key="slug" sorter={true} {...getColumnFilterProps('slug')} />
        <Column title="Title" dataIndex="title" key="title" sorter={true} {...getColumnFilterProps('title')} />
        <Column title="Sub Title" dataIndex="sub_title" key="sub_title" sorter={true} {...getColumnFilterProps('sub_title')} />
        <Column
          sorter={true} {...getColumnDynamicFilter('status_remarks', [
            { value: "Draft", text: "Draft" },
            { value: "Not Publish", text: "Not Published" },
            { value: "Published", text: "Published" }
          ])}
          title="Status"
          key="status_remarks"
          dataIndex="status_remarks"
          render={(status_remarks) =>
            status_remarks === "Draft" ? (
              <Tag color={"default"}>{status_remarks.toUpperCase()}</Tag>
            ) : status_remarks === "Not Publish" ? (
              <Tag color={"blue"}>{status_remarks.toUpperCase()}</Tag>
            ) : (
              <Tag color={"green"}>{status_remarks.toUpperCase()}</Tag>
            )
          }
        />
        {/* <Column
          title="Preview Actions"
          key="actions"
          render={(row) => (
            <Space size="small">
              {" "}
              <Button
                type="primary"
                size="small"
                icon={<EyeOutlined />}
                onClick={() => handleClickViewHeroHtml(row.html_hero_content)}
              >
                View Hero
              </Button>
              <Button
                type="primary"
                size="small"
                icon={<EyeOutlined />}
                onClick={() => {
                  handleClickViewPostHTML(row.html_post_content);
                }}
              >
                View Post
              </Button>
            </Space>
          )}
        /> */}
        <Column title="Date Created" dataIndex="created_at" key="created_at" sorter={true}  {...getColumnFilterProps('created_at')} />
      </Table>
    </div>
  );
};
