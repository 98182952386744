import React from "react";
import { Form, Modal, Input } from "antd";

const { TextArea } = Input;
export const FAQIndexCreationComponent = (props) => {
  const { form, visible, onOk, onCancel } = props;
  return (
    <>
      <Modal
        visible={visible}
        title="Create FAQ Index"
        onOk={onOk}
        onCancel={onCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="question" label="Question (?)">
            <TextArea rows={2} />
          </Form.Item>
          <Form.Item name="answer" label="Answer">
            <TextArea rows={10} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
