import { FetchRemarksSubGroup } from "../../api/remarks_sub_group/FetchRemarksSubGroup";

const useFetchRemarksSubGroup = async (
  isForTable,
  query = { page: 1, limit: 10, filter: "" }
) => {
  if (isForTable) {
    const data = await FetchRemarksSubGroup(query);
    for (const item of data.data.data) {
      item["key"] = item.id;
    }

    return data.data;
  }

  const selectOptionQuery = {
    page: 1,
    limit: null,
    filter: "",
  };

  const data = await FetchRemarksSubGroup(selectOptionQuery);

  for (const item of data.data.data) {
    item["key"] = item.id;
  }

  return data.data.data;
};

export default useFetchRemarksSubGroup;
