import React from "react";
import { Form, Modal, Input, InputNumber, Select, Radio } from "antd";
import { SaveOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { SelectOptionFooterExtraButtonComponent } from "./SelectOptionFooterExtraButtonComponent";
import { FormRules } from "../dist/functions/formRules";

const { TextArea } = Input;
const { Option } = Select;

export const ModalwithFormComponent = ({
  title,
  form,
  formLayout,
  onOk,
  onCancel,
  destroyOnClose,
  okText,
  cancelText,
  visible,
  readOnly = false,
  formInputsComponent,
}) => {
  const renderFormItem = (item) => {
    const { type, textAreaRow, list_value, placeholder, total } = item?.configs;

    const inputComponents = {
      input: <Input disabled={readOnly} />,
      input_password: <Input type="password" disabled={readOnly} />,
      input_number: <InputNumber disabled={readOnly} />,
      text_area: <TextArea disabled={readOnly} rows={textAreaRow} />,
      select: (
        <Select disabled={readOnly} placeholder={placeholder}>
          {list_value &&
            list_value?.map(({ id, name }) => (
              <Option key={id} value={id}>
                {name}
              </Option>
            ))}
          <SelectOptionFooterExtraButtonComponent
            menu={null}
            onClickShowMoreList={item.onClickShowMoreList}
            isClickShowMoreDisabled={list_value?.length < total ? false : true}
          />
        </Select>
      ),
      radio: (
        <Radio.Group>
          {list_value &&
            list_value?.map(({ id, name }) => (
              <Radio key={id} value={id}>
                {name}
              </Radio>
            ))}
        </Radio.Group>
      ),
    };

    return inputComponents[type] || null;
  };

  return (
    <Modal
      title={title}
      onOk={onOk}
      onCancel={onCancel}
      destroyOnClose={destroyOnClose}
      visible={visible}
      okText={okText}
      cancelText={cancelText}
      okButtonProps={{ icon: <SaveOutlined /> }}
      cancelButtonProps={{ icon: <ArrowLeftOutlined /> }}
    >
      <Form form={form} layout={formLayout}>
        {formInputsComponent?.map((item) => (
          <Form.Item key={item.name} name={item.name} label={item.label} rules={item.configs.rules}>
            {renderFormItem(item)}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};
