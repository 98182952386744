import React from "react";
import { Divider, PageHeader, Table, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import useFetchSessions from "../../hooks/sessions/useFetchSessions";
import useDeleteSession from "../../hooks/sessions/useDeleteSession";

export const SessionComponent = () => {
  const { activeSessions } = useFetchSessions();
  const { executeDelete } = useDeleteSession();

  const columns = [
    {
      title: "Id",
      key: "id",
      dataIndex: "id",
    },
    {
      title: "Session Id",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "User",
      key: "value",
      dataIndex: "value",
      render: (value) => {
        return <pre>{JSON.stringify(value.user_info, null, 2)}</pre>;
      },
    },
    {
      key: "actions",
      render: (row) => {
        return (
          <Button
            type="text"
            icon={<DeleteOutlined />}
            danger
            onClick={() => executeDelete(row)}
          />
        );
      },
    },
  ];

  return (
    <>
      <PageHeader
        title="Active Sessions"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["sessions"]} />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <Table
          columns={columns}
          dataSource={activeSessions}
          scroll={{ x: 1500 }}
          size="small"
          bordered={true}
        />
      </div>
    </>
  );
};
