import React from "react";
import { Modal, Form, Row, Col, Typography, Descriptions } from "antd";
import { WarehouseGenralInformationFormComponent } from "./WarehouseGenralInformationFormComponent";
import { SaveOutlined } from "@ant-design/icons";
import useCreateWarehouse from "../../../hooks/warehouses/useCreateWarehouse";
import useOnFormChanges from "../../../hooks/warehouses/useOnFormChanges";

export const WarehouseCreationComponent = ({
  showWarehouseCreationModal = false,
  setShowWarehouseCreationModal,
  banks = { data: [], total: 0 },
  onClickShowMoreCity,
  onClickShowMoreBanks,
  onClickShowMoreAdviser,
  specialists,
  onChangeCity,
  onChangeProvince,
  onChangeBarangay,
  provinces,
  isProvinceFetchingLoading,
  cities,
  isCityFetchingLoading,
  barangays,
  isBarangayFetchLoading,
  oldCities = [],
  providers = [],
}) => {
  const [form] = Form.useForm();

  const { onFormChanges, resetForm } = useOnFormChanges();
  const { createWarehouse } = useCreateWarehouse();

  return (
    <>
      <Modal
        title="Warehouse Creation"
        open={showWarehouseCreationModal}
        okText="Create Warehouse"
        okButtonProps={{
          icon: <SaveOutlined />,
        }}
        onOk={() =>
          createWarehouse(form, resetForm, setShowWarehouseCreationModal)
        }
        onCancel={() =>
          setShowWarehouseCreationModal((prevState) => {
            return !prevState;
          })
        }
        centered
        cancelButtonProps={{
          hidden: true,
        }}
        width={"60%"}
      >
        <Form
          form={form}
          layout="vertical"
          name="warehouses"
          onChange={() => onFormChanges(form)}
        >
          <WarehouseGenralInformationFormComponent
            banks={banks}
            provinces={provinces}
            providers={providers}
            isProvinceFetchingLoading={isProvinceFetchingLoading}
            isCityFetchingLoading={isCityFetchingLoading}
            isBarangayFetchingLoading={isBarangayFetchLoading}
            cities={cities}
            oldCities={oldCities}
            barangays={barangays}
            specialists={specialists}
            onClickShowMoreCity={onClickShowMoreCity}
            onClickShowMoreBanks={onClickShowMoreBanks}
            onClickShowMoreAdviser={onClickShowMoreAdviser}
            onChangeProvince={onChangeProvince}
            onChangeCity={onChangeCity}
            onChangeBarangay={onChangeBarangay}
          />
        </Form>
      </Modal>
    </>
  );
};
