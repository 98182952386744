import axios from "axios";
import { useEffect, useState } from "react";

const useFetchWarehouses = () => {
  const [warehouses, setWarehouses] = useState([]);
  const [meta, setMeta] = useState(null);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState(null);
  const [filterTags, setFilterTags] = useState([]);

  useEffect(() => {
    const fetchWarehouses = async () => {
      setLoading(true);

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/warehouses`,
        {
          params: { ...query },
          headers: { Authorization: localStorage.getItem("token") },
        }
      );

      if (response.status === 200 && response.data) {
        const { data = [], ...meta } = response.data;
        setWarehouses(data);
        setMeta(meta);
      }

      setLoading(false);
    };

    fetchWarehouses();
  }, [query]);

  const onPaginationChange = (value, currentCount) => {
    setQuery((prevState) => {
      return { ...prevState, page: value, limit: currentCount };
    });
  };

  const onSearch = (e) => {
    setQuery((prevState) => {
      return { ...prevState, search_value: e };
    });
  };

  return {
    warehouses,
    warehouseTableMeta: meta,
    warehouseTableLoading: loading,
    query,
    setQuery,
    onPaginationChange,
    onWarehouseSearch: onSearch,
    filterTags,
  };
};

export default useFetchWarehouses;
