// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.specialist-warehouses-card-div.ant-card.ant-card-bordered {
  width: 100% !important;
  margin-top: 2%;
}

.card-div-content-scrollable {
  overflow-x: auto;
}

@media screen and (max-width: 521px) {
  .ant-space.ant-space-vertical {
    width: 100% !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/dist/style/specialists.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".specialist-warehouses-card-div.ant-card.ant-card-bordered {\n  width: 100% !important;\n  margin-top: 2%;\n}\n\n.card-div-content-scrollable {\n  overflow-x: auto;\n}\n\n@media screen and (max-width: 521px) {\n  .ant-space.ant-space-vertical {\n    width: 100% !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
