import React from "react";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";

export const ForbiddenResult403Component = () => {
  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Link to="/dashboard">
          <Button type="primary" icon={<LeftOutlined />}>
            Back to Dashboard
          </Button>
        </Link>
      }
    />
  );
};
