import { CreateNewModules } from "../../api/admin/CreateNewModules";
import { message } from "antd";

const useCreateModules = async (platform_id, payload) => {
  const response = await CreateNewModules(platform_id, payload);

  if (response.status === 201) {
    await message.success("Successfuly Created New Modules", 2);
    return true;
  }

  await message.error("Error creating new modules", 2);
  return false;
};

export default useCreateModules;
