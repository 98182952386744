import React from "react";
import { Tag, Button, Typography, Divider } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import useIsMobile from "../../../hooks/useIsMobile";

const { Title } = Typography;

export const UserFiltersAndSelectionComponent = (props) => {
  const {
    onClearSearcheAndSelection,
    selectedUser,
    isFiltered,
    querySearch,
    isShowColumnFilterTags,
    columnFilterTags = [],
  } = props;

  const isMobile = useIsMobile();

  return (
    <>
      {(selectedUser && selectedUser.email) ||
      isFiltered ||
      isShowColumnFilterTags ? (
        <div
          style={{ marginTop: "2%", textAlign: isMobile ? "center" : "left" }}
        >
          <Divider />
          <Title level={5}>Filters:</Title>

          {selectedUser && (
            <Tag
              color="processing"
              visible={!selectedUser.email ? false : true}
            >
              {`Selected User: ${
                !selectedUser.email ? "none" : selectedUser.email
              }`}
            </Tag>
          )}

          <Tag visible={isFiltered ? true : false} color="processing">
            {`Searched User Containing: ${
              querySearch.search_value ? querySearch.search_value : "None"
            }`}
          </Tag>
          <Tag
            visible={
              isFiltered
                ? querySearch.date_from && querySearch.date_to
                  ? true
                  : false
                : false
            }
            color="processing"
          >
            {`Searched Date of Creation: ${querySearch.date_from} - ${querySearch.date_to}`}
          </Tag>

          {columnFilterTags.length
            ? columnFilterTags.map((item, index) => (
                <Tag
                  key={index}
                  color="processing"
                  visible={!item.value ? false : true}
                  closable={item.closable ? item.closable : false}
                  onClose={item.closable ? () => item.onClose(item.id) : null}
                >
                  {`${item.selection_label}: ${
                    !item.value ? "none" : item.value
                  }`}
                </Tag>
              ))
            : null}

          <Button
            type="link"
            icon={<CloseOutlined />}
            size="small"
            onClick={onClearSearcheAndSelection}
          >
            Clear Filters
          </Button>
          <Divider />
        </div>
      ) : null}
    </>
  );
};
