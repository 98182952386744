import { CreateFAQsIndex } from "../../api/faq_index/CreateFAQsIndex";
import { message } from "antd";

const useCreateFAQsIndex = async (data) => {
  const payload = {
    data: data,
  };

  await message.loading("Creating FAQ Index", 2);
  const response = await CreateFAQsIndex(payload);
  if (response.status === 201) {
    message.success("Successfuly Created FAQ Index");
    return true;
  }

  message.error("Error Creating FAQ's Index");
  return false;
};

export default useCreateFAQsIndex;
