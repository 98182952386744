import { useState, useCallback } from "react";
import axios from "axios";

const useFetchBids = () => {
  const [bids, setBids] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchBids = useCallback(async (query) => {
    setLoading(true);
    setError(null);

    try {
      const url = `${process.env.REACT_APP_API_URL}/biddings/query`;
      const response = await axios.get(url, {
        params: query,
        headers: { Authorization: localStorage.getItem("token") },
      });

      const formattedData =
        response?.data?.data?.map((item) => ({
          ...item,
          key: item.id,
        })) || [];

      setBids(formattedData);
    } catch (err) {
      console.error("ERROR:", err);
      setError(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const clearGeneratedData = () => {
    setBids([]);
  };

  return { bids, loading, error, fetchBids, clearGeneratedData };
};

export default useFetchBids;
