import { useCreate } from "../../hooks/useCreate";

export const CreateUserAttachedRole = async (payload) => {
  try {
    return await useCreate(
      `${process.env.REACT_APP_API_URL}/users/roles`,
      payload
    );
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;

    return err.message;
  }
};
