import React from "react";
import { Space, Button, Divider, Modal } from "antd";
import { ExclamationCircleOutlined, RedoOutlined, FilterOutlined } from "@ant-design/icons";
import { BuyNowReportsComponent } from "./BuyNowReportsComponent";
import useIsMobile from "../../../hooks/useIsMobile";

const { confirm } = Modal;

export const BuyNowFunctionalityComponent = ({
  isUpdateButtonHidden = true,
  OnUpdateStatus,
  data = [],
  GENERATE_BUY_NOW_WINNERS_REPORT = 0,
  UPDATE_BUY_NOW_WINNERS_STATUS = 0,
  platform = "car",
  onClickFilterBuyNow,
}) => {
  const isMobile = useIsMobile();

  const showConfirm = () => {
    confirm({
      centered: true,
      title: "Update Status",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to update status of this unit?",
      onOk: OnUpdateStatus,
    });
  };

  return (
    <div style={{ marginTop: "2%" }}>
      <Space size="small" direction={isMobile ? "vertical" : "horizontal"}>
        {!isUpdateButtonHidden && (
          <>
            <Divider type="vertical" />
            {UPDATE_BUY_NOW_WINNERS_STATUS === 1 && (
              <Button type="primary" icon={<RedoOutlined />} onClick={showConfirm} danger>
                Cancel Buy Now
              </Button>
            )}
          </>
        )}
        <Button
          type="primary"
          title="Filter Bids"
          icon={<FilterOutlined />}
          onClick={onClickFilterBuyNow}
          block={isMobile}
        >
          Filter Buy Now
        </Button>
        {GENERATE_BUY_NOW_WINNERS_REPORT === 1 && (
          <BuyNowReportsComponent dataSet={data} platform={platform} />
        )}
      </Space>
    </div>
  );
};
