import React, { useState, useEffect, useRef } from "react";
import { PageHeader, Form, message, Modal, Divider } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { FetchWarehouseSpecialistsList } from "../../api/warehouse/FetchWarehouseSpecialistsList";
import { FetchWarehousesList } from "../../api/warehouse/FetchWarehousesList";
import { CreateSpecialists } from "../../api/specialists/CreateSpecialists";
import { SpecialistCreationModalFormComponent } from "../sub_components/specialists/SpecialistCreationModalFormComponent";
import { SpecialistsFunctionalityComponent } from "../sub_components/specialists/SpecialistsFunctionalityComponent";
import { SpecialistsTableComponent } from "../sub_components/specialists/SpecialistsTableComponent";
import { SpecialistUpdateGenModalFormComponent } from "../sub_components/specialists/SpecialistUpdateGenModalFormComponent";
import { SpecialistFiltersAndSelectionComponent } from "../sub_components/specialists/SpecialistFiltersAndSelectionComponent";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { UpdateSpecialists } from "../../api/specialists/UpdateSpecialists";
import { UpdateSpecialistWarehouse } from "../../api/specialists/UpdateSpecialistWarehouse";
import { ClearWarehouseBySpecialist } from "../../api/specialists/ClearWarehouseBySpecialist";
import { DeleteSpecialist } from "../../api/specialists/DeleteSpecialist";
import { QuerySpecialist } from "../../api/specialists/QuerySpecialist";
import "../../dist/style/specialists.css";
import { useComponentDidUpdate } from "../../hooks/useComponentDidUpdate";
import { ModulesSelectAndColumnTagsComponent } from "../ModulesSelectAndColumnTagsComponent";

const { confirm } = Modal;

const initialUserRolesState = {
  CREATE_ADVISER: 0,
  UPDATE_ADVISER_WAREHOUSE: 0,
  CLEAR_WAREHOUSE: 0,
  DELETE_ADVISER: 0,
};

const initialQuerySearch = {
  search_value: null,
};

export const SpecialistsManagementComponent = () => {
  const [createSpecialistForm] = Form.useForm();
  const [updateWarehouseForm] = Form.useForm();
  const [updateGenSpecialistForm] = Form.useForm();
  const [specialists, setSpecialists] = useState({ data: [] });
  const [warehouses, setWarehouses] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);
  const [selectedSpecialistId, setSelectedSpecialistId] = useState(null);
  const [selectedSpecialistInfo, setSelectedSpecialistInfo] = useState(null);
  const [specialistCreationModadal, setSpecialistCreationModal] =
    useState(false);
  const [specialistUpdateModal, setSpecialistUpdateModal] = useState(false);
  const [specialistGenUpdateModal, setSpecialistGenUpdateModal] =
    useState(false);
  const [userPermissions, setUserPermissions] = useState(initialUserRolesState);
  const [currentStep, setCurrentStep] = useState(0);
  const [isFiltered, setIsFiltered] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [searchValue, setSearchValue] = useState(null);
  const [currentPagination, setCurrentPagination] = useState(1);
  const [currentCount, setCurrentCount] = useState(10);
  const [isClickShowMoreDisabled, setIsClickShowMoreDisabled] = useState(false);
  const [, setState] = useState({});

  const [isShowColumnFilter, setIsShowColumnFilter] = useState(false);
  const [columnFilterTags, setColumnFilterTags] = useState([]);

  const [querySearch, setQuerySearch] = useState(initialQuerySearch);
  const [columnFilter, setColumnFilter] = useState([]);
  const sortColumn = useRef(null);
  const sortType = useRef("asc");

  const setSortColumn = (value) => {
    sortColumn.current = value;
  };

  const setSortType = (value) => {
    if (value === "descend") {
      sortType.current = "desc";
    } else if (value === "ascend") {
      sortType.current = "asc";
    } else {
      sortType.current = "none";
    }
  };

  const onSortChange = async (sortCol, sortType) => {
    setSortColumn(sortCol);
    setSortType(sortType);

    if (!isFiltered) {
      await FetchWarehouseSpecialists(currentPagination, currentCount);
    } else if (isFiltered) {
      const { search_value } = querySearch;

      const query = {
        page: currentPagination,
        limit: currentCount,
        search_value: search_value,
      };
      const data = await FilterSpecialist(query);

      setFilteredData(data.data);
    }
  };

  const FetchWarehouseSpecialists = async (page = 1, limit = 10) => {
    setCurrentPagination(page);
    setTableLoading(true);
    const query = {
      page: page,
      limit: limit,
      sortColumn: sortColumn.current,
      sortType: sortType.current,
      columnFilter: columnFilter,
    };

    const data = await FetchWarehouseSpecialistsList(query);
    for (const i of data.data) {
      i["key"] = i.id;
    }

    setSpecialists(data);
    setTimeout(() => {
      setTableLoading(false);
    }, 500);
  };

  const FetchWarehouses = async () => {
    const data = await FetchWarehousesList();
    setWarehouses(data);
  };

  ///////////////////////////////
  // for column filter tags
  const onCloseColumnFilterTag = (id) => {
    setColumnFilterTags((prevState) => {
      return prevState.filter((item) => item.id !== id);
    });

    setColumnFilter((prevState) => {
      let newData = prevState.filter((item) => {
        return Array.isArray(item.id)
          ? JSON.stringify(item.id) != id
          : item.id != id;
      });

      return newData;
    });

    if (!columnFilterTags.length) {
      setIsShowColumnFilter(false);
    }
  };

  const onLoadColumnFilterTags = () => {
    if (columnFilter.length) {
      let filterTags = columnFilter.map((e) => {
        let [label, tagId] = Array(2).fill(e.id);

        if (Array.isArray(e.id)) {
          tagId = JSON.stringify(tagId);
          label = "";

          for (let i = 0, n = e.id.length; i < n; i++) {
            label += `${e.id[i]} `;
          }
        }

        return {
          color: "processing",
          closable: true,
          selection_label: label,
          value: e.value,
          id: tagId,
          onClose: onCloseColumnFilterTag,
        };
      });

      setColumnFilterTags(filterTags);
      setIsShowColumnFilter(true);
    } else {
      // if columnFilter is just blank, then remove the tags
      setColumnFilterTags([]);
      setIsShowColumnFilter(false);
    }
  };

  const onClearColumnFilters = () => {
    setColumnFilterTags([]);
    setIsShowColumnFilter(false);
    setColumnFilter([]);
  };

  useEffect(() => {
    (async function () {
      if (!isFiltered) {
        await FetchWarehouseSpecialists();
      } else if (isFiltered) {
        const { search_value } = querySearch;
        setCurrentPagination(1);

        const query = {
          page: 1,
          limit: currentCount,
          search_value: search_value,
        };
        const data = await FilterSpecialist(query);

        setFilteredData(data.data);
      }
    })();

    FetchWarehouses();
    setCurrentPagination(1);
  }, []);

  useComponentDidUpdate(() => {
    setCurrentPagination(1);

    (async function () {
      if (!isFiltered) {
        await FetchWarehouseSpecialists(1, currentCount);
      } else if (isFiltered) {
        const { search_value } = querySearch;

        const query = {
          page: 1,
          limit: currentCount,
          search_value: search_value,
        };
        const data = await FilterSpecialist(query);

        setFilteredData(data.data);
      }
    })();

    onLoadColumnFilterTags();
  }, [columnFilter]);

  const handleModalSpecialistCreationOnClick = () => {
    setSpecialistCreationModal(!specialistCreationModadal);
  };

  const handleModalSpecialistUpdateOnClick = (records = null) => {
    if (Array.isArray(records)) {
      const warehouse_ids = [];
      for (const item of records) {
        warehouse_ids.push(item.warehouse_id);
      }

      updateWarehouseForm.setFieldsValue({
        warehouses: warehouse_ids,
      });
    }
    setSpecialistUpdateModal(!specialistUpdateModal);
  };

  const HandleModalFinish = async () => {
    const { landlinePrefix, ...values } =
      await createSpecialistForm.validateFields();
    const { mobile_globe, mobile_smart, landline, url } = values;

    const warehouses = [];
    for (const value of values.warehouses) {
      warehouses.push({
        warehouse_id: value,
      });
    }

    const payload = {
      ...values,
    };

    payload["warehouses"] = warehouses;
    payload["url"] = url ? url.file.response.data.path : "";
    payload["mobile_globe"] = mobile_globe === "" ? "" : `+639${mobile_globe}`;
    payload["mobile_smart"] = mobile_smart === "" ? "" : `+639${mobile_smart}`;
    payload["landline"] =
      landline === "" ? "" : `+(${landlinePrefix}) ${values.landline}`;

    await message.loading("Creating Adviser With Warehouses..", 2);
    const response = await CreateSpecialists(payload);
    if (response.status === 201) {
      setTimeout(() => {
        message.success("Successfuly Created Adviser with Warehouses");
        handleModalSpecialistCreationOnClick();
        createSpecialistForm.resetFields();
        FetchWarehouses();
        FetchWarehouseSpecialists();
        setState({});
      }, 3000);

      return;
    }

    if (response.status === 400) {
      for (const item of response.data) {
        message.error(item.message, 2);
      }
      return;
    }

    message.error("Error Creating Adviser, please try again later");
    message.error("If still not working, seek help on the support team");
    return;
  };

  const HandleUpdateWarehousesOnFinish = async (data) => {
    const values = await updateWarehouseForm.validateFields();
    const arr_warehouse = [];
    for (const item of values.warehouses) {
      var obj = {
        warehouse_id: item,
      };

      arr_warehouse.push(obj);
    }

    const payload = {
      warehouses: arr_warehouse,
    };

    const resp = await UpdateSpecialistWarehouse(payload, data.id);
    message.loading("Updating Warehouses Attached By Adviser", 2).then(() => {
      if (resp.status === 200) {
        message.success("Successfuly Updated Warehouses Attached By Adviser");
        updateWarehouseForm.resetFields();
        resetSpecialist();
        handleModalSpecialistUpdateOnClick();
        return;
      }

      if (resp.status === 400) {
        for (const item of resp.data) {
          message.error(item.message, 2);
        }
        return;
      }

      message.error(`Error Updating Warehouse information by adviser`, 2);
    });
  };

  const prevPage = () => {
    setCurrentStep(currentStep - 1);
  };

  const nextPage = () => {
    setCurrentStep(currentStep + 1);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedSpecialistId(selectedRowKeys);
      setSelectedSpecialistInfo(selectedRows[0]);
    },
  };

  const handleUpdateSpecialistInfo = async () => {
    const { page, lastPage } = warehouses;

    if (page >= lastPage) {
      setIsClickShowMoreDisabled(true);
    }

    if (selectedSpecialistId !== (null || "null")) {
      const {
        mobile_globe,
        mobile_smart,
        landline,
        first_name,
        last_name,
        email,
        url,
      } = selectedSpecialistInfo;

      var fields = {
        first_name: first_name,
        last_name: last_name,
        email: email,
        url: url,
      };

      if (landline) {
        let _trimLand = landline.replace(/ /g, "");

        fields["landline"] = _trimLand.replace(/[^]*\)/, "");

        let _trimPref = _trimLand.match(/[^]*\)/, "");

        fields["landlinePrefix"] = _trimPref[0].replace(/\D/g, "");
      }

      if (mobile_globe) {
        const _trimG = mobile_globe.replace(/ /g, "");
        fields["mobile_globe"] = _trimG.includes("+639")
          ? _trimG.replace("+639", "")
          : _trimG;
      } else {
        fields["mobile_globe"] = "";
      }

      if (mobile_smart) {
        const _trimS = mobile_smart.replace(/ /g, "");
        if (_trimS.includes("+639")) {
          fields.mobile_smart = _trimS.replace("+639", "");
        } else {
          fields["mobile_smart"] = mobile_smart.trim();
        }
      } else {
        fields["mobile_smart"] = "";
      }

      updateGenSpecialistForm.setFieldsValue(fields);
    }
    setSpecialistGenUpdateModal(!specialistGenUpdateModal);
  };

  const UpdateSpecialistInformation = async () => {
    const { landlinePrefix, ...values } =
      await updateGenSpecialistForm.validateFields();
    const { mobile_globe, mobile_smart, landline, url } = values;

    const payload = {
      ...values,
    };

    const _mobileGlobe = mobile_globe.trim();
    payload["mobile_globe"] =
      _mobileGlobe === "" ? "" : `+639${_mobileGlobe.replace("+639", "")}`;

    const _mobileSmart = mobile_smart.trim();
    payload["mobile_smart"] =
      _mobileSmart === "" ? "" : `+639${_mobileSmart.replace("+639", "")}`;

    payload["landline"] =
      landline === "" ? "" : `+(${landlinePrefix}) ${values.landline}`;
    payload["url"] = url ? (url.file ? url.file.response.data.path : url) : "";

    const response = await UpdateSpecialists(payload, selectedSpecialistId);

    if (response.status === 400) {
      response.data.forEach((item) => {
        message.error(item.message, 2);
      });
      return;
    }

    if (response.status === 200) {
      message.success("Successfuly Updated Adviser Information");
      resetUpdateForm();
      return;
    }
  };

  const HandleDeleteSpecialist = () => {
    confirm({
      title: `Are you sure, you want to remove this adviser?`,
      icon: <ExclamationCircleOutlined />,
      content: `All information attached to this adviser will be removed`,
      async onOk() {
        message
          .loading("Deleting Adviser Information..", 2.5)
          .then(async () => {
            await DeleteSpecialist(selectedSpecialistId);
            message.success("Successfuly Deleted Adviser", 2).then(() => {
              resetSpecialist();
            });
          });
      },
      onCancel() {},
    });
  };

  const HandleClearWarehouse = (data) => {
    confirm({
      title: `Are you sure, you want to remove all warehouse under this adviser?`,
      icon: <ExclamationCircleOutlined />,
      content: `All of the warehouse attached to this adviser will be removed`,
      async onOk() {
        message.loading("Clearing attached warehouse..", 2.5).then(async () => {
          await ClearWarehouseBySpecialist(data.id);
          message
            .success("Successfuly Cleared Warehouse By adviser", 2)
            .then(() => {
              resetSpecialist();
            });
        });
      },
      onCancel() {},
    });
  };

  const onClickShowMoreList = async () => {
    if (warehouses) {
      const { lastPage, page } = warehouses.pagination;
      if (page < lastPage) {
        const newPage = parseInt(page) + 1;
        const data = await FetchWarehousesList({
          page: newPage,
          limit: 10,
        });

        const oldWarehouseList = warehouses.data;
        for (const item of data.data) {
          oldWarehouseList.push(item);
        }

        const newState = {
          pagination: data.pagination,
          data: oldWarehouseList,
        };

        setWarehouses(newState);
        if (newState.pagination.page >= newState.pagination.lastPage) {
          setIsClickShowMoreDisabled(true);
        }

        return;
      } else {
        setIsClickShowMoreDisabled(true);
      }
    }
  };

  const resetSpecialist = () => {
    FetchWarehouses();
    FetchWarehouseSpecialists(1, currentCount);
    setSelectedSpecialistInfo(null);
    setSelectedSpecialistId(null);
    setIsFiltered(false);
    setSearchValue(null);
  };

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const CREATE_ADVISER = permissions.find(
      (item) => item.name === "CREATE_ADVISER"
    );
    const UPDATE_ADVISER_WAREHOUSE = permissions.find(
      (item) => item.name === "UPDATE_ADVISER_WAREHOUSE"
    );
    const CLEAR_WAREHOUSE = permissions.find(
      (item) => item.name === "CLEAR_WAREHOUSE"
    );
    const DELETE_ADVISER = permissions.find(
      (item) => item.name === "DELETE_ADVISER"
    );

    const permissionObj = {};
    permissionObj["CREATE_ADVISER"] =
      typeof CREATE_ADVISER === "undefined" ? 0 : CREATE_ADVISER.has_access;
    permissionObj["UPDATE_ADVISER_WAREHOUSE"] =
      typeof UPDATE_ADVISER_WAREHOUSE === "undefined"
        ? 0
        : UPDATE_ADVISER_WAREHOUSE.has_access;
    permissionObj["CLEAR_WAREHOUSE"] =
      typeof CLEAR_WAREHOUSE === "undefined" ? 0 : CLEAR_WAREHOUSE.has_access;
    permissionObj["DELETE_ADVISER"] =
      typeof DELETE_ADVISER === "undefined" ? 0 : DELETE_ADVISER.has_access;
    setUserPermissions(permissionObj);
  }, []);

  const onSearch = async (e) => {
    setCurrentPagination(1);
    setIsFiltered(true);
    setSearchValue(e);
    const query = {
      search_value: e,
      page: 1,
      limit: currentCount,
    };

    setQuerySearch(query);
    const data = await FilterSpecialist(query);
    setFilteredData(data.data);
  };

  const FilterSpecialist = async (query) => {
    if (sortColumn) {
      query["sortColumn"] = sortColumn.current;
    }

    if (sortType) {
      query["sortType"] = sortType.current;
    }

    if (columnFilter && columnFilter.length) {
      query["columnFilter"] = columnFilter;
    }

    return await QuerySpecialist(query);
  };

  const onSearchClose = () => {
    setSearchValue(null);
    setIsFiltered(false);
    setFilteredData(null);
  };

  const onSelectionClose = () => {
    setSelectedSpecialistId([]);
    setSelectedSpecialistInfo(null);
  };

  const onPaginationChange = async (value, currentCount) => {
    if (!isFiltered) {
      await FetchWarehouseSpecialists(value, currentCount);
    } else if (isFiltered) {
      const { search_value } = querySearch;

      const query = {
        page: value,
        limit: currentCount,
        search_value: search_value,
      };
      const data = await FilterSpecialist(query);

      setFilteredData(data.data);
    }

    setCurrentCount(currentCount);
    setCurrentPagination(value);
  };

  const resetUpdateForm = () => {
    updateGenSpecialistForm.resetFields();
    resetSpecialist();
    handleUpdateSpecialistInfo();
  };

  return (
    <>
      <PageHeader
        title="ADVISERS"
        subTitle="ADVISERS MANAGEMENT MODULE"
        breadcrumbRender={() => (
          <BreadCrumbsComponent
            breadcrumbsItem={["Administrations", "Advisers"]}
          />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <SpecialistsFunctionalityComponent
          selectedSpecialistId={selectedSpecialistId}
          handleUpdateSpecialistInfo={handleUpdateSpecialistInfo}
          handleModalSpecialistCreationOnClick={
            handleModalSpecialistCreationOnClick
          }
          handleDeleteSpecialist={HandleDeleteSpecialist}
          userPermissions={userPermissions}
          onSearch={onSearch}
        />

        <ModulesSelectAndColumnTagsComponent
          onClearColumnFilters={onClearColumnFilters}
          isShowColumnFilterTags={isShowColumnFilter}
          columnFilterTags={columnFilterTags}
          isShowSelection={!!selectedSpecialistInfo || !!searchValue}
          onClearSearcheAndSelection={resetSpecialist}
          tags={[
            {
              color: "processing",
              selection_label: "Selected Specialist",
              value: selectedSpecialistInfo
                ? `${selectedSpecialistInfo.email}`
                : null,
            },
            {
              color: "processing",
              selection_label: "Adviser Containing",
              value: searchValue ? `${searchValue}` : null,
            },
          ]}
        />
        <Divider />
        <SpecialistsTableComponent
          tableLoading={tableLoading}
          specialists={specialists}
          specialistUpdateModal={specialistUpdateModal}
          handleModalSpecialistUpdateOnClick={
            handleModalSpecialistUpdateOnClick
          }
          HandleUpdateWarehousesOnFinish={HandleUpdateWarehousesOnFinish}
          HandleClearWarehouse={HandleClearWarehouse}
          form={updateWarehouseForm}
          warehouses={warehouses}
          rowSelection={rowSelection}
          userPermissions={userPermissions}
          isFiltered={isFiltered}
          filteredData={filteredData}
          onSearchClose={onSearchClose}
          onSelectionClose={onSelectionClose}
          searchValue={searchValue}
          selectedSpecialistId={selectedSpecialistId}
          selectedSpecialistInfo={selectedSpecialistInfo}
          onPaginationChange={onPaginationChange}
          currentPagination={currentPagination}
          onClickShowMoreList={onClickShowMoreList}
          isClickShowMoreDisabled={isClickShowMoreDisabled}
          onSortChange={onSortChange}
          columnFilter={columnFilter}
          setColumnFilter={setColumnFilter}
        />
        <SpecialistCreationModalFormComponent
          specialistCreationModadal={specialistCreationModadal}
          handleModalSpecialistCreationOnClick={
            handleModalSpecialistCreationOnClick
          }
          currentStep={currentStep}
          nextPage={nextPage}
          prevPage={prevPage}
          HandleModalFinish={HandleModalFinish}
          warehouses={warehouses}
          form={createSpecialistForm}
          onClickShowMoreList={onClickShowMoreList}
          isClickShowMoreDisabled={isClickShowMoreDisabled}
        />
        <SpecialistUpdateGenModalFormComponent
          form={updateGenSpecialistForm}
          specialistUpdateModal={specialistGenUpdateModal}
          handleModalSpecialistUpdateOnClick={handleUpdateSpecialistInfo}
          UpdateSpecialistInformation={UpdateSpecialistInformation}
          isClickShowMoreDisabled={isClickShowMoreDisabled}
        />
      </div>
    </>
  );
};
