import React from "react";
import { Form, Modal, Tabs } from "antd";
import { RolesPermissionListComponent } from "./RolesPermissionListComponent";

export const EditRolesPermissionComponent = (props) => {
  const { data, visible, form, onSubmit, onCancel } = props;

  return (
    <Modal
      visible={visible}
      width={"35%"}
      title={"Edit Permissions"}
      onOk={onSubmit}
      onCancel={onCancel}
      destroyOnClose={onCancel}
    >
      <Form form={form} name="permissions">
        {data ? (
          <Tabs tabPosition={"top"}>
            <RolesPermissionListComponent data={data} />
          </Tabs>
        ) : null}
      </Form>
    </Modal>
  );
};
