import { useDelete } from "../../hooks/useDelete";

export const DeleteColor = async (id) => {
  try {
    return await useDelete(`${process.env.REACT_APP_API_URL}/colors/${id}`);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};
