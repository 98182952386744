import { useFetch } from "../../hooks/useFetch";

export const QueryMake = async (query) => {
  try {
    const data = await useFetch(
      `${process.env.REACT_APP_API_URL}/makes/query`,
      query
    );
    return data.data;
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};
