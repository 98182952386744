import React, { useState, useEffect, useRef } from "react";
import { Divider, PageHeader, Form } from "antd";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { RemarksIndexListComponent } from "../sub_components/remarks_index/RemarksIndexListComponent";
import { RemarksIndexFunctionalityComponent } from "../sub_components/remarks_index/RemarksIndexFunctionalityComponent";
import { RemarksIndexCreationComponent } from "../sub_components/remarks_index/RemarksIndexCreationComponent";
import { RemarksIndexUpdateComponent } from "../sub_components/remarks_index/RemarksIndexUpdateComponent";
import { ModulesFilterAndSelectionComponent } from "../ModulesFilterAndSelectionComponent";
import useFetchRemarksIndex from "../../hooks/remarks_index/useFetchRemarksIndex";
import useCreateRemarksIndex from "../../hooks/remarks_index/useCreateRemarksIndex";
import useUpdateRemarksIndex from "../../hooks/remarks_index/useUpdateRemarksIndex";
import { useComponentDidUpdate } from "../../hooks/useComponentDidUpdate";
import useFetchPermissionSets from "../../hooks/useFetchPermissions";
import { ModulesSelectAndColumnTagsComponent } from "../ModulesSelectAndColumnTagsComponent";

const initialTableState = {
  data: [],
  total: 0,
};

const initialRolesRestriction = {
  CREATE_REMARKS_INDEX: 0,
  UPDATE_REMARKS_INDEX: 0,
  DELETE_REMARKS_INDEX: 0,
  RESTORE_REMARKS_INDEX: 0,
};

const permissionSet = [
  "CREATE_REMARKS_INDEX",
  "UPDATE_REMARKS_INDEX",
  "DELETE_REMARKS_INDEX",
  "RESTORE_REMARKS_INDEX",
];

export const RemarksIndexManagementComponent = () => {
  const [createRemarksIndexForm] = Form.useForm();
  const [updateRemarksIndexForm] = Form.useForm();
  const [remarksIndex, setRemarksIndex] = useState(initialTableState);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [currentPagination, setCurrentPagination] = useState(1);
  const [createRemarksModalStatus, setCreateRemarksModalStatus] =
    useState(false);
  const [updateRemarksModalStatus, setUpdateRemarksModalStatus] =
    useState(false);

  const [isShowColumnFilter, setIsShowColumnFilter] = useState(false);
  const [columnFilterTags, setColumnFilterTags] = useState([]);

  const [tableLoading, setTableLoading] = useState(false);
  const [currentCount, setCurrentCount] = useState(10);
  const [columnFilter, setColumnFilter] = useState([]);
  const [searchParameters, setSearchParameters] = useState({
    page: 1,
    limit: 10,
    filter: "",
  });
  const [isShowSelection, setIsShowSelection] = useState(false);
  const [tags, setTags] = useState([]);
  const sortColumn = useRef(null);
  const sortType = useRef("asc");
  // Query restriction using hook
  // Then hook, to get local storage
  const rolesRestriction = useFetchPermissionSets(
    permissionSet,
    initialRolesRestriction
  );

  const setSortColumn = (value) => {
    sortColumn.current = value;
  };

  const setSortType = (value) => {
    if (value === "descend") {
      sortType.current = "desc";
    } else if (value === "ascend") {
      sortType.current = "asc";
    } else {
      sortType.current = "none";
    }
  };

  const onSortChange = async (sortCol, sortType) => {
    setSortColumn(sortCol);
    setSortType(sortType);

    await ExecuteFetchRemarksIndexHook(currentPagination, currentCount);
  };

  ///////////////////////////////
  // for column filter tags
  const onCloseColumnFilterTag = (id) => {
    setColumnFilterTags((prevState) => {
      return prevState.filter((item) => item.id !== id);
    });

    setColumnFilter((prevState) => {
      let newData = prevState.filter((item) => {
        return Array.isArray(item.id)
          ? JSON.stringify(item.id) != id
          : item.id != id;
      });

      return newData;
    });

    if (!columnFilterTags.length) {
      setIsShowColumnFilter(false);
    }
  };

  const onLoadColumnFilterTags = () => {
    if (columnFilter.length) {
      let filterTags = columnFilter.map((e) => {
        let [label, tagId] = Array(2).fill(e.id);

        if (Array.isArray(e.id)) {
          tagId = JSON.stringify(tagId);
          label = "";

          for (let i = 0, n = e.id.length; i < n; i++) {
            label += `${e.id[i]} `;
          }
        }

        return {
          color: "processing",
          closable: true,
          selection_label: label,
          value: e.value,
          id: tagId,
          onClose: onCloseColumnFilterTag,
        };
      });

      setColumnFilterTags(filterTags);
      setIsShowColumnFilter(true);
    } else {
      // if columnFilter is just blank, then remove the tags
      setColumnFilterTags([]);
      setIsShowColumnFilter(false);
    }
  };

  const onClearColumnFilters = () => {
    setColumnFilterTags([]);
    setIsShowColumnFilter(false);
    setColumnFilter([]);
  };

  useEffect(() => {
    const { page, limit, filter } = searchParameters;
    ExecuteFetchRemarksIndexHook(page, limit, filter);
  }, [searchParameters]);

  const onSearchRemarks = (value) => {
    setSearchParameters((prevState) => {
      return { ...prevState, filter: value };
    });
    setIsShowSelection(true);
    setTags(() => {
      return [
        {
          color: "blue",
          value: value,
          closable: false,
          onClose: null,
          selection_label: "Includes",
        },
      ];
    });
  };

  useEffect(() => {
    ExecuteFetchRemarksIndexHook();
  }, []);

  useComponentDidUpdate(() => {
    ExecuteFetchRemarksIndexHook(1, currentCount);
    onLoadColumnFilterTags();
  }, [columnFilter]);

  const ExecuteFetchRemarksIndexHook = async (
    page = 1,
    limit = 10,
    filter = ""
  ) => {
    setTableLoading(true);
    setCurrentPagination(page);
    const query = {
      page: page,
      limit: limit,
      filter: filter,
      classification: "",
      sortColumn: sortColumn.current,
      sortType: sortType.current,
      columnFilter: columnFilter,
    };

    const data = await useFetchRemarksIndex(true, query);
    setRemarksIndex(data);
    setTableLoading(false);
  };

  const onClickCreateRemarksIndexButton = () => {
    setCreateRemarksModalStatus(!createRemarksModalStatus);
  };

  const onClickUpdateRemarksIndexButton = () => {
    setUpdateRemarksModalStatus(!updateRemarksModalStatus);
  };

  const onSelection = (selectedRowKey, selectedRow) => {
    setSelectedRowKey(selectedRowKey[0]);
    setSelectedRow(selectedRow[0]);
    updateRemarksIndexForm.setFieldsValue(selectedRow[0]);
  };

  const onPaginationChange = (page, limit) => {
    setCurrentPagination(page);
    setCurrentCount(limit);
    ExecuteFetchRemarksIndexHook(page, limit);
  };

  const OnSubmitRemarksIndexCreation = async () => {
    const values = await createRemarksIndexForm.validateFields();
    const isSuccess = await useCreateRemarksIndex(values);
    if (isSuccess) {
      reset();
      onClickCreateRemarksIndexButton();
    }
  };

  const OnSubmitRemarksIndexUpdate = async () => {
    const values = await updateRemarksIndexForm.validateFields();
    const isSuccess = await useUpdateRemarksIndex(selectedRowKey, values);
    if (isSuccess) {
      reset();
      onClickUpdateRemarksIndexButton();
    }
  };

  const OnClickDeleteRemarksIndexButton = async () => {
    const payload = { is_show: false };
    const isSuccess = await useUpdateRemarksIndex(selectedRowKey, payload);
    if (isSuccess) {
      reset();
    }
  };

  const OnClickRestoreRemarksIndexButton = async (id) => {
    const payload = { is_show: true };
    const isSuccess = await useUpdateRemarksIndex(id, payload);
    if (isSuccess) {
      reset();
    }
  };

  const reset = () => {
    createRemarksIndexForm.resetFields();
    updateRemarksIndexForm.resetFields();
    setSelectedRowKey(null);
    setSelectedRow(null);
    ExecuteFetchRemarksIndexHook();
  };

  const resetFiltersAdnSelection = () => {
    setTags([]);
    setIsShowSelection(false);
    reset();
  };

  return (
    <>
      <PageHeader
        title="Pros & Cons Index"
        subTitle="Pros & Cons Index Management"
        breadcrumbRender={() => (
          <BreadCrumbsComponent
            breadcrumbsItem={["Pros & Cons", "Pros & Cons Index"]}
          />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <RemarksIndexFunctionalityComponent
          selectedRowKey={selectedRowKey}
          selectedRow={selectedRow}
          onClickCreateRemarksIndexButton={onClickCreateRemarksIndexButton}
          onClickUpdateRemarksIndexButton={onClickUpdateRemarksIndexButton}
          onClickDeleteRemarksIndexButton={OnClickDeleteRemarksIndexButton}
          onSearchRemarks={onSearchRemarks}
          rolesRestriction={rolesRestriction}
        />
        <ModulesFilterAndSelectionComponent
          onClearSearcheAndSelection={resetFiltersAdnSelection}
          isShowSelection={isShowSelection}
          tags={tags}
        />
        <ModulesSelectAndColumnTagsComponent
          onClearColumnFilters={onClearColumnFilters}
          isShowColumnFilterTags={isShowColumnFilter}
          columnFilterTags={columnFilterTags}
        />
        <RemarksIndexListComponent
          data={remarksIndex}
          onSelection={onSelection}
          selectedRowKey={selectedRowKey}
          currentPagination={currentPagination}
          onPaginationChange={onPaginationChange}
          columnFilter={columnFilter}
          setColumnFilter={setColumnFilter}
          onSortChange={onSortChange}
          tableLoading={tableLoading}
          onClickRestoreRemarksIndexButton={OnClickRestoreRemarksIndexButton}
          rolesRestriction={rolesRestriction}
        />
      </div>
      <RemarksIndexCreationComponent
        visible={createRemarksModalStatus}
        form={createRemarksIndexForm}
        onSubmit={OnSubmitRemarksIndexCreation}
        onCancel={onClickCreateRemarksIndexButton}
      />
      <RemarksIndexUpdateComponent
        visible={updateRemarksModalStatus}
        form={updateRemarksIndexForm}
        onSubmit={OnSubmitRemarksIndexUpdate}
        onCancel={onClickUpdateRemarksIndexButton}
      />
    </>
  );
};
