import React, { useState, useEffect } from "react";
import { Row, Col, PageHeader, Divider, Form, message } from "antd";
import { CarsCreationComponent } from "../sub_components/cars/CarsCreationComponent";
import { CarsTableListComponent } from "../sub_components/cars/CarsTableListComponent";
import { CarsFunctionalityComponent } from "../sub_components/cars/CarsFunctionalityComponent";
import { FetchCarsList } from "../../api/cars/FetchCarsList";
import { CreateCar } from "../../api/cars/CreateCar";
import { FetchModelsList } from "../../api/models/FetchModelsList";
import { FetchWarehouseSpecialistsList } from "../../api/warehouse/FetchWarehouseSpecialistsList";
import "../../dist/style/cars.css";

export const CarsManagementComponent = () => {
  const [form] = Form.useForm();
  const [models, setModels] = useState([]);
  const [cars, setCars] = useState([]);
  const [specialists, setSpecialists] = useState([]);
  const [loading, setTableLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [isShowModalCarsCreation, setIsShowModalCarsCreation] = useState(false);

  const FetchWarehouseSpecialists = async () => {
    const data = await FetchWarehouseSpecialistsList();
    setSpecialists(data);
  };

  const FetchModelLists = async () => {
    const data = await FetchModelsList();
    setModels(data);
  };

  const FetchCarLists = async () => {
    setTableLoading(true);
    const data = await FetchCarsList();
    for (const d of data) {
      d["key"] = d.id;
    }
    setCars(data);
    setTableLoading(false);
  };

  useEffect(() => {
    FetchModelLists();
    FetchCarLists();
    FetchWarehouseSpecialists();
  }, []);

  const handleCarCreationModalVisibility = () => {
    setIsShowModalCarsCreation(!isShowModalCarsCreation);
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const HandleFormSubmit = async () => {
    const data = await form.validateFields();
    const result = await CreateCar(data);
    if (result !== null) {
      message.success("Successfuly Created Car Information", 2);
      await form.resetFields();
      handleCarCreationModalVisibility();
    }
  };

  return (
    <>
      <PageHeader title="Cars Management" subTitle="management for cars" />
      <Divider />
      <Row>
        <Col span={24}>
          <div className="cars-btns-functionality-layout-div">
            <CarsFunctionalityComponent
              handleCreateCarsClick={handleCarCreationModalVisibility}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <CarsTableListComponent loading={loading} data={cars} />
        </Col>
      </Row>
      <CarsCreationComponent
        form={form}
        visible={isShowModalCarsCreation}
        currentStep={currentStep}
        models={models}
        specialists={specialists}
        nextStep={nextStep}
        prevStep={prevStep}
        handleCancel={handleCarCreationModalVisibility}
        handleSubmit={HandleFormSubmit}
      />
    </>
  );
};
