import { Form, Input, Button, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { FormRules } from "../dist/functions/formRules";
import useLogin from "../hooks/authentication/useLogin";
import "../dist/style/login.css";

export const LoginComponent = (props) => {
  const { setPermissions } = props;
  const [form] = Form.useForm();

  const OnFinish = async (values) => {
    const response = await useLogin(values);
    const { status, permissions } = response;

    if (status) {
      window.location.reload();
      setPermissions(permissions);
    }
    // try {

    //   const result = await useCreate(
    //     `${process.env.REACT_APP_API_URL}/authentications/login`,
    //     values
    //   );

    //   const { status } = result.data.data;
    //   if (status === "AUTHENTICATION_SUCCESS") {
    //     let permissionArr = [];
    //     for (const permission of result.data.data.permissions) {
    //       const { permissions, user_id } = permission;
    //       localStorage.setItem("user_id", user_id);
    //       const permissionObj = {
    //         name: permissions.permissionIndex.name,
    //         has_access: permission.has_access,
    //       };

    //       permissionArr.push(permissionObj);
    //     }

    //     setPermissions(permissionArr);

    //     message
    //       .loading("Authenticated User, Preparing details..", 2)
    //       .then(async () => {
    //         const { jwt } = result.data.data;
    //         const resp = await ReturnAuthenticationStatus(jwt.token);
    //         if (!resp) {
    //           message.warning("Apologies, this user is not a dashboard user");
    //           return;
    //         }

    //         login(jwt.token);
    //         window.location.reload();
    //         localStorage.setItem("permissions", JSON.stringify(permissionArr));
    //       });
    //   }
    // } catch (err) {
    //   if (err.response.status === 401) {
    //     message.error("Incorrect Email or Password, Please try again", 2);
    //     form.resetFields();
    //   }
    // }
  };

  return (
    <div className="login-div-layout">
      <Form
        form={form}
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
        onFinish={OnFinish}
      >
        <Form.Item name="email_address" rules={[FormRules.REQUIRED_EMAIL]}>
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item name="password" rules={[FormRules.REQUIRED_PASSWORD]}>
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            block
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
