import React from "react";
import { ModulesTableComponent } from "../../ModulesTableComponent";
import useGetFAQsIndexColumns from "../../../hooks/faq_index/useGetFAQsIndexColumns";
import useColumnFilter from "../../../hooks/useColumnFilter";

export const FAQIndexListComponent = (props) => {
  const {
    data = { data: [], total: 0 },
    onPaginationChange,
    currentPagination,
    onSelection,
    selectedRowKey,
    onSortChange,
    columnFilter,
    setColumnFilter

  } = props;

  const faqsIndexColumns = useGetFAQsIndexColumns();

  const { getColumnFilterProps } = useColumnFilter(columnFilter, setColumnFilter);

  let newCols = [];

  for (let i = 0, n = faqsIndexColumns.length; i < n; i++) {
    newCols.push({ ...faqsIndexColumns[i], sorter: true, ...getColumnFilterProps(faqsIndexColumns[i].dataIndex) })
  }

  return (
    <div>
      <ModulesTableComponent
        data={data}
        onPaginationChange={onPaginationChange}
        onSelection={onSelection}
        selectedRowKey={selectedRowKey}
        tableColumns={newCols}
        currentPagination={currentPagination}
        columnFilter={columnFilter}
        setColumnFilter={setColumnFilter}
        onSortChange={onSortChange}
      />
    </div>
  );
};
