import { useEffect, useState } from "react";

const useOnChangeProvince = () => {
  const [provinceId, setSelectedProvinceId] = useState(null);
  const [provinceName, setSelectedProvinceName] = useState(null);

  const onChangeProvince = (id, provinces) => {
    setSelectedProvinceId(id);

    const province = provinces.find((province) => province.province_id === id);

    if (province) {
      setSelectedProvinceName(province.province_name);
    }
  };

  useEffect(() => {
    return () => {
      setSelectedProvinceId(null);
      setSelectedProvinceName(null);
    };
  }, []);

  return {
    provinceId,
    provinceName,
    onChangeProvince,
  };
};

export default useOnChangeProvince;
