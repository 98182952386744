import { useFetch } from "../../hooks/useFetch";

export const FetchCities = async () => {
  try {
    return await useFetch(`${process.env.REACT_APP_API_URL}/cities`);
  } catch (err) {
    console.log("ERROR: ", err.stack);
    return {
      data: [],
    };
  }
};
