import { useUpdate } from "../../hooks/useUpdate";

export const UpdateRolesPermissionsDefault = async (data) => {
  try {
    return await useUpdate(
      `${process.env.REACT_APP_API_URL}/roles/permissions-default`,
      data
    );
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};
