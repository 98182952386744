import React, { useState, useEffect, useRef } from "react";
import { PageHeader, Row, Col, Form, Divider, message } from "antd";
import { useFetch } from "../../hooks/useFetch";
import { useUpdate } from "../../hooks/useUpdate";
import { useCreate } from "../../hooks/useCreate";
import { FetchBankList } from "../../api/banks/FetchBankList";
import { BankDataActionsComponent } from "../sub_components/banks/BankDataActionsComponent";
import { BanksFunctionalityComponent } from "../sub_components/banks/BanksFunctionalityComponent";
import { BanksTableListComponent } from "../sub_components/banks/BanksTableListComponent";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { BanksFilterAndSelectionComponent } from "../sub_components/banks/BanksFilterAndSelectionComponent";
import { useComponentDidUpdate } from "../../hooks/useComponentDidUpdate";
import { ModulesSelectAndColumnTagsComponent } from "../ModulesSelectAndColumnTagsComponent";

const initialRolesRestriction = {
  CREATE_PROVIDER: 0,
  UPDATE_PROVIDER: 0,
};

export const BanksManagementComponent = () => {
  const [form] = Form.useForm();
  const [banks, setBanks] = useState({ data: [], total: 0 });
  const [tableLoading, setTableLoading] = useState(false);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [transactionType, setTransactionType] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState(null);
  const [selectedBank, setSelectedBank] = useState(null);
  const [rolesRestrictions, setRolesRestrictions] = useState(
    initialRolesRestriction
  );

  const [isShowColumnFilter, setIsShowColumnFilter] = useState(false);
  const [columnFilterTags, setColumnFilterTags] = useState([]);

  const [currentPagination, setCurrentPagination] = useState(1);
  const [currentCount, setCurrentCount] = useState(10);

  const [columnFilter, setColumnFilter] = useState([]);
  const sortColumn = useRef(null);
  const sortType = useRef("asc");

  const setSortColumn = (value) => {
    sortColumn.current = value;
  }

  const setSortType = (value) => {
    if (value === "descend") {
      sortType.current = "desc";
    } else if (value === "ascend") {
      sortType.current = "asc";
    } else {
      sortType.current = "none";
    }
  }

  const onSortChange = async (sortCol, sortType) => {
    setSortColumn(sortCol);
    setSortType(sortType);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    await GetBanksList(currentPagination, currentCount);

  };

  const GetBanksList = async (page = 1, limit = 10) => {
    setCurrentPagination(page)
    setTableLoading(true);
    const query = {
      page: page,
      limit: limit,
      sortColumn: sortColumn.current,
      sortType: sortType.current,
      columnFilter: columnFilter
    };
    const data = await FetchBankList(query);
    setBanks(data);
    setTableLoading(false);
    // setTimeout(() => {

    // }, 1500);
  };

  const onPaginationChange = (page, limit) => {
    setCurrentPagination(page);
    setCurrentCount(limit);
    GetBanksList(page, limit);
  };

  ///////////////////////////////
  // for column filter tags
  const onCloseColumnFilterTag = (id) => {
    setColumnFilterTags((prevState) => {
      return prevState.filter(
        (item) => item.id !== id
      );
    });

    setColumnFilter((prevState) => {
      let newData = prevState.filter(
        (item) => {

          return Array.isArray(item.id) ? JSON.stringify(item.id) != id : item.id != id;
        }
      );

      return newData;
    });

    if (!columnFilterTags.length) {
      setIsShowColumnFilter(false);
    }
  };

  const onLoadColumnFilterTags = () => {

    if (columnFilter.length) {
      let filterTags = columnFilter.map((e) => {
        let [label, tagId] = Array(2).fill(e.id);

        if (Array.isArray(e.id)) {
          tagId = JSON.stringify(tagId);
          label = "";

          for (let i = 0, n = e.id.length; i < n; i++) {
            label += `${e.id[i]} `;
          }
        }

        return {
          color: "processing",
          closable: true,
          selection_label: label,
          value: e.value,
          id: tagId,
          onClose: onCloseColumnFilterTag
        }
      })

      setColumnFilterTags(filterTags);
      setIsShowColumnFilter(true);
    } else {
      // if columnFilter is just blank, then remove the tags
      setColumnFilterTags([]);
      setIsShowColumnFilter(false);
    }
  };

  const onClearColumnFilters = () => {
    setColumnFilterTags([]);
    setIsShowColumnFilter(false);
    setColumnFilter([]);

  };

  const HandleCreate = async (data) => {
    return await useCreate(`${process.env.REACT_APP_API_URL}/banks`, data);
  };

  const HandleUpdate = async (data) => {
    return await useUpdate(
      `${process.env.REACT_APP_API_URL}/banks/${selectedRowKeys}`,
      data
    );
  };

  const HandleModaOkClicked = async () => {
    const payload = await form.validateFields();
    transactionType === "Create"
      ? await HandleCreate(payload)
      : await HandleUpdate(payload);

    setTimeout(() => {
      form.resetFields();
      HandleModalCancelClicked();
      GetBanksList();
      handleClearSelection();
    }, 1000);

    if (transactionType === "Create") {
      message.success("Successfuly Added New Bank");
    } else {
      message.success("Successfuly Updated Bank");
    }
  };

  const HandleModalCancelClicked = () => {
    setTransactionType(null);
    setModalVisibility(!modalVisibility);
  };

  const handleCreateButtonModalClicked = () => {
    setTransactionType("Create");
    form.resetFields();
    setModalVisibility(!modalVisibility);
  };

  const handleUpdateButtonModalClicked = () => {
    setTransactionType("Update");
    setModalVisibility(!modalVisibility);
    form.setFieldsValue({
      name: selectedBank.name,
      min_loan_amount: selectedBank.min_loan_amount,
    });
  };

  const onChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedBank(selectedRows[0]);
  };

  const handleClearSelection = () => {
    setSelectedRowKeys(null);
    setSelectedBank(null);

  };

  useEffect(() => {
    GetBanksList();
  }, []);

  useComponentDidUpdate(() => {
    GetBanksList(1, currentCount);
    onLoadColumnFilterTags();

  }, [columnFilter]);

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const CREATE_PROVIDER = permissions.find(
      (item) => item.name === "CREATE_PROVIDER"
    );
    const UPDATE_PROVIDER = permissions.find(
      (item) => item.name === "UPDATE_PROVIDER"
    );

    const permissionsObj = {};
    permissionsObj["CREATE_PROVIDER"] =
      typeof CREATE_PROVIDER === "undefined" ? 0 : CREATE_PROVIDER.has_access;
    permissionsObj["UPDATE_PROVIDER"] =
      typeof UPDATE_PROVIDER === "undefined" ? 0 : UPDATE_PROVIDER.has_access;

    setRolesRestrictions(permissionsObj);
  }, []);

  return (
    <>
      <PageHeader
        title={"PROVIDERS"}
        subTitle={"PROVIDERS MANAGEMENT MODULE"}
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["Providers"]} />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <BanksFunctionalityComponent
          handleCreateButtonModalClicked={handleCreateButtonModalClicked}
          handleUpdateButtonModalClicked={handleUpdateButtonModalClicked}
          hasSelected={selectedRowKeys}
          CREATE_PROVIDER={rolesRestrictions.CREATE_PROVIDER}
          UPDATE_PROVIDER={rolesRestrictions.UPDATE_PROVIDER}
          handleClearSelection={handleClearSelection}
        />
        <Divider />
        <BanksFilterAndSelectionComponent
          onClearSearchAndSelection={handleClearSelection}
          selectedBank={selectedBank}
          isShowColumnFilterTags={isShowColumnFilter}
          columnFilterTags={columnFilterTags}
          onClearColumnFilters={onClearColumnFilters}
        />

        {/* <ModulesSelectAndColumnTagsComponent
          onClearColumnFilters={onClearColumnFilters}
          isShowColumnFilterTags={isShowColumnFilter}
          columnFilterTags={columnFilterTags}
        /> */}
        <BanksTableListComponent
          tableLoading={tableLoading}
          data={banks}
          selectedRowKeys={selectedRowKeys}
          onChange={onChange}
          onPaginationChange={onPaginationChange}
          currentPagination={currentPagination}
          columnFilter={columnFilter}
          setColumnFilter={setColumnFilter}
          onSortChange={onSortChange}
        />
      </div>
      <BankDataActionsComponent
        form={form}
        isModalVisible={modalVisibility}
        OnHandleOk={HandleModaOkClicked}
        OnHandleCancel={HandleModalCancelClicked}
        handleClearSelection={handleClearSelection}
        selectedBank={selectedBank}
      />
    </>
  );
};
