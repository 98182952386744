import React from "react";
import { Row, Col, Empty } from "antd";
import { ModelsDescriptionComponent } from "../models/ModelsDescriptionComponent";
import { AdsDescriptionComponent } from "../ads/AdsDescriptionComponent";
import { NotesDescriptionComponent } from "../models/NotesDescriptionComponent";

export const CarsSubInformationComponent = (props) => {
  const {
    engine_displacement,
    fuel_system,
    fuel_type,
    name,
    starter_mechanism,
    transmission_type,
    trim,
    make_name,
    vehicle_type,
    other_notes,
    specialist_notes,
    ads,
  } = props;

  return (
    <>
      <Row>
        <Col span={17}>
          <ModelsDescriptionComponent
            engine_displacement={engine_displacement}
            fuel_system={fuel_system}
            fuel_type={fuel_type}
            name={name}
            starter_mechanism={starter_mechanism}
            transmission_type={transmission_type}
            trim={trim}
            make_name={make_name}
            vehicle_type={vehicle_type}
          />
          <br />
          <br />
          {ads ? (
            <AdsDescriptionComponent
              title={ads.title}
              description={ads.description}
              ads_type={ads.ads_type}
              expired_at={ads.expired_at}
              price_order={ads.price_order}
              price={ads.price}
            />
          ) : (
            <Empty description="No Ads Attached" />
          )}
        </Col>
        <Col span={7}>
          <div className="expanded-table-description-notes-layout">
            <NotesDescriptionComponent
              other_notes={other_notes}
              specialist_notes={specialist_notes}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
