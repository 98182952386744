import React, { Fragment } from "react";
import { Modal, Form, Input, Card, Select, Tabs, Radio } from "antd";

const { TabPane } = Tabs;
const { Option } = Select;
const { Meta } = Card;

export const CreatingRolesFormComponent = (props) => {
  const {
    isModalVisible,
    OnFinish,
    handlePlatformSelectChange,
    platforms,
    queriedModules,
    handleModalVisibleOnClick,
    form,
  } = props;

  return (
    <Fragment>
      <Modal
        visible={isModalVisible}
        title="Roles Creation"
        onOk={OnFinish}
        onCancel={handleModalVisibleOnClick}
        width={800}
      >
        <Form form={form} layout={"vertical"}>
          <Form.Item
            label="Roles Name"
            name="name"
            rules={[{ required: true, message: "Please input roles name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: "Please input roles name" }]}
          >
            <Input.TextArea></Input.TextArea>
          </Form.Item>
          <Form.Item
            label="Platform Name"
            name="platform_id"
            rules={[{ required: true, message: "Please select platform name" }]}
          >
            <Select onChange={handlePlatformSelectChange}>
              {platforms.map((e, key) => {
                return (
                  <Option key={key} value={e.id}>
                    {e.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          {queriedModules.length === 0 ? null : (
            <Tabs defaultActiveKey="1" tabPosition={"top"}>
              {queriedModules.map((e, key) => {
                return (
                  <TabPane
                    tab={e.name}
                    key={key}
                    forceRender={true}
                    style={{ overflowY: "auto", height: 300, width: "100%" }}
                  >
                    <Card
                      title="Permissions"
                      size="small"
                      bordered={false}
                      style={{ textAlign: "center" }}
                    >
                      {e.permission_index.map((permission_index, key) => (
                        <>
                          <Meta
                            key={`meta-${key}`}
                            title={permission_index.name}
                            description={permission_index.description}
                          />
                          <br />
                          <Form.Item
                            name={`${permission_index.id}/${permission_index.name}/platform_name`}
                          >
                            <Radio.Group
                              key={permission_index.id}
                              name={permission_index.id}
                              defaultValue={0}
                            >
                              <Radio value={1}>ENABLED</Radio>
                              <Radio value={0}>DISABLED</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </>
                      ))}
                    </Card>
                  </TabPane>
                );
              })}
            </Tabs>
          )}
        </Form>
      </Modal>
    </Fragment>
  );
};
