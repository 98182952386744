import React from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Checkbox,
  Space,
  Button,
  Divider,
  Empty,
} from "antd";
import { SelectedReviewItemListComponent } from "./SelectedReviewItemListComponent";
import { CheckSquareOutlined, CloseSquareOutlined } from "@ant-design/icons";

export const ReviewsTransactionComponent = (props) => {
  const {
    form,
    reviews = [],
    selectedReviews = [],
    setSelectedReviews,
    transactionType = "for_creation",
    onClickUnselectButton,
    onClickUnselectAll,
  } = props;

  return (
    <>
      <Form form={form} layout="vertical">
        <Row>
          <Col span={13}>
            <Form.Item name="name" label="Name">
              <Input />
            </Form.Item>
            {"Selected Reviews: "}
            <div>
              <br />
              <Button
                type="default"
                size="small"
                icon={<CloseSquareOutlined />}
                onClick={onClickUnselectAll}
                disabled={selectedReviews.legth === 0 ? true : false}
                danger
              >
                Deselect All
              </Button>
              <Divider />
              {selectedReviews.length > 0 ? (
                <SelectedReviewItemListComponent
                  listData={selectedReviews}
                  transactionType={transactionType}
                  onClickUnselectButton={onClickUnselectButton}
                />
              ) : (
                <Empty />
              )}
            </div>
          </Col>
          <Col span={11}>
            <div style={{ textAlign: "left", paddingLeft: "10%" }}>
              {/* <Space direction="horizontal">
                <Button
                  type="default"
                  icon={<CheckSquareOutlined />}
                  size="small"
                  disabled={true}
                >
                  Select All
                </Button>
              </Space> */}
              <Divider />
              <Form.Item name="indices">
                <div style={{ overflowY: "auto", height: "400px" }}>
                  <Space direction={"vertical"} size="small">
                    {reviews.map((item, index) => (
                      <Checkbox
                        name={item.name}
                        key={index}
                        checked={item.checked ? item.checked : false}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedReviews((prevState) => {
                              return [...prevState, item];
                            });
                          }

                          if (!e.target.checked) {
                            setSelectedReviews((prevState) => {
                              return prevState.filter((prevStateItem) => {
                                return prevStateItem.id !== item.id;
                              });
                            });
                          }
                        }}
                      >
                        {
                          <>
                            <div>{`Name:`}</div>
                            <div>{item.name}</div>
                            <br />
                            <div>{`Comment:`}</div>
                            <div>{item.comment}</div>
                            <br />
                            <div>Ratings: </div>
                            <div>{item.rating}</div>
                            <br />
                            <div>Reviewed Date: </div>
                            <div>{item.reviewed_date}</div>
                            <Divider />
                          </>
                        }
                      </Checkbox>
                    ))}
                  </Space>
                </div>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};
