import { useCreate } from "../../hooks/useCreate";

export const CreateAds = async (data) => {
  try {
    const result = await useCreate(
      `${process.env.REACT_APP_API_URL}/ads`,
      data
    );
    return result;
  } catch (err) {
    return null;
  }
};
