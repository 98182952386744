import { useFetch } from "../../hooks/useFetch";

export const FetchFAQs = async (query = { page: 1, limit: 10 }) => {
  try {
    return await useFetch(`${process.env.REACT_APP_API_URL}/faqs`, query);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};
