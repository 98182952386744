import React from "react";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";

export const BidsReportsComponent = ({ dataSet }) => {
  const generateRow = (item) => {
    const {
      ads,
      users,
      bid_amount,
      payment_method,
      created_at,
      user_bid_status,
      auction_status,
    } = item;
    const { title, slug, warehouses } = ads;
    const { first_name, last_name, middle_name, email, mobile } = users;

    const model = title.split(" ")[0];
    const trans_type = ads?.car?.model?.transmission_type;
    const new_adviser = ads?.car?.new_adviser || ads?.car?.adviser;

    let _title = title;

    if (trans_type) {
      _title += ` ${trans_type}`;
    }
    if (model) {
      _title = _title.replace(model, "");
    }

    const middle_initial = middle_name
      ? middle_name.toLowerCase() === "n/a"
        ? ""
        : middle_name.charAt(0)
      : "";

    const adviserName = new_adviser
      ? `${new_adviser.first_name || ""} ${new_adviser.last_name || ""}`
      : "";

    return [
      model,
      _title,
      ads?.car?.conduction_sticker,
      slug,
      first_name || "",
      last_name || "",
      middle_initial,
      email || "",
      mobile || "",
      bid_amount || "",
      payment_method || "",
      warehouses?.location || "",
      adviserName,
      created_at,
      user_bid_status || "",
      payment_method || "",
      item.is_accepted === 1 ? "No" : "Yes",
      auction_status || "",
      users?.referrer || "AutomartPH",
      ads?.car?.color?.specific_name || "",
    ];
  };

  const generateData = (rows, platform) => {
    const header = [
      "Year Model",
      "Car Title",
      "Conduction Sticker",
      "Plate Number",
      "Winner Firstname",
      "Winner Lastname",
      "Middle Initial",
      "Winner Email",
      "Winner Phone",
      "Final Bid",
      "Payment Method",
      "Location",
      "Seller Name",
      "Bid Date",
      "Bid Status",
      "Payment Method",
      "Previous Bids",
      "Auction Status",
      "Referrer",
      "Unit Color",
    ];

    return [header, ...rows.map((item) => generateRow(item))];
  };

  const exportToExcel = (rows) => {
    const data = generateData(rows);
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(
      wb,
      `bids${new Date()
        .toLocaleString()
        .replace("/", "")
        .replace("/", "")
        .replace(":", "")
        .replace(":", "")}.xlsx`
    );
  };

  return (
    <Button icon={<DownloadOutlined />} onClick={() => exportToExcel(dataSet)}>
      Generate Report
    </Button>
  );
};
