import React from "react";
import { Form, Select, Modal } from "antd";
import { SearchOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { AuctionSearchInputsComponent } from "../auctions/AuctionSearchInputsComponent";
import useIsMobile from "../../../hooks/useIsMobile";

const { Option } = Select;

export const BidsSearchFunctionalityComponent = ({ onSubmitSearch, form, visible, onCancel }) => {
  const isMobile = useIsMobile();

  return (
    <Modal
      visible={visible}
      title="Filter Bids"
      okText="Search Bids"
      okButtonProps={{ icon: <SearchOutlined />, block: isMobile }}
      cancelButtonProps={{ icon: <ArrowLeftOutlined />, block: isMobile }}
      onOk={() => onSubmitSearch(1, 10)}
      onCancel={onCancel}
    >
      <div
        style={{
          overflowY: isMobile ? "auto" : "hidden",
          height: isMobile ? 300 : "100%",
          padding: isMobile ? "2%" : undefined,
        }}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="report_type" initialValue="active" label="Filter Type">
            <Select placeholder="Select Bid Status">
              {["all", "active", "completed"].map((value) => (
                <Option key={value} value={value}>
                  {value === "all" ? "All" : value === "active" ? "On Going" : "Winners"}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <AuctionSearchInputsComponent is_bidding_module />
        </Form>
      </div>
    </Modal>
  );
};
