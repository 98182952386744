import React from "react";
import { Button, Divider, Space } from "antd";
import { PlusCircleOutlined, FormOutlined } from "@ant-design/icons";
import useIsMobile from "../../../hooks/useIsMobile";

export const MakesFunctionalitiesComponent = (props) => {
  const {
    onClickCreateMake,
    onClickUpdateMake,
    rolesRestriction = {},
    selectedRowKeys,
  } = props;
  const { CREATE_MAKES = 1, UPDATE_MAKES = 1 } = rolesRestriction;
  const isMobile = useIsMobile();

  return (
    <div
      style={{
        width: "100%",
        textAlign: isMobile ? "center" : "right",
        display: CREATE_MAKES === 0 && UPDATE_MAKES === 0 ? "none" : null,
      }}
    >
      <Space size="small" direction={isMobile ? "vertical" : "horizontal"}>
        <Button
          hidden={CREATE_MAKES === 0 ? true : false}
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={onClickCreateMake}
          block={isMobile ? true : false}
        >
          Create Make
        </Button>
        <Button
          hidden={UPDATE_MAKES === 0 || !selectedRowKeys ? true : false}
          type="default"
          icon={<FormOutlined />}
          onClick={onClickUpdateMake}
          block={isMobile ? true : false}
        >
          Update Make
        </Button>
      </Space>
      <Divider />
    </div>
  );
};
