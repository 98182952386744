import { useUpdate } from "../../hooks/useUpdate";

export const UpdateModel = async (id, payload) => {
  try {
    return await useUpdate(
      `${process.env.REACT_APP_API_URL}/models/${id}`,
      payload
    );
  } catch (err) {
    console.log("ERROR: ", err.stack);
  }
};
