import React from "react";
import { Table, Button, Checkbox, Space } from "antd";
import { EditOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import useIsMobile from "../../../hooks/useIsMobile";

const { Column, ColumnGroup } = Table;
export const PermissionRolesResultsComponent = (props) => {
  const {
    roles = [],
    onClickEditRolePermission,
    tableLoading,
    OnClickAddUserOnRole,
  } = props;

  const isMobile = useIsMobile();

  return (
    <div style={{ textAlign: "left", marginTop: "2%" }}>
      <Table
        dataSource={roles}
        size="small"
        bordered={true}
        scroll={{ x: 1500 }}
        loading={tableLoading}
      >
        <Column
          title="Roles Name"
          dataIndex="name"
          width={340}
          fixed={isMobile ? false : "left"}
        />
        {roles.length > 0
          ? roles[0].permissions.map((item, permissionIndex) => (
              <ColumnGroup key={permissionIndex} title={item.name} width={200}>
                {item.permissions.map((item) => (
                  <Column
                    key={`permision-${permissionIndex}`}
                    title={item.name}
                    width={300}
                    dataIndex={item.name.toString()}
                    render={(has_access) => <Checkbox checked={has_access} />}
                    editable={true}
                  />
                ))}
              </ColumnGroup>
            ))
          : null}
        <Column
          title="Action(s)"
          width={80}
          fixed={isMobile ? false : "right"}
          render={(_, record) => (
            <Space size="small" direction={"horizontal"}>
              <Button
                type="default"
                size="small"
                icon={<EditOutlined />}
                onClick={() => onClickEditRolePermission(record)}
              />
              <Button
                type="default"
                size="small"
                disabled={true}
                icon={<UsergroupAddOutlined />}
                onClick={() => OnClickAddUserOnRole(record)}
              />
            </Space>
          )}
        />
      </Table>
    </div>
  );
};
