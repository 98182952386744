import { FetchActivityLogs } from "../../api/activity_logs/FetchActivityLogs";
import { message } from "antd";

const useGetActivityLogs = async (query) => {
  // NOTE: its faster to make message with key and not await it... just destroy it afterwards
  message.loading({ content: 'Fetching Activity Logs...', key: "activityLoad", duration: 2 });

  const data = await FetchActivityLogs(query);

  for (const item of data.data.data) {
    item["key"] = item.id;
    const meta_information = [];

    for (const key in item) {

      if (key.includes("meta_information")) {
        const meta_value = JSON.parse(item[key]);
        for (const meta_key in meta_value) {
          const single_meta = {
            key: meta_key,
            value:
              meta_key === "source_payload" || meta_key === "user_general_info"
                ? JSON.stringify(meta_value[meta_key])
                : meta_value[meta_key],
          };

          meta_information.push(single_meta);
        }
      }
    }

    item["meta_information"] = meta_information;
  }

  message.destroy("activityLoad")
  message.success("Successfuly Retrieved All Activity Logs");
  return data.data;
};

export default useGetActivityLogs;
