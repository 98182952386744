import { useFetch } from "../../hooks/useFetch";

export const FetchAdsList = async (query) => {
	try {
		return await useFetch(`${process.env.REACT_APP_API_URL}/ads`, query);
	} catch (err) {
		if (err.request) return err.request;
		if (err.response) return err.response;
		return err.message;
	}
};
