import React from "react";
import { ModulesFunctionalitiesComponent } from "../../ModulesFunctionalitiesComponent";
import { SaveOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

export const RemarksIndexFunctionalityComponent = (props) => {
  const {
    onClickCreateRemarksIndexButton,
    onClickUpdateRemarksIndexButton,
    onClickDeleteRemarksIndexButton,
    onSearchRemarks,
    selectedRowKey,
    selectedRow,
    rolesRestriction,
  } = props;

  const { CREATE_REMARKS_INDEX, UPDATE_REMARKS_INDEX, DELETE_REMARKS_INDEX } =
    rolesRestriction;

  const functionalitiesComponent = [
    {
      component_type: "search_input",
      _onSearch: onSearchRemarks,
      place_holder: "Search Remarks",
    },
    {
      component_type: "button",
      type: "primary",
      icon: <SaveOutlined />,
      _onClick: onClickCreateRemarksIndexButton,
      size: "medium",
      label: "Create Pros & Cons Index",
      is_hidden: CREATE_REMARKS_INDEX === 1 ? false : true,
    },
    {
      component_type: "button",
      type: "default",
      icon: <EditOutlined />,
      _onClick: onClickUpdateRemarksIndexButton,
      size: "medium",
      label: "Update Pros & Cons Index",
      is_hidden:
        UPDATE_REMARKS_INDEX === 1 ? (selectedRowKey ? false : true) : true,
    },
    {
      component_type: "button",
      type: "default",
      icon: <DeleteOutlined />,
      _onClick: onClickDeleteRemarksIndexButton,
      size: "medium",
      label: "Delete Pros & Cons Index",
      danger: true,
      is_hidden:
        selectedRow?.is_show === 1
          ? selectedRowKey
            ? false
            : DELETE_REMARKS_INDEX === 1
            ? false
            : true
          : true,
    },
  ];

  return (
    <ModulesFunctionalitiesComponent
      alignment="right"
      spaceSize="small"
      functionalitiesComponent={functionalitiesComponent}
    />
  );
};
