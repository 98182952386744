import { useEffect, useState } from "react";
import { Space, Button, Tag } from "antd";
import { FormOutlined, RedoOutlined } from "@ant-design/icons";

const columns = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
    fixed: "left",
    width: "80px",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Post Count",
    dataIndex: "post_count",
    key: "post_count",
  },
  {
    title: "Status",
    dataIndex: "is_show",
    key: "is_show",
    render: (is_show) =>
      is_show || is_show === null ? (
        <Tag color="green">Enable</Tag>
      ) : (
        <Tag color="orange">Archived</Tag>
      ),
  },
  {
    title: "Created Date",
    dataIndex: "created_at",
    key: "created_at",
  },
  {
    title: "Last Modified Date",
    dataIndex: "updated_at",
    key: "updated_at",
  },
];

export default function useGetReviewGroupListColumns(
  onClickEditReviewGroup,
  onClickRestoreReviewGroup,
  rolesRestriction
) {
  const [tableColumns, setColumns] = useState(columns);

  const { REVIEW_GROUP_UPDATE, REVIEW_GROUP_DELETE, REVIEW_GROUP_RESTORE } =
    rolesRestriction;

  const returHasEditFunctionality = () => {
    if (REVIEW_GROUP_UPDATE === 0 && REVIEW_GROUP_DELETE === 0) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    setColumns([
      ...columns,
      {
        dataIndex: "id",
        key: "id",
        title: "Actions",
        fixed: "right",
        width: "80px",
        render: (id, row) => (
          <Space direction="horizontal" size={0}>
            <Button
              type="link"
              icon={<FormOutlined />}
              disabled={!returHasEditFunctionality()}
              size="small"
              onClick={() => onClickEditReviewGroup(id, row)}
            />
            <Button
              type="link"
              icon={<RedoOutlined />}
              size="small"
              disabled={
                REVIEW_GROUP_RESTORE === 0
                  ? true
                  : row.is_show
                  ? row.is_show
                  : true
              }
              danger
              onClick={() => onClickRestoreReviewGroup(id)}
            />
          </Space>
        ),
      },
    ]);
  }, [rolesRestriction]);

  return tableColumns;
}
