// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expanded-table-description-notes-layout {
  padding-left: 4%;
  padding-top: 8%;
  padding-right: 0%;
}

.cars-btns-functionality-layout-div {
  margin-bottom: 2%;
  text-align: right;
}
`, "",{"version":3,"sources":["webpack://./src/dist/style/cars.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;AACnB","sourcesContent":[".expanded-table-description-notes-layout {\n  padding-left: 4%;\n  padding-top: 8%;\n  padding-right: 0%;\n}\n\n.cars-btns-functionality-layout-div {\n  margin-bottom: 2%;\n  text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
