import React from "react";
import { Divider, Result, Typography, Button, Collapse } from "antd";
import { RedoOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { BulkUploaderLogsCollapseComponent } from "./BulkUploaderLogsCollapseComponent";
import useIsMobile from "../../../hooks/useIsMobile";

const { Title } = Typography;

export const BulkUploadValidationFeedBacksComponent = (props) => {
  const {
    hasErrors = false,
    validationLogs = null,
    onReset,
    handleSyncingModalVisibility,
    onCreateNewModel,
    ExecuteRelistSlug,
    showConfirm,
  } = props;

  const isMobile = useIsMobile();
  return (
    <>
      {validationLogs ? (
        <>
          <Divider />
          <div
            style={{ textAlign: "right", marginBottom: isMobile ? "10%" : 0 }}
          >
            <Button
              icon={<RedoOutlined />}
              type="default"
              onClick={onReset}
              block={isMobile ? true : false}
            >
              Upload New
            </Button>
          </div>
        </>
      ) : null}
      {!hasErrors ? (
        <>
          <Result
            status="success"
            subTitle={
              <h3>No error found, You can proceed with the uploading</h3>
            }
            style={{ padding: 0 }}
          />
        </>
      ) : null}
      {validationLogs ? (
        <>
          <Divider />
          <div style={{ textAlign: "center" }}>
            <Title level={4}>Validation Logs Information</Title>
          </div>
          <div style={{ marginBottom: "1%", marginTop: "1%" }}>
            {validationLogs ? (
              <BulkUploaderLogsCollapseComponent
                validationLogs={validationLogs}
                onCreateNewModel={onCreateNewModel}
                ExecuteRelistSlug={ExecuteRelistSlug}
                showConfirm={showConfirm}
              />
            ) : null}
          </div>
          <div
            style={{
              textAlign: isMobile ? "center" : "left",
              marginBottom: "2%",
            }}
          >
            <Button
              type="link"
              onClick={handleSyncingModalVisibility}
              icon={<QuestionCircleOutlined />}
              size="middle"
            >
              Check Logs
            </Button>
          </div>
        </>
      ) : null}
    </>
  );
};
