import { useUpdate } from "../../hooks/useUpdate";

export const UpdateBulkUploaderData = async (data) => {
  try {
    return await useUpdate(
      `${process.env.REACT_APP_API_URL}/bulk-uploader/update`,
      data
    );
  } catch (err) {
    console.log("ERROR : ", err.stack);
    return null;
  }
};
