import React from "react";
import { Space, Form, Button, Modal } from "antd";
import { AuctionSearchInputsComponent } from "../auctions/AuctionSearchInputsComponent";
import { SearchOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import useIsMobile from "../../../hooks/useIsMobile";

export const BuyNowSearchFunctionalityComponent = (props) => {
  const {
    advisers = [],
    banks = [],
    onSubmitSearch,
    form,
    visible,
    onCancel,
  } = props;

  const isMobile = useIsMobile();

  return (
    <Modal
      visible={visible}
      title="Filter Buy Now"
      okText="Search Buy Now"
      okButtonProps={{
        icon: <SearchOutlined />,
        block: isMobile ? true : false,
      }}
      cancelButtonProps={{
        icon: <ArrowLeftOutlined />,
        block: isMobile ? true : false,
      }}
      onOk={() => onSubmitSearch(1, 10)}
      onCancel={onCancel}
    >
      <Form form={form} layout={"vertical"}>
        <AuctionSearchInputsComponent advisers={advisers} banks={banks} />
      </Form>
    </Modal>
    // <Space size="small" direction={isMobile ? "vertical" : "horizontal"}>
    //   <Form form={form} layout={isMobile ? "vertical" : "inline"}>
    //     <AuctionSearchInputsComponent advisers={advisers} banks={banks} />
    //   </Form>
    //   <Button
    //     type="primary"
    //     icon={<SearchOutlined />}
    //     onClick={() => onSubmitSearch(1, 10)}
    //     block={isMobile ? true : false}
    //   >
    //     Search
    //   </Button>
    // </Space>
  );
};
