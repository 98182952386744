import React from "react";
import { Form, Modal, Input, InputNumber, Select, Radio } from "antd";
import { SaveOutlined, ArrowLeftOutlined } from "@ant-design/icons";

const { TextArea } = Input;
const { Option } = Select;
export const UserReportsModal = (props) => {

    const {
        title,
        form,
        onOk,
        onCancel,
        destroyOnClose,
        okText,
        cancelText,
        visible,
        referrerData
    } = props;

    return (
        <>
            <Modal
                title={title}
                onOk={onOk}
                onCancel={onCancel}
                destroyOnClose={destroyOnClose}
                visible={visible}
                okText={okText}
                cancelText={cancelText}
                okButtonProps={{
                    icon: <SaveOutlined />,
                }}
                cancelButtonProps={{
                    icon: <ArrowLeftOutlined />,
                }}
            >
                <Form form={form} layout="vertical">

                    <Form.Item
                        name="referrer"
                        label="Select Referrer(s)"
                        initialValue={[]}
                    >
                        <Select
                            mode="multiple"
                            placeholder="Please select referrers"
                            virtual={true}
                        >
                            {referrerData.map((item, index) => (
                                <Option
                                    key={index}
                                    value={item.referrer}
                                >{item.referrer}</Option>
                            ))}
                        </Select>
                    </Form.Item>

                </Form>
            </Modal>
        </>
    );
};
