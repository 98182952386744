import React from "react";
import { Table } from "antd";

export const ModulesTableComponent = (props) => {
  const {
    data = { data: [], total: 0 },
    onPaginationChange,
    onSelection,
    selectedRowKey,
    tableColumns = [],
    size = "small",
    selectionType = "radio",
    currentPagination,
    onSortChange,
    scrollSize = 1500,
    hasSelection = true,
    expandable = null,
    tableLoading = false,
  } = props;

  return (
    <Table
      loading={tableLoading}
      columns={tableColumns}
      dataSource={data.data}
      size={size}
      scroll={{ x: scrollSize }}
      onChange={(pagination, filters, sorter, action) => {
        if (action.action === "sort") {
          onSortChange(sorter.field, sorter.order);
        }
      }}
      rowSelection={
        hasSelection
          ? {
              type: selectionType,
              selectedRowKeys:
                selectionType === "radio" ? [selectedRowKey] : selectedRowKey,
              onChange: onSelection,
            }
          : null
      }
      expandable={expandable}
      pagination={{
        total: data.total,
        onChange: onPaginationChange,
        current: currentPagination,
      }}
      bordered
    >
      {/* {tableColumns.map((item, index) => (
        <Column key={index} title={item.title} dataIndex={item.dataIndex} />
      ))} */}
    </Table>
  );
};
