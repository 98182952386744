import React from "react";
import { Col, Form, Input, Row } from "antd";
import { FormRules } from "../../../dist/functions/formRules";

export const SpecialistContactInformationFormComponent = (props) => {
  return (
    <>
      <Form.Item
        label="Email Address"
        name="email"
        fieldKey="email"
        rules={[FormRules.EMAIL_FORMAT, FormRules.REQUIRED_EMAIL]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Contact Number (Globe)"
        name="mobile_globe"
        fieldKey="mobile_globe"
        rules={[
          FormRules.CONTACT_CHARACTER_COUNT,
          FormRules.NUMBER_FORMAT_ONLY,
        ]}
      >
        <Input addonBefore={"+639"} style={{ width: "100%" }} maxLength={9} />
      </Form.Item>
      <Form.Item
        label="Contact Number (Smart)"
        name="mobile_smart"
        fieldKey="mobile_smart"
        rules={[
          FormRules.CONTACT_CHARACTER_COUNT,
          FormRules.NUMBER_FORMAT_ONLY,
        ]}
      >
        <Input addonBefore={"+639"} style={{ width: "100%" }} maxLength={9} />
      </Form.Item>

      <Row>
        <Col span={6}>
          <Form.Item name="landlinePrefix" label="Landline">
            <Input addonBefore={"+("} addonAfter={")"} style={{ width: "100%" }} maxLength={4} />
          </Form.Item>
        </Col>
        <Col span={18}>
          <Form.Item
            label="Landline"
            name="landline"
            fieldKey="landline"
            rules={[
              FormRules.LANDLINE_CHARACTER_COUNT,
              FormRules.NUMBER_FORMAT_ONLY,
              FormRules.REQUIRED_CONTACT,
            ]}
          >
            <Input style={{ width: "100%" }} maxLength={8} />
          </Form.Item>
        </Col>
      </Row>

    </>
  );
};
