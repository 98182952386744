import { useUpdate } from "../../hooks/useUpdate";

export const UpdateMake = async (id, data) => {
  try {
    return await useUpdate(
      `${process.env.REACT_APP_API_URL}/makes/${id}`,
      data
    );
  } catch (err) {
    console.log("ERROR: ", err.stack);
    return null;
  }
};
