import { useCreate } from "../../hooks/useCreate";

export const CreateVehicleType = async (payload) => {
  try {
    return await useCreate(
      `${process.env.REACT_APP_API_URL}/vehicle-types`,
      payload
    );
  } catch (err) {
    if (err.response) return err.response;
    if (err.request) return err.request;
    return err.message;
  }
};
