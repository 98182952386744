import React, { useEffect, useRef, useState } from "react";
import { PageHeader, Divider, Form, message } from "antd";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { OptionsTableComponent } from "../sub_components/options/OptionsTableComponent";
import { OptionsFunctionalityComponent } from "../sub_components/options/OptionsFunctionalityComponent";
import { OptionCreationModalComponent } from "../sub_components/options/OptionCreationModalComponent";
import { OptionUpdateModalComponent } from "../sub_components/options/OptionUpdateModalComponent";
import { OptionsFilterAndSelectionComponent } from "../sub_components/options/OptionsFilterAndSelectionComponent";
import { GetAllOptions } from "../../api/options/GetAllOptions";
import { CreateOption } from "../../api/options/CreateOption";
import { UpdateOption } from "../../api/options/UpdateOption";
import { useComponentDidUpdate } from "../../hooks/useComponentDidUpdate";
import { ModulesSelectAndColumnTagsComponent } from "../ModulesSelectAndColumnTagsComponent";

const initialRolesState = {
  CREATE_SETTINGS: 0,
  UPDATE_SETTINGS: 0,
};
export const OptionsComponent = () => {
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [data, setData] = useState({ data: [], total: 0 });
  const [showOptionModalCreation, setShowOptionModalCreation] = useState(false);
  const [showOptionModalUpdate, setShowOptionModalUpdate] = useState(false);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [rolesRestriction, setRolesRestrictions] = useState(initialRolesState);
  const [currentPagination, setCurrentPagination] = useState(1);

  const [isShowColumnFilter, setIsShowColumnFilter] = useState(false);
  const [columnFilterTags, setColumnFilterTags] = useState([]);

  const [currentCount, setCurrentCount] = useState(10);

  const [columnFilter, setColumnFilter] = useState([]);
  const sortColumn = useRef(null);
  const sortType = useRef("asc");

  const setSortColumn = (value) => {
    sortColumn.current = value;
  }

  const setSortType = (value) => {
    if (value === "descend") {
      sortType.current = "desc";
    } else if (value === "ascend") {
      sortType.current = "asc";
    } else {
      sortType.current = "none";
    }
  }

  const onSortChange = async (sortCol, sortType) => {
    setSortColumn(sortCol);
    setSortType(sortType);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    await FetchAllOptions(currentPagination, currentCount);

  };

  const onShowModal = () => {
    setShowOptionModalCreation(!showOptionModalCreation);
  };

  const onShowUpdateModal = () => {
    setShowOptionModalUpdate(!showOptionModalUpdate);
  };

  const OnFinish = async () => {
    const values = await form.validateFields();
    console.log(values, "values");
    const payload = { data: values };
    await message.loading("Creating Option...", 2);
    const response = await CreateOption(payload);
    if (response.status === 201) {
      message.success("Successfuly Created Option", 2);
      onShowModal();
      FetchAllOptions();
    }
  };

  const OnFinishUpdate = async () => {
    const values = await updateForm.validateFields();
    const data = { data: values };
    await message.loading("Updating Option...", 2);
    const response = await UpdateOption(data, selectedRowKey);
    if (response.status === 200) {
      message.success("Successfuly Updated Option", 2);
      onShowUpdateModal();
      FetchAllOptions();
      onClearSearcheAndSelection();
    }
  };

  const onPaginateChange = (page, limit) => {
    setCurrentPagination(page);
    setCurrentCount(limit);
    FetchAllOptions(page, limit);
  };

  const FetchAllOptions = async (page = 1, limit = 10) => {
    setCurrentPagination(page)
    const data = await GetAllOptions({
      page: page,
      limit: limit,
      sortColumn: sortColumn.current,
      sortType: sortType.current,
      columnFilter: columnFilter
    });
    setData(data);
  };

  ///////////////////////////////
  // for column filter tags
  const onCloseColumnFilterTag = (id) => {
    setColumnFilterTags((prevState) => {
      return prevState.filter(
        (item) => item.id !== id
      );
    });

    setColumnFilter((prevState) => {
      let newData = prevState.filter(
        (item) => {

          return Array.isArray(item.id) ? JSON.stringify(item.id) != id : item.id != id;
        }
      );

      return newData;
    });

    if (!columnFilterTags.length) {
      setIsShowColumnFilter(false);
    }
  };

  const onLoadColumnFilterTags = () => {

    if (columnFilter.length) {
      let filterTags = columnFilter.map((e) => {
        let [label, tagId] = Array(2).fill(e.id);

        if (Array.isArray(e.id)) {
          tagId = JSON.stringify(tagId);
          label = "";

          for (let i = 0, n = e.id.length; i < n; i++) {
            label += `${e.id[i]} `;
          }
        }

        return {
          color: "processing",
          closable: true,
          selection_label: label,
          value: e.value,
          id: tagId,
          onClose: onCloseColumnFilterTag
        }
      })

      setColumnFilterTags(filterTags);
      setIsShowColumnFilter(true);
    } else {
      // if columnFilter is just blank, then remove the tags
      setColumnFilterTags([]);
      setIsShowColumnFilter(false);
    }
  };

  const onClearColumnFilters = () => {
    setColumnFilterTags([]);
    setIsShowColumnFilter(false);
    setColumnFilter([]);

  };

  useEffect(() => {
    FetchAllOptions();
  }, []);

  useComponentDidUpdate(() => {
    FetchAllOptions(1, currentCount);
    onLoadColumnFilterTags();
  }, [columnFilter]);

  const onRowSelectionChange = (selectedRowKeys, selectedRows) => {
    setSelectedRow(selectedRows[0]);
    setSelectedRowKey(selectedRowKeys[0]);
  };

  useEffect(() => {
    if (selectedRow) {
      updateForm.setFieldsValue({
        option_value: selectedRow.option_value,
      });
    }
  }, [selectedRow]);

  const onClearSearcheAndSelection = () => {
    setSelectedRowKey(null);
    setSelectedRow(null);
  };

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const rolesRestriction = {};

    const CREATE_SETTINGS = permissions.find(
      (item) => item.name === "CREATE_SETTINGS"
    );

    rolesRestriction["CREATE_SETTINGS"] =
      typeof CREATE_SETTINGS === "undefined" ? 0 : CREATE_SETTINGS.has_access;

    const UPDATE_SETTINGS = permissions.find(
      (item) => item.name === "UPDATE_SETTINGS"
    );

    rolesRestriction["UPDATE_SETTINGS"] =
      typeof UPDATE_SETTINGS === "undefined" ? 0 : UPDATE_SETTINGS.has_access;

    setRolesRestrictions(rolesRestriction);
  }, []);

  return (
    <>
      <PageHeader
        title="SETTINGS"
        subTitle="SITE SETTINGS/CONFIGS MANAGEMENT"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["Settings", "Options"]} />
        )}
      />

      <div className="content-pane-transaction-div-style">
        <Divider />
        <OptionsFunctionalityComponent
          onShowModal={onShowModal}
          onShowUpdateModal={onShowUpdateModal}
          selectedRow={selectedRow}
          rolesRestriction={rolesRestriction}
        />

        <ModulesSelectAndColumnTagsComponent
          onClearColumnFilters={onClearColumnFilters}
          isShowColumnFilterTags={isShowColumnFilter}
          columnFilterTags={columnFilterTags}
          isShowSelection={!!selectedRow}
          onClearSearcheAndSelection={onClearSearcheAndSelection}
          tags={[
            {
              color: "processing",
              selection_label: "Selected Option",
              value: selectedRow
                ? `${selectedRow.option_key}`
                : null,
            },
          ]}
        />
        <OptionsTableComponent
          data={data}
          onPaginateChange={onPaginateChange}
          onRowSelectionChange={onRowSelectionChange}
          selectedRowKeys={selectedRowKey}
          currentPagination={currentPagination}
          columnFilter={columnFilter}
          setColumnFilter={setColumnFilter}
          onSortChange={onSortChange}
        />
      </div>
      <OptionCreationModalComponent
        form={form}
        showModal={showOptionModalCreation}
        OnFinish={OnFinish}
        onShowModal={onShowModal}
      />
      <OptionUpdateModalComponent
        showModal={showOptionModalUpdate}
        OnFinish={OnFinishUpdate}
        onCancel={onShowUpdateModal}
        form={updateForm}
        selectedRow={selectedRow}
      />
    </>
  );
};
