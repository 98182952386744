import React from "react";
import { Space, Button, Divider, Input, Modal } from "antd";
import {
  ExclamationCircleOutlined,
  FormOutlined,
  EditOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import { WarehouseReportsComponent } from "./WarehouseReportsComponent";
import useIsMobile from "../../../hooks/useIsMobile";

const { Search } = Input;
const { confirm } = Modal;
export const WarehouseFunctionComponent = (props) => {
  const {
    selectedWarehouse,
    userPermissions,
    setShowWarehouseCreationModal,
    handleUpdateModalVisibility,
    handleUpdateAdviserModalVisibility,
    reportsData,
    onSearch,
    OnUpdateStatus,
  } = props;

  const isMobile = useIsMobile();

  const showConfirm = async () => {
    confirm({
      centered: true,
      title: "Archive Warehouse",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to archive this warehouse?",
      onOk() {
        OnUpdateStatus();
      },
      onCancel() {
        return null;
      },
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "center",
        flexWrap: "wrap",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        marginBottom: "2%",
      }}
    >
      <Space direction={isMobile ? "vertical" : "horizontal"}>
        <Search placeholder="Search Location" onSearch={onSearch} enterButton />
        <Button
          hidden={userPermissions.CREATE_WAREHOUSE === 1 ? false : true}
          type="primary"
          onClick={() =>
            setShowWarehouseCreationModal((prevState) => {
              return !prevState;
            })
          }
          icon={<FormOutlined />}
          block={isMobile ? true : false}
        >
          Create Warehouse
        </Button>
        <div
          style={{
            display: userPermissions.GENERATE_REPORT === 1 ? null : "none",
          }}
        >
          <WarehouseReportsComponent dataSet={reportsData.data} />
        </div>
        {selectedWarehouse !== null ? (
          <>
            <Divider type={isMobile ? "horizontal" : "vertical"} />
            <Button
              hidden={userPermissions.UPDATE_WAREHOUSE === 1 ? false : true}
              type="default"
              onClick={handleUpdateModalVisibility}
              icon={<FormOutlined />}
              block={isMobile ? true : false}
            >
              Update Warehouse
            </Button>
            <Button
              hidden={userPermissions.UPDATE_WAREHOUSE === 1 ? false : true}
              type="default"
              onClick={showConfirm}
              icon={<RedoOutlined />}
              block={isMobile ? true : false}
              danger
            >
              Archive Warehouse
            </Button>
          </>
        ) : null}
      </Space>
    </div>
  );
};
