import React from "react";
import { Form, Input, InputNumber, Select } from "antd";

const { Option } = Select;
export const AdsCreationGeneralInformationFormComponent = (props) => {
  const { Cars = [] } = props;
  return (
    <>
      <Form.Item name="car_id" label="Car">
        <Select>
          {Cars.map((item) => (
            <Option value={item.id}>{item.plate_number}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="title" label="Title">
        <Input />
      </Form.Item>
      <Form.Item name="slug" label="Slug">
        <Input />
      </Form.Item>
      <Form.Item name="description" label="Description">
        <Input.TextArea />
      </Form.Item>
      <Form.Item name="price_order" label="Price Order">
        <InputNumber style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name="price" label="Price">
        <InputNumber style={{ width: "100%" }} />
      </Form.Item>
    </>
  );
};
