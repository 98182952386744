import { useEffect, useState } from "react";

export default function useGetPlatform() {
  const [currentPlatform, setCurrentPlatform] = useState("automart");

  useEffect(() => {
    var ads_type =
      process.env.REACT_APP_PLATFORM === "automart" ? "car" : "motor";
    setCurrentPlatform(ads_type);
  }, []);

  return currentPlatform;
}
