import React from "react";
import { Button } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

export const ModulesFunctionalityComponent = (props) => {
  const { showaddModulesModal, rolesRestriction = {} } = props;
  const { ADD_NEW_MODULES = 0 } = rolesRestriction;

  return (
    <>
      <div style={{ width: "100%", textAlign: "right", marginBottom: "2%" }}>
        <Button
          hidden={ADD_NEW_MODULES === 0 ? true : false}
          type="primary"
          onClick={showaddModulesModal}
          icon={<PlusCircleOutlined />}
        >
          Add New Module(s)
        </Button>
      </div>
    </>
  );
};
