// can't use custom hooks inside the loop, so we use axios directly instead
// import {useUpdate} from "../../hooks/useUpdate";
import { message } from "antd";
import axios from "axios";

export const ImageBatchUpload = (payload, setProgressInfo) => {
  const setProgressValue = (index, obj) => {
    setProgressInfo((prevState) => {
      const newState = prevState.map((x, i) => {
        return { ...x };
      });
      newState[index] = { ...newState[index], ...obj };
      return newState;
    });
  };

  const length = payload.length;
  //this is a recursive function for processing each data
  const sendFormData = async (index) => {
    try {
      setProgressValue(index, { percent: 20, status: "processing" });

      //TODO: catch network error

      const data = await axios.put(
        `${process.env.REACT_APP_API_URL}/ads/medias`,
        payload[index],
        { headers: { Authorization: localStorage.getItem("token") } }
      );

      if (data.status == 200) {
        setProgressValue(index, {
          percent: 100,
          status: "success",
          done: true,
        });
      } else {
        message.error(`Entry ${index} failed to update`, 3);
        setProgressValue(index, {
          status: "failed",
          done: true,
        });
      }
      //re-call this function
      if (index < length - 1) sendFormData(index + 1);
    } catch (err) {
      message.error(`Failed to update, please try again`, 3);
      console.log("ERROR: ", err.stack);
      setProgressValue(index, {
        status: "failed",
        done: true,
      });
      //re-call this function
      if (index < length - 1) sendFormData(index + 1);
    }
  };
  payload.length > 0 && sendFormData(0);
};
