import React from "react";
import { SaveOutlined } from "@ant-design/icons";
import { ModulesFunctionalitiesComponent } from "../../ModulesFunctionalitiesComponent";
import useIsMobile from "../../../hooks/useIsMobile";

export const AuctionCloserFunctionalityComponent = (props) => {
  const { onClickSubmit, ads = { data: [] }, rolesRestriction } = props;

  const isMobile = useIsMobile();
  return (
    <ModulesFunctionalitiesComponent
      functionalitiesComponent={[
        {
          type: "primary",
          component_type: "button",
          _onClick: onClickSubmit,
          icon: <SaveOutlined />,
          label: "Close Auction(s)",
          size: "default",
          is_hidden:
            rolesRestriction.CLOSE_AUCTIONS_BY_FILE === 1
              ? ads.data.length > 0
                ? false
                : true
              : true,
        },
      ]}
      alignment={isMobile ? "center" : "right"}
      spaceSize={"small"}
    />
  );
};
