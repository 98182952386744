import React from "react";
import { Divider, PageHeader, Tabs } from "antd";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { CMSPostContentsListComponent } from "../sub_components/cms_post_contents/CMSPostContentsListComponent";

const { TabPane } = Tabs;
export const CMSPostContentManagementComponent = () => {
  return (
    <>
      <PageHeader
        title="POST SUB CONTENTS"
        subTitle="CMS Post Contents"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["POST SUB CONTENTS"]} />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <CMSPostContentsListComponent />
      </div>
    </>
  );
};
