import React from "react";
import { Form, Select, DatePicker, Input, Radio, Checkbox } from "antd";

const { Option } = Select;
const { RangePicker } = DatePicker;
export const AuctionSearchInputsComponent = (props) => {
  const {
    advisers = { data: [], total: 0 },
    banks = { data: [], total: 0 },
    is_bidding_module = false,
  } = props;

  const dateFormat = "YYYY-MM-DD hh:mm:ss";

  return (
    <>
      <Form.Item name="adviser_id" label="Adviser/Specialist">
        <Select placeholder="Select Adviser">
          {advisers.data.map((item, index) => (
            <Option key={index} value={item.id}>
              {`${item.first_name} ${item.last_name}`}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="bank_id" label={"Bank"}>
        <Select placeholder="Select Bank">
          {banks.data.map((item, index) => (
            <Option key={index} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="report_date_range" label={"Bid Date Range"}>
        <RangePicker format={dateFormat} style={{ width: "100%" }} />
      </Form.Item>
      {is_bidding_module ? (
        <Form.Item
          name="isIncludePreviousBids"
          label="Include Previous Bids?"
          valuePropName="checked"
          initialValue={true}
        >
          <Checkbox />
        </Form.Item>
      ) : null}
      <Form.Item initialValue={"plate_number"} name="type" label="Type">
        <Radio.Group defaultValue="plate_number">
          <Radio value="winner">Winner</Radio>
          <Radio value="plate_number">Plate Number</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="keyword">
        <Input placeholder="Input Keyword" allowClear />
      </Form.Item>
    </>
  );
};
