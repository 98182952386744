import React from "react";
import { Modal, Form, Input } from "antd";

export const OptionCreationModalComponent = (props) => {
  const { showModal = false, OnFinish, form, onShowModal } = props;
  return (
    <>
      <Modal
        title="Create Option"
        visible={showModal}
        onOk={OnFinish}
        destroyOnClose={true}
        onCancel={onShowModal}
      >
        <Form form={form} name="options" layout="vertical">
          <Form.Item name="option_key" label="Key" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="option_value"
            label="value"
            rules={[{ required: true }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          {"For the list type of option value, please seperate data by ','"}
        </Form>
      </Modal>
    </>
  );
};
