import React, { useState } from "react";
import {
  Menu,
  Layout,
  Space,
  Avatar,
  Dropdown,
  Modal,
  Image,
  Divider,
} from "antd";
import { Link, useHistory, Route } from "react-router-dom";
import { useDelete } from "../hooks/useDelete";
import { ProfileDrawerComponent } from "./ProfileDrawerComponent";
import { logout } from "../dist/functions/auth";
import useFetchPermissionSets from "../hooks/useFetchPermissions";
import useFetchIfhasMenuAccess from "../hooks/useFetchIfhasMenuAccess";
import {
  permissionsList,
  createDefaultPermissionSet,
} from "../dist/statics/permission_directory";
import {
  SettingOutlined,
  HomeOutlined,
  BankOutlined,
  BuildOutlined,
  LogoutOutlined,
  ProfileOutlined,
  ExclamationCircleOutlined,
  UserOutlined,
  CloudUploadOutlined,
  PieChartOutlined,
  DollarCircleOutlined,
  PushpinOutlined,
  SettingFilled,
  FileDoneOutlined,
} from "@ant-design/icons";
import "../MenuComponent.css";
import logo from "../dist/images/automart_logo_square_transparent_256.png";

const { Header, Footer, Sider, Content } = Layout;
const { SubMenu } = Menu;
const { confirm } = Modal;

const initialRolesRestriction = createDefaultPermissionSet();

export const MenuComponent = (props) => {
  const { permissions, dashboardRoutes = [] } = props;
  const [, setCurrentMenu] = useState("1");
  const [collapsed, setCollapsedMode] = useState(false);
  const [showDrawer, setDrawerStatus] = useState(false);
  const history = useHistory();
  const rolesRestriction = useFetchPermissionSets(
    permissionsList,
    initialRolesRestriction
  );

  const {
    CLOSE_AUCTIONS_BY_BANK,
    CLOSE_AUCTIONS_BY_FILE,
    REVIEW_GROUP_CREATE,
    REVIEW_GROUP_UPDATE,
    REVIEW_GROUP_DELETE,
    REVIEW_GROUP_RESTORE,
    REVIEW_INDEX_CREATE,
    REVIEW_INDEX_UPDATE,
    REVIEW_INDEX_DELETE,
    REVIEW_INDEX_RESTORE,
    CREATE_REMARKS_SUB_GROUP,
    UPDATE_REMARKS_SUB_GROUP,
    DELETE_REMARKS_SUB_GROUP,
    RESTORE_REMARKS_SUB_GROUP,
    CREATE_REMARKS_INDEX,
    UPDATE_REMARKS_INDEX,
    DELETE_REMARKS_INDEX,
    RESTORE_REMARKS_INDEX,
  } = rolesRestriction;

  const reviewGroupPermissionSet = [
    REVIEW_GROUP_CREATE,
    REVIEW_GROUP_UPDATE,
    REVIEW_GROUP_DELETE,
    REVIEW_GROUP_RESTORE,
  ];

  const reviewGroupHasAccess = useFetchIfhasMenuAccess(
    reviewGroupPermissionSet
  );

  const reviewIndexPermissionSet = [
    REVIEW_INDEX_CREATE,
    REVIEW_INDEX_UPDATE,
    REVIEW_INDEX_DELETE,
    REVIEW_INDEX_RESTORE,
  ];

  const reviewIndexHasAccess = useFetchIfhasMenuAccess(
    reviewIndexPermissionSet
  );

  const remarksSubGroupPermissionSet = [
    CREATE_REMARKS_SUB_GROUP,
    UPDATE_REMARKS_SUB_GROUP,
    DELETE_REMARKS_SUB_GROUP,
    RESTORE_REMARKS_SUB_GROUP,
  ];

  const remarksSubGroupHasAccess = useFetchIfhasMenuAccess(
    remarksSubGroupPermissionSet
  );

  const remarksIndexPermissionSet = [
    CREATE_REMARKS_INDEX,
    UPDATE_REMARKS_INDEX,
    DELETE_REMARKS_INDEX,
    RESTORE_REMARKS_INDEX,
  ];

  const remarksIndexHasAccess = useFetchIfhasMenuAccess(
    remarksIndexPermissionSet
  );

  const handleClick = (e) => {
    setCurrentMenu(e.key);
  };

  const toggle = () => {
    setCollapsedMode(!collapsed);
  };

  const OnConfirm = async () => {
    confirm({
      title: "Do you Want to Logout?",
      icon: <ExclamationCircleOutlined />,
      content: "all unfinished tasks, will be discarded after signing out",
      onOk() {
        Logout();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const Logout = async () => {
    await useDelete(
      `${
        process.env.REACT_APP_API_URL
      }/authentications/tokens/${localStorage.getItem("token")}`
    );
    localStorage.clear();
    logout();
    history.push("/login");
    window.location.reload();
  };

  const showProfile = () => {
    setDrawerStatus(!showDrawer);
  };

  function handleMenuClick(e) {}

  return (
    <>
      <Layout>
        <Sider
          breakpoint="lg"
          collapsible
          collapsed={collapsed}
          onCollapse={toggle}
          collapsedWidth={0}
          style={{
            left: 0,
          }}
        >
          <div className="automart-logo">
            <Image width={"50%"} src={logo} preview={false} />
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={[]}
            onClick={handleClick}
          >
            <Menu.Item key="0" icon={<PieChartOutlined />}>
              <Link to="/dashboard">Dashboard</Link>
            </Menu.Item>
            <SubMenu key="sub1" icon={<UserOutlined />} title="Administration">
              <Menu.Item key="1">
                <Link to="/administration/platform">Platform</Link>
              </Menu.Item>
              <Menu.Item key="2">
                <Link to="/administration/modules">Modules</Link>
              </Menu.Item>
              <Menu.Item key="3">
                <Link to="/administration/permission-index">
                  Permission Index
                </Link>
              </Menu.Item>
              <Menu.Item key="4">
                <Link to="/administration/roles-access">Roles Access</Link>
              </Menu.Item>
              <Menu.Item key="5">
                <Link to="/administration/user-management">
                  User Management
                </Link>
              </Menu.Item>
              <Menu.Item key="advr-12">
                <Link to="/administration/adviser-management">Advisers</Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="6" icon={<HomeOutlined />}>
              <Link to="/warehouses">Warehouse</Link>
            </Menu.Item>
            <Menu.Item key="7" icon={<BankOutlined />}>
              <Link to="/providers">Providers</Link>
            </Menu.Item>
            <SubMenu key="sub2" title="CMS" icon={<BuildOutlined />}>
              <Menu.Item key="8">
                <Link to="/cms/pages">Pages</Link>
              </Menu.Item>
              <Menu.Item key="9">
                <Link to="/cms/posts">Posts</Link>
              </Menu.Item>
              <Menu.Item key="9.1">
                <Link to="/cms/assets">Manage Assets</Link>
              </Menu.Item>
              <Menu.Item key="9.2">
                <Link to="/cms/post/sub-contents">Post Contents</Link>
              </Menu.Item>
              <SubMenu key="faq-sub-menu" title="FAQ's">
                <Menu.Item key="9.3.1">
                  <Link to="/faq/group">FAQ Group</Link>
                </Menu.Item>
                <Menu.Item key="9.3.2">
                  <Link to="/faq/index">FAQ Index</Link>
                </Menu.Item>
              </SubMenu>
              {remarksSubGroupHasAccess && remarksIndexHasAccess ? (
                <SubMenu key="remarks-sub-menu" title="Pros & Cons">
                  <Menu.Item key="9.4.1" hidden={!remarksIndexHasAccess}>
                    <Link to="/remarks/index">Pros & Cons Index</Link>
                  </Menu.Item>
                  <Menu.Item key="9.4.2" hidden={!remarksSubGroupHasAccess}>
                    <Link to="/remarks/group">Pros & Cons Group</Link>
                  </Menu.Item>
                </SubMenu>
              ) : null}
            </SubMenu>
            {reviewIndexHasAccess && reviewGroupHasAccess ? (
              <SubMenu
                key="reviews-sub-menu"
                title="Reviews"
                icon={<BuildOutlined />}
              >
                <Menu.Item
                  key="7.0.0.1"
                  hidden={!reviewIndexHasAccess}
                  icon={<BuildOutlined />}
                >
                  <Link to="/reviews">Reviews Index</Link>
                </Menu.Item>
                <Menu.Item
                  key="7.0.0.2"
                  hidden={!reviewGroupHasAccess}
                  icon={<BuildOutlined />}
                >
                  <Link to="/reviews-group">Reviews Group</Link>
                </Menu.Item>
              </SubMenu>
            ) : null}
            <SubMenu
              key="sub4"
              title="Auctions"
              icon={<DollarCircleOutlined />}
            >
              <Menu.Item key="12">
                <Link to="/auctions/biddings-winner">Bidding Winners</Link>
              </Menu.Item>
              <Menu.Item key="13">
                <Link to="/auctions/buynow-winner">Buy Now Winners</Link>
              </Menu.Item>
              <Menu.Item
                key="13.5"
                hidden={
                  CLOSE_AUCTIONS_BY_FILE === 0 && CLOSE_AUCTIONS_BY_BANK === 0
                    ? true
                    : false
                }
              >
                <Link to="/auctions/closer">Auction Closer</Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item
              key="14"
              icon={<CloudUploadOutlined />}
              disabled={
                rolesRestriction.FULL_ACCESS_BULK_UPLOADER === 0 ? false : false
              }
            >
              <Link to="/bulk-uploader">Bulk Uploader</Link>
            </Menu.Item>
            {/* Ads Editor */}
            <Menu.Item
              key="15"
              icon={<FileDoneOutlined />}
              disabled={
                rolesRestriction.FULL_ACCESS_BULK_UPLOADER === 0 ? false : false
              }
            >
              <Link to="/ads-editor">Ads</Link>
            </Menu.Item>
            <Menu.Item
              key="16"
              icon={<SettingOutlined />}
              disabled={
                rolesRestriction.FULL_ACCESS_SETTINGS === 0 ? true : false
              }
            >
              <Link to="/settings">Settings</Link>
            </Menu.Item>

            <SubMenu key="sub5" title="Item Settings" icon={<SettingFilled />}>
              <Menu.Item key="17">
                <Link to="/item/makes">Makes</Link>
              </Menu.Item>
              <Menu.Item key="18">
                <Link to="/item/models">Models</Link>
              </Menu.Item>
              <Menu.Item key="19">
                <Link to="/item/vehicle-types">Vehicle Types</Link>
              </Menu.Item>
              <Menu.Item key="20">
                <Link to="/item/colors">Colors</Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="22" icon={<BuildOutlined />}>
              <Link to="/activity">Activity Logs</Link>
            </Menu.Item>
            <Menu.Item key="23" icon={<BuildOutlined />}>
              <Link to="/xml">XML</Link>
            </Menu.Item>
            <Menu.Item key="24" icon={<BuildOutlined />}>
              <Link to="/active-sessions">Sessions</Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <Header className="site-layout-background menu-header-custom-style">
            <Space size={0} align="center">
              {`${localStorage.getItem("name")} / ${localStorage.getItem(
                "role_name"
              )}`}
              <Dropdown.Button
                size="small"
                type="text"
                icon={
                  <Avatar
                    style={{ backgroundColor: "#001529" }}
                    icon={<UserOutlined />}
                  />
                }
                overlay={
                  <Menu onClick={handleMenuClick}>
                    <Menu.Item
                      key="1"
                      icon={<ProfileOutlined />}
                      onClick={showProfile}
                    >
                      View Profile
                    </Menu.Item>
                    <Menu.Item
                      key="2"
                      icon={<LogoutOutlined />}
                      onClick={OnConfirm}
                    >
                      Logout
                    </Menu.Item>
                  </Menu>
                }
              />
            </Space>
          </Header>
          <Content className="content-layout">
            <div className="site-layout-background content-style-padding-div">
              <Route exact path={dashboardRoutes.path}>
                <dashboardRoutes.component permissions={permissions} />
              </Route>
            </div>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            Admin 3.0 ©{new Date().getFullYear()} Created by QMarketz Corp
            <div>
              <a href="https://automart.ph">Automart.PH</a>
            </div>
          </Footer>
        </Layout>
      </Layout>
      <ProfileDrawerComponent visible={showDrawer} onClose={showProfile} />
    </>
  );
};
