import { useFetch } from "../../hooks/useFetch";

export const FetchPermissionsByRolesId = async (role_ids) => {
  try {
    const data = await useFetch(
      `${process.env.REACT_APP_API_URL}/roles/search`,
      role_ids
    );
    return data;
  } catch (err) {
    return null;
  }
};
