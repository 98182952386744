import React from "react";
import { Table } from "antd";
import useColumnFilter from "../../../hooks/useColumnFilter";

const columns = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Platform Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Date Created",
    dataIndex: "created_at",
    key: "created_at",
  },
];

export const PlatformWithRolesAndModulesTableComponent = (props) => {
  const {
    data = { data: [], total: 0 },
    tableLoading,
    currentPagination,
    onPaginationChange,
    onSortChange,
    columnFilter,
    setColumnFilter
  } = props;

  const { getColumnFilterProps } = useColumnFilter(columnFilter, setColumnFilter);

  let newCols = [];

  for (let i = 0, n = columns.length; i < n; i++) {
    newCols.push({ ...columns[i], sorter: true, ...getColumnFilterProps(columns[i].dataIndex) })
  }

  return (
    <div style={{ marginTop: "2%" }}>
      <Table
        loading={tableLoading}
        columns={newCols}
        dataSource={data.data}
        pagination={{
          total: data.total,
          current: currentPagination,
          onChange: onPaginationChange,
        }}
        onChange={(pagination, filters, sorter, action) => {
          if (action.action == "sort") {
            onSortChange(sorter.field, sorter.order);
          }

        }}
        scroll={{ y: 240 }}
        size="small"
        bordered={true}
      />
    </div>
  );
};
