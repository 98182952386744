import React from "react";
import { Form, Button, Input, Select, List, Divider, Collapse } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { FeatureImagesModalComponent } from "./FeatureImagesModalComponent";

const { TextArea } = Input;
const { Option } = Select;
const { Panel } = Collapse;
export const PostCreateionMetaDataFormComponent = (props) => {
  const {
    handlePluginsModalVisible,
    setMediaIds,
    imageUploadsModal,
    onClickUploadButton,
    mediaForm,
    imageSet,
    faqs = [],
    selectedFAQs = [],
    onChangeFAQSelection,
    reviewGroups = [],
    selectedReviews = [],
    onChangeReviewSelection,
    remarksGroup = [],
    onChangeDisadvantageRemarksGroup,
    onChangeAdvantageRemarksGroup,
    selectedAdvantageRemarks = [],
    selectedDisadvantageRemarks = [],
  } = props;

  return (
    <>
      <Form.Item
        name="meta_title"
        label="Meta Title"
        hidden={true}
        initialValue={null}
      >
        <Input placeholder="Input Title" />
      </Form.Item>
      <Form.Item
        name="meta_description"
        label="Meta Description"
        hidden={true}
        initialValue={null}
      >
        <TextArea rows={5} />
      </Form.Item>
      <Form.Item name="faq_id" label="FAQ Group">
        <Select placeholder="Select FAQ Group" onChange={onChangeFAQSelection}>
          {faqs.data.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      {selectedFAQs.length > 0 ? (
        <>
          <Collapse defaultActiveKey={["1"]} ghost>
            <Panel header={"FAQ Questions:"} key="1">
              <div className="faq-div-layout">
                <List
                  itemLayout="horizontal"
                  dataSource={selectedFAQs}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        title={item.faq_index.question}
                        description={item.faq_index.answer}
                      />
                    </List.Item>
                  )}
                />
              </div>
            </Panel>
          </Collapse>
          <Divider />
        </>
      ) : null}
      <Form.Item name="review_id" label="Review Group">
        <Select
          placeholder="Select Review Group"
          onChange={onChangeReviewSelection}
        >
          {reviewGroups.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      {selectedReviews.length > 0 ? (
        <>
          <Collapse defaultActiveKey={["1"]} ghost>
            <Panel header={"Reviews Questions:"} key="1">
              <div className="faq-div-layout">
                <List
                  itemLayout="horizontal"
                  dataSource={selectedReviews}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        title={item.reviews_index.name}
                        description={item.reviews_index.comment}
                      />
                    </List.Item>
                  )}
                />
              </div>
            </Panel>
          </Collapse>
          <Divider />
        </>
      ) : null}
      <Form.Item name="disadvantage_sub_group_id" label="Disadvantage Remarks">
        <Select
          placeholder="Select Disadvantage Remarks Group"
          onChange={onChangeDisadvantageRemarksGroup}
        >
          {remarksGroup
            .filter((item) => item.classification === "disadvantages")
            .map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
        </Select>
      </Form.Item>
      {selectedDisadvantageRemarks.length > 0 ? (
        <>
          <Collapse defaultActiveKey={["1"]} ghost>
            <Panel header={"Advantage Remarks Index:"} key="1">
              <div className="faq-div-layout">
                <List
                  itemLayout="horizontal"
                  dataSource={selectedDisadvantageRemarks}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta title={item.remarks_index.content} />
                    </List.Item>
                  )}
                />
              </div>
            </Panel>
          </Collapse>
          <Divider />
        </>
      ) : null}
      <Form.Item name="advantage_sub_group_id" label="Advantage Remarks">
        <Select
          placeholder="Select Advantage Remarks Group"
          onChange={onChangeAdvantageRemarksGroup}
        >
          {remarksGroup
            .filter((item) => item.classification === "advantages")
            .map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
        </Select>
      </Form.Item>
      {selectedAdvantageRemarks.length > 0 ? (
        <>
          <Collapse defaultActiveKey={["1"]} ghost>
            <Panel header={"Advantage Remarks Index:"} key="1">
              <div className="faq-div-layout">
                <List
                  itemLayout="horizontal"
                  dataSource={selectedAdvantageRemarks}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta title={item.remarks_index.content} />
                    </List.Item>
                  )}
                />
              </div>
            </Panel>
          </Collapse>
          <Divider />
        </>
      ) : null}
      <Form.Item
        name="og_title"
        label="Facebook Title"
        hidden={true}
        initialValue={null}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="og_description"
        label="Facebook Description"
        hidden={true}
        initialValue={null}
      >
        <TextArea rows={5} />
      </Form.Item>
      <Form.Item name="feature_image" label="Feature Image / Banner Image">
        <Button icon={<UploadOutlined />} onClick={onClickUploadButton}>
          Click to Upload
        </Button>
      </Form.Item>
      <Button type="default" size="middle" onClick={handlePluginsModalVisible}>
        Add Plugins
      </Button>
      <FeatureImagesModalComponent
        visible={imageUploadsModal}
        setMediaIds={setMediaIds}
        onClickUploadButton={onClickUploadButton}
        mediaForm={mediaForm}
        imageSet={imageSet}
      />
    </>
  );
};
