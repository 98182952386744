import React, { Fragment } from "react";
import { Form, Input, Modal, Button } from "antd";
import {
  CloseCircleOutlined,
  SaveOutlined,
  CloseOutlined,
} from "@ant-design/icons";

export const PlatformFormComponent = (props) => {
  const { isModalVisible, onCancel, isConfirmLoading, form, onOk } = props;
  return (
    <Fragment>
      <Modal
        visible={isModalVisible}
        title="Create New Platform"
        okText="Create"
        cancelText="Cancel"
        onCancel={onCancel}
        confirmLoading={isConfirmLoading}
        onOk={onOk}
        footer={[
          <Button type="default" onClick={onCancel} icon={<CloseOutlined />}>
            Cancel
          </Button>,
          <Button type="primary" onClick={onOk} icon={<SaveOutlined />}>
            Create Platform
          </Button>,
        ]}
      >
        <Form
          layout="vertical"
          form={form}
          name="Platform Creation Form"
          scrollToFirstError={true}
          preserve={false}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input platform name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
};
