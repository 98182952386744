import React, { useState, useEffect, useRef } from "react";
import { Form, message, Divider, PageHeader } from "antd";
import { PlatformWithRolesAndModulesTableComponent } from "../sub_components/platform/PlatformWithRolesAndModulesTableComponent";
import { ModulesFiltersAndSelectionsComponent } from "../sub_components/modules/ModulesFiltersAndSelectionsComponent";
import { AddNewModulesPlatformComponent } from "../sub_components/modules/AddNewModulesPlatformComponent";
import { ModulesFunctionalityComponent } from "../sub_components/modules/ModulesFunctionalityComponent";
import { CreateNewModules } from "../../api/admin/CreateNewModules";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { ModulesListComponent } from "../sub_components/modules/ModulesListComponent";
import useGetModulesIndex from "../../hooks/modules/useGetModulesIndex";
import useFetchPermissionSets from "../../hooks/useFetchPermissions";
import useGetPlatforms from "../../hooks/platforms/useGetPlatforms";
import useCreateModules from "../../hooks/modules/useCreateModules";
import { useComponentDidUpdate } from "../../hooks/useComponentDidUpdate";
import { ModulesSelectAndColumnTagsComponent } from "../ModulesSelectAndColumnTagsComponent";

const initialRolesRestriction = {
  ADD_NEW_MODULES: 0,
};

const permissionSet = ["ADD_NEW_MODULES"];

const initialModuleTableState = {
  data: [],
  total: 0,
};
export const ModulesComponent = () => {
  const [addModulesForm] = Form.useForm();
  const [selectedRows, setSelectedRows] = useState(null);
  const [selectedRowkey, setSelectedRowKeys] = useState([]);
  const [showAddModules, setShowAddModules] = useState(false);
  const [platforms, setPlatforms] = useState([]);
  const [modules, setModules] = useState(initialModuleTableState);
  const [currentPagination, setCurrentPagination] = useState(1);
  const [currentCount, setCurrentCount] = useState(10);
  const rolesRestriction = useFetchPermissionSets(
    permissionSet,
    initialRolesRestriction
  );
  const [addNewModulesButtonLoading, setAddNewModulesButtonLoading] =
    useState(false);

  const [isShowColumnFilter, setIsShowColumnFilter] = useState(false);
  const [columnFilterTags, setColumnFilterTags] = useState([]);

  const [columnFilter, setColumnFilter] = useState([]);
  const sortColumn = useRef(null);
  const sortType = useRef("asc");

  const setSortColumn = (value) => {
    sortColumn.current = value;
  }

  const setSortType = (value) => {
    if (value === "descend") {
      sortType.current = "desc";
    } else if (value === "ascend") {
      sortType.current = "asc";
    } else {
      sortType.current = "none";
    }
  }

  const onSortChange = async (sortCol, sortType) => {

    setSortColumn(sortCol);
    setSortType(sortType);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    await fetchModules(currentPagination, currentCount);

  };

  const fetchPlatforms = async () => {
    const query = { page: 1, limit: null };
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const data = await useGetPlatforms(query, false);
    setPlatforms(data);
  };

  const OnFinish = async () => {
    setAddNewModulesButtonLoading(true);
    const values = await addModulesForm.validateFields();
    const { platform_id, ...other_value } = values;
    const isSuccess = await useCreateModules(platform_id, other_value);
    if (isSuccess) {
      setAddNewModulesButtonLoading(false);
      showaddModulesModal();
      resetSelection();
    }
  };

  const fetchModules = async (page = 1, limit = 10) => {
    setCurrentPagination(page);
    const query = {
      page: page,
      limit: limit,
      sortColumn: sortColumn.current,
      sortType: sortType.current,
      columnFilter: columnFilter
    };
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const data = await useGetModulesIndex(query, true);
    setModules(data);
  };

  ///////////////////////////////
  // for column filter tags
  const onCloseColumnFilterTag = (id) => {
    setColumnFilterTags((prevState) => {
      return prevState.filter(
        (item) => item.id !== id
      );
    });

    setColumnFilter((prevState) => {
      let newData = prevState.filter(
        (item) => {

          return Array.isArray(item.id) ? JSON.stringify(item.id) != id : item.id != id;
        }
      );

      return newData;
    });

    if (!columnFilterTags.length) {
      setIsShowColumnFilter(false);
    }
  };

  const onLoadColumnFilterTags = () => {

    if (columnFilter.length) {
      let filterTags = columnFilter.map((e) => {
        let [label, tagId] = Array(2).fill(e.id);

        if (Array.isArray(e.id)) {
          tagId = JSON.stringify(tagId);
          label = "";

          for (let i = 0, n = e.id.length; i < n; i++) {
            label += `${e.id[i]} `;
          }
        }

        return {
          color: "processing",
          closable: true,
          selection_label: label,
          value: e.value,
          id: tagId,
          onClose: onCloseColumnFilterTag
        }
      })

      setColumnFilterTags(filterTags);
      setIsShowColumnFilter(true);
    } else {
      // if columnFilter is just blank, then remove the tags
      setColumnFilterTags([]);
      setIsShowColumnFilter(false);
    }
  };

  const onClearColumnFilters = () => {
    setColumnFilterTags([]);
    setIsShowColumnFilter(false);
    setColumnFilter([]);

  };

  useEffect(() => {
    fetchPlatforms();
    fetchModules();
  }, []);

  useComponentDidUpdate(() => {
    fetchModules(1, currentCount);
    onLoadColumnFilterTags();
  }, [columnFilter]);

  const onPaginationChange = (page, limit) => {
    setCurrentPagination(page);
    setCurrentCount(limit);
    fetchModules(page, limit);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows[0]);
    },
  };

  const resetSelection = () => {
    setSelectedRowKeys(null);
    setSelectedRows(null);
  };

  const showaddModulesModal = () => {
    setShowAddModules(!showAddModules);
  };

  return (
    <>
      <PageHeader
        title="MODULES"
        subTitle="MODULE PER PLATFORM MANAGEMENT"
        breadcrumbRender={() => (
          <BreadCrumbsComponent
            breadcrumbsItem={["Administration", "Modules"]}
          />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <ModulesFunctionalityComponent
          showaddModulesModal={showaddModulesModal}
          selectedRows={selectedRows}
          rolesRestriction={rolesRestriction}
        />

        <ModulesSelectAndColumnTagsComponent
          onClearColumnFilters={onClearColumnFilters}
          isShowColumnFilterTags={isShowColumnFilter}
          columnFilterTags={columnFilterTags}
          isShowSelection={!!selectedRows}
          onClearSearcheAndSelection={resetSelection}
          tags={[
            {
              color: "processing",
              selection_label: "Selected Platform",
              value: selectedRows
                ? `${selectedRows.name}`
                : null,
            },
          ]}
        />
        <ModulesListComponent
          data={modules}
          onPaginationChange={onPaginationChange}
          currentPagination={currentPagination}
          columnFilter={columnFilter}
          setColumnFilter={setColumnFilter}
          onSortChange={onSortChange}
        />
        <AddNewModulesPlatformComponent
          isVisible={showAddModules}
          handleOk={OnFinish}
          handleCancel={showaddModulesModal}
          loading={addNewModulesButtonLoading}
          form={addModulesForm}
          platforms={platforms}
        />
      </div>
    </>
  );
};
