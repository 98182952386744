import { FetchRolesIndex } from "../../api/admin/FetchRolesIndex";
import { message } from "antd";

const useGetRolesWithPermissionsIndex = async () => {
  await message.loading("fetching list of roles", 2);

  const roles = await FetchRolesIndex();

  for (const role of roles.data) {
    role["key"] = role.id;
    for (const permission of role.permissions) {
      for (const indexPermission of permission.permissions) {
        role[indexPermission.name] =
          indexPermission.access_rights.has_access === 1 ? true : false;
      }
    }
  }

  await message.success(
    "successfully retrieved list of roles with permissions",
    2
  );

  return roles;
};

export default useGetRolesWithPermissionsIndex;
