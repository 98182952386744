import React from "react";
import { Form, Modal, Input } from "antd";

export const OptionUpdateModalComponent = (props) => {
  const { showModal, onCancel, OnFinish, form, selectedRow } = props;
  return (
    <>
      {selectedRow ? (
        <Modal
          title="Update Option"
          visible={showModal}
          onOk={OnFinish}
          destroyOnClose={true}
          onCancel={onCancel}
        >
          <Form form={form} layout="vertical">
            <Form.Item
              name="option_value"
              label="Option Value"
              key="option_value"
              rules={[{ required: true, message: "Please input Option Value" }]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          </Form>
        </Modal>
      ) : null}
    </>
  );
};
