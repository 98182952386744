import React from "react";
import { ModalwithFormComponent } from "../../ModalwithFormComponent";

export const ChangePasswordviaInputsComponent = (props) => {
  const {
    title,
    form,
    formLayout,
    formInputsComponent,
    onOk,
    onCancel,
    destroyOnClose,
    okText,
    cancelText,
    visible,
  } = props;

  return (
    <ModalwithFormComponent
      title={title}
      form={form}
      formLayout={formLayout}
      formInputsComponent={formInputsComponent}
      onOk={onOk}
      onCancel={onCancel}
      destroyOnClose={destroyOnClose}
      okText={okText}
      cancelText={cancelText}
      visible={visible}
    />
  );
};
