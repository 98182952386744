import React from "react";
import { ModulesTableComponent } from "../../ModulesTableComponent";
import useGetRemarksSubGroupListColumns from "../../../hooks/remarks_sub_group/useGetRemarksSubGroupListColumns";
import useColumnFilter from "../../../hooks/useColumnFilter";

export const RemarksSubGroupListComponent = (props) => {
  const {
    data = { data: [], total: 0 },
    onPaginationChange,
    currentPagination,
    onSelection,
    selectedRowKey,
    onSortChange,
    columnFilter,
    setColumnFilter,
    tableLoading,
    onClickRestoreRemarksGroup,
    onClickEditRemarksGroup,
    rolesRestriction,
  } = props;

  const remarksSubGroupColumns = useGetRemarksSubGroupListColumns(
    onClickRestoreRemarksGroup,
    onClickEditRemarksGroup,
    rolesRestriction
  );

  const { getColumnFilterProps } = useColumnFilter(
    columnFilter,
    setColumnFilter
  );

  let newCols = [];

  for (let i = 0, n = remarksSubGroupColumns.length; i < n; i++) {
    newCols.push({
      ...remarksSubGroupColumns[i],
      sorter: true,
      ...getColumnFilterProps(remarksSubGroupColumns[i].dataIndex),
    });
  }

  return (
    <div>
      <ModulesTableComponent
        data={data}
        onPaginationChange={onPaginationChange}
        onSelection={onSelection}
        selectedRowKey={selectedRowKey}
        tableColumns={newCols || []}
        currentPagination={currentPagination}
        columnFilter={columnFilter}
        setColumnFilter={setColumnFilter}
        onSortChange={onSortChange}
        tableLoading={tableLoading}
        hasSelection={false}
      />
    </div>
  );
};
