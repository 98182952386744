import React from "react";
import { Modal } from "antd";

export const PostHTMLViewComponent = (props) => {
  const { visible, onOk, htmlContent = null } = props;

  return (
    <>
      <Modal
        title="View Post Content"
        visible={visible}
        onOk={onOk}
        onCancel={onOk}
        width={1600}
        centered={false}
        closable={true}
      >
        {htmlContent ? (
          <div
            dangerouslySetInnerHTML={{
              __html: htmlContent,
            }}
          />
        ) : null}
      </Modal>
    </>
  );
};
