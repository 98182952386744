import { useFetch } from "../../hooks/useFetch";

export const FetchBiddingWinnersList = async (query) => {
  try {
    const data = await useFetch(
      `${process.env.REACT_APP_API_URL}/biddings/query`,
      query
    );

    for (const i of data.data) {
      i["key"] = i.id;
    }

    return data.data;
  } catch (err) {
    console.log("ERROR: ", err);
    return null;
  }
};
