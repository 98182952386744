import React from "react";
import { Form, Row, Col, Divider } from "antd";
import { AdsFunctionalityComponent } from "./AdsFunctionalityComponent";
import { AdsCreationGeneralInformationFormComponent } from "./AdsCreationGeneralInformationFormComponent";
import { AdsCreationSubInformationFormComponent } from "./AdsCreationSubInformationFormComponent";

export const AdsCreationComponent = (props) => {
  const {
    warehouses,
    categories,
    models,
    brands,
    brandTypes,
    form,
    CreateNewAds,
  } = props;
  return (
    <>
      <Row>
        <AdsFunctionalityComponent handleSubmitAds={CreateNewAds} />
      </Row>
      <Divider />
      <Form layout="vertical" form={form}>
        <Row>
          <Col span={12}>
            <AdsCreationGeneralInformationFormComponent />
          </Col>
          <Col span={12}>
            <div className="ads-creation-form-other-information-div">
              <AdsCreationSubInformationFormComponent
                warehouses={warehouses}
                categories={categories}
                models={models}
                brands={brands}
                brandTypes={brandTypes}
              />
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};
