import { Button, Input, Radio, Space } from "antd";

function useColumnFilter(columnFilter, setColumnFilter) {
  const handleFilter = (colName, filterVal) => {
    const tempArray = [...columnFilter];
    const filterIndex = tempArray.findIndex(
      (o) => o.id.toString() === colName.toString()
    );

    if (filterIndex !== -1) {
      if (filterVal && filterVal.length) {
        tempArray[filterIndex] = { id: colName, value: filterVal };
      } else {
        tempArray.splice(filterIndex, 1);
      }
    } else {
      if (filterVal && filterVal.length) {
        tempArray.push({ id: colName, value: filterVal });
      }
    }

    setColumnFilter(tempArray);
  };

  const handleReset = (clearFilters, colName) => {
    clearFilters();
    handleFilter(colName, ""); // send a blank value of filter
  };

  const getColumnFilterProps = (dataIndex, transformValue = (val) => val) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={node => {
          //   this.searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleFilter(dataIndex, selectedKeys[0])}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() =>
            handleFilter(dataIndex, transformValue(selectedKeys[0]))
          }
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters, dataIndex)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
  });

  const getColumnBooleanFilter = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Radio.Group
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          value={selectedKeys[0]}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        >
          <Space direction="vertical">
            <Radio value={"0"}>False</Radio>
            <Radio value={"1"}>True</Radio>
          </Space>
        </Radio.Group>

        <Button
          type="primary"
          onClick={() => handleFilter(dataIndex, selectedKeys[0])}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters, dataIndex)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
  });

  const getColumnYesNoFilter = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Radio.Group
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          value={selectedKeys[0]}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        >
          <Space direction="vertical">
            <Radio value={"0"}>No</Radio>
            <Radio value={"1"}>Yes</Radio>
          </Space>
        </Radio.Group>

        <Button
          type="primary"
          onClick={() => handleFilter(dataIndex, selectedKeys[0])}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters, dataIndex)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
  });

  const getColumnDynamicBoolFilter = (
    dataIndex,
    positiveText,
    negativeText
  ) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Radio.Group
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          value={selectedKeys[0]}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        >
          <Space direction="vertical">
            <Radio value={"0"}>{negativeText}</Radio>
            <Radio value={"1"}>{positiveText}</Radio>
          </Space>
        </Radio.Group>

        <Button
          type="primary"
          onClick={() => handleFilter(dataIndex, selectedKeys[0])}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters, dataIndex)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
  });

  const getColumnDynamicFilter = (dataIndex, arrOptions) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Radio.Group
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          value={selectedKeys[0]}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        >
          <Space direction="vertical">
            {arrOptions.map((e) => {
              return (
                <Radio key={e.value} value={e.value}>
                  {e.text}
                </Radio>
              );
            })}
          </Space>
        </Radio.Group>

        <Button
          type="primary"
          onClick={() => handleFilter(dataIndex, selectedKeys[0])}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters, dataIndex)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
  });

  return {
    getColumnFilterProps,
    getColumnBooleanFilter,
    getColumnYesNoFilter,
    getColumnDynamicBoolFilter,
    getColumnDynamicFilter,
    handleFilter,
  };
}

export default useColumnFilter;
