import React from "react";
import { Modal, Form } from "antd";
import { CreationModalFooterComponent } from "./CreationModalFooterComponent";
import { SpecialistGeneralInformationFormComponent } from "./SpecialistGeneralInformationFormComponent";
import { SpecialistContactInformationFormComponent } from "./SpecialistContactInformationFormComponent";

export const SpecialistCreationModalFormComponent = (props) => {
  const {
    specialistCreationModadal,
    handleModalSpecialistCreationOnClick,
    currentStep,
    nextPage,
    prevPage,
    HandleModalFinish,
    warehouses = { data: [], total: 0 },
    form,
    onClickShowMoreList,
    isClickShowMoreDisabled,
  } = props;

  return (
    <Modal
      title="Create Advisers"
      visible={specialistCreationModadal}
      centered={true}
      onCancel={handleModalSpecialistCreationOnClick}
      footer={
        <CreationModalFooterComponent
          currentStep={currentStep}
          nextPage={nextPage}
          prevPage={prevPage}
          HandleModalFinish={HandleModalFinish}
          handleModalSpecialistCreationOnClick={
            handleModalSpecialistCreationOnClick
          }
        />
      }
    >
      <Form form={form} layout="vertical">
        <div style={{ display: currentStep > 0 ? "none" : null }}>
          <SpecialistGeneralInformationFormComponent
            warehouses={warehouses ? warehouses.data : []}
            onClickShowMoreList={onClickShowMoreList}
            isClickShowMoreDisabled={isClickShowMoreDisabled}
          />
        </div>
        <div style={{ display: currentStep < 1 ? "none" : null }}>
          <SpecialistContactInformationFormComponent />
        </div>
      </Form>
    </Modal>
  );
};
