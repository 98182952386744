import React, { useEffect, useState } from "react";
import {
  Form,
  Tabs,
  Button,
  Radio,
  Descriptions,
  Divider,
  Menu,
  Empty,
  message,
  Popconfirm,
} from "antd";
import { QueryUser } from "../../../api/admin/QueryUser";
import { UpdateUserInformation } from "../../../api/admin/UpdateUseInformation";
import { SaveOutlined, LeftOutlined } from "@ant-design/icons";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import "../../../MenuComponent.css";

const { TabPane } = Tabs;
export const UserEditPageComponent = () => {
  const [data, setData] = useState(null);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [form] = Form.useForm();
  let query = useQuery();

  useEffect(() => {
    const fetchOnLoadUser = async () => {
      // const url = window.location.href;
      const queryParams = { email: query.get("email") };
      const data = await fetchUser(queryParams);

      if (!data) {
        setData(null);
        return;
      }

      setData(data);
      form.setFieldsValue({
        has_deposited: data.has_deposited,
        active_status: data.active_status,
        user_type: data.user_type,
      });
    };

    fetchOnLoadUser();
  }, []);

  const fetchUser = async (query) => {
    return await QueryUser(query);
  };

  const onSubmit = async () => {
    setButtonDisabled(true);
    const values = await form.validateFields();
    message.loading("Updating User Information", 2).then(async () => {
      const resp = await UpdateUserInformation(values, data.id);
      if (resp.status === 200) {
        message.success("Successfuly Updated User Information");
        setButtonDisabled(false);
      }
    });
  };

  const onCancel = () => {
    message.info("No changes, were made", 2);
  };

  const onBackToDashboardClick = () => {
    window.location.href = "/dashboard";
  };

  return (
    <Router>
      <div className="site-layout">
        <Menu mode="horizontal" theme={"dark"}>
          <Menu.Item
            key="dashboard"
            icon={<LeftOutlined />}
            onClick={onBackToDashboardClick}
          >
            Back to Dashboard
          </Menu.Item>
        </Menu>
      </div>
      <div style={{ padding: "7%" }}>
        {data ? (
          <>
            <Descriptions bordered title="User Information" size={"small"}>
              <Descriptions.Item label="Fullname">{`${data.first_name} ${data.last_name}`}</Descriptions.Item>
              <Descriptions.Item label="Gender">
                {data.gender}
              </Descriptions.Item>
              <Descriptions.Item label="Email">{data.email}</Descriptions.Item>
              <Descriptions.Item label="Mobile Number">
                {data.mobile}
              </Descriptions.Item>
              <Descriptions.Item label="User Type">
                {data.user_type}
              </Descriptions.Item>
              <Descriptions.Item label="Webiste">
                {data.website}
              </Descriptions.Item>
              <Descriptions.Item label="Address">
                {data.address}
              </Descriptions.Item>
            </Descriptions>
            <br />
            <Divider />
            <div style={{ textAlign: "right" }}>
              <Popconfirm
                title="Are you sure?"
                onConfirm={onSubmit}
                onCancel={onCancel}
              >
                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  disabled={isButtonDisabled}
                  loading={isButtonDisabled ? true : false}
                >
                  Save
                </Button>
              </Popconfirm>
            </div>
            <br />
            <Form form={form} layout="vertical" name="user">
              <Tabs tabPosition={"left"}>
                <TabPane tab="User Account Information" key="1">
                  <div style={{ padding: "2%" }}>
                    <Form.Item
                      name="user_type"
                      label="User Type"
                      rules={[
                        {
                          required: true,
                          message: "User Type is required",
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={"user"}>User</Radio>
                        <Radio value={"admin"}>Admin</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item
                      name="has_deposited"
                      label="Has Deposited?"
                      rules={[
                        {
                          required: true,
                          message: "Has Deposited Data is required",
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={1}>Yes</Radio>
                        <Radio value={0}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item
                      name="active_status"
                      label="Account Activated?"
                      rules={[
                        {
                          required: true,
                          message: "Active Status Data is required",
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={"1"}>Yes</Radio>
                        <Radio value={"0"}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </TabPane>
              </Tabs>
            </Form>
          </>
        ) : (
          <Empty description="No user found by using the email provided" />
        )}
      </div>
    </Router>
  );
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
