import React from "react";
// import ReactExport from "react-export-excel";
import { Button } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import useIsMobile from "../../../hooks/useIsMobile";

// const { ExcelFile } = ReactExport;
// const { ExcelSheet, ExcelColumn } = ExcelFile;

export const UserReportsComponent = (props) => {
  const { dataSet, reportRef } = props;
  const isMobile = useIsMobile();

  return (
    <>
      {/* <ExcelFile
                element={
                    <Button
                        disabled={dataSet.length > 0 || dataSet === null ? false : true}
                        hidden={true}
                        type="default"
                        icon={<ExportOutlined />}
                        block={isMobile ? true : false}
                        ref={reportRef}
                    >
                        Generate Report
                    </Button>
                }
                filename={`users${new Date()
                    .toLocaleString()
                    .replace("/", "")
                    .replace("/", "")
                    .replace(":", "")
                    .replace(":", "")}`}
            >
                {dataSet.length > 0 ? (
                    <ExcelSheet data={dataSet} name="Users">
                        <ExcelColumn label="Sign-up Date" value="created_at" />
                        <ExcelColumn label="First Name" value="first_name" />
                        <ExcelColumn label="Last Name" value="last_name" />
                        <ExcelColumn label="Contact Number" value="mobile" />
                        <ExcelColumn label="Email Address" value="email" />
                        <ExcelColumn label="Address" value="address" />
                    </ExcelSheet>
                ) : null}
            </ExcelFile> */}
    </>
  );
};
