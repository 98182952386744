import { FetchAdsList } from "../../api/auctions/FetchAdsList";
import { message } from "antd";

const defaultQuery = { page: 1, limit: 10, filter_type: "ACTIVE" };

const useFetchAdsList = async (query = defaultQuery) => {
  await message.loading("Fetching List of Items/Ads", 4);

  const data = await FetchAdsList(query);

  for (const i of data.data) {
    i["key"] = i.id;
    i["conduction_sticker"] = i.car.conduction_sticker;
    i["plate_number"] = i.car.plate_number;
    i["veh_location"] = i.warehouses.location + i.warehouses.viewing_address;
    i["date_updated"] = i.updated_at;
  }

  if (data.data.length === 0) {
    await message.info("No active ads listed from search", 4);
  } else {
    await message.success("Successfuly Searched List of Ads", 4);
  }

  return data.data;
};

export default useFetchAdsList;
