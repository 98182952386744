import React from "react";
import { Button } from "antd";
import { RightOutlined, LeftOutlined, SaveOutlined } from "@ant-design/icons";

export const UserCreationModalFooterComponent = (props) => {
  const { currentStep, setCurrentStep, handleModaOkClicked } = props;
  return (
    <>
      {currentStep === 0
        ? [
            <Button
              type="primary"
              size="medium"
              icon={<RightOutlined />}
              onClick={() => setCurrentStep((currentStep) => currentStep + 1)}
            >
              Next
            </Button>,
          ]
        : currentStep === 3
        ? [
            <Button
              size="medium"
              icon={<LeftOutlined />}
              onClick={() => setCurrentStep((currentStep) => currentStep - 1)}
            >
              Back
            </Button>,
            <Button
              type="primary"
              size="medium"
              onClick={handleModaOkClicked}
              icon={<SaveOutlined />}
            >
              Submit
            </Button>,
          ]
        : [
            <Button
              size="medium"
              icon={<LeftOutlined />}
              onClick={() => setCurrentStep((currentStep) => currentStep - 1)}
            >
              Back
            </Button>,
            <Button
              type="primary"
              size="medium"
              icon={<RightOutlined />}
              onClick={() => setCurrentStep((currentStep) => currentStep + 1)}
            >
              Next
            </Button>,
          ]}
    </>
  );
};
