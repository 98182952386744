import React from "react";
import { Table, Alert, Collapse } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import useColumnFilter from "../../../hooks/useColumnFilter";

const { Column } = Table;
const { Panel } = Collapse;

export const BidsTableComponent = ({
  data,
  tableLoading,
  onSelectRowChange,
  selectedRowKeys,
  platform = "car",
  onSortChange,
  columnFilterFrontend,
  setColumnFilterFrontend,
}) => {
  const { getColumnDynamicFilter } = useColumnFilter(columnFilterFrontend, setColumnFilterFrontend);

  return (
    <>
      <Collapse>
        <Panel
          showArrow={false}
          header={
            <>
              <InfoCircleOutlined /> Information
            </>
          }
        >
          <Alert
            message="Bids Report Filtering/Searching Important Notes"
            description={
              <ul>
                <li>
                  If checkbox is checked, will include all previous bids on the table and report.
                </li>
                <li>
                  If checkbox is not checked, previous bids will not be included in the table and
                  report.
                </li>
              </ul>
            }
            type="info"
            showIcon
          />
          <br />
          <Alert
            message="Bids Report Additional Notes"
            description={
              <>
                <ul>
                  <li>Previous bids = bids to a specific unit/item but hidden.</li>
                  <li>Current bids = bids attached to a current unit/item but not hidden.</li>
                </ul>
                <span>
                  Hidden bids mean the unit is relisted or the previous winner was removed from the
                  unit.
                </span>
              </>
            }
            type="info"
            showIcon
          />
        </Panel>
      </Collapse>
      <br />
      <Table
        dataSource={data}
        size="small"
        loading={tableLoading}
        scroll={{ x: 2500, y: 500 }}
        rowSelection={{
          type: "radio",
          selectedRowKeys,
          onChange: onSelectRowChange,
        }}
        onChange={(pagination, filters, sorter, action) => {
          if (action.action === "sort" && sorter.column?.sorter) {
            onSortChange(sorter.field, sorter.order);
          }
        }}
        pagination={false}
        bordered
      >
        <Column title="Bid Date" dataIndex="created_at" fixed="left" />
        <Column
          title="Year Model"
          dataIndex={["ads", "title"]}
          render={(row) => row.split(" ")[0]}
        />
        <Column
          title={`${platform.charAt(0).toUpperCase() + platform.slice(1)} Title`}
          dataIndex={["ads", "title"]}
          render={(row, record) => {
            let model = row.split(" ")[0];
            let trans_type = record?.ads?.car?.model?.transmission_type;
            return trans_type ? `${row.replace(model, "")} ${trans_type}` : row.replace(model, "");
          }}
        />
        <Column
          title="Conduction Sticker"
          dataIndex={["ads", "car", "conduction_sticker"]}
          render={(row) => row.split(" ")[0]}
        />
        <Column
          title="Plate Number"
          dataIndex={["ads", "slug"]}
          sorter={(a, b) => a["ads,slug"].localeCompare(b["ads,slug"])}
          render={(value) => value.toUpperCase()}
        />
        <Column title="Middle Initial" render={(row) => row.users?.middle_name?.charAt(0) || ""} />
        <Column
          title="Winner First Name"
          dataIndex={["users", "first_name"]}
          sorter={(a, b) => a["users,first_name"].localeCompare(b["users,first_name"])}
        />
        <Column
          title="Winner Last Name"
          dataIndex={["users", "last_name"]}
          sorter={(a, b) => a["users,last_name"].localeCompare(b["users,last_name"])}
        />
        <Column title="Starting Bid Price" dataIndex={["ads", "price_order"]} />
        <Column
          title="Bid Amount"
          dataIndex="bid_amount"
          sorter={(a, b) => a["bid_amount"].toString().localeCompare(b["bid_amount"].toString())}
        />
        <Column
          title="Payment Method"
          dataIndex="payment_method"
          sorter={(a, b) => a["payment_method"].localeCompare(b["payment_method"])}
        />
        <Column
          title="Seller Location"
          dataIndex={["ads", "warehouses", "location"]}
          sorter={(a, b) =>
            a["ads,warehouses,location"].localeCompare(b["ads,warehouses,location"])
          }
        />
        <Column
          title="Seller Name"
          render={(row) => {
            const adviser = row.ads[platform]?.new_adviser || row.ads[platform]?.adviser;
            return adviser ? `${adviser.first_name || ""} ${adviser.last_name || ""}` : "";
          }}
        />
        <Column
          title="Winner Phone"
          dataIndex={["users", "mobile"]}
          sorter={(a, b) => a["users,mobile"].localeCompare(b["users,mobile"])}
        />
        <Column
          title="Winner Email"
          dataIndex={["users", "email"]}
          sorter={(a, b) => a["users,email"].localeCompare(b["users,email"])}
        />
        <Column
          title="Unit Color"
          dataIndex={["ads", platform, "color", "specific_name"]}
          sorter={(a, b) =>
            a["users,${platform},color,specific_name"].localeCompare(
              b["users,${platform},color,specific_name"]
            )
          }
        />
        <Column
          title="Auction Status"
          dataIndex="auction_status"
          {...getColumnDynamicFilter("auction_status", [
            { text: "Ended", value: "Ended" },
            { text: "On Going", value: "On Going" },
            { text: "Invalid", value: "Invalid" },
          ])}
          sorter={(a, b) => a.auction_status.localeCompare(b.auction_status)}
          fixed="right"
        />
        <Column
          title="Bid Status"
          dataIndex="user_bid_status"
          filterMultiple={false}
          {...getColumnDynamicFilter("user_bid_status", [
            { text: "Outbidded", value: "Outbidded" },
            { text: "Winner", value: "Winner" },
          ])}
          sorter={(a, b) => a.user_bid_status.localeCompare(b.user_bid_status)}
          fixed="right"
        />
        <Column
          title="Previous Bid?"
          dataIndex="is_accepted"
          render={(isAccepted) => (isAccepted === 1 ? "No" : "Yes")}
          fixed="right"
        />
        <Column title="Bidding Type" dataIndex={["ads", "buy_now_type"]} fixed="right" />
      </Table>
    </>
  );
};
