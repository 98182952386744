import { useFetch } from "../../hooks/useFetch";

export const FetchWarehousesList = async (query = { page: 1, limit: 10 }) => {
  try {
    const data = await useFetch(
      `${process.env.REACT_APP_API_URL}/warehouses`,
      query
    );
    return data.data;
  } catch (err) {
    return null;
  }
};
