import { CloseAuctionsStatus } from "../../api/auctions/CloseAuctionsStatus";
import { message } from "antd";

const useUpdateAuctionStatus = async (payload) => {
  // Output loading message, when API is called
  await message.loading("Closing Selected Auction(s)", 4);

  // Call the API function in the api/modules folder
  const response = await CloseAuctionsStatus(payload);

  // Validate response code, and output necessary message and return data
  // Return true if the API call executed successfuly
  if (response.status === 200) {
    await message.success("Successfuly Closed Auction Status", 2);
    return true;
  }

  // Return false if the API call failed on executing
  await message.error("Error on Closing Auction Status", 2);
  return false;
};

export default useUpdateAuctionStatus;
