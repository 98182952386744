import React from "react";
import { Table } from "antd";
import useGetAuctionTableCloserColumns from "../../../hooks/auctions/useGetAuctionTableCloserColumns";

const { Column } = Table;
export const ViewListofActiveAdsComponent = (props) => {
  const { data = { data: [], total: 0 } } = props;
  const tableColumns = useGetAuctionTableCloserColumns();
  return (
    <>
      <Table
        dataSource={data.data}
        size={"small"}
        scroll={{ x: 1500 }}
        bordered
      >
        {tableColumns.map((item, index) => (
          <Column key={index} title={item.title} dataIndex={item.dataIndex} />
        ))}
      </Table>
    </>
  );
};
