import React from "react";
import { Button, Space, Input, Divider, DatePicker, Form } from "antd";
import {
  UserAddOutlined,
  UserSwitchOutlined,
  SearchOutlined,
  UserOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import useIsMobile from "../../../hooks/useIsMobile";
import useIsTablet from "../../../hooks/useIsTablet";

const { RangePicker } = DatePicker;
export const UserManagementFunctionsComponent = ({
  changePasswordModal,
  createUserModal,
  updateUserRoleModal,
  addRolesModal,
  isForUpdate = null,
  userType = null,
  rolesRestrictions,
  onSearch,
  handleAdminChangePasswordModal,
  handleReportsModal,
  handleOnClickUpdateUserModal,
  form,
  selectedUserInfo,
}) => {
  const {
    CREATE_USER = 0,
    CHANGE_PASSWORD = 0,
    ADD_UPDATE_USER_ROLE = 0,
    UPDATE_USER = 0,
    GENERATE_USER_REPORT = 0,
  } = rolesRestrictions;

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  return (
    <>
      <div style={{ textAlign: isMobile || isTablet ? "center" : "right" }}>
        <Space size={"small"} direction={isMobile ? "vertical" : "horizontal"}>
          <Form form={form} layout={isMobile ? "vertical" : "inline"}>
            <Form.Item name="search_value">
              <Input placeholder="Input Search" />
            </Form.Item>
            <Form.Item name="created_date_range">
              <RangePicker className="user-date-filter" />
            </Form.Item>
            <Button
              icon={<SearchOutlined />}
              type="default"
              onClick={onSearch}
              block={isMobile ? true : false}
            >
              Search
            </Button>
          </Form>
        </Space>
        <Divider />
        <Space
          size={"small"}
          direction={isMobile || isTablet ? "vertical" : "horizontal"}
        >
          <Button
            hidden={CREATE_USER === 1 ? false : true}
            type="primary"
            size="middle"
            icon={<UserAddOutlined />}
            onClick={createUserModal}
            block={isMobile || isTablet ? true : false}
          >
            Create User
          </Button>
          <Button
            hidden={GENERATE_USER_REPORT === 1 ? false : true}
            size="middle"
            icon={<ExportOutlined />}
            onClick={handleReportsModal}
            block={isMobile || isTablet ? true : false}
          >
            Generate Reports
          </Button>
          {userType ? (
            <>
              <Button
                size="middle"
                icon={<UserOutlined />}
                hidden={UPDATE_USER === 1 ? false : true}
                onClick={handleOnClickUpdateUserModal}
                block={isMobile || isTablet ? true : false}
              >
                Update User
              </Button>
              <Button
                hidden={CHANGE_PASSWORD === 1 ? false : true}
                size="middle"
                icon={<UserAddOutlined />}
                onClick={handleAdminChangePasswordModal}
                block={isMobile || isTablet ? true : false}
              >
                Change Password
              </Button>
              <Button
                hidden={selectedUserInfo?.user_attached_roles ? false : true}
                size="middle"
                icon={<UserAddOutlined />}
                onClick={changePasswordModal}
                block={isMobile || isTablet ? true : false}
                danger
              >
                Remove Admin Access
              </Button>
              <Button
                hidden={ADD_UPDATE_USER_ROLE === 1 ? false : true}
                size="middle"
                icon={<UserSwitchOutlined />}
                onClick={isForUpdate ? updateUserRoleModal : addRolesModal}
                block={isMobile || isTablet ? true : false}
              >
                {isForUpdate ? "Update User Role" : "Add User Role"}
              </Button>
            </>
          ) : null}
        </Space>
      </div>
    </>
  );
};
