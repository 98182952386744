import React from "react";
import { Form, Modal } from "antd";
import { AuctionSearchInputsComponent } from "../auctions/AuctionSearchInputsComponent";
import { SearchOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import useIsMobile from "../../../hooks/useIsMobile";

export const BuyNowSearchFunctionalityComponent = ({ onSubmitSearch, form, visible, onCancel }) => {
  const isMobile = useIsMobile();

  return (
    <Modal
      open={visible}
      title="Filter Buy Now"
      okText="Search Buy Now"
      okButtonProps={{ icon: <SearchOutlined />, block: isMobile }}
      cancelButtonProps={{ icon: <ArrowLeftOutlined />, block: isMobile }}
      onOk={() => onSubmitSearch(1, 10)}
      onCancel={onCancel}
    >
      <Form form={form} layout="vertical">
        <AuctionSearchInputsComponent />
      </Form>
    </Modal>
  );
};
