import { useUpdate } from "../../hooks/useUpdate";

export const UpdateBuyNowStatus = async (payload, id) => {
  try {
    return await useUpdate(
      `${process.env.REACT_APP_API_URL}/buys/${id}`,
      payload
    );
  } catch (err) {
    console.log("ERROR: ", err);
    return null;
  }
};
