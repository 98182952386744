import React from "react";
import { Button, Form, Select, Space, Divider } from "antd";
import { SearchOutlined, SaveOutlined } from "@ant-design/icons";
import useIsMobile from "../../../hooks/useIsMobile";

const { Option } = Select;
export const AuctionCloserSearchingFormComponent = (props) => {
  const {
    form,
    banks = { data: [], total: 0 },
    ads = { data: [], total: 0 },
    onSearch,
    onSubmitCloseAuctions,
    rolesRestriction,
  } = props;
  const isMobile = useIsMobile();

  return (
    <div
      style={{
        width: "100%",
        marginBottom: "2%",
        textAlign: isMobile ? "center" : "right",
      }}
    >
      <Space
        size={"small"}
        direction={isMobile ? "vertical" : "horizontal"}
        split={<Divider type={isMobile ? "horizontal" : "vertical"} />}
      >
        <Form
          form={form}
          name="search_form"
          layout={isMobile ? "vertical" : "inline"}
        >
          <Form.Item
            name="bank_id"
            key="bank_id"
            style={{
              textAlign: "left !important",
            }}
            rules={[
              { required: true, message: "Bank is required when searching" },
            ]}
          >
            <Select placeholder="Select Bank">
              {banks.data.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Button
            size="middle"
            icon={<SearchOutlined />}
            type="primary"
            block={isMobile ? true : false}
            onClick={onSearch}
          >
            Search
          </Button>
        </Form>
        <Button
          size="middle"
          type="primary"
          block={isMobile ? true : false}
          icon={<SaveOutlined />}
          onClick={onSubmitCloseAuctions}
          disabled={ads.total > 0 ? false : true}
          hidden={
            rolesRestriction.CLOSE_AUCTIONS_BY_BANK === 1
              ? ads.data.length > 0
                ? false
                : true
              : true
          }
        >
          Close Auction(s)
        </Button>
      </Space>
    </div>
  );
};
