import React from "react";
import { Upload, Form } from "antd";
import { InboxOutlined } from "@ant-design/icons";

const { Dragger } = Upload;

export const AuctionCloserUploaderComponent = (props) => {
  const { draggerProps, form } = props;
  return (
    <Form form={form}>
      <Form.Item>
        <div style={{ width: "100%" }}>
          <Dragger {...draggerProps} style={{ height: "100%" }}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          </Dragger>
        </div>
      </Form.Item>
    </Form>
  );
};
