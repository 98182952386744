import axios from "axios";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { confirm } = Modal;

const useDeleteSession = () => {
  const executeDelete = async (row) => {
    confirm({
      title: "Do you want to delete current session?",
      icon: <ExclamationCircleOutlined />,
      content: "deleting this session, will make user logged out",
      async onOk() {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/sessions`,
          {
            data: {
              session_key: row?.key,
            },
          }
        );
      },
      onCancel() {
        return;
      },
    });
  };

  return {
    executeDelete,
  };
};

export default useDeleteSession;
