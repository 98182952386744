import axios from "axios";
import { message } from "antd";

const useRemoveAdminAccess = () => {
  const removeAdminAccess = async (user_id) => {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/roles/user/${user_id}`
    );

    if (response.status === 200) {
      message.success("successfuly remove admin access", 2);
      return;
    }

    message.error("something went wrong, please try again later", 3);
    message.info("please contact dev team if the error still persist");
    return;
  };

  return { removeAdminAccess };
};

export default useRemoveAdminAccess;
