import React, { useState, useEffect, useRef } from "react";
import { Tabs, PageHeader, Divider, Form, message } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { FetchPages } from "../../api/cms/FetchPages";
import { UpdatePost } from "../../api/cms/UpdatePost";
import { PageCreationComponent } from "../sub_components/pages/PageCreationComponent";
import { PageListComponent } from "../sub_components/pages/PageListComponent";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { PostHTMLViewComponent } from "../sub_components/posts/PostHTMLViewComponent";
import { ModulesFunctionalitiesComponent } from "../ModulesFunctionalitiesComponent";
import { ModulesFilterAndSelectionComponent } from "../ModulesFilterAndSelectionComponent";
import { ModalwithFormComponent } from "../ModalwithFormComponent";
import "../../dist/style/pages.css";
import { useComponentDidUpdate } from "../../hooks/useComponentDidUpdate";
import { ModulesSelectAndColumnTagsComponent } from "../ModulesSelectAndColumnTagsComponent";

const { TabPane } = Tabs;
const initialRolesRestriction = {
  CREATE_PAGE: 0,
  UPDATE_PAGE: 0,
};
const initialForUpdateInformation = { post_id: null, meta_id: null };
export const PagesManagementComponent = () => {
  const [createEditForm] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [currentTab, setCurrentTab] = useState("1");
  const [page, setPages] = useState({ data: [], total: 0 });
  const [currentPagination, setCurrentPagination] = useState(1);
  const [forUpdateIds, setForUpdateIds] = useState(initialForUpdateInformation);
  const [postHTMLcontent, setPostHTMLcontent] = useState(null);
  const [isShowPreviewModalPost, setIsShowPreviewModalPost] = useState(false);
  const [isForUpdate, setIsForUpdate] = useState(false);
  const [selectedRowkey, setSelectedRowKey] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isShowUpdateModal, setIsShowUpdateModal] = useState(false);
  const [rolesRestriction, setRolesRestrictions] = useState(
    initialRolesRestriction
  );

  const [isShowColumnFilter, setIsShowColumnFilter] = useState(false);
  const [columnFilterTags, setColumnFilterTags] = useState([]);

  const [currentCount, setCurrentCount] = useState(10);
  const [columnFilter, setColumnFilter] = useState([]);
  const sortColumn = useRef(null);
  const sortType = useRef("asc");

  const setSortColumn = (value) => {
    sortColumn.current = value;
  }

  const setSortType = (value) => {
    if (value === "descend") {
      sortType.current = "desc";
    } else if (value === "ascend") {
      sortType.current = "asc";
    } else {
      sortType.current = "none";
    }
  }

  const onSortChange = async (sortCol, sortType) => {

    setSortColumn(sortCol);
    setSortType(sortType);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    await GetPostsList(currentPagination, currentCount);

  };

  const GetPostsList = async (page = 1, limit = 10) => {
    setCurrentPagination(page);
    const query = {
      page: page,
      limit: limit,
      sortColumn: sortColumn.current,
      sortType: sortType.current,
      columnFilter: columnFilter
    };

    const data = await FetchPages(query);
    setPages(data);
  };

  const handleOnChange = (key) => {
    setCurrentTab(key);
  };

  ///////////////////////////////
  // for column filter tags
  const onCloseColumnFilterTag = (id) => {
    setColumnFilterTags((prevState) => {
      return prevState.filter(
        (item) => item.id !== id
      );
    });

    setColumnFilter((prevState) => {
      let newData = prevState.filter(
        (item) => {

          return Array.isArray(item.id) ? JSON.stringify(item.id) != id : item.id != id;
        }
      );

      return newData;
    });

    if (!columnFilterTags.length) {
      setIsShowColumnFilter(false);
    }
  };

  const onLoadColumnFilterTags = () => {

    if (columnFilter.length) {
      let filterTags = columnFilter.map((e) => {
        let [label, tagId] = Array(2).fill(e.id);

        if (Array.isArray(e.id)) {
          tagId = JSON.stringify(tagId);
          label = "";

          for (let i = 0, n = e.id.length; i < n; i++) {
            label += `${e.id[i]} `;
          }
        }

        return {
          color: "processing",
          closable: true,
          selection_label: label,
          value: e.value,
          id: tagId,
          onClose: onCloseColumnFilterTag
        }
      })

      setColumnFilterTags(filterTags);
      setIsShowColumnFilter(true);
    } else {
      // if columnFilter is just blank, then remove the tags
      setColumnFilterTags([]);
      setIsShowColumnFilter(false);
    }
  };

  const onClearColumnFilters = () => {
    setColumnFilterTags([]);
    setIsShowColumnFilter(false);
    setColumnFilter([]);

  };

  useEffect(() => {
    GetPostsList();
  }, []);

  useComponentDidUpdate(() => {
    GetPostsList(1, currentCount);
    onLoadColumnFilterTags();
  }, [columnFilter]);


  const onSelectionChange = (selectedRowKey, selectedRow) => {
    setSelectedRow(selectedRow[0]);
    setSelectedRowKey(selectedRowKey[0]);
  };

  const onClearSearcheAndSelection = () => {
    setSelectedRow(null);
    setSelectedRowKey(null);
  };

  const onPaginationChange = (page, limit) => {
    setCurrentPagination(page);
    setCurrentCount(limit);
    GetPostsList(page, limit);
  };

  const handleIsShowPreviewModalStatusPost = () => {
    setIsShowPreviewModalPost(!isShowPreviewModalPost);
  };

  const handleClickViewPostHTML = (html_content) => {
    setPostHTMLcontent(html_content);
    setIsShowPreviewModalPost(true);
  };

  const onClickEditPage = async (row) => {
    setForUpdateIds({ post_id: row.id, meta_id: null });
    createEditForm.setFieldsValue(row);
    setCurrentTab("2");
    setIsForUpdate(true);
  };

  const onClickUpdateModal = () => {
    updateForm.setFieldsValue(selectedRow);
    setIsShowUpdateModal(!isShowUpdateModal);
  };

  const onClickUpdateFinish = async () => {
    const values = await updateForm.validateFields();
    await message.loading("Updating Page Information...", 2);
    const response = await UpdatePost(selectedRowkey, values);
    if (response.status === 200) {
      await message.success("Successfuly Updated Page Information", 2);
      onClickUpdateModal();
      updateForm.resetFields();
      onClearSearcheAndSelection();
      setCurrentPagination(1);
      GetPostsList();
    }

    if (response.status === 400) {
      for (const item of response.data) {
        message.error(item.message, 2);
      }
      return;
    }
  };

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const rolesRestriction = {};

    const CREATE_PAGE = permissions.find((item) => item.name === "CREATE_PAGE");

    rolesRestriction["CREATE_PAGE"] =
      typeof CREATE_PAGE === "undefined" ? 0 : CREATE_PAGE.has_access;

    const UPDATE_PAGE = permissions.find((item) => item.name === "UPDATE_PAGE");

    rolesRestriction["UPDATE_PAGE"] =
      typeof UPDATE_PAGE === "undefined" ? 0 : UPDATE_PAGE.has_access;

    setRolesRestrictions(rolesRestriction);
  }, []);

  const functionalitiesComponent = [
    {
      component_type: "button",
      type: "primary",
      icon: <EditOutlined />,
      _onClick: onClickUpdateModal,
      is_hidden:
        rolesRestriction.UPDATE_PAGE === 0
          ? true
          : selectedRowkey
            ? false
            : true,
      label: "Update Page",
      size: "default",
    },
  ];

  const formInputsComponent = [
    {
      name: "title",
      label: "Title",
      default_value: "",
      configs: {
        type: "input",
        rules: [],
      },
    },
    {
      name: "slug",
      label: "Slug",
      default_value: "",
      configs: {
        type: "input",
        rules: [],
      },
    },
    {
      name: "sub_title",
      label: "Sub Title",
      default_value: "",
      configs: {
        type: "input",
        rules: [],
      },
    },
    {
      name: "status",
      label: "Status",
      default_value: "",
      configs: {
        type: "radio",
        rules: [],
        list_value: [
          { id: "0", name: "Draft" },
          { id: "1", name: "Saved / Not Publish" },
          { id: "2", name: "Publish" },
        ],
      },
    },
  ];

  return (
    <>
      <PageHeader
        title="PAGE MANAGEMENT"
        subTitle="MANAGE PAGES AND CONTENTS"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["CMS", "Page Management"]} />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <Tabs onChange={handleOnChange} activeKey={currentTab}>
          <TabPane tab="View Pages" key="1">
            <ModulesFunctionalitiesComponent
              alignment={"right"}
              functionalitiesComponent={functionalitiesComponent}
              spaceSize={"small"}
            />

            <ModulesSelectAndColumnTagsComponent
              onClearColumnFilters={onClearColumnFilters}
              isShowColumnFilterTags={isShowColumnFilter}
              columnFilterTags={columnFilterTags}
              onClearSearcheAndSelection={onClearSearcheAndSelection}
              isShowSelection={selectedRow ? true : false}
              tags={[
                {
                  color: "processing",
                  selection_label: "Selected Post",
                  value: selectedRow
                    ? `${selectedRow.title} - ${selectedRow.slug}`
                    : null,
                },
              ]}
            />
            <PageListComponent
              data={page}
              onSelectionChange={onSelectionChange}
              onPaginationChange={onPaginationChange}
              handleClickViewPostHTML={handleClickViewPostHTML}
              onClickEditPage={onClickEditPage}
              currentPagination={currentPagination}
              selectedRowKey={selectedRowkey}
              columnFilter={columnFilter}
              setColumnFilter={setColumnFilter}
              onSortChange={onSortChange}
            />
          </TabPane>
          <TabPane tab="Create / Edit Page" key="2">
            <PageCreationComponent
              form={createEditForm}
              isForUpdate={isForUpdate}
              forUpdateIds={forUpdateIds}
              setIsForUpdate={setIsForUpdate}
              setForUpdateIds={setForUpdateIds}
              rolesRestriction={rolesRestriction}
            />
          </TabPane>
        </Tabs>
      </div>
      <ModalwithFormComponent
        title={"Update Post"}
        onOk={onClickUpdateFinish}
        onCancel={onClickUpdateModal}
        visible={isShowUpdateModal}
        destroyOnClose={true}
        okText={"Update Post"}
        cancelText={"Cancel"}
        form={updateForm}
        formLayout={"vertical"}
        formInputsComponent={formInputsComponent}
      />
      <PostHTMLViewComponent
        visible={isShowPreviewModalPost}
        onOk={handleIsShowPreviewModalStatusPost}
        htmlContent={postHTMLcontent}
      />
    </>
  );
};
