export const returnScriptingSchema_ = {
  "@context": "https://schema.org",
  "@type": "Product",
  aggregateRating: {
    "@type": "AggregateRating",
    ratingValue: "3.5",
    reviewCount: "11",
  },
  description:
    "0.7 cubic feet countertop microwave. Has six preset cooking categories and convenience features like Add-A-Minute and Child Lock.",
  name: 'Kenmore White 17" Microwave',
  image: "kenmore-microwave-17in.jpg",
  offers: {
    "@type": "Offer",
    availability: "https://schema.org/InStock",
    price: "55.00",
    priceCurrency: "USD",
  },
  review: [
    {
      "@type": "Review",
      author: "Ellie",
      datePublished: "2011-04-01",
      reviewBody: "The lamp burned out and now I have to replace it.",
      name: "Not a happy camper",
      reviewRating: {
        "@type": "Rating",
        bestRating: "5",
        ratingValue: "1",
        worstRating: "1",
      },
    },
    {
      "@type": "Review",
      author: "Lucas",
      datePublished: "2011-03-25",
      reviewBody:
        "Great microwave for the price. It is small and fits in my apartment.",
      name: "Value purchase",
      reviewRating: {
        "@type": "Rating",
        bestRating: "5",
        ratingValue: "4",
        worstRating: "1",
      },
    },
  ],
};
