import React from "react";
import { Space, Button } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";

export const CarsFunctionalityComponent = (props) => {
  const { handleCreateCarsClick } = props;
  return (
    <>
      <Space size="small">
        <Button
          icon={<PlusOutlined />}
          onClick={handleCreateCarsClick}
          type="primary"
        >
          Create Car
        </Button>
        {/* <Button icon={<EditOutlined />} type="primary">
          Update Car General Information
        </Button>
        <Button icon={<EditOutlined />} type="primary">
          Attach Ads
        </Button> */}
      </Space>
    </>
  );
};
