import { useCreate } from "../../hooks/useCreate";

export const CreateSpecialists = async (payload) => {
  try {
    const data = await useCreate(
      `${process.env.REACT_APP_API_URL}/specialists`,
      payload
    );
    return data;
  } catch (err) {
    if (err.response) {
      return err.response;
    }

    if (err.request) {
      return err.request;
    }

    return err.message;
  }
};
