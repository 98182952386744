import { UpdateFAQsIndex } from "../../api/faq_index/UpdateFAQsIndex";
import { message } from "antd";

const useUpdateFAQsIndex = async (id, payload) => {
  await message.loading("Updating FAQ Index", 2);
  const response = await UpdateFAQsIndex(id, payload);

  if (response.status === 200) {
    await message.success("Successfully Updated FAQ Index", 2);
    return true;
  }

  await message.error("Error updating FAQ Index", 2);
  return false;
};

export default useUpdateFAQsIndex;
