import React from "react";
import { Space, Button, Divider } from "antd";
import { EditOutlined, SaveOutlined } from "@ant-design/icons";

export const ReviewsFunctionalityComponent = (props) => {
  const {
    onClickCreateReviewGroup,
    onClickUpdateReviewGroup,
    onClickDeleteReviewGroup,
    selectedReviewId,
    rolesRestriction,
  } = props;

  const { REVIEW_GROUP_CREATE, REVIEW_GROUP_UPDATE, REVIEW_GROUP_DELETE } =
    rolesRestriction;

  return (
    <>
      <div style={{ textAlign: "right" }}>
        <Space direction="horizontal">
          <Button
            type="primary"
            icon={<SaveOutlined />}
            onClick={onClickCreateReviewGroup}
            hidden={
              REVIEW_GROUP_CREATE === 0 ? true : selectedReviewId ? true : false
            }
          >
            Create Review Group
          </Button>
          <Button
            type="default"
            icon={<EditOutlined />}
            onClick={onClickUpdateReviewGroup}
            hidden={
              REVIEW_GROUP_UPDATE === 0
                ? true
                : !selectedReviewId
                ? true
                : false
            }
          >
            Update Review Group
          </Button>
          <Button
            type="default"
            icon={<EditOutlined />}
            onClick={onClickDeleteReviewGroup}
            hidden={
              REVIEW_GROUP_DELETE === 0
                ? true
                : !selectedReviewId
                ? true
                : false
            }
            danger
          >
            Delete Review Group
          </Button>
        </Space>
        <Divider />
      </div>
    </>
  );
};
