import { UpdateRolesPermissions } from "../../api/admin/UpdateRolesPermission";
import { message } from "antd";

const useUpdateUserAttachedRoles = async (payload) => {
  await message.loading("Updating User Information...", 2);

  const response = await UpdateRolesPermissions(payload);
  console.log(response, "response");

  if (response.status === 200) {
    await message.success("Successfuly Updated Role to Specific User", 2);
    return true;
  }

  await message.error("Error Updating roles of the user", 2);
  return false;
};

export default useUpdateUserAttachedRoles;
