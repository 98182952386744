import React from "react";
import { Form, Input, Row, Col, Checkbox, Space, Button, Divider } from "antd";
import { CheckSquareOutlined, CloseSquareOutlined } from "@ant-design/icons";

export const FAQCreationComponent = (props) => {
  const { form, faqIndex = [], setSelectedFAQIndices } = props;
  return (
    <>
      <Form form={form} layout="vertical">
        <Row>
          <Col span={16}>
            <Form.Item name="name" label="Name">
              <Input />
            </Form.Item>
            {"Selected FAQ Index: Work In Progress"}
          </Col>
          <Col span={8}>
            <div style={{ textAlign: "left", paddingLeft: "10%" }}>
              <Space direction="horizontal">
                <Button
                  type="default"
                  icon={<CheckSquareOutlined />}
                  size="small"
                  disabled={true}
                >
                  Select All
                </Button>
                <Button
                  type="default"
                  size="small"
                  icon={<CloseSquareOutlined />}
                  disabled={true}
                  danger
                >
                  Deselect All
                </Button>
              </Space>
              <Divider />
              <Form.Item name="indices">
                <Space direction={"vertical"} size="small">
                  {faqIndex.map((item, index) => (
                    <Checkbox
                      key={index}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedFAQIndices((prevState) => {
                            return [...prevState, item];
                          });
                        }

                        if (!e.target.checked) {
                          setSelectedFAQIndices((prevState) => {
                            return prevState.filter((prevStateItem) => {
                              return prevStateItem.id !== item.id;
                            });
                          });
                        }
                      }}
                    >
                      {
                        <>
                          <div>{item.question}</div>
                          <div>{item.answer}</div>
                        </>
                      }
                    </Checkbox>
                  ))}
                </Space>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};
