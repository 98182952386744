import React from "react";
import { Table, Pagination, Space, Divider } from "antd";

const { Column } = Table;
export const CMSAssetsTableComponent = (props) => {
  const {
    assets,
    onRowSelectionChange,
    selectedRowKeys = [],
    defaultCurrent = 1,
    total = 0,
    onPaginateChange,
  } = props;

  const { Contents = [], Name, IsTruncated, Prefix } = assets;
  return (
    <>
      <div style={{ textAlign: "center", marginBottom: "2%" }}>
        <div style={{ marginBottom: "2%" }}>
          DO Spaces/Storage Summary Information
        </div>
        <Space direction={"horizontal"} split={<Divider type="vertical" />}>
          <div style={{ textAlign: "center" }}>
            <span>DO/AWS Storage Bucket</span>
            <div>{Name}</div>
          </div>
          <div style={{ textAlign: "center" }}>
            <span>Sub Directory Storage</span>
            <div>{Prefix}</div>
          </div>
          <div style={{ textAlign: "center" }}>
            <span>Has more Files?</span>
            <div>{IsTruncated ? "Yes" : "No"}</div>
          </div>
        </Space>
      </div>
      <Table
        dataSource={Contents}
        size="small"
        rowSelection={{
          type: "radio",
          onChange: onRowSelectionChange,
          selectedRowKeys: [selectedRowKeys],
        }}
        scroll={{ x: 1550 }}
        bordered={true}
        pagination={false}
      >
        <Column title="Id" dataIndex="ETag" key="ETag" />
        <Column title="Asset Url" dataIndex="location" key="location" />
        <Column title="File Size (KB)" dataIndex="Size" key="Size" />
        <Column
          title="Storage Class"
          dataIndex="StorageClass"
          key="StorageClass"
        />
        <Column
          title="Last Modified Date"
          dataIndex="LastModified"
          key="LastModified"
        />
      </Table>
      <div style={{ width: "100%", textAlign: "center", marginTop: "1%" }}>
        <Pagination defaultCurrent={defaultCurrent} total={total} />
      </div>
    </>
  );
};
