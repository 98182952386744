import { useEffect, useState } from "react";
import { Button, Space, Tag } from "antd";
import { RedoOutlined } from "@ant-design/icons";

const columns = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Content",
    dataIndex: "content",
    key: "content",
  },
  {
    title: "Classification",
    dataIndex: "classification",
    key: "classification",
    render: (classification) =>
      classification === "advantages" ? (
        <Tag color="green">Advantages</Tag>
      ) : (
        <Tag color="red">Disadvantages</Tag>
      ),
  },
  {
    title: "Status",
    dataIndex: "is_show",
    key: "is_show",
    render: (is_show) =>
      is_show || is_show === null ? (
        <Tag color="green">Enable</Tag>
      ) : (
        <Tag color="orange">Archived</Tag>
      ),
  },
  {
    title: "Created Date",
    dataIndex: "created_at",
    key: "created_at",
  },
  {
    title: "Last Modified Date",
    dataIndex: "updated_at",
    key: "updated_at",
  },
];

export default function useGetRemarksIndexListColumns(
  onClickRestoreRemarksIndex,
  rolesRestriction
) {
  const [tableColumns, setColumns] = useState(columns);

  const { RESTORE_REMARKS_INDEX } = rolesRestriction;

  useEffect(() => {
    setColumns([
      ...columns,
      {
        dataIndex: "id",
        key: "id",
        title: "Actions",
        fixed: "right",
        width: "100px",
        render: (id, row) => (
          <Space direction="horizontal" size={0}>
            <Button
              type="link"
              icon={<RedoOutlined />}
              size="small"
              onClick={() => onClickRestoreRemarksIndex(id, row)}
              hidden={RESTORE_REMARKS_INDEX === 1 ? false : true}
              disabled={
                row.is_show === 1 || row.is_show === null ? true : false
              }
              danger
            />
          </Space>
        ),
      },
    ]);
  }, [rolesRestriction]);

  return tableColumns;
}
