import React from "react";
import { Modal, Form, Input, Radio } from "antd";

export const MakeCreationModalComponent = (props) => {
  const { onOk, onCancel, form, visible } = props;
  return (
    <>
      <Modal
        title="Create Make"
        onOk={onOk}
        onCancel={onCancel}
        visible={visible}
        destroyOnClose={true}
        closable={true}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            key="name"
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please input name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            key="status"
            name="status"
            label="Status"
            rules={[{ required: true, message: "Please input status" }]}
          >
            <Radio.Group>
              <Radio value={0}>Not Active</Radio>
              <Radio value={1}>Active</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
