import { useState, useEffect } from "react";

const useGetBiddingReportsPermission = () => {
  const [rolesRestrictions, setRolesRestrictions] = useState({});

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("permissions")) || [];

    const getAccess = (permissionName) => {
      const permission = permissions.find((item) => item.name === permissionName);
      return permission ? permission.has_access : 0;
    };

    setRolesRestrictions({
      GENERATE_WINNING_BIDDERS_REPORT: getAccess("GENERATE_WINNING_BIDDERS_REPORT"),
      UPDATE_WINNING_BIDDERS_STATUS: getAccess("UPDATE_WINNING_BIDDERS_STATUS"),
    });
  }, []);

  return rolesRestrictions;
};

export default useGetBiddingReportsPermission;
