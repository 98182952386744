import { useRef } from "react";

const useSort = () => {
  const sortColumn = useRef(null);
  const sortType = useRef("asc");

  const setSortColumn = (value) => {
    sortColumn.current = value;
  };

  const setSortType = (value) => {
    sortType.current = value === "descend" ? "desc" : value === "ascend" ? "asc" : "none";
  };

  return {
    sortColumn,
    sortType,
    setSortColumn,
    setSortType,
  };
};

export default useSort;
