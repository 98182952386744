import { UpdateReviewsGroup } from "../../api/reviews_group/UpdateReviewsGroup";
import { message } from "antd";

const useUpdateReviewsGroup = async (id, payload) => {
  const response = await UpdateReviewsGroup(id, payload);
  if (response.status === 200) {
    await message.success("Updated Reviews Group Successfuly", 2);
    return true;
  }

  await message.error("Error Updating Reviews Group", 2);
  return false;
};

export default useUpdateReviewsGroup;
