import { useCreate } from "../../hooks/useCreate";

export const CreateMake = async (data) => {
  try {
    return await useCreate(`${process.env.REACT_APP_API_URL}/makes`, data);
  } catch (err) {
    if (err.response) return err.response;
    if (err.request) return err.request;
    return err.message;
  }
};
