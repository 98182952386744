import { Form, Input, Button, Row, Col, message, Image } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { FormRules } from "../dist/functions/formRules";
import useLogin from "../hooks/authentication/useLogin";
import "../dist/style/login.css";
import bgImage from "../dist/images/blue_desktop_wallpaper_automart_1080.png";
import iconImage from "../dist/images/automart_logo_square_transparent_256.png";

export const LoginComponent = ({ setPermissions }) => {
  const [form] = Form.useForm();

  const OnFinish = async (values) => {
    const response = await useLogin(values);
    const { status, permissions } = response;

    if (status) {
      window.location.reload();
      setPermissions(permissions);
    } else {
      message.error("Login failed. Please check your credentials.");
    }
  };

  return (
    <Row style={{ minHeight: "100vh" }}>
      {/* Left Column - Login Form (60%) */}
      <Col
        xs={24}
        md={10}
        style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "2rem" }}
      >
        <div style={{ width: "100%", maxWidth: "400px" }}>
          <h2 style={{ textAlign: "center", marginBottom: "1rem" }}>
            <Image
              src={iconImage}
              preview={false}
              style={{
                height: "8rem",
                width: "7rem",
              }}
            />
          </h2>
          <Form
            form={form}
            name="login_form"
            initialValues={{ remember: true }}
            onFinish={OnFinish}
            layout="vertical"
          >
            <Form.Item name="email_address" rules={[FormRules.REQUIRED_EMAIL]}>
              <Input prefix={<UserOutlined />} placeholder="Email" />
            </Form.Item>
            <Form.Item name="password" rules={[FormRules.REQUIRED_PASSWORD]}>
              <Input.Password prefix={<LockOutlined />} placeholder="Password" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Log in
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Col>

      {/* Right Column - Logo (40%) */}
      <Col
        xs={0}
        md={14}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#2a2d4c",
        }}
      >
        <Image
          src={bgImage} // Path to the image in the public folder
          alt="Centered Image"
          preview={false}
        />
      </Col>
    </Row>
  );
};
