import { useUpdate } from "../../hooks/useUpdate";

export const UpdateSpecialistWarehouse = async (payload, specialist_id) => {
  try {
    const data = await useUpdate(
      `${process.env.REACT_APP_API_URL}/specialists/warehouses/${specialist_id}`,
      payload
    );
    return data;
  } catch (err) {
    return null;
  }
};
