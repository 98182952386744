import { useState } from "react";
import {
  EditOutlined,
  InfoCircleOutlined,
  InfoOutlined,
} from "@ant-design/icons";
import { Table, Card, Button, Spin, Image } from "antd";
import { Link } from "react-router-dom";
import useClassName from "../../../hooks/useClassName";
import useColumnFilter from "../../../hooks/useColumnFilter";
import useIsMobile from "../../../hooks/useIsMobile";

const platform = process.env.REACT_APP_PLATFORM;
const vehicle = platform == "automart" ? "car" : "motor";

export default function ViewList({
  setActiveKey,
  loadingList,
  setToEditData,
  paginationProps,
  data,
  onSortChange,
  columnFilter,
  setColumnFilter,
}) {
  const [moreInfo, setMoreInfo] = useState(null);
  const isMobile = useIsMobile();
  const { getColumnFilterProps } = useColumnFilter(
    columnFilter,
    setColumnFilter
  );
  const getClassName = useClassName();

  let columns = [
    {
      title: "Featured Image",
      key: "preview",
      width: 100,
      render: (_text, record) => {
        return (
          <div style={{ width: "100%", textAlign: "center" }}>
            <Image
              style={{ width: "100px" }}
              src={
                record.medias.length > 0
                  ? record.medias[0].url || "/no_image_available.svg"
                  : "/no_image_available.svg"
              }
              preview={false}
              placeholder={
                <Spin
                  size="large"
                  style={{
                    background: "white",
                    width: "100%",
                    height: "100%",
                  }}
                />
              }
            />
          </div>
        );
      },
    },
    {
      title: "Plate Number",
      dataIndex: [vehicle, "plate_number"],
      key: "plate_number",
      width: 100,
      //   sorter: true,
      //   ...getColumnFilterProps([vehicle, "plate_number"]),
    },
    {
      title: "Conduction Sticker",
      dataIndex: [vehicle, "conduction_sticker"],
      key: "conduction_sticker",
      width: 100,
      //   sorter: true,
      //   ...getColumnFilterProps([vehicle, "conduction_sticker"]),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "name",
      width: 150,
      //   sorter: true,
      //   ...getColumnFilterProps("title"),
    },
    {
      title: "Viewing Address",
      dataIndex: ["warehouses", "viewing_address"],
      key: "warehouses",
      width: 150,
      //   sorter: true,
      //   ...getColumnFilterProps(["warehouses", "viewing_address"]),
    },
    {
      title: "Date Created",
      dataIndex: "created_at",
      key: "date",
      width: 150,
      //   sorter: true,
      //   ...getColumnFilterProps("created_at"),
    },
    {
      title: "Actions",
      key: "action",
      fixed: !isMobile ? "right" : false,
      width: 70,
      className: "viewlist-column-actions",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            marginInline: "2%",
          }}
        >
          <Link to={`/ads-editor/${record.slug}`}>
            <Button
              icon={<EditOutlined />}
              type="primary"
              size="small"
              onClick={() => {
                setActiveKey((prevState) => ({
                  ...prevState,
                  parent: "2",
                }));
                setToEditData(record);
              }}
            >
              Edit
            </Button>
          </Link>
          <Button
            icon={<InfoCircleOutlined />}
            size="small"
            onClick={() => setMoreInfo(record)}
          >
            Info
          </Button>
        </div>
      ),
    },
  ];

  columns = moreInfo ? columns.filter((x) => x.title != "Actions") : columns;

  const metaColumns = [
    {
      dataIndex: "key",
      key: "key",
      render: (text, _record) => (
        <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
          {text}
        </div>
      ),
    },
    {
      dataIndex: "value",
      key: "value",
      render: (text, _record) => (
        <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
          {text}
        </div>
      ),
    },
  ];

  const MoreInfoComponent = () => {
    return (
      <div className="progress-dialog-background">
        <Card className="progress-dialog-foreground">
          <h4>{moreInfo.description || "No description available."}</h4>
          <div className="more-info-container">
            {moreInfo.medias.map((media, i) => {
              const metadataEntries =
                media.meta.Metadata &&
                Object.entries(media.meta.Metadata).map((x) => ({
                  key: x[0],
                  value: x[1],
                }));
              return (
                <Card.Grid className={getClassName("more-info-item")} key={i}>
                  <Image
                    width={100}
                    // style={{maxWidth: 100, margin: 15}}
                    src={(media && media.url) || "/no_image_available.svg"}
                    preview={false}
                    placeholder={
                      <Spin
                        size="large"
                        style={{
                          background: "white",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    }
                  />
                  {metadataEntries && (
                    <Table
                      className="more-info-meta-card"
                      size="small"
                      bordered={true}
                      columns={metaColumns}
                      dataSource={metadataEntries}
                      pagination={{ pageSize: 3 }}
                    />
                  )}
                </Card.Grid>
              );
            })}
          </div>
          <Button
            onClick={() => setMoreInfo(null)}
            style={{ alignSelf: "center" }}
          >
            Close
          </Button>
        </Card>
      </div>
    );
  };
  return (
    <div className="viewlist-root">
      {moreInfo && <MoreInfoComponent />}
      <Table
        onChange={(pagination, filters, sorter, action) => {
          if (action.action == "sort") {
            console.log(sorter.field);
            onSortChange(sorter.field, sorter.order);
          }
        }}
        sticky={true}
        loading={loadingList}
        pagination={paginationProps}
        size="small"
        bordered={true}
        columns={columns}
        dataSource={data}
        scroll={{
          x: "max-content",
          y: 500,
          scrollToFirstRowOnChange: false,
        }}
      />
    </div>
  );
}
