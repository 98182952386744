import React from "react";
import { Modal, Table, Tag } from "antd";
import {
  LoadingOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";

const { Column } = Table;
export const BulkUploaderSyncingProcessComponent = ({
  data = [],
  isVisible = false,
  onCancel,
}) => {
  return (
    <>
      <Modal
        visible={isVisible}
        title="Syncing Process"
        footer={null}
        closable={true}
        onCancel={onCancel}
        width={700}
      >
        <div>
          <Table
            size="small"
            bordered={true}
            dataSource={data}
            scroll={{ y: 1550, x: 700 }}
          >
            <Column title="Slug" key="_slug" dataIndex="_slug" align="center" />
            <Column title="Validation Status" key="status" dataIndex="status" />
            <Column
              title="Syncing Status"
              key="sync_status"
              dataIndex="sync_status"
              render={(item) => (
                <Tag
                  icon={
                    item === "Syncing" ? (
                      <LoadingOutlined />
                    ) : item === "Completed" ? (
                      <CheckCircleOutlined />
                    ) : (
                      <ClockCircleOutlined />
                    )
                  }
                  color={
                    item === "Syncing"
                      ? "processing"
                      : item === "Successfuly Updated"
                      ? "green"
                      : item === "Successfuly Inserted"
                      ? "success"
                      : "default"
                  }
                  key={item}
                >
                  {!item ? "For Uploading" : item}
                </Tag>
              )}
            />
          </Table>
        </div>
      </Modal>
    </>
  );
};
