import React from "react";
import { Space, Button } from "antd";
import { FormOutlined, EditOutlined } from "@ant-design/icons";
import useIsMobile from "../../../hooks/useIsMobile";

export const BanksFunctionalityComponent = (props) => {
  const {
    handleCreateButtonModalClicked,
    handleUpdateButtonModalClicked,
    hasSelected = false,
    CREATE_PROVIDER = 0,
    UPDATE_PROVIDER = 0,
  } = props;

  const isMobile = useIsMobile();
  return (
    <>
      <div style={{ width: "100%", textAlign: isMobile ? "center" : "right" }}>
        <Space size="small" direction={isMobile ? "vertical" : "horizontal"}>
          <Button
            hidden={CREATE_PROVIDER === 0 ? true : false}
            type="primary"
            size="default"
            onClick={handleCreateButtonModalClicked}
            icon={<FormOutlined />}
            block={isMobile ? true : false}
          >
            Create Provider
          </Button>
          {hasSelected ? (
            <>
              <Button
                hidden={UPDATE_PROVIDER === 0 ? true : false}
                type="default"
                onClick={handleUpdateButtonModalClicked}
                icon={<EditOutlined />}
                block={isMobile ? true : false}
              >
                Update Provider
              </Button>
            </>
          ) : null}
        </Space>
      </div>
    </>
  );
};
