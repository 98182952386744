import React from "react";
import { ModulesTableComponent } from "../../ModulesTableComponent";
import useGetCMSPostContentsColumns from "../../../hooks/cms_post_contents/useGetCMSPostContentsColumns";

export const CMSPostContentsListComponent = (props) => {
  const {
    data = { data: [], total: 0 },
    onPaginationChange,
    currentPagination,
    onSelection,
    selectedRowKey,
  } = props;

  const cMSPostContentsColumns = useGetCMSPostContentsColumns();

  return (
    <div>
      <ModulesTableComponent
        data={data.data}
        onPaginationChange={onPaginationChange}
        onSelection={onSelection}
        selectedRowKey={selectedRowKey}
        tableColumns={cMSPostContentsColumns || []}
        currentPagination={currentPagination}
      />
    </div>
  );
};
