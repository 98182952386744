import { useCreate } from "../../hooks/useCreate";

export const CreateReviewsGroup = async (payload) => {
  try {
    return await useCreate(
      `${process.env.REACT_APP_API_URL}/reviews-group`,
      payload
    );
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};
