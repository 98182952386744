import React from "react";
import { Form, Modal, Select, Button } from "antd";

const { Option } = Select;
export const SpecialistUpdateModalFormComponent = (props) => {
  const {
    specialistUpdateModal,
    handleModalSpecialistUpdateOnClick,
    HandleUpdateWarehousesOnFinish,
    form,
    warehouses,
    isClickShowMoreDisabled,
    onClickShowMoreList,
  } = props;
  return (
    <>
      <Modal
        title="Update Warehouses"
        visible={specialistUpdateModal}
        centered={true}
        onCancel={handleModalSpecialistUpdateOnClick}
        onOk={HandleUpdateWarehousesOnFinish}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="warehouses"
            label="Select Warehouses(s)"
            rules={[
              {
                required: true,
                message: "Please select warehouses!",
                type: "array",
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Please select warehouses"
              virtual={true}
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <div style={{ textAlign: "center", margin: "1.5%" }}>
                    <Button
                      type="link"
                      size="small"
                      onClick={onClickShowMoreList}
                      disabled={isClickShowMoreDisabled}
                    >
                      Show More
                    </Button>
                  </div>
                </div>
              )}
            >
              {warehouses.map((item, index) => (
                <Option
                  key={index}
                  value={item.id}
                >{`${item.location} - ${item.viewing_address}`}</Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
