import { useCreate } from "../../hooks/useCreate";

export const CreateCar = async (payload) => {
  try {
    const data = await useCreate(
      `${process.env.REACT_APP_API_URL}/cars`,
      payload
    );
    return data;
  } catch (err) {
    return null;
  }
};
