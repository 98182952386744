import {
  createPermissionTransformer,
  createPermissionByNewUserTransformer,
} from "../transformers/permissionTransformer";

const createPermissionsByExistingUser = (data = null) => {
  const permissions_arr = [];

  const { permissions, ...other_data } = data;

  if (data) {
    for (const key in other_data) {
      if (key.includes("PERMISSIONS-")) {
        const has_access = other_data[key];
        const permission_details = key.split("-");
        const permission_index_id = parseInt(permission_details[1]);
        const roles_id = parseInt(data.roles_id);
        const user_id = parseInt(data.user_id);

        const itemPayload = createPermissionTransformer(
          (data = {
            permission_index_id,
            roles_id,
            has_access,
            user_id,
          })
        );

        permissions_arr.push(itemPayload);
      }
    }
  }

  // Returns Array of the Permission to be included when creating permissions under role
  // Or creation of permissions in a bulk
  return permissions_arr;
};

const createPermissionsByNewUser = (data = null) => {
  const permissions = [];

  if (data) {
    var roles_id = parseInt(data.user_attached_roles);

    for (const key in data) {
      if (key.includes("PERMISSIONS")) {
        const permission_details = key.split("-");

        const permission_index_id = parseInt(permission_details[1]);
        const has_access = data[key];

        const itemPayload = createPermissionByNewUserTransformer(
          (data = {
            permission_index_id,
            roles_id,
            has_access,
          })
        );

        permissions.push(itemPayload);
      }
    }
  }

  // Returns Array of the Permission to be included when creating permissions under role
  // Or creation of permissions in a bulk
  return permissions;
};

export { createPermissionsByExistingUser, createPermissionsByNewUser };
