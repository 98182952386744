import { CreatePermissionIndex } from "../../api/admin/CreatePermissionIndex";
import { message } from "antd";

const useCreatePermissionIndex = async (module_id, payload) => {
  await message.loading("Creating Permission Indexes", 2);
  const response = await CreatePermissionIndex(module_id, payload);
  console.log(response, "response");

  if (response.status === 201) {
    await message.success("Successsfuly Created Permission Indexes");
    return true;
  }

  await message.error("Error creating permission index", 2);
  return false;
};

export default useCreatePermissionIndex;
