import React from "react";
import { Table, Tag, Button } from "antd";
import { FormOutlined, EyeOutlined } from "@ant-design/icons";
import useColumnFilter from "../../../hooks/useColumnFilter";

const { Column } = Table;
export const PageListComponent = (props) => {
  const {
    data = { data: [], total: 0 },
    onSelectionChange,
    selectedRowKey,
    onPaginationChange,
    currentPagination,
    handleClickViewPostHTML,
    onClickEditPage,
    onSortChange,
    columnFilter,
    setColumnFilter
  } = props;

  const { getColumnFilterProps, getColumnDynamicFilter, getColumnDynamicBoolFilter } = useColumnFilter(columnFilter, setColumnFilter);

  return (
    <>
      <Table
        size="small"
        dataSource={data.data}
        rowSelection={{
          type: "radio",
          onChange: onSelectionChange,
          selectedRowKeys: [selectedRowKey],
        }}
        onChange={(pagination, filters, sorter, action) => {
          if (action.action == "sort") {
            onSortChange(sorter.field, sorter.order);
          }

        }}
        pagination={{
          onChange: onPaginationChange,
          total: data.total,
          current: currentPagination,
        }}
        bordered
      >
        <Column
          title=""
          render={(row) => (
            <Button
              type="link"
              size="small"
              icon={<FormOutlined />}
              onClick={() => onClickEditPage(row)}
            />
          )}
        />
        <Column title="Id" dataIndex="id" key="id" sorter={true} {...getColumnFilterProps('id')} />
        <Column title="Title" dataIndex="title" key="title" sorter={true} {...getColumnFilterProps('title')} />
        <Column
          sorter={true} {...getColumnDynamicFilter('status_remarks', [
            { value: "Draft", text: "Draft" },
            { value: "Not Publish", text: "Not Published" },
            { value: "Published", text: "Published" }
          ])}
          title="Status"
          key="status_remarks"
          dataIndex="status_remarks"
          render={(status_remarks) =>
            status_remarks === "Draft" ? (
              <Tag color={"default"}>{status_remarks.toUpperCase()}</Tag>
            ) : status_remarks === "Not Publish" ? (
              <Tag color={"blue"}>{status_remarks.toUpperCase()}</Tag>
            ) : (
              <Tag color={"green"}>{status_remarks.toUpperCase()}</Tag>
            )
          }
        />
        <Column
          sorter={true} {...getColumnDynamicBoolFilter('show_in_header_menu', "Visible", "Not Visible")}
          title="Show in Header?"
          dataIndex="show_in_header_menu"
          key="show_in_header_menu"
          render={(show_in_header_menu) =>
            show_in_header_menu == "0" ? (
              <Tag color={"default"}>NOT VISIBLE</Tag>
            ) : (
              <Tag color={"green"}>VISIBLE</Tag>
            )
          }
        />
        <Column
          sorter={true} {...getColumnDynamicBoolFilter('show_in_footer_menu', "Visible", "Not Visible")}
          title="Show in Footer?"
          dataIndex="show_in_footer_menu"
          key="show_in_footer_menu"
          render={(show_in_footer_menu) =>
            show_in_footer_menu == "0" ? (
              <Tag color={"default"}>NOT VISIBLE</Tag>
            ) : (
              <Tag color={"green"}>VISIBLE</Tag>
            )
          }
        />
        <Column
          title="Preview Actions"
          key="actions"
          render={(row) => (
            <Button
              type="primary"
              size="small"
              icon={<EyeOutlined />}
              onClick={() => handleClickViewPostHTML(row.post_content)}
            >
              View Post
            </Button>
          )}
        />
        <Column title="Date Created" dataIndex="created_at" key="created_at" sorter={true}  {...getColumnFilterProps('created_at')} />
      </Table>
    </>
  );
};
