import React from "react";
import { Modal, Form, Select, Input, Button, Divider } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { FormRules } from "../../../dist/functions/formRules";

const { Option } = Select;
const { TextArea } = Input;
const { REQUIRED_REMARKS_CONTENT, REQUIRED_REMARKS_CLASSIFICATION } = FormRules;
export const RemarksIndexCreationComponent = (props) => {
  const { visible, form, onSubmit, onCancel } = props;
  return (
    <>
      <Modal
        visible={visible}
        title="Create Remarks Index"
        width={700}
        onOk={onSubmit}
        onCancel={onCancel}
      >
        <Form form={form} layout="vertical">
          <Form.List name="data">
            {(fields, { add, remove }) => (
              <div style={{ overflowY: "auto", height: "400px" }}>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <>
                    <Form.Item
                      {...restField}
                      label="Content"
                      name={[name, "content"]}
                      fieldKey={[fieldKey, "content"]}
                      rules={[REQUIRED_REMARKS_CONTENT]}
                    >
                      <TextArea rows={9} />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="Classification"
                      name={[name, "classification"]}
                      fieldKey={[fieldKey, "classification"]}
                      rules={[REQUIRED_REMARKS_CLASSIFICATION]}
                    >
                      <Select placeholder="Select Classification">
                        <Option key="advantages" value="advantages">
                          Advantages
                        </Option>
                        <Option key="disadvantages" value="disadvantages">
                          {"Disadvantages (Known Issues)"}
                        </Option>
                      </Select>
                    </Form.Item>
                    <Button
                      onClick={() => remove(name)}
                      icon={<MinusCircleOutlined />}
                      block
                      danger
                    >
                      Remove Field
                    </Button>
                    <Divider />
                  </>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add field
                  </Button>
                </Form.Item>
              </div>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
};
