const createUserTransformer = (payload) => {
  return {
    first_name: payload.first_name,
    middle_name: payload.middle_name,
    last_name: payload.last_name,
    gender: payload.gender,
    interest: payload.interest,
    address: `${payload.address_line_1} ${payload.address_line_2} ${payload.city} ${payload.state_province_region}`,
    city: payload.city,
    state_province_region: payload.state_province_region,
    email: payload.email,
    country_id: 1,
    photo:
      typeof payload.image === "undefined"
        ? null
        : payload.image.file.response.data.path,
    image: typeof payload.image === "undefined" ? null : "s3",
    mobile: payload.mobile,
    phone: payload.phone,
    user_name: payload.user_name,
    password: payload.password,
    user_type: payload.user_type || null,
    website: payload.website || null,
    user_attach_role: payload.user_attach_role,
  };
};

export { createUserTransformer };
