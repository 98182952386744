import React from "react";
import { Tag, Button, Typography, Divider } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import useIsMobile from "../../../hooks/useIsMobile";

const { Title } = Typography;
export const BanksFilterAndSelectionComponent = (props) => {
  const {
    onClearSearchAndSelection,
    selectedBank,
    onClearColumnFilters,
    isShowColumnFilterTags,
    columnFilterTags = [],
  } = props;
  const isMobile = useIsMobile();
  return (
    <>
      {selectedBank || isShowColumnFilterTags ? (
        <div
          style={{ marginTop: "2%", textAlign: isMobile ? "center" : "left" }}
        >
          <Title level={5}>Filters:</Title>

          {selectedBank && (
            <Tag color="processing" visible={!selectedBank.name ? false : true}>
              {`Selected Provider: ${
                !selectedBank.name ? "none" : selectedBank.name
              }`}
            </Tag>
          )}

          {columnFilterTags.length
            ? columnFilterTags.map((item, index) => (
                <Tag
                  key={index}
                  color="processing"
                  visible={!item.value ? false : true}
                  closable={item.closable ? item.closable : false}
                  onClose={item.closable ? () => item.onClose(item.id) : null}
                >
                  {`${item.selection_label}: ${
                    !item.value ? "none" : item.value
                  }`}
                </Tag>
              ))
            : null}

          <Button
            type="link"
            icon={<CloseOutlined />}
            size="small"
            onClick={() => {
              onClearSearchAndSelection();
              onClearColumnFilters();
            }}
          >
            Clear Filters
          </Button>
          <Divider />
        </div>
      ) : null}
    </>
  );
};
