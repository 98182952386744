import { CreateUser } from "../../api/admin/CreateUser";
import { message } from "antd";

const useCreateUser = async (data) => {
  await message.loading("Creating User Information...", 2);

  const response = await CreateUser(data);

  if (response.status === 201) {
    message.success(`Successfuly Created User`, 3);
    return { success: true, step: null };
  } else if (response.status === 400) {
    message.error("Email Already Exists, please choose different email", 5);
    return { success: false, step: 1 };
  }
};

export default useCreateUser;
