import { FetchReviews } from "../../api/reviews/FetchReviews";

const useFetchReviews = async (
  isForTable = true,
  query = { page: 1, limit: 10, filter: "", is_show: null }
) => {
  if (isForTable) {
    const data = await FetchReviews(query);

    for (const item of data.data.data) {
      item["key"] = item.id;
    }

    return data.data;
  }

  const selectOptionQuery = {
    page: 1,
    limit: "ALL",
    filter: "",
    is_show: true,
  };

  const data = await FetchReviews(selectOptionQuery);
  for (const item of data.data.data) {
    item["key"] = item.id;
  }

  return data.data.data;
};

export default useFetchReviews;
