import { useUpdate } from "../../hooks/useUpdate";

export const UpdateUserInformation = async (payload, user_id) => {
  try {
    const data = await useUpdate(
      `${process.env.REACT_APP_API_URL}/users/${user_id}`,
      payload
    );
    return data;
  } catch (err) {
    return null;
  }
};
