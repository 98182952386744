import React from "react";
import { Form, Input, Select, InputNumber } from "antd";

const { Option } = Select;
export const CarsGeneralFormCreationComponent = (props) => {
  const { models } = props;
  return (
    <>
      <Form.Item name="plate_number" label="Plate Number">
        <Input />
      </Form.Item>
      <Form.Item name="conduction_sticker" label="Conduction Sticker">
        <Input />
      </Form.Item>
      <Form.Item name="odometer_reading" label="Odometer Reading">
        <Input />
      </Form.Item>
      <Form.Item name="model_id" label="Model">
        <Select>
          {models.map((item) => (
            <Option
              value={item.id}
            >{`${item.name}-${item.transmission_type} ${item.trim}`}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="year_model" label="Year Model">
        <InputNumber />
      </Form.Item>
    </>
  );
};
