import React from "react";
import { Table } from "antd";
import useColumnFilter from "../../../hooks/useColumnFilter";

const { Column } = Table;
export const BuyNowTableListComponent = (props) => {
  const {
    data,
    tableLoading = false,
    selectedRowKeys,
    onSelectRowChange,
    onPaginationChange,
    platform = "car",
    currentPagination,
    onSortChange,
    columnFilter,
    setColumnFilter,
  } = props;

  const { getColumnFilterProps, getColumnDynamicFilter } = useColumnFilter(
    columnFilter,
    setColumnFilter
  );

  return (
    <>
      <Table
        dataSource={data}
        size="small"
        loading={tableLoading}
        rowSelection={{
          type: "radio",
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectRowChange,
        }}
        scroll={{ x: 2500, y: 500 }}
        pagination={false}
        onChange={(pagination, filters, sorter, action) => {
          if (
            action.action == "sort" &&
            sorter.column &&
            sorter.column.sorter === true
          ) {
            onSortChange(sorter.field, sorter.order);
          }
        }}
        bordered
      >
        <Column
          title="Date Purchased"
          name="created_at"
          dataIndex="created_at"
          sorter={true}
          {...getColumnFilterProps("created_at")}
        />
        <Column
          title="Year Model"
          render={(row) => {
            return row.split(" ")[0];
          }}
          dataIndex={["ads", "title"]}
          sorter={true}
          {...getColumnFilterProps(["ads", "title"])}
        />
        <Column
          title="Title"
          name={["ads", "title"]}
          dataIndex={["ads", "title"]}
          render={(row, record) => {
            let row_ = row;

            const model = row_.split(" ")[0];
            const trans_type = record?.ads?.car?.model?.transmission_type;

            if (trans_type) {
              row_ = `${row} ${trans_type}`;
            }

            if (model) {
              row_ = row_.replace(model, "");
            }

            return row_;
          }}
          sorter={true}
        />
        <Column
          title="Conduction Sticker"
          name={["ads", "car", "conduction_sticker"]}
          dataIndex={["ads", "car", "conduction_sticker"]}
        />
        <Column
          title="Plate Number"
          name={["ads", "slug"]}
          dataIndex={["ads", "slug"]}
          sorter={true}
          {...getColumnFilterProps(["ads", "slug"])}
          render={(value) => {
            return value.toUpperCase();
          }}
        />
        <Column
          title="Unit Color"
          name={["ads", `${platform}`, "color", "specific_name"]}
          dataIndex={["ads", `${platform}`, "color", "specific_name"]}
          sorter={true}
          {...getColumnFilterProps([
            "ads",
            `${platform}`,
            "color",
            "specific_name",
          ])}
        />
        <Column
          title="Winner First Name"
          name={["users", "first_name"]}
          dataIndex={["users", "first_name"]}
          sorter={true}
          {...getColumnFilterProps(["users", "first_name"])}
        />
        <Column
          title="Winner Last Name"
          name={["users", "last_name"]}
          dataIndex={["users", "last_name"]}
          sorter={true}
          {...getColumnFilterProps(["users", "last_name"])}
        />
        <Column
          title="Price"
          name={["ads", "buy_now_price"]}
          dataIndex={["ads", "buy_now_price"]}
          sorter={true}
          {...getColumnFilterProps(["ads", "buy_now_price"])}
        />
        <Column
          title="Payment Method"
          name="payment_method"
          dataIndex="payment_method"
          sorter={true}
          {...getColumnFilterProps("payment_method")}
        />
        <Column
          title="Seller Name"
          render={(row) => {
            if (!row.ads.warehouses) {
              const { user } = row.ads.warehouses.topSpecialist;
              return !user ? "" : `${user.first_name} ${user.last_name}`;
            }
          }}
        />
        <Column
          title="Location"
          name={["ads", "warehouses", "location"]}
          dataIndex={["ads", "warehouses", "location"]}
          sorter={true}
          {...getColumnFilterProps(["ads", "warehouses", "location"])}
        />
        <Column
          title="Buy Now Type"
          name={["ads", "buy_now_type"]}
          dataIndex={["ads", "buy_now_type"]}
          sorter={true}
          {...getColumnFilterProps(["ads", "buy_now_type"])}
        />
        <Column
          title="Winner Phone"
          name={["users", "mobile"]}
          dataIndex={["users", "mobile"]}
          sorter={true}
          {...getColumnFilterProps(["users", "mobile"])}
        />
        <Column
          title="Winner Email"
          name={["users", "email"]}
          dataIndex={["users", "email"]}
          sorter={true}
          {...getColumnFilterProps(["users", "email"])}
        />
        <Column
          title="Referrer"
          name={["users", "referrer"]}
          dataIndex={["users", "referrer"]}
          sorter={true}
          {...getColumnFilterProps(["users", "referrer"])}
        />
      </Table>
    </>
  );
};
