import React, { useState, useEffect, useRef } from "react";
import { PageHeader, Divider, Form, Tabs } from "antd";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { FAQListComponent } from "../sub_components/faq/FAQListComponent";
import { FAQFunctionalityComponent } from "../sub_components/faq/FAQFunctionalityComponent";
import { FAQCreationComponent } from "../sub_components/faq/FAQCreationComponent";
import useFetchFAQs from "../../hooks/faq/useFetchFAQs";
import useFetchFAQsIndex from "../../hooks/faq_index/useFetchFAQsIndex";
import useCreateFAQ from "../../hooks/faq/useCreateFAQ";
import { useComponentDidUpdate } from "../../hooks/useComponentDidUpdate";
import { ModulesSelectAndColumnTagsComponent } from "../ModulesSelectAndColumnTagsComponent";

const initialTableState = {
  total: 0,
  data: [],
};

const { TabPane } = Tabs;

export const FAQManagementComponent = () => {
  const [createFAQform] = Form.useForm();
  const [faqs, setFAQs] = useState(initialTableState);
  const [faqIndices, setfaqIndices] = useState([]);
  const [currentPagination, setCurrentPagination] = useState(1);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedFAQIndices, setSelectedFAQIndices] = useState([]);

  const [isShowColumnFilter, setIsShowColumnFilter] = useState(false);
  const [columnFilterTags, setColumnFilterTags] = useState([]);

  const [currentCount, setCurrentCount] = useState(10);
  const [columnFilter, setColumnFilter] = useState([]);
  const sortColumn = useRef(null);
  const sortType = useRef("asc");

  const setSortColumn = (value) => {
    sortColumn.current = value;
  };

  const setSortType = (value) => {
    if (value === "descend") {
      sortType.current = "desc";
    } else if (value === "ascend") {
      sortType.current = "asc";
    } else {
      sortType.current = "none";
    }
  };

  const onSortChange = async (sortCol, sortType) => {
    setSortColumn(sortCol);
    setSortType(sortType);

    await ExecutFetchFAQHook(currentPagination, currentCount);
  };

  const ExecutFetchFAQHook = async (page = 1, limit = 10) => {
    setCurrentPagination(page);
    const query = {
      page,
      limit,
      sortColumn: sortColumn.current,
      sortType: sortType.current,
      columnFilter: columnFilter,
    };
    const data = await useFetchFAQs(true, query);
    setFAQs(data);
  };

  const ExecuteFetchFAQIndexHook = async () => {
    const query = { page: 1, limit: null, filter: "" };
    const data = await useFetchFAQsIndex(false, query);
    setfaqIndices(data);
  };

  ///////////////////////////////
  // for column filter tags
  const onCloseColumnFilterTag = (id) => {
    setColumnFilterTags((prevState) => {
      return prevState.filter((item) => item.id !== id);
    });

    setColumnFilter((prevState) => {
      let newData = prevState.filter((item) => {
        return Array.isArray(item.id)
          ? JSON.stringify(item.id) != id
          : item.id != id;
      });

      return newData;
    });

    if (!columnFilterTags.length) {
      setIsShowColumnFilter(false);
    }
  };

  const onLoadColumnFilterTags = () => {
    if (columnFilter.length) {
      let filterTags = columnFilter.map((e) => {
        let [label, tagId] = Array(2).fill(e.id);

        if (Array.isArray(e.id)) {
          tagId = JSON.stringify(tagId);
          label = "";

          for (let i = 0, n = e.id.length; i < n; i++) {
            label += `${e.id[i]} `;
          }
        }

        return {
          color: "processing",
          closable: true,
          selection_label: label,
          value: e.value,
          id: tagId,
          onClose: onCloseColumnFilterTag,
        };
      });

      setColumnFilterTags(filterTags);
      setIsShowColumnFilter(true);
    } else {
      // if columnFilter is just blank, then remove the tags
      setColumnFilterTags([]);
      setIsShowColumnFilter(false);
    }
  };

  const onClearColumnFilters = () => {
    setColumnFilterTags([]);
    setIsShowColumnFilter(false);
    setColumnFilter([]);
  };

  useEffect(() => {
    ExecutFetchFAQHook();
    ExecuteFetchFAQIndexHook();
  }, []);

  useComponentDidUpdate(() => {
    ExecutFetchFAQHook(1, currentCount);
    onLoadColumnFilterTags();
  }, [columnFilter]);

  const onSelection = (selectedRowKey, selectedRow) => {
    setSelectedRowKey(selectedRowKey[0]);
    setSelectedRow(selectedRow[0]);
  };

  const onPaginationChange = (page, limit) => {
    setCurrentPagination(page);
    setCurrentCount(limit);
    ExecutFetchFAQHook(page, limit);
  };

  const OnSubmitFAQCreation = async () => {
    const values = await createFAQform.validateFields();
    const payload = {
      group: {
        name: values.name,
        is_show: true,
      },
      indices: selectedFAQIndices,
    };

    const isCreated = await useCreateFAQ(payload);
    if (isCreated) {
      reset();
    }
  };

  const reset = () => {
    createFAQform.resetFields();
    setSelectedFAQIndices([]);
  };

  useEffect(() => {
    console.log(selectedFAQIndices, "selected FAQ indicies");
  }, [selectedFAQIndices]);

  return (
    <>
      <PageHeader
        title="FAQ"
        subTitle="List of Frequesntly Asked Questions Group"
        breadcrumbRender={() => (
          <BreadCrumbsComponent
            breadcrumbsItem={["CMS", "FAQ's", "FAQ Group"]}
          />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <Tabs defaultActiveKey="1">
          <TabPane tab="List of FAQ's" key="1">
            <ModulesSelectAndColumnTagsComponent
              onClearColumnFilters={onClearColumnFilters}
              isShowColumnFilterTags={isShowColumnFilter}
              columnFilterTags={columnFilterTags}
            />
            <FAQListComponent
              data={faqs}
              selectedRowKey={selectedRowKey}
              onSelection={onSelection}
              currentPagination={currentPagination}
              onPaginationChange={onPaginationChange}
              columnFilter={columnFilter}
              setColumnFilter={setColumnFilter}
              onSortChange={onSortChange}
            />
          </TabPane>
          <TabPane tab="Create FAQ's" key="2">
            <FAQFunctionalityComponent
              onSubmitFAQCreation={OnSubmitFAQCreation}
              selectedRowKey={selectedRowKey}
            />
            <FAQCreationComponent
              form={createFAQform}
              setSelectedFAQIndices={setSelectedFAQIndices}
              faqIndex={faqIndices || []}
            />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};
