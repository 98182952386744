import React from "react";
import { Typography, Collapse, Empty, Button, Divider } from "antd";
import {
  CloseCircleOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  InfoCircleOutlined,
  FormOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import useIsMobile from "../../../hooks/useIsMobile";

const { Paragraph, Text } = Typography;
const { Panel } = Collapse;

export const BulkUploaderLogsCollapseComponent = (props) => {
  const { validationLogs, onCreateNewModel, ExecuteRelistSlug, showConfirm } =
    props;

  const isMobile = useIsMobile();

  const returnCreateModelButton = (remarks, rowData) => {
    return remarks.includes("Model not found") ? (
      <>
        <Divider type="vertical" />
        <Button
          type="link"
          size="small"
          icon={<FormOutlined />}
          onClick={() => {
            onCreateNewModel(rowData);
          }}
        >
          Create Model
        </Button>
      </>
    ) : null;
  };

  const executeRelistAllFoundAds = async (validationLogs) => {
    const slugs = [];
    const rawItems = validationLogs.bulk_info.forItemUpdating;
    for (const item of rawItems) {
      const { has_winner_id, _slug } = item;
      if (has_winner_id) {
        const relistBtnStatusSlug = document.getElementById(
          `btn-relist-${_slug}`
        ).disabled;

        if (!relistBtnStatusSlug) {
          slugs.push(_slug);
        }
      }
    }

    if (slugs.length < 1) {
      const message = `All the items were already updated and remove the previous Winner`;
      showConfirm(message);
      return;
    }

    const response = await ExecuteRelistSlug(slugs);
    document.getElementById(`relist-update-all-ads`).disabled = response;
    for (const slug of slugs) {
      document.getElementById(`btn-relist-${slug}`).disabled = true;
    }
    return;
  };

  const executeOnClickRelistButton = async (slug) => {
    const arrSlug = [slug];
    const response = await ExecuteRelistSlug(arrSlug);
    document.getElementById(`btn-relist-${slug}`).disabled = response;
  };

  const returnRelistButton = (has_winner_id, slug) => {
    return has_winner_id ? (
      <>
        <Button
          id={`btn-relist-${slug}`}
          type="default"
          size="small"
          icon={<DeleteOutlined />}
          danger
          disabled={false}
          onClick={() => executeOnClickRelistButton(slug)}
        >
          Remove Previous Winner
        </Button>
        {isMobile ? <Divider type="horizontal" /> : null}
      </>
    ) : null;
  };

  return (
    <>
      <div style={{ textAlign: isMobile ? "center" : "right" }}>
        <Paragraph>
          <InfoCircleOutlined /> {`Total Items : ${validationLogs.totalItems}`}
        </Paragraph>
      </div>
      <Collapse id="errorCollapse" bordered={true} defaultActiveKey={["1"]}>
        <Panel
          style={{ background: "lightgreen", color: "white" }}
          showArrow={false}
          header={
            <div id="itemCreationHeaderCollapse">
              {`${validationLogs.totalItemsForCreation} For creation found`}
              {"  "}
              <CheckCircleOutlined />
            </div>
          }
          key="3"
        >
          {validationLogs.totalItemsForCreation > 0 ? (
            <div style={{ overflowX: "auto", height: 250 }}>
              {validationLogs.bulk_info.forItemCreation.map((item, index) => (
                <ul>
                  <Paragraph key={index} type="success">
                    <CheckCircleOutlined /> {`${item._slug}`}
                  </Paragraph>
                </ul>
              ))}
            </div>
          ) : (
            <Empty />
          )}
        </Panel>
        <Panel
          style={{ background: "lightgreen", color: "white" }}
          showArrow={false}
          header={
            <div id="itemUpdateHeaderCollapse">
              {`${validationLogs.totalItemsForUpdating} For update found`}
              {"  "}
              <CheckCircleOutlined />
            </div>
          }
          key="4"
        >
          {validationLogs.totalItemsForUpdating > 0 ? (
            <div>
              {validationLogs.hasWinnerIdAttachedCount > 0 ? (
                <div style={{ textAlign: isMobile ? "center" : "right" }}>
                  <Button
                    id={"relist-update-all-ads"}
                    type="default"
                    size="small"
                    icon={<DeleteOutlined />}
                    onClick={() => executeRelistAllFoundAds(validationLogs)}
                    danger
                  >
                    Remove All Previous Winners
                  </Button>
                  <Divider />
                </div>
              ) : null}
              <div
                style={{
                  overflowX: "auto",
                  height: 250,
                  textAlign: isMobile ? "center" : "left",
                }}
              >
                {validationLogs.bulk_info.forItemUpdating.map((item, index) => {
                  return (
                    <ul style={{ padding: isMobile ? 0 : null }}>
                      <Paragraph key={index} type="success">
                        <CheckCircleOutlined /> {`${item._slug}`}{" "}
                        <Text type="secondary">
                          {`( plate: ${
                            item?.current_plate || "N/A"
                          } | conduction: ${
                            item?.current_conduction_sticker || "N/A"
                          } )`}
                        </Text>
                        {returnRelistButton(
                          item.has_winner_id,
                          item._slug,
                          item
                        )}
                      </Paragraph>
                    </ul>
                  );
                })}
              </div>
            </div>
          ) : (
            <Empty />
          )}
        </Panel>
        <Panel
          style={{ background: "darkred" }}
          showArrow={false}
          header={
            <div id="errorLogsHeaderCollapse">
              {`${validationLogs.itemErrorsCount} Error found`}
              {"  "}
              <CloseCircleOutlined />
            </div>
          }
          key="1"
        >
          {validationLogs.itemErrorsCount > 0 ? (
            <div style={{ overflowX: "auto", height: 250 }}>
              {validationLogs.bulk_info.rejectedItemInfo.map((item, index) => (
                <ul>
                  <Paragraph key={index} type="danger">
                    <CloseCircleOutlined className="site-result-demo-error-icon" />{" "}
                    {`${item._slug} : ${item.remarks} `}
                    {returnCreateModelButton(item.remarks, item)}
                  </Paragraph>
                </ul>
              ))}
            </div>
          ) : (
            <Empty />
          )}
        </Panel>
        <Panel
          style={{ background: "yellow" }}
          showArrow={false}
          header={
            <div id="duplicateHeaderCollapse">
              {`${validationLogs.duplicateItems} Duplicate found`}
              {"  "}
              <WarningOutlined />
            </div>
          }
          key="2"
        >
          {validationLogs.duplicateItems > 0 ? (
            <div style={{ overflowX: "auto", height: 250 }}>
              {validationLogs.bulk_info.duplicateItemInfo.map((item, index) => (
                <ul>
                  <Paragraph key={index} type="warning">
                    <WarningOutlined /> {`${item._slug} : ${item.remarks}`}
                  </Paragraph>
                </ul>
              ))}
            </div>
          ) : (
            <Empty />
          )}
        </Panel>
      </Collapse>
    </>
  );
};
