import React from "react";
import { Button, Space, Input, Divider } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import useIsMobile from "../../../hooks/useIsMobile";

const { Search } = Input;
export const BulkUploaderFunctionalityComponent = (props) => {
  const {
    onSearch,
    hasErrors,
    OnUploadData,
    hasData = false,
    rolesRestriction = { UPLOAD_BULK_UPLOADER_DATA: 0 },
    fileSyncingStatus,
  } = props;

  const isMobile = useIsMobile();

  return (
    <div style={{ textAlign: isMobile ? "center" : "right", width: "100%" }}>
      <Space size={0} direction={isMobile ? "vertical" : "horizontal"}>
        <Search
          disabled={hasData ? false : true}
          placeholder="input search text"
          onSearch={onSearch}
          enterButton
          allowClear
        />
        <Divider type={isMobile ? "horizontal" : "vertical"} />
        <Button
          hidden={
            rolesRestriction.UPLOAD_BULK_UPLOADER_DATA === 0
              ? true
              : !hasErrors
              ? false
              : true
          }
          disabled={fileSyncingStatus === "Pending" ? false : true}
          type="primary"
          icon={<UploadOutlined />}
          onClick={OnUploadData}
          block={isMobile ? true : false}
        >
          Upload Data
        </Button>
      </Space>
    </div>
  );
};
