import React from "react";
import { Button } from "antd";

export const ReturnModalFooterComponents = (props) => {
  const { handleCancel, handleOk, loading } = props;
  return [
    <Button key="back" onClick={handleCancel}>
      Return
    </Button>,
    <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
      Submit
    </Button>,
  ];
};
