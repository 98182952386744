import { useUpdate } from "../../hooks/useUpdate";

export const UpdateWarehouseSpecialist = async (warehouse_id, payload) => {
  try {
    const data = await useUpdate(
      `${process.env.REACT_APP_API_URL}/warehouses/specialists/${warehouse_id}`,
      payload
    );
    return data;
  } catch (err) {
    return null;
  }
};
