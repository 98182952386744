import React, { useState, useEffect, useRef } from "react";
import { PageHeader, Divider, Form, message } from "antd";
import { BuyNowFunctionalityComponent } from "../sub_components/buy_now/BuyNowFunctionalityComponent";
import { BuyNowTableListComponent } from "../sub_components/buy_now/BuyNowTableListComponent";
import { BuyNowFiltersAndSelectionsComponent } from "../sub_components/buy_now/BuyNowFiltersAndSelectionsComponent";
import { FetchBankList } from "../../api/banks/FetchBankList";
import { FetchBuyNowList } from "../../api/auctions/FetchBuyNowList";
import { FetchWarehouseSpecialistsList } from "../../api/warehouse/FetchWarehouseSpecialistsList";
import { EmailBuyNowStatusUpdate } from "../../api/auctions/EmailBuyNowStatusUpdate";
import { UpdateBuyNowStatus } from "../../api/auctions/UpdateBuyNowStatus";
import { BuyNowSearchFunctionalityComponent } from "../sub_components/buy_now/BuyNowSearchFunctionalityComponent";
import { ModalwithFormComponent } from "../ModalwithFormComponent";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { FormRules } from "../../dist/functions/formRules";
import { returnBuyNowWinnersPermissions } from "../../dist/functions/auctions/auctionsFunctions";
import returnLastWednesdayDate from "../../dist/functions/returnLastWednesdayDate";
import moment from "moment";
import useGetPlatform from "../../hooks/useGetPlatform";
import useIsMobile from "../../hooks/useIsMobile";
import { useComponentDidUpdate } from "../../hooks/useComponentDidUpdate";
import { ModulesSelectAndColumnTagsComponent } from "../ModulesSelectAndColumnTagsComponent";

const initialRolesRestriction = {
  GENERATE_BUY_NOW_WINNERS_REPORT: 0,
  UPDATE_BUY_NOW_WINNERS_STATUS: 0,
};

export const BuyNowWinnersManagementComponent = () => {
  const [form] = Form.useForm();
  const [remarksForm] = Form.useForm();
  const [data, setData] = useState([]);
  const [advisers, setAdvisers] = useState({ data: [], total: 0 });
  const [banks, setBanks] = useState({ data: [], total: 0 });
  const [isFiltered, setIsFiltered] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [selectedAdviser, setSelectedAdviser] = useState(null);
  const [selectedBank, setSelectedBank] = useState(null);
  const [reportDateRange, setReportDateRange] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isShowRemarksModal, setIShowRemarksModal] = useState(false);
  const [isShowBuyNowFiltersModal, setIsShowBuyNowFiltersModal] =
    useState(false);
  const [isUpdateButtonHidden, setIsUpdateButtonHidden] = useState(true);
  const [rolesRestrictions, setRolesRestrictions] = useState(
    initialRolesRestriction
  );
  const [searchedKeyword, setSearchedKeyword] = useState(null);
  const [keywordType, setKeywordType] = useState(null);
  const currentPlatform = useGetPlatform();
  const isMobile = useIsMobile();

  const [isShowColumnFilter, setIsShowColumnFilter] = useState(false);
  const [columnFilterTags, setColumnFilterTags] = useState([]);

  const [currentPagination, setCurrentPagination] = useState(1);
  const [currentCount, setCurrentCount] = useState(10);
  const [columnFilter, setColumnFilter] = useState([]);
  const sortColumn = useRef(null);
  const sortType = useRef("asc");

  const setSortColumn = (value) => {
    sortColumn.current = value;
  };

  const { GENERATE_BUY_NOW_WINNERS_REPORT, UPDATE_BUY_NOW_WINNERS_STATUS } =
    rolesRestrictions;

  const setSortType = (value) => {
    if (value === "descend") {
      sortType.current = "desc";
    } else if (value === "ascend") {
      sortType.current = "asc";
    } else {
      sortType.current = "none";
    }
  };

  const onSortChange = async (sortCol, sortType) => {
    setSortColumn(sortCol);
    setSortType(sortType);

    if (isFiltered) {
      await onSubmitSearch(currentPagination, currentCount);
    } else if (!isFiltered) {
      await FetchBuyNowLists(currentPagination, currentCount);
    }
  };

  const handleseShowRemarksModal = async () => {
    setIShowRemarksModal(!isShowRemarksModal);
  };

  const handlisShowBuyNowFiltersModal = async () => {
    setIsShowBuyNowFiltersModal(!isShowBuyNowFiltersModal);
  };

  const onSubmitSearch = async (page, limit) => {
    const values = await form.validateFields();
    const { adviser_id, bank_id, report_date_range, keyword, type } = values;

    const specialist_id =
      typeof adviser_id !== "undefined" ? adviser_id : "none";

    for (const adviser of advisers.data) {
      if (adviser.id === specialist_id) {
        setSelectedAdviser(`${adviser.first_name} ${adviser.last_name}`);
      }
    }

    const date_from =
      typeof report_date_range === "undefined"
        ? returnLastWednesdayDate()
        : report_date_range[0].format("YYYY-MM-DD hh:mm:ss");

    const date_to =
      typeof report_date_range === "undefined"
        ? moment().format("YYYY-MM-DD hh:mm:ss")
        : report_date_range[1].format("YYYY-MM-DD hh:mm:ss");

    const _bank_id = typeof bank_id !== "undefined" ? bank_id : "none";

    for (const bank of banks.data) {
      if (bank.id === _bank_id) {
        setSelectedBank(bank.name);
      }
    }
    const keyword_type = typeof keyword !== "undefined" ? type : "none";
    const search_value = typeof keyword !== "undefined" ? keyword : "none";

    await FetchBuyNowLists(
      page,
      limit,
      date_from,
      date_to,
      _bank_id,
      specialist_id,
      keyword_type,
      search_value
    );

    setIsFiltered(true);
    handlisShowBuyNowFiltersModal();
    setReportDateRange(`${date_from} - ${date_to}`);

    if (typeof keyword !== "undefined") {
      setKeywordType(keyword_type);
      setSearchedKeyword(search_value);
    }
  };

  const OnUpdateStatus = async () => {
    await message.info("To proceed kindly provide reason for cancelling", 4);
    handleseShowRemarksModal();
  };

  const OnFinishCancelReason = async () => {
    const values = await remarksForm.validateFields();

    const payload = {
      is_active: 0,
      ads_id: selectedRow.ad_id,
      winner_id: null,
      is_closed: 0,
    };

    await message.loading("Updating Status...", 2);
    const data = await UpdateBuyNowStatus(payload, selectedRowKeys[0]);
    if (data.status === 200) {
      onRemoveAllSearches();
      message.success("Successfuly Updated Status");
      await FetchBuyNowLists();
      const email_payload = {
        user_id: parseInt(localStorage.getItem("user_id")),
        title: "BUY NOW STATUS UPDATE NOTIFICATION",
        remarks: values.reason_for_cancellation,
        amount: selectedRow.amount,
      };
      EmailBuyNowStatusUpdate(email_payload);
      remarksForm.resetFields();
      handleseShowRemarksModal();
      return;
    }

    message.error("Error updating status");
    message.info("Please seek help from support");
  };

  const FetchBuyNowLists = async (
    page = 1,
    limit = 10,
    date_from = returnLastWednesdayDate(),
    date_to = moment().format("YYYY-MM-DD hh:mm:ss"),
    bank_id = "none",
    specialist_id = "none",
    keyword_type,
    search_value
  ) => {
    // setCurrentPagination(page);
    setTableLoading(true);
    const query = {
      filter_type: "active",
      page: page,
      limit: limit,
      date_from: date_from,
      date_to: date_to,
      specialist_id: specialist_id,
      bank_id: bank_id,
      keyword_type: keyword_type,
      search_value: search_value,
      sortColumn: sortColumn.current,
      sortType: sortType.current,
      columnFilter: columnFilter,
    };
    const data = await FetchBuyNowList(query);
    // for (const i of data.data) {
    //   i["key"] = i.id;
    // }

    setData(data);
    setTableLoading(false);
    return data;
  };

  ///////////////////////////////
  // for column filter tags
  const onCloseColumnFilterTag = (id) => {
    setColumnFilterTags((prevState) => {
      return prevState.filter((item) => item.id !== id);
    });

    setColumnFilter((prevState) => {
      let newData = prevState.filter((item) => {
        return Array.isArray(item.id)
          ? JSON.stringify(item.id) != id
          : item.id != id;
      });

      return newData;
    });

    if (!columnFilterTags.length) {
      setIsShowColumnFilter(false);
    }
  };

  const onLoadColumnFilterTags = () => {
    if (columnFilter.length) {
      let filterTags = columnFilter.map((e) => {
        let [label, tagId] = Array(2).fill(e.id);

        if (Array.isArray(e.id)) {
          tagId = JSON.stringify(tagId);
          label = "";

          for (let i = 0, n = e.id.length; i < n; i++) {
            label += `${e.id[i]} `;
          }
        }

        return {
          color: "processing",
          closable: true,
          selection_label: label,
          value: e.value,
          id: tagId,
          onClose: onCloseColumnFilterTag,
        };
      });

      setColumnFilterTags(filterTags);
      setIsShowColumnFilter(true);
    } else {
      // if columnFilter is just blank, then remove the tags
      setColumnFilterTags([]);
      setIsShowColumnFilter(false);
    }
  };

  const onClearColumnFilters = () => {
    setColumnFilterTags([]);
    setIsShowColumnFilter(false);
    setColumnFilter([]);
  };

  useEffect(() => {
    const fetchBanksList = async () => {
      const banksData = await FetchBankList({ page: 1, limit: 60 });
      setBanks(banksData);
    };

    fetchBanksList();
  }, []);

  useComponentDidUpdate(() => {
    if (isFiltered) {
      onSubmitSearch(1, currentCount);
    } else if (!isFiltered) {
      FetchBuyNowLists(1, currentCount);
    }

    onLoadColumnFilterTags();
  }, [columnFilter]);

  useEffect(() => {
    const fetchWarehouseSpecilistLists = async () => {
      const advisersData = await FetchWarehouseSpecialistsList({
        page: 1,
        limit: 120,
      });
      setAdvisers(advisersData);
    };

    fetchWarehouseSpecilistLists();
  }, []);

  useEffect(() => {
    FetchBuyNowLists();
  }, []);

  const onRemoveAdviserSearch = () => {
    setSelectedAdviser(null);
    form.setFieldsValue({
      adviser_id: undefined,
    });
    onSubmitSearch();
  };

  const onRemoveBankSearch = () => {
    setSelectedBank(null);
    form.setFieldsValue({
      bank_id: undefined,
    });
    onSubmitSearch();
  };

  const onRemoveSelection = () => {
    setSelectedRowKeys(null);
    setSelectedRow(null);
    setIsUpdateButtonHidden(true);
  };

  const onRemoveAllSearches = () => {
    form.resetFields();
    setSelectedBank(null);
    setSelectedAdviser(null);
    setIsFiltered(false);
    setFilteredData([]);
    setData([]);
    onRemoveSelection();
  };

  const onSelectRowChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRow(selectedRows[0]);
    setIsUpdateButtonHidden(false);
  };

  useEffect(() => {
    const fetchBuyNowWinnerPermissions = async () => {
      const restrictions = await returnBuyNowWinnersPermissions();
      setRolesRestrictions(restrictions);
    };

    fetchBuyNowWinnerPermissions();
  }, []);

  const formInputsComponent = [
    {
      name: "reason_for_cancellation",
      label: "Reason For Cancellation",
      default_value: "",
      configs: {
        type: "text_area",
        rules: [FormRules.REQUIRED_CANCELLATION_REASON],
        text_area_row: 5,
      },
    },
  ];

  return (
    <>
      <PageHeader
        title="BUY NOW WINNERS"
        subTitle="BUY NOW REPORTS MANAGEMENT"
        breadcrumbRender={() => (
          <BreadCrumbsComponent
            breadcrumbsItem={["Auctions", "Buy Now Reports"]}
          />
        )}
      />
      <Divider />
      <div
        style={{
          textAlign: isMobile ? "center" : "left",
        }}
      >
        <BuyNowSearchFunctionalityComponent
          advisers={advisers}
          banks={banks}
          onSubmitSearch={onSubmitSearch}
          form={form}
          visible={isShowBuyNowFiltersModal}
          onCancel={handlisShowBuyNowFiltersModal}
        />
      </div>
      <div style={{ textAlign: isMobile ? "center" : "right" }}>
        <BuyNowFunctionalityComponent
          isUpdateButtonHidden={isUpdateButtonHidden}
          OnUpdateStatus={OnUpdateStatus}
          data={data}
          GENERATE_BUY_NOW_WINNERS_REPORT={GENERATE_BUY_NOW_WINNERS_REPORT}
          UPDATE_BUY_NOW_WINNERS_STATUS={UPDATE_BUY_NOW_WINNERS_STATUS}
          platform={currentPlatform}
          onClickFilterBuyNow={handlisShowBuyNowFiltersModal}
        />
      </div>
      <div
        style={{
          marginTop: "1%",
          display: isFiltered || selectedRow ? null : "none",
          textAlign: isMobile ? "center" : "left",
        }}
      >
        <BuyNowFiltersAndSelectionsComponent
          onRemoveAllSearches={onRemoveAllSearches}
          onRemoveAdviserSearch={onRemoveAdviserSearch}
          onRemoveBankSearch={onRemoveBankSearch}
          onRemoveSelection={onRemoveSelection}
          selectedBank={selectedBank}
          reportDateRange={reportDateRange}
          selectedAdviser={selectedAdviser}
          selectedRow={selectedRow}
          searchKeyword={searchedKeyword}
          keywordType={keywordType}
          onClearColumnFilters={onClearColumnFilters}
          isShowColumnFilterTags={isShowColumnFilter}
          columnFilterTags={columnFilterTags}
        />
      </div>
      <div style={{ marginTop: "2%" }}>
        <BuyNowTableListComponent
          data={data}
          tableLoading={tableLoading}
          isFiltered={isFiltered}
          filteredData={filteredData}
          onSelectRowChange={onSelectRowChange}
          selectedRowKeys={selectedRowKeys}
          platform={currentPlatform}
          columnFilter={columnFilter}
          setColumnFilter={setColumnFilter}
          onSortChange={onSortChange}
        />
      </div>
      <ModalwithFormComponent
        title={"INPUT REASON FOR CANCELLING"}
        form={remarksForm}
        formLayout={"vertical"}
        formInputsComponent={formInputsComponent}
        onOk={OnFinishCancelReason}
        onCancel={handleseShowRemarksModal}
        destroyOnClose={true}
        okText={"Save Remarks"}
        cancelText={"Cancel"}
        visible={isShowRemarksModal}
      />
    </>
  );
};
