import React from "react";
import { Button, Card, Statistic, Table, Tag, Modal, Typography } from "antd";
import { EyeOutlined, SlidersOutlined } from "@ant-design/icons";

const gridStyle = {
  width: "33.3%",
  textAlign: "left",
};

const { confirm } = Modal;
const { Column } = Table;
const { Text, Paragraph } = Typography;
export const ExpandedRowTableComponent = (props) => {
  const { record = {} } = props;

  const showData = (data) => {
    confirm({
      icon: null,
      content: (
        <Paragraph>
          <pre>{data}</pre>
        </Paragraph>
      ),
      width: 570,
      onOk() {},
      onCancel() {},
    });
  };

  return (
    <>
      <div style={{ padding: "2%", background: "white" }}>
        <Card size="small" title="Plugin Summary Information">
          <Card.Grid style={gridStyle}>
            <Statistic
              title="Disabled Plugin(s)"
              prefix={<SlidersOutlined style={{ color: "red" }} />}
              value={record.disabled_plugin_count}
            />
          </Card.Grid>
          <Card.Grid style={gridStyle}>
            <Statistic
              title="Enabled Plugin(s)"
              prefix={<SlidersOutlined style={{ color: "green" }} />}
              value={record.active_plugin_count}
            />
          </Card.Grid>
          <Card.Grid style={gridStyle}>
            <Statistic
              title="Total Plugin(s)"
              prefix={<SlidersOutlined style={{ color: "blue" }} />}
              value={record.total_plugin_count}
            />
          </Card.Grid>
        </Card>
        <br />
        <Table size="small" bordered dataSource={record.plugins}>
          <Column title="Id" dataIndex="id" />
          <Column
            title="Pugin Data"
            dataIndex="plugin_data"
            render={(plugin_data) => (
              <Button
                size="small"
                type="primary"
                icon={<EyeOutlined />}
                onClick={() => showData(plugin_data)}
              >
                Show Data
              </Button>
            )}
          />
          <Column title="Type" dataIndex="plugin_type" />
          <Column
            title="Status"
            dataIndex="is_active"
            render={(status) => (
              <Tag color={status === 1 ? "green" : "red"} key={status}>
                {status === 1 ? "Enabled" : "Disabled"}
              </Tag>
            )}
          />
        </Table>
      </div>
    </>
  );
};
