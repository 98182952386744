import { useState, useEffect } from "react";
import axios from "axios";

const useFetchBarangays = (city_id) => {
  const [barangays, setBarangays] = useState([]);
  const [metaDetails, setMetaDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBarangays = async () => {
      if (!city_id) {
        return;
      }

      setIsLoading(true);

      const response = await axios.get(
        `${process.env.REACT_APP_ASSETMART_ADMIN_API_URL}/barangays`,
        {
          params: { city_id, page: 1, limit: "ALL" },
        }
      );

      if (response.data) {
        const { data, ...meta } = response.data.data;
        setMetaDetails(meta);
        setBarangays(data);
        setIsLoading(false);
        setError(null);
      }
    };

    fetchBarangays();
  }, [city_id]);

  return {
    barangays,
    isBarangayFetchLoading: isLoading,
    error,
    metaDetails,
  };
};

export default useFetchBarangays;
