import React from "react";
import { Row, Result, PageHeader, Divider } from "antd";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import "../../dist/style/dashboard.css";

export const DashboardComponent = () => {
  return (
    <div className="main-component-default-style">
      <PageHeader
        title="DASHBOARD"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["Dashboard"]} />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <br />
        <Divider />
        <Row>
          <div style={{ textAlign: "center", width: "100%" }}>
            <Result
              status="404"
              title="Service Unavailable"
              subTitle="Sorry, this part of the dashboard is under development"
            />
          </div>
        </Row>
      </div>
    </div>
  );
};
