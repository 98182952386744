import { CreateReviews } from "../../api/reviews/CreateReviews";
import { message } from "antd";

const useCreateReviews = async (payload = {}) => {
  await message.loading("Creating Reviews", 2);

  const data = { data: payload };
  const response = await CreateReviews(data);
  if (response.status === 201) {
    await message.success("Successfuly Created Reviews", 2);
    return true;
  }

  await message.error("Error Creating Reviews", 2);
  return false;
};

export default useCreateReviews;
