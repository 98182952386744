import { useFetch } from "../../hooks/useFetch";

export const FetchRemarksIndex = async (
  query = { page: 1, limit: 10, filter: "" }
) => {
  try {
    return await useFetch(
      `${process.env.REACT_APP_API_URL}/remarks-index`,
      query
    );
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};
