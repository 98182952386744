import { useFetch } from "../../hooks/useFetch";

export const FetchBrandsList = async () => {
  try {
    const data = await useFetch(`${process.env.REACT_APP_API_URL}/brands`);
    return data.data;
  } catch (err) {
    return null;
  }
};
