import React from "react";
import {
  Form,
  Space,
  Button,
  Divider,
  Input,
  Rate,
  DatePicker,
  Row,
  Col,
  Upload,
  message,
  Select,
  Image,
  Empty,
  Typography,
} from "antd";
import {
  SaveOutlined,
  InboxOutlined,
  DeleteOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import { FormRules } from "../../../dist/functions/formRules";

const { TextArea } = Input;
const { Dragger } = Upload;
const { Option } = Select;
const { Text } = Typography;
const {
  REQUIRED_FULL_NAME,
  REQUIRED_RATINGS,
  REQUIRED_REVIEW_DATE,
  REQUIRED_COMMENT,
  REQUIRED_CHANNEL,
  REQUIRED_REVIEW_TYPE,
} = FormRules;
export const ReviewsTransactionComponent = (props) => {
  const {
    form,
    onSubmitCreation,
    onSubmitDelete,
    onSubmitUpdate,
    selectedRow,
    currentImage,
    onClickCreateNew,
  } = props;

  const uploadProps = {
    name: "reviews_image",
    action: `${process.env.REACT_APP_API_URL}/uploads/reviews/reviews_image`,
    multiple: false,
    maxCount: 1,
    onChange(info) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <>
      <div style={{ textAlign: "right" }}>
        <Space size="small" direction="horizontal">
          <Button
            type="default"
            icon={<RedoOutlined />}
            onClick={onClickCreateNew}
          >
            Create New
          </Button>
          <Button
            type="primary"
            icon={<SaveOutlined />}
            onClick={onSubmitCreation}
            hidden={!selectedRow ? false : true}
          >
            Create Reviews
          </Button>
          <Button
            type="default"
            icon={<SaveOutlined />}
            hidden={selectedRow ? false : true}
            onClick={onSubmitUpdate}
          >
            Update Reviews
          </Button>
          <Button
            type="default"
            icon={<DeleteOutlined />}
            hidden={selectedRow ? false : true}
            onClick={onSubmitDelete}
            danger
          >
            Delete Review
          </Button>
        </Space>
      </div>
      <Divider />
      <Form form={form} layout="vertical">
        <Row>
          <Col span={14}>
            <Form.Item
              name="name"
              label="Full Name"
              rules={[REQUIRED_FULL_NAME]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="rating" label="Rating" rules={[REQUIRED_RATINGS]}>
              <Rate />
            </Form.Item>
            <Form.Item
              name="reviewed_date"
              label="Reviewed Date"
              rules={[REQUIRED_REVIEW_DATE]}
            >
              <DatePicker format={"YYYY-MM-DD"} />
            </Form.Item>
            <Form.Item
              name="channel"
              label="Channel"
              rules={[REQUIRED_CHANNEL]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="type"
              label="Review Type"
              rules={[REQUIRED_REVIEW_TYPE]}
            >
              <Select placeholder="Select Review Type">
                <Option value="car">Car</Option>
                <Option value="warehouse">Warehouse</Option>
                <Option value="adviser">Adviser</Option>
                <Option value="automart">Automart</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="comment"
              label="Comment"
              rules={[REQUIRED_COMMENT]}
            >
              <TextArea rows={10} />
            </Form.Item>
          </Col>
          <Col span={10}>
            <div style={{ paddingLeft: "2%" }}>
              <Form.Item
                name="image"
                label="Image"
                help={
                  <Text type="danger">
                    Note: Uploading new Image here will replace the current
                    Image if any
                  </Text>
                }
              >
                <Dragger
                  {...uploadProps}
                  data={{
                    has_feature: false,
                    feature_image_index: 0,
                    sub_type: "reviews_image",
                    height: 0,
                    width: 0,
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                </Dragger>
              </Form.Item>
              <div>
                <Divider />
                <div>{"Current Image: "}</div>
                <br />
                {currentImage ? (
                  <>
                    <Image width={450} src={currentImage} />
                  </>
                ) : (
                  <Empty description={"No Image"} />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};
