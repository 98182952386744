import { useDelete } from "../../hooks/useDelete";

export const ClearWarehouseBySpecialist = async (specialist_id) => {
  try {
    const data = await useDelete(
      `${process.env.REACT_APP_API_URL}/specialists/warehouses/${specialist_id}`
    );
    return data;
  } catch (err) {
    return null;
  }
};
