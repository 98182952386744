// import {useFetch} from "../../hooks/useFetch";
import axios from "axios";
export const FetchDropDownList = async (queries) => {
  //fetcher function
  const fetcher = async ({ param, limit }) => {
    console.log(param, "params");

    // const data = (
    //   await axios.get(
    //     `${process.env.REACT_APP_API_URL}/${param}?page=1&limit=${limit}`,
    //     { headers: { Authorization: localStorage.getItem("token") } }
    //   )
    // ).data.data;
    const data = await axios.get(
      `${process.env.REACT_APP_API_URL}/${param}?page=1&limit=${limit}`,
      { headers: { Authorization: localStorage.getItem("token") } }
    );

    if (param === "warehouses") {
      return data.data;
    }

    return data.data.data;
  };
  try {
    //array of unresolved promises
    const promisedDataArray = queries.map((q) => fetcher(q));

    //array of resolved promises
    const resolvedDataArray = await Promise.all(promisedDataArray);

    //reassign to the property names from queries.param
    const reassignedObject = {};
    queries.map(({ param }, i) => {
      reassignedObject[param] = resolvedDataArray[i];
    });

    // console.log("Done Fetching Dropdown List", reassignedObject);
    return reassignedObject;
  } catch (err) {
    console.log("ERROR: ", err.stack);
    return null;
  }
};
