import { useCreate } from "../../hooks/useCreate";

export const CreateOption = async (payload) => {
  try {
    return await useCreate(`${process.env.REACT_APP_API_URL}/options`, payload);
  } catch (err) {
    console.log("ERROR: ", err.stack);
    return null;
  }
};
