// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timeline-content-dynamic-schedules {
  padding-top: 2% !important;
  padding-bottom: 0% !important;
}

.warehouse-google-map-tag:hover {
  cursor: pointer;
}

.warehouse-financing-switch {
  /* width: 100% !important; */
}

.warehouse-financing-switch span.ant-switch-inner {
  color: #258ffb !important;
}

.warehouse-financing-switch .ant-switch-handle:before {
  background: #258ffb;
}

button.warehouse-financing-switch {
  background: none;
  border: 1px solid #258ffb;
  line-height: 23px !important;
  height: 23px !important;
}

.ant-switch.warehouse-financing-switch.ant-switch-checked {
  background: #258ffb;
}
.warehouse-financing-switch.ant-switch-checked span.ant-switch-inner {
  color: #ffffff !important;
}
.warehouse-financing-switch.ant-switch-checked .ant-switch-handle:before {
  background: #ffffff;
}
`, "",{"version":3,"sources":["webpack://./src/dist/style/warehouse_component.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,6BAA6B;AAC/B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,4BAA4B;EAC5B,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".timeline-content-dynamic-schedules {\n  padding-top: 2% !important;\n  padding-bottom: 0% !important;\n}\n\n.warehouse-google-map-tag:hover {\n  cursor: pointer;\n}\n\n.warehouse-financing-switch {\n  /* width: 100% !important; */\n}\n\n.warehouse-financing-switch span.ant-switch-inner {\n  color: #258ffb !important;\n}\n\n.warehouse-financing-switch .ant-switch-handle:before {\n  background: #258ffb;\n}\n\nbutton.warehouse-financing-switch {\n  background: none;\n  border: 1px solid #258ffb;\n  line-height: 23px !important;\n  height: 23px !important;\n}\n\n.ant-switch.warehouse-financing-switch.ant-switch-checked {\n  background: #258ffb;\n}\n.warehouse-financing-switch.ant-switch-checked span.ant-switch-inner {\n  color: #ffffff !important;\n}\n.warehouse-financing-switch.ant-switch-checked .ant-switch-handle:before {\n  background: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
