import { FetchPlatforms } from "../../api/admin/FetchPlatforms";
import { message } from "antd";

const useGetPlatforms = async (query, is_for_table = true) => {
  if (is_for_table) {
    // NOTE: its faster to make message with key and not await it... just destroy it afterwards
    message.loading({ content: 'Fetching Platforms...', key: "useGetPlatform", duration: 10 });

  }

  const data = await FetchPlatforms(query);

  for (const item of data.data.data) {
    item["key"] = item.id;
  }

  if (is_for_table) {
    message.destroy("useGetPlatform");
    message.success("Successfuly Fetch Platforms", 2);
    return data.data;
  }

  return data.data.data;
};

export default useGetPlatforms;
