import React from "react";
import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import { ModulesFunctionalitiesComponent } from "../../ModulesFunctionalitiesComponent";

export const FAQFunctionalityComponent = (props) => {
  const { onSubmitFAQCreation, onClickUpdateFAQButton, selectedRowKey } = props;

  const functionalitiesComponent = [
    {
      component_type: "button",
      type: "primary",
      icon: <EditOutlined />,
      _onClick: onSubmitFAQCreation,
      label: "Create FAQ ",
      size: "default",
    },
    {
      component_type: "button",
      type: "default",
      icon: <SaveOutlined />,
      _onClick: onClickUpdateFAQButton,
      label: "Update FAQ",
      size: "default",
      is_hidden: selectedRowKey ? false : true,
    },
  ];

  return (
    <>
      <ModulesFunctionalitiesComponent
        functionalitiesComponent={functionalitiesComponent}
        alignment="right"
        spaceSize="small"
      />
    </>
  );
};
