import React from "react";
import { Empty, Card } from "antd";
import { UserOutlined } from "@ant-design/icons";

const { Meta } = Card;
export const WarehouseSpecialistsComponent = (props) => {
  const { specialists = [] } = props;
  const gridStyle = {
    width: "20%",
    textAlign: "left",
  };

  return (
    <>
      {specialists.length > 0 ? (
        <Card title="Adviser(s)" bordered={false} size="small">
          {specialists.map((info, index) => (
            <Card.Grid key={index} style={gridStyle}>
              <UserOutlined />{" "}
              {`${info.user.first_name} ${info.user.last_name}`}
              <Meta description={info.user.email} />
            </Card.Grid>
          ))}
        </Card>
      ) : (
        <Empty description="No Advisers assigned for this warehouse" />
      )}
    </>
  );
};
