import React from "react";
import { Form, Modal, Input } from "antd";

const { TextArea } = Input;
export const UpdateUserInformationComponent = (props) => {
  const { form, onSubmit, onCancel, visible } = props;
  return (
    <>
      <Modal
        title="Update User"
        visible={visible}
        onOk={onSubmit}
        onCancel={onCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="first_name" label="Firstname">
            <Input />
          </Form.Item>
          <Form.Item name="middle_name" label="Middlename">
            <Input />
          </Form.Item>
          <Form.Item name="last_name" label="Lastname">
            <Input />
          </Form.Item>
          <Form.Item name="email" label="Email Address">
            <Input />
          </Form.Item>
          <Form.Item name="address" label="Address">
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item name="mobile" label="Mobile">
            <Input />
          </Form.Item>
          <Form.Item name="phone" label="Phone">
            <Input />
          </Form.Item>
          <Form.Item name="referrer" label="Referrer">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
