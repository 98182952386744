import React from "react";
import { Modal, Form, Input, Typography, Divider, Table } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";

const { Title } = Typography;
const { Column } = Table;
export const AddUserToRoleComponent = (props) => {
  const {
    visible = false,
    data = null,
    form,
    onClickFinish,
    onCancel,
    validatedEmailList,
  } = props;

  return (
    <Modal visible={visible} title={"Add User(s) to Role"} onOk={onClickFinish}>
      <Form form={form} layout="vertical">
        {data ? (
          <span level={5}>
            Selected Role: {<Title level={5}>{data.name}</Title>}
            <Divider />
          </span>
        ) : null}
        <Form.Item
          name="email_list"
          label="Email List"
          help={"Please seperate emai list by ',' for the valid structure"}
          rules={[
            {
              required: true,
              message: "Please provide email list to proceed",
            },
          ]}
        >
          <Input.TextArea rows={5} />
        </Form.Item>
      </Form>
      {validatedEmailList.length > 0 ? (
        <>
          <Divider />
          <Table
            size="small"
            dataSource={validatedEmailList}
            bordered={true}
            pagination={false}
          >
            <Column title="Email" dataIndex="email" />
            <Column
              title="Format"
              dataIndex="format_validation"
              render={(row) => (
                <CheckCircleTwoTone
                  twoToneColor={
                    row === "pending"
                      ? "darkgray"
                      : row === "error"
                      ? "red"
                      : "#52c41a"
                  }
                />
              )}
            />
            <Column
              title="Existing Role"
              dataIndex="existing_role_warning"
              render={(row) => (
                <CheckCircleTwoTone
                  twoToneColor={
                    row === "pending"
                      ? "darkgray"
                      : row === "error"
                      ? "red"
                      : "#52c41a"
                  }
                />
              )}
            />
          </Table>
        </>
      ) : null}
    </Modal>
  );
};
