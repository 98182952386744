import React, { useState, useEffect, useRef } from "react";
import { Tabs, PageHeader, Divider, Form, message } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { PostsCreationComponent } from "../sub_components/posts/PostsCreationComponent";
import { PostsListComponent } from "../sub_components/posts/PostsListComponent";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { ModulesFunctionalitiesComponent } from "../ModulesFunctionalitiesComponent";
import { ModalwithFormComponent } from "../ModalwithFormComponent";
import { PostHTMLViewComponent } from "../sub_components/posts/PostHTMLViewComponent";
import { ModulesFilterAndSelectionComponent } from "../ModulesFilterAndSelectionComponent";
import { FetchPosts } from "../../api/cms/FetchPosts";
import { UpdatePost } from "../../api/cms/UpdatePost";
import { SearchPost } from "../../api/cms/SearchPost";
import useFetchPermissionSets from "../../hooks/useFetchPermissions";
import useCMSFormComponents from "../../hooks/cms/useCMSFormComponents";
import useFetchFAQs from "../../hooks/faq/useFetchFAQs";
import useFetchReviewsGroup from "../../hooks/reviews_group/useFetchReviewsGroup";
import useFetchRemarksSubGroup from "../../hooks/remarks_sub_group/useFetchRemarksSubGroup";
import "../../dist/style/posts.css";
import { useComponentDidUpdate } from "../../hooks/useComponentDidUpdate";
import { ModulesSelectAndColumnTagsComponent } from "../ModulesSelectAndColumnTagsComponent";

const { TabPane } = Tabs;

const initialDataSet = {
  isFiltered: false,
  data: { data: [], total: 0 },
};

const initialImageSet = {
  header_image_desktop_size: null,
  header_image_mobile_size: null,
  header_image_tablet_size: null,
  share_image: null,
  thumbnail_image: null,
};

const initialRolesRestriction = {
  UPDATE_POST: 0,
  CREATE_POST: 0,
};

const permissionSet = ["CREATE_POST", "UPDATE_POST"];

const initialForUpdateInformation = { post_id: null, meta_id: null };
export const PostManagementComponent = () => {
  const [updateForm] = Form.useForm();
  const [mediaForm] = Form.useForm();
  const [createOrEditForm] = Form.useForm();
  const [currentTab, setCurrentTab] = useState("1");
  const [posts, setPosts] = useState({ data: [], total: 0 });
  const [postTableLoading, setPostTableLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isForUpdate, setIsForUpdate] = useState(false);
  const [isShowUpdateModal, setIsShowUpdateModal] = useState(false);
  const [isShowPreviewModalPost, setIsShowPreviewModalPost] = useState(false);
  const [isShowPreviewModalHero, setIsShowPreviewModalHero] = useState(false);
  const [postHTMLcontent, setPostHTMLcontent] = useState(null);
  const [herotHTMLcontent, setHerotHTMLcontent] = useState(null);
  const [forUpdateIds, setForUpdateIds] = useState(initialForUpdateInformation);
  const [editorJSHeroContent, setEditorJSHeroContent] = useState(null);
  const [editorJSPostContent, setEditorJSPostContent] = useState(null);
  const [type, setEditorType] = useState("editorjs");
  const [filteredDataSet, setFilteredDataSet] = useState(initialDataSet);
  const [mediaIds, setMediaIds] = useState([]);
  const [tags, setTags] = useState([]);
  const [imageSet, setImageSet] = useState(initialImageSet);
  const [faqs, setFaqs] = useState([]);
  const [selectedFAQs, setSelectedFAQs] = useState([]);
  const [reviewGroups, setReviewGroups] = useState([]);
  const [selectedReviews, setSelectedReviews] = useState([]);
  const [remarksSubGroups, setRemarksSubGroups] = useState([]);
  const [disadvantageRemarksItem, setDisadvantageRemarksItem] = useState([]);
  const [advantageRemarksItem, setAdvantageRemarksItem] = useState([]);

  const [isShowColumnFilter, setIsShowColumnFilter] = useState(false);
  const [columnFilterTags, setColumnFilterTags] = useState([]);

  const [currentCount, setCurrentCount] = useState(10);
  const [columnFilter, setColumnFilter] = useState([]);
  const formInputsComponent = useCMSFormComponents();
  const sortColumn = useRef(null);
  const sortType = useRef("asc");

  const setSortColumn = (value) => {
    sortColumn.current = value;
  };

  const setSortType = (value) => {
    if (value === "descend") {
      sortType.current = "desc";
    } else if (value === "ascend") {
      sortType.current = "asc";
    } else {
      sortType.current = "none";
    }
  };

  const onSortChange = async (sortCol, sortType) => {
    setSortColumn(sortCol);
    setSortType(sortType);

    const { isFiltered, filter_value = null } = filteredDataSet;

    if (!isFiltered) {
      await GetPostsList(currentPage, currentCount);
    } else if (isFiltered) {
      setCurrentPage(currentPage);
      await executeSearch(filter_value, currentPage, currentCount);
    }
  };

  // Query restriction using hook
  // Then hook, to get local storage
  const rolesRestriction = useFetchPermissionSets(
    permissionSet,
    initialRolesRestriction
  );

  const GetPostsList = async (page = 1, limit = 10) => {
    setCurrentPage(page);
    setPostTableLoading(true);
    const query = {
      page: page,
      limit: limit,
      sortColumn: sortColumn.current,
      sortType: sortType.current,
      columnFilter: columnFilter,
    };
    const data = await FetchPosts(query);
    setPosts(data);
    setPostTableLoading(false);
  };

  const onPaginationChange = async (page, limit) => {
    setCurrentPage(page);
    setCurrentCount(limit);

    const { isFiltered, filter_value = null } = filteredDataSet;

    if (!isFiltered) {
      await GetPostsList(page, limit);
    } else if (isFiltered) {
      setCurrentPage(page);
      await executeSearch(filter_value, page, limit);
    }
  };

  const handleOnChange = (key) => {
    setCurrentTab(key);
  };

  ///////////////////////////////
  // for column filter tags
  const onCloseColumnFilterTag = (id) => {
    setColumnFilterTags((prevState) => {
      return prevState.filter((item) => item.id !== id);
    });

    setColumnFilter((prevState) => {
      let newData = prevState.filter((item) => {
        return Array.isArray(item.id)
          ? JSON.stringify(item.id) != id
          : item.id != id;
      });

      return newData;
    });

    if (!columnFilterTags.length) {
      setIsShowColumnFilter(false);
    }
  };

  const onLoadColumnFilterTags = () => {
    if (columnFilter.length) {
      let filterTags = columnFilter.map((e) => {
        let [label, tagId] = Array(2).fill(e.id);

        if (Array.isArray(e.id)) {
          tagId = JSON.stringify(tagId);
          label = "";

          for (let i = 0, n = e.id.length; i < n; i++) {
            label += `${e.id[i]} `;
          }
        }

        return {
          color: "processing",
          closable: true,
          selection_label: label,
          value: e.value,
          id: tagId,
          onClose: onCloseColumnFilterTag,
        };
      });

      setColumnFilterTags(filterTags);
      setIsShowColumnFilter(true);
    } else {
      // if columnFilter is just blank, then remove the tags
      setColumnFilterTags([]);
      setIsShowColumnFilter(false);
    }
  };

  const onClearColumnFilters = () => {
    setColumnFilterTags([]);
    setIsShowColumnFilter(false);
    setColumnFilter([]);
  };

  useEffect(() => {
    GetPostsList(1, 10);
    ExecuteFetchFAQs();
    ExecuteFetchReviewGroups();
    ExecuteFetchRemarksSubGroup();
  }, []);

  useComponentDidUpdate(async () => {
    const { isFiltered, filter_value = null } = filteredDataSet;

    if (!isFiltered) {
      await GetPostsList(1, currentCount);
    } else if (isFiltered) {
      setCurrentPage(1);
      await executeSearch(filter_value, 1, currentCount);
    }

    onLoadColumnFilterTags();
  }, [columnFilter]);

  const onSelectionChange = (selectedRowkey, selectedRow) => {
    setSelectedRowKey(selectedRowkey[0]);
    setSelectedRow(selectedRow[0]);
  };

  const onClickUpdateModal = async () => {
    updateForm.setFieldsValue(selectedRow);
    setIsShowUpdateModal(!isShowUpdateModal);
  };

  const onClickEditPost = async (row) => {
    var ids = {};

    const { id, meta, plugins, tags, main_images = [], remarks } = row;

    for (const img of main_images) {
      const { sub_type } = img;
      if (sub_type === "header_image_tablet") {
        setImageSet((prevState) => {
          return { ...prevState, header_image_tablet_size: img };
        });
      }

      if (sub_type === "header_image_mobile") {
        setImageSet((prevState) => {
          return { ...prevState, header_image_mobile_size: img };
        });
      }

      if (sub_type === "header_image_desktop") {
        setImageSet((prevState) => {
          return { ...prevState, header_image_desktop_size: img };
        });
      }

      if (sub_type === "share_image") {
        setImageSet((prevState) => {
          return { ...prevState, share_image: img };
        });
      }

      if (sub_type === "thumbnail_image") {
        setImageSet((prevState) => {
          return { ...prevState, thumbnail_image: img };
        });
      }
    }

    const current_tags = [];

    for (const tag_pivot of tags) {
      const { tag } = tag_pivot;
      current_tags.push(tag.name);
    }

    for (const remark of remarks) {
      const { classification } = remark;
      if (classification === "advantages") {
        row["advantage_sub_group_id"] =
          remark.remarks_sub_group_pivot?.sub_group_id || null;
      } else if (classification === "disadvantages") {
        row["disadvantage_sub_group_id"] =
          remark.remarks_sub_group_pivot?.sub_group_id || null;
      }
    }

    setTags(current_tags);
    onChangeFAQSelection(row.faq_id);
    onChangeReviewSelection(row.review_id);
    onChangeAdvantageRemarksGroup(row.advantage_sub_group_id);
    onChangeDisadvantageRemarksGroup(row.disadvantage_sub_group_id);

    row["tags"] = "";
    row["plugins"] = plugins;
    row["use_legacy_content"] = row.use_legacy_content !== 0 ? true : false;
    ids = { post_id: id, meta_id: meta?.id || null };

    createOrEditForm.setFieldsValue(row);
    setCurrentTab("2");
    setForUpdateIds(ids);
    setIsForUpdate(true);
  };

  const UpdatePostInformation = async (id, data) => {
    return await UpdatePost(id, data);
  };

  const onClickUpdateFinish = async () => {
    const values = await updateForm.validateFields();
    await message.loading("Updating Post Information...", 2);
    const response = await UpdatePostInformation(selectedRowKey, values);
    if (response.status === 200) {
      await message.success("Successfuly Updated Post Information", 2);
      onClickUpdateModal();
      updateForm.resetFields();
      setSelectedRow(null);
      setSelectedRowKey(null);
      setCurrentPage(1);
      GetPostsList();
    }
  };

  const onClearSearcheAndSelection = () => {
    GetPostsList(1, currentCount);
    setSelectedRow(null);
    setSelectedRowKey(null);
    setFilteredDataSet(initialDataSet);
  };

  const handleIsShowPreviewModalStatusPost = () => {
    setIsShowPreviewModalPost(false);
    setPostHTMLcontent(null);
  };

  const handleIsShowPreviewModalStatusHero = () => {
    setIsShowPreviewModalHero(false);
    setHerotHTMLcontent(null);
  };

  const handleClickViewPostHTML = async (postContent) => {
    setPostHTMLcontent(postContent);
    setIsShowPreviewModalPost(true);
  };

  const handleClickViewHeroHtml = async (heroContent) => {
    setHerotHTMLcontent(heroContent);
    setIsShowPreviewModalHero(true);
  };

  const OnSearchSlug = async (value) => {
    executeSearch(value);
  };

  const executeSearch = async (value, page = 1, limit = 25) => {
    const data = await SearchPost({
      filter_type: "post",
      slug: value,
      page: page,
      limit: limit,
      sortColumn: sortColumn.current,
      sortType: sortType.current,
      columnFilter: columnFilter,
    });

    setFilteredDataSet({ isFiltered: true, filter_value: value, ...data });
  };

  const ExecuteFetchFAQs = async () => {
    const data = await useFetchFAQs(false, {});
    setFaqs(data);
  };

  const onChangeFAQSelection = (value) => {
    if (value) {
      if (faqs.data.length > 0) {
        for (const faq of faqs.data) {
          if (faq.id === +value) {
            setSelectedFAQs(faq.faq_pivot);
            return;
          }
        }
      }

      setSelectedFAQs([]);
      return;
    }

    setSelectedFAQs([]);
  };

  const ExecuteFetchReviewGroups = async () => {
    const data = await useFetchReviewsGroup(false, {});
    setReviewGroups(data);
  };

  const onChangeReviewSelection = (value) => {
    if (value) {
      if (reviewGroups.length > 0) {
        for (const reviewGroup of reviewGroups) {
          if (reviewGroup.id === +value) {
            setSelectedReviews(reviewGroup.reviews_pivot);
            return;
          }
        }
      }

      setSelectedReviews([]);
      return;
    }

    setSelectedReviews([]);
  };

  const ExecuteFetchRemarksSubGroup = async (
    page = 1,
    limit = 10,
    filter = "",
    classification = "",
    isForTable = false
  ) => {
    const query = {
      page: page,
      limit: limit,
      filter: filter,
      classification: classification,
    };
    const data = await useFetchRemarksSubGroup(isForTable, query);
    setRemarksSubGroups(data);
  };

  const onChangeAdvantageRemarksGroup = (value) => {
    if (value) {
      if (remarksSubGroups.length > 0) {
        for (const remarksSubGroup of remarksSubGroups) {
          if (remarksSubGroup.id === +value) {
            setAdvantageRemarksItem(remarksSubGroup.remarks_pivot);
            return;
          }
        }
      }

      setAdvantageRemarksItem([]);
      return;
    }

    setAdvantageRemarksItem([]);
  };

  const onChangeDisadvantageRemarksGroup = (value) => {
    if (value) {
      if (remarksSubGroups.length > 0) {
        for (const remarksSubGroup of remarksSubGroups) {
          if (remarksSubGroup.id === +value) {
            setDisadvantageRemarksItem(remarksSubGroup.remarks_pivot);
            return;
          }
        }
      }

      setDisadvantageRemarksItem([]);
      return;
    }

    setDisadvantageRemarksItem([]);
  };

  const functionalitiesComponent = [
    {
      component_type: "button",
      type: "primary",
      icon: <EditOutlined />,
      _onClick: onClickUpdateModal,
      is_hidden:
        rolesRestriction.UPDATE_POST === 0
          ? true
          : selectedRowKey
          ? false
          : true,
      label: "Update Post",
      size: "default",
    },
    {
      component_type: "search_input",
      _onSearch: OnSearchSlug,
      place_holder: "Search Post By Slug",
    },
  ];

  return (
    <>
      <PageHeader
        title="POSTS"
        subTitle="POST MANAGEMENT"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["CMS", "Post"]} />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <Tabs
          onChange={handleOnChange}
          activeKey={currentTab}
          defaultActiveKey={"1"}
        >
          <TabPane tab="View Posts" key="1">
            <ModulesFunctionalitiesComponent
              alignment={"right"}
              functionalitiesComponent={functionalitiesComponent}
              spaceSize={"small"}
            />

            <ModulesSelectAndColumnTagsComponent
              onClearColumnFilters={onClearColumnFilters}
              isShowColumnFilterTags={isShowColumnFilter}
              columnFilterTags={columnFilterTags}
              onClearSearcheAndSelection={onClearSearcheAndSelection}
              isShowSelection={
                selectedRow || filteredDataSet.isFiltered ? true : false
              }
              tags={[
                {
                  color: "processing",
                  selection_label: "Selected Post",
                  value: selectedRow
                    ? `${selectedRow.title} - ${selectedRow.slug}`
                    : null,
                },
                {
                  color: "processing",
                  selection_label: "Searched Post",
                  value: filteredDataSet.isFiltered
                    ? filteredDataSet.filter_value
                    : null,
                },
              ]}
            />
            <PostsListComponent
              data={posts}
              loading={postTableLoading}
              onPaginationChange={onPaginationChange}
              currentPage={currentPage}
              onSelectionChange={onSelectionChange}
              selectedRowKey={selectedRowKey}
              handleClickViewPostHTML={handleClickViewPostHTML}
              handleClickViewHeroHtml={handleClickViewHeroHtml}
              onClickEditPost={onClickEditPost}
              filteredDataSet={filteredDataSet}
              onSortChange={onSortChange}
              columnFilter={columnFilter}
              setColumnFilter={setColumnFilter}
            />
          </TabPane>
          <TabPane tab="Create / Edit Post" key="2">
            <PostsCreationComponent
              form={createOrEditForm}
              isForUpdate={isForUpdate}
              forUpdateIds={forUpdateIds}
              setForUpdateIds={setForUpdateIds}
              setIsForUpdate={setIsForUpdate}
              rolesRestriction={rolesRestriction}
              editorJSHeroContent={editorJSHeroContent}
              editorJSPostContent={editorJSPostContent}
              setEditorJSHeroContent={setEditorJSHeroContent}
              setEditorJSPostContent={setEditorJSPostContent}
              type={type}
              setMediaIds={setMediaIds}
              mediaIds={mediaIds}
              tags={tags}
              setTags={setTags}
              mediaForm={mediaForm}
              imageSet={imageSet}
              faqs={faqs}
              selectedFAQs={selectedFAQs}
              onChangeFAQSelection={onChangeFAQSelection}
              reviewGroups={reviewGroups}
              selectedReviews={selectedReviews}
              onChangeReviewSelection={onChangeReviewSelection}
              remarksGroup={remarksSubGroups}
              onChangeAdvantageRemarksGroup={onChangeAdvantageRemarksGroup}
              onChangeDisadvantageRemarksGroup={
                onChangeDisadvantageRemarksGroup
              }
              selectedAdvantageRemarks={advantageRemarksItem}
              selectedDisadvantageRemarks={disadvantageRemarksItem}
              setSelectedFAQs={setSelectedFAQs}
              setSelectedReviews={setSelectedReviews}
              setDisadvantageRemarksItem={setDisadvantageRemarksItem}
              setAdvantageRemarksItem={setAdvantageRemarksItem}
            />
          </TabPane>
        </Tabs>
      </div>
      <ModalwithFormComponent
        title={"Update Post"}
        onOk={onClickUpdateFinish}
        onCancel={onClickUpdateModal}
        visible={isShowUpdateModal}
        destroyOnClose={true}
        okText={"Update Post"}
        cancelText={"Cancel"}
        form={updateForm}
        formLayout={"vertical"}
        formInputsComponent={formInputsComponent}
      />
      <PostHTMLViewComponent
        visible={isShowPreviewModalHero}
        onOk={handleIsShowPreviewModalStatusHero}
        htmlContent={herotHTMLcontent}
      />
      <PostHTMLViewComponent
        visible={isShowPreviewModalPost}
        onOk={handleIsShowPreviewModalStatusPost}
        htmlContent={postHTMLcontent}
      />
    </>
  );
};
