import { CreateRemarksIndex } from "../../api/remarks_index/CreateRemarksIndex";
import { message } from "antd";

const useCreateRemarksIndex = async (data) => {
  const response = await CreateRemarksIndex(data);

  if (response.status === 201) {
    await message.success("Successfuly Created Remarks Index", 2);
    return true;
  }

  await message.error("Error creating Remarks Index", 2);
  return false;
};

export default useCreateRemarksIndex;
