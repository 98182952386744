import React from "react";
import { Modal, Form, Select, Divider } from "antd";
import { ReturnModalFooterComponents } from "./ReturnModalFooterComponents";
import { AddNewModulesToPlatformFormComponent } from "./AddNewModulesToPlatformFormComponent";

const { Option } = Select;
export const AddNewModulesPlatformComponent = (props) => {
  const {
    form,
    isVisible,
    handleOk,
    handleCancel,
    loading,
    platforms = [],
  } = props;

  return (
    <>
      <Modal
        visible={isVisible}
        title="Add Modules"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={
          <ReturnModalFooterComponents
            handleCancel={handleCancel}
            handleOk={handleOk}
            loading={loading}
          />
        }
        destroyOnClose={true}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="platform_id" label="Platform">
            <Select placeholder="Select Platform">
              {platforms.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Divider />
          <AddNewModulesToPlatformFormComponent />
        </Form>
      </Modal>
    </>
  );
};
