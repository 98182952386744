// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.plugins-div-layout {
  padding-left: 4%;
  padding-right: 4%;
  padding-bottom: 4%;
  overflow-y: auto;
  height: 1200px;
  border-left: whitesmoke;
  border-left-style: solid;
  border-left-width: 0.2ch;
}

.faq-div-layout {
  overflow-y: auto;
  height: 400px;
}

.btn-div-actions {
  margin-top: 2%;
  text-align: "right";
}

.json-viewer-layout {
  overflow-y: "scroll";
  height: 100px;
}

.feature-image-div-layout {
  margin-top: 4%;
}

.post-create-general-info-form {
  padding-right: 2% !important;
}

.post-creation-function-btns-div {
  width: 100%;
  text-align: right;
  margin-top: 1%;
}

.post-cms-image-uploader .ant-upload.ant-upload-select-picture-card {
  width: 280px !important;
}

element::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
`, "",{"version":3,"sources":["webpack://./src/dist/style/posts.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;EACd,uBAAuB;EACvB,wBAAwB;EACxB,wBAAwB;AAC1B;;AAEA;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;EACpB,aAAa;AACf;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,aAAa,EAAE,kCAAkC;AACnD","sourcesContent":[".plugins-div-layout {\n  padding-left: 4%;\n  padding-right: 4%;\n  padding-bottom: 4%;\n  overflow-y: auto;\n  height: 1200px;\n  border-left: whitesmoke;\n  border-left-style: solid;\n  border-left-width: 0.2ch;\n}\n\n.faq-div-layout {\n  overflow-y: auto;\n  height: 400px;\n}\n\n.btn-div-actions {\n  margin-top: 2%;\n  text-align: \"right\";\n}\n\n.json-viewer-layout {\n  overflow-y: \"scroll\";\n  height: 100px;\n}\n\n.feature-image-div-layout {\n  margin-top: 4%;\n}\n\n.post-create-general-info-form {\n  padding-right: 2% !important;\n}\n\n.post-creation-function-btns-div {\n  width: 100%;\n  text-align: right;\n  margin-top: 1%;\n}\n\n.post-cms-image-uploader .ant-upload.ant-upload-select-picture-card {\n  width: 280px !important;\n}\n\nelement::-webkit-scrollbar {\n  display: none; /* for Chrome, Safari, and Opera */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
