import { useState, useEffect } from "react";

const useFetchIfhasMenuAccess = (permissions = []) => {
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    for (const permission of permissions) {
      if (permission === 1) {
        setHasAccess(true);
        return;
      }
    }
  }, [permissions, setHasAccess]);

  return hasAccess;
};

export default useFetchIfhasMenuAccess;
