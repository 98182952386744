import React, { useEffect, useState } from "react";
import { PageHeader, Form, Col, Tabs, Divider, Button, Space } from "antd";
import { useFetch } from "../../hooks/useFetch";
import { AdsCreationComponent } from "../sub_components/ads/AdsCreationComponent";
import { AdsListComponent } from "../sub_components/ads/AdsListComponent";
import { FetchWarehousesList } from "../../api/warehouse/FetchWarehousesList";
import { FetchCategoriesList } from "../../api/categories/FetchCategoriesList";
import { FetchBrandsList } from "../../api/brands/FetchBrandsList";
import { FetchBrandTypesList } from "../../api/brands/FetchBrandTypesList";
import { FetchModelsList } from "../../api/models/FetchModelsList";
import { QueryCars } from "../../api/cars/QueryCars";
import { CreateAds } from "../../api/ads/CreateAds";
import "../../dist/style/ads.css";

const { TabPane } = Tabs;
export const AdsManagementComponent = () => {
  const [form] = Form.useForm();
  const [currentKey, setCurrentKey] = useState("1");
  const [ads, setAds] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [warehouseList, setWarehouseSource] = useState([]);
  const [categoriesList, setCategoriesSource] = useState([]);
  const [brandList, setBrandSource] = useState([]);
  const [brandTypeList, setBrandTypeSource] = useState([]);
  const [modelList, setModelSource] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [currentTableSpan, setCurrentTableSpan] = useState(24);

  const handleOnChange = (currentKey) => {
    setCurrentKey(currentKey);
  };

  const CreateNewAds = async () => {
    const values = await form.validateFields();

    if (!values.isForBuyNow) {
      values["buy_now_type"] = null;
      values["buy_now_price"] = null;
    }

    const data = await CreateAds(values);
  };

  const FetchCarList = async () => {
    const params = { ad_id: null };

    const data = await QueryCars(params);
  };

  const FetchBrandList = async () => {
    const brands = await FetchBrandsList();
    setBrandSource(brands);
  };

  const FetchBrandTypeList = async () => {
    const brandTypes = await FetchBrandTypesList();
    setBrandTypeSource(brandTypes);
  };

  const FetchModelList = async () => {
    const models = await FetchModelsList();
    setModelSource(models);
  };

  const FetchWarehouseList = async () => {
    const warehouses = await FetchWarehousesList();
    setWarehouseSource(warehouses);
  };

  const FetchCategoryList = async () => {
    const categories = await FetchCategoriesList();
    setCategoriesSource(categories);
  };

  const GetListOfAds = async () => {
    const data = await useFetch(`${process.env.REACT_APP_API_URL}/ads`);
    for (const i of data.data) {
      i["key"] = i.id;
    }
    setAds(data.data);
  };

  useEffect(() => {
    GetListOfAds();
    FetchWarehouseList();
    FetchCategoryList();
    FetchBrandList();
    FetchModelList();
    FetchBrandTypeList();
    FetchCarList();
  }, []);

  const onChange = (selectedRowKeys, selectedRows) => {
    const warehouseList = [];
    for (var warehouse of selectedRows[0].warehouses) {
      warehouseList.push(warehouse.viewing_address);
    }
    setWarehouses(warehouseList);
    setSelectedRow(selectedRows[0]);
    setCurrentTableSpan(18);
  };

  return (
    <>
      <PageHeader title="Ads Management" subTitle="ads" />
      <Divider />
      <Tabs onChange={handleOnChange}>
        <TabPane tab="View Ads" key="1">
          <AdsListComponent
            data={ads}
            warehouseList={warehouses}
            onChange={onChange}
            selectedRow={selectedRow}
            currentTableSpan={currentTableSpan}
          />
        </TabPane>
        <TabPane tab="Create Ads" key="2">
          <AdsCreationComponent
            form={form}
            CreateNewAds={CreateNewAds}
            warehouses={warehouseList}
            categories={categoriesList}
            models={modelList}
            brands={brandList}
            brandTypes={brandTypeList}
          />
        </TabPane>
      </Tabs>
    </>
  );
};
