const permissionsList = [
  "GENERATE_WINNING_BIDDERS_REPORT",
  "UPDATE_WINNING_BIDDERS_STATUS",
  "GENERATE_BUY_NOW_WINNERS_REPORT",
  "UPDATE_BUY_NOW_WINNERS_STATUS",
  "CREATE_USER",
  "UPDATE_USER",
  "GENERATE_USER_REPORT",
  "CHANGE_PASSWORD",
  "ADD_UPDATE_USER_ROLE",
  "UPDATE_DEPOSIT_STATUS",
  "UPDATE_STATUS",
  "CREATE_ADVISER",
  "UPDATE_ADVISER_WAREHOUSE",
  "CLEAR_WAREHOUSE",
  "UPDATE_DEPOSIT_STATUS",
  "DELETE_ADVISER",
  "CREATE_WAREHOUSE",
  "UPDATE_WAREHOUSE",
  "DELETE_WAREHOUSE",
  "UPDATE_SCHEDULE",
  "UPDATE_ADVISERS",
  "GENERATE_WAREHOUSE_REPORT",
  "CREATE_PROVIDER",
  "UPDATE_PROVIDER",
  "CREATE_ROLES_ACCESS",
  "CREATE_PLATFORM",
  "ADD_NEW_MODULES",
  "ADD_PERMISSION_INDEX",
  "UPLOAD_BULK_UPLOADER_DATA",
  "FULL_ACCESS_BULK_UPLOADER",
  "FULL_ACCESS_SETTINGS",
  "CREATE_SETTINGS",
  "UPDATE_SETTINGS",
  "CREATE_MAKES",
  "UPDATE_MAKES",
  "CREATE_MODELS",
  "UPDATE_MODELS",
  "CREATE_VEHICLE_TYPE",
  "UPDATE_VEHICLE_TYPE",
  "CREATE_CITY",
  "UPDATE_CITY",
  "CREATE_COLORS",
  "UPDATE_COLORS",
  "DELETE_COLORS",
  "CREATE_PAGE",
  "UPDATE_PAGE",
  "CREATE_POST",
  "UPDATE_POST",
  "EDIT_ADS",
  "EDIT_GENERAL_INFO",
  "EDIT_IMAGES",
  "CLOSE_AUCTIONS_BY_FILE",
  "CLOSE_AUCTIONS_BY_BANK",
  "REVIEW_INDEX_CREATE",
  "REVIEW_INDEX_UPDATE",
  "REVIEW_INDEX_DELETE",
  "REVIEW_INDEX_RESTORE",
  "REVIEW_GROUP_CREATE",
  "REVIEW_GROUP_UPDATE",
  "REVIEW_GROUP_DELETE",
  "REVIEW_GROUP_RESTORE",
  "CREATE_REMARKS_SUB_GROUP",
  "UPDATE_REMARKS_SUB_GROUP",
  "DELETE_REMARKS_SUB_GROUP",
  "RESTORE_REMARKS_SUB_GROUP",
  "CREATE_REMARKS_INDEX",
  "UPDATE_REMARKS_INDEX",
  "DELETE_REMARKS_INDEX",
  "RESTORE_REMARKS_INDEX",
];

const createDefaultPermissionSet = () => {
  const permissions = {};
  for (const item of permissionsList) {
    permissions[item] = 0;
  }

  return permissions;
};

export { permissionsList, createDefaultPermissionSet };
