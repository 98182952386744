import React, { useCallback, useEffect, useRef, useState } from "react";
import { Divider, PageHeader, Tabs, Form } from "antd";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { RemarksSubGroupListComponent } from "../sub_components/remarks_sub_group/RemarksSubGroupListComponent";
import { RemarksSubGroupTransactionComponent } from "../sub_components/remarks_sub_group/RemarksSubGroupTransactionComponent";
import { RemarksSubGroupFunctionalityComponent } from "../sub_components/remarks_sub_group/RemarksSubGroupFunctionalityComponent";
import { RemarksSubGroupListFunctionalityComponent } from "../sub_components/remarks_sub_group/RemarksSubGroupListFunctionalityComponent";
import { ModulesFilterAndSelectionComponent } from "../ModulesFilterAndSelectionComponent";
import useFetchRemarksSubGroup from "../../hooks/remarks_sub_group/useFetchRemarksSubGroup";
import useFetchRemarksIndex from "../../hooks/remarks_index/useFetchRemarksIndex";
import useCreateRemarksSubGroup from "../../hooks/remarks_sub_group/useCreateRemarksSubGroup";
import useUpdateRemarksSubGroup from "../../hooks/remarks_sub_group/useUpdateRemarksSubGroup";
import useDeleteRemarksSubGroup from "../../hooks/remarks_sub_group/useDeleteRemarksSubGroup";
import { useComponentDidUpdate } from "../../hooks/useComponentDidUpdate";
import useFetchPermissionSets from "../../hooks/useFetchPermissions";

const initialTableState = { data: [], total: 0 };
const { TabPane } = Tabs;

const initialRolesRestriction = {
  CREATE_REMARKS_SUB_GROUP: 0,
  UPDATE_REMARKS_SUB_GROUP: 0,
  DELETE_REMARKS_SUB_GROUP: 0,
  RESTORE_REMARKS_SUB_GROUP: 0,
};

const permissionSet = [
  "CREATE_REMARKS_SUB_GROUP",
  "UPDATE_REMARKS_SUB_GROUP",
  "DELETE_REMARKS_SUB_GROUP",
  "RESTORE_REMARKS_SUB_GROUP",
];

export const RemarksSubGroupManagement = () => {
  const [remarksSubGroupCreateForm] = Form.useForm();
  const [remarksSubGroups, setRemarksSubGroups] = useState(initialTableState);
  const [selectedClassification, setSelectedClassification] = useState(null);
  const [selectedDisadvantageRemarks, setSelectedDisadvantageRemarks] =
    useState([]);
  const [selectedAdvantageRemarks, setSelectedAdvantageRemarks] = useState([]);
  const [advantageRemarks, setAdvantageRemarks] = useState([]);
  const [disadvantageRemarks, setDisadvantageRemarks] = useState([]);
  const [currentTab, setCurrentTab] = useState("1");
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [isShowColumnFilter, setIsShowColumnFilter] = useState(false);
  const [columnFilterTags, setColumnFilterTags] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [currentPagination, setCurrentPagination] = useState(1);
  const [currentCount, setCurrentCount] = useState(10);
  const [columnFilter, setColumnFilter] = useState([]);
  const [remarksGroupSearchParameter, setRemarksGroupSearchParameter] =
    useState({
      page: 1,
      limit: 10,
      filter: "",
      classification: "",
    });
  const [, setState] = useState({});
  const [isShowSelection, setIsShowSelection] = useState(false);
  const [tags, setTags] = useState([]);
  const sortColumn = useRef(null);
  const sortType = useRef("asc");

  // Query restriction using hook
  // Then hook, to get local storage
  const rolesRestriction = useFetchPermissionSets(
    permissionSet,
    initialRolesRestriction
  );

  const setSortColumn = (value) => {
    sortColumn.current = value;
  };

  const setSortType = (value) => {
    if (value === "descend") {
      sortType.current = "desc";
    } else if (value === "ascend") {
      sortType.current = "asc";
    } else {
      sortType.current = "none";
    }
  };

  const onSortChange = async (sortCol, sortType) => {
    setSortColumn(sortCol);
    setSortType(sortType);

    await ExecuteFetchRemarksSubGroup(currentPagination, currentCount);
  };

  const onPaginationChange = (page, limit) => {
    setCurrentPagination(page);
    setCurrentCount(limit);
    ExecuteFetchRemarksSubGroup(page, limit);
  };

  ///////////////////////////////
  // for column filter tags
  const onCloseColumnFilterTag = (id) => {
    setColumnFilterTags((prevState) => {
      return prevState.filter((item) => item.id !== id);
    });

    setColumnFilter((prevState) => {
      let newData = prevState.filter((item) => {
        return Array.isArray(item.id)
          ? JSON.stringify(item.id) != id
          : item.id != id;
      });

      return newData;
    });

    if (!columnFilterTags.length) {
      setIsShowColumnFilter(false);
    }
  };

  const onLoadColumnFilterTags = () => {
    if (columnFilter.length) {
      let filterTags = columnFilter.map((e) => {
        let [label, tagId] = Array(2).fill(e.id);

        if (Array.isArray(e.id)) {
          tagId = JSON.stringify(tagId);
          label = "";

          for (let i = 0, n = e.id.length; i < n; i++) {
            label += `${e.id[i]} `;
          }
        }

        return {
          color: "processing",
          closable: true,
          selection_label: label,
          value: e.value,
          id: tagId,
          onClose: onCloseColumnFilterTag,
        };
      });

      setColumnFilterTags(filterTags);
      setIsShowColumnFilter(true);
    } else {
      // if columnFilter is just blank, then remove the tags
      setColumnFilterTags([]);
      setIsShowColumnFilter(false);
    }
  };

  const onClearColumnFilters = () => {
    setColumnFilterTags([]);
    setIsShowColumnFilter(false);
    setColumnFilter([]);
  };

  useEffect(() => {
    ExecuteFetchRemarksSubGroup();
  }, []);

  useComponentDidUpdate(() => {
    ExecuteFetchRemarksSubGroup(1, currentCount);
    onLoadColumnFilterTags();
  }, [columnFilter]);

  useEffect(() => {
    const { page, limit, filter, classification } = remarksGroupSearchParameter;
    ExecuteFetchRemarksSubGroup(page, limit, filter, classification, true);
  }, [remarksGroupSearchParameter]);

  const ExecuteFetchRemarksByClassification = useCallback(
    async (classification) => {
      if (!classification) return;
      const data = await ExecuteFetchRemarksIndexByClassification(
        1,
        10,
        null,
        classification,
        false
      );

      if (classification === "advantages") {
        setAdvantageRemarks(data);
      }

      if (classification === "disadvantages") {
        setDisadvantageRemarks(data);
      }
    },
    []
  );

  const checkSelectedItemsByRemarksClassification = React.useCallback(
    (selectedRemarks, remarksIndexList, classification) => {
      if (selectedRemarks.length > 0 && remarksIndexList.length > 0) {
        if (classification === "advantages") {
          for (const advantageRemarks of selectedRemarks) {
            for (const advantageRemarksList of remarksIndexList) {
              if (advantageRemarks.id === advantageRemarksList.id) {
                advantageRemarksList["is_checked"] = true;
              }
            }
            setAdvantageRemarks(remarksIndexList);
          }
        } else if (classification === "disadvantages") {
          for (const disadvantageRemarks of selectedRemarks) {
            for (const disadvantageRemarksList of remarksIndexList) {
              if (disadvantageRemarks.id === disadvantageRemarksList.id) {
                disadvantageRemarksList["is_checked"] = true;
              }
            }
            setDisadvantageRemarks(remarksIndexList);
          }
        }
      }

      setState({});
    },
    []
  );

  useEffect(() => {
    checkSelectedItemsByRemarksClassification(
      selectedDisadvantageRemarks,
      disadvantageRemarks,
      "disadvantages"
    );
    checkSelectedItemsByRemarksClassification(
      selectedAdvantageRemarks,
      advantageRemarks,
      "advantages"
    );
  }, [
    advantageRemarks,
    disadvantageRemarks,
    selectedDisadvantageRemarks,
    selectedAdvantageRemarks,
    checkSelectedItemsByRemarksClassification,
  ]);

  const ExecuteFetchRemarksIndexByClassification = async (
    page = 10,
    limit = 10,
    filter = "",
    classification = "",
    isForTable = false
  ) => {
    const query = { page, limit, filter, classification };
    return await useFetchRemarksIndex(isForTable, query);
  };

  useEffect(() => {
    ExecuteFetchRemarksByClassification(selectedClassification);
  }, [selectedClassification, ExecuteFetchRemarksByClassification]);

  const ExecuteFetchRemarksSubGroup = async (
    page = 1,
    limit = 10,
    filter = "",
    classification = "",
    isForTable = true
  ) => {
    setTableLoading(true);
    setCurrentPagination(page);
    const query = {
      page: page,
      limit: limit,
      filter: filter,
      classification: classification,
      sortColumn: sortColumn.current,
      sortType: sortType.current,
      columnFilter: columnFilter,
    };
    const data = await useFetchRemarksSubGroup(isForTable, query);
    setRemarksSubGroups(data);
    setTableLoading(false);
  };

  const onChangeClassification = (value) => {
    setSelectedClassification(value);
  };

  const OnCreateRemarksSubGroup = async () => {
    const values = await remarksSubGroupCreateForm.validateFields();
    const { classification } = values;
    values.indices =
      classification === "advantages"
        ? selectedAdvantageRemarks
        : selectedDisadvantageRemarks;

    const isSuccess = await useCreateRemarksSubGroup(values);
    if (isSuccess) {
      reset();
    }
  };

  const OnUpdateRemarksSubGroup = async () => {
    const values = await remarksSubGroupCreateForm.validateFields();
    const { classification } = values;
    values.indices =
      classification === "advantages"
        ? selectedAdvantageRemarks
        : selectedDisadvantageRemarks;

    const isSuccess = await useUpdateRemarksSubGroup(selectedGroupId, values);
    if (isSuccess) {
      reset();
    }
  };

  const OnClickRestoreRemarksGroup = async (id, row) => {
    const payload = { is_show: true };
    const isSuccess = await useUpdateRemarksSubGroup(id, payload);
    if (isSuccess) {
      reset();
    }
  };

  const OnClickDeleteRemarksGroup = async () => {
    const payload = { is_show: false };
    const isSuccess = await useDeleteRemarksSubGroup(selectedGroupId, payload);
    if (isSuccess) {
      reset();
    }
  };

  const onClickEditRemarksGroup = (id, row) => {
    setCurrentTab("2");
    setSelectedGroupId(id);
    setSelectedClassification(row.classification);

    const remarksIndexList = [];

    for (const item of row.remarks_pivot) {
      remarksIndexList.push(item.remarks_index);
    }

    if (row.classification === "advantages") {
      setSelectedAdvantageRemarks(remarksIndexList);
    }

    if (row.classification === "disadvantages") {
      setSelectedDisadvantageRemarks(remarksIndexList);
    }

    remarksSubGroupCreateForm.setFieldsValue(row);
  };

  const reset = () => {
    remarksSubGroupCreateForm.resetFields();
    ExecuteFetchRemarksSubGroup();
    setCurrentTab("1");
    setSelectedClassification(null);
    setSelectedDisadvantageRemarks([]);
    setSelectedAdvantageRemarks([]);
    setAdvantageRemarks([]);
    setDisadvantageRemarks([]);
    setSelectedGroupId(null);
  };

  const onChangeCurrentTab = (e) => {
    setCurrentTab(e);
  };

  const onChangeSelectionRemarks = (isChecked, classification, item) => {
    if (classification === "advantages") {
      if (isChecked) {
        setSelectedAdvantageRemarks((prevState) => {
          return [...prevState, item];
        });
      } else {
        setSelectedAdvantageRemarks((prevState) => {
          return prevState.filter(
            (prevStateItem) => prevStateItem.id !== item.id
          );
        });
        setAdvantageRemarks((prevState) => {
          const advantageRemarks = prevState;
          advantageRemarks.forEach((advantageRemarksItem) => {
            if (advantageRemarksItem.id === item.id) {
              advantageRemarksItem["is_checked"] = false;
              return;
            }
          });
          return advantageRemarks;
        });
      }
    } else if (classification === "disadvantages") {
      if (isChecked) {
        setSelectedDisadvantageRemarks((prevState) => {
          return [...prevState, item];
        });
      } else {
        setSelectedDisadvantageRemarks((prevState) => {
          return prevState.filter(
            (prevStateItem) => prevStateItem.id !== item.id
          );
        });
        setDisadvantageRemarks((prevState) => {
          const disadvantageRemarks = prevState;
          disadvantageRemarks.forEach((disadvantageRemarks) => {
            if (disadvantageRemarks.id === item.id) {
              disadvantageRemarks["is_checked"] = false;
              return;
            }
          });
          return disadvantageRemarks;
        });
      }
    }
  };

  const onClickUnselectButton = (item, classification) => {
    onChangeSelectionRemarks(false, classification, item);
  };

  const onSearchRemaksSubGroup = (value) => {
    setRemarksGroupSearchParameter((prevState) => {
      return { ...prevState, filter: value };
    });
    setIsShowSelection(true);
    setTags(() => {
      return [
        {
          color: "blue",
          value: value,
          onClose: null,
          closable: false,
          selection_label: "Includes",
        },
      ];
    });
  };

  const resetFiltersAdnSelection = () => {
    setIsShowSelection(false);
    setTags([]);
    reset();
  };

  return (
    <>
      <PageHeader
        title="Pros & Cons Sub Group"
        subTitle="Pros & Cons Sub Group Management"
        breadcrumbRender={() => (
          <BreadCrumbsComponent
            breadcrumbsItem={["Pros & Cons", "Pros & Cons Index Sub Group"]}
          />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <Tabs activeKey={currentTab} onChange={onChangeCurrentTab}>
          <TabPane tab="List of Pros & Cons Sub Group" key="1">
            <RemarksSubGroupListFunctionalityComponent
              onSearchRemarksSubGroup={onSearchRemaksSubGroup}
            />
            <ModulesFilterAndSelectionComponent
              onClearSearcheAndSelection={resetFiltersAdnSelection}
              isShowSelection={isShowSelection}
              tags={tags}
            />
            {/* <ModulesFilterAndSelectionComponent
              onClearColumnFilters={onClearColumnFilters}
              isShowColumnFilterTags={isShowColumnFilter}
              columnFilterTags={columnFilterTags}
            /> */}
            <RemarksSubGroupListComponent
              data={remarksSubGroups}
              currentPagination={currentPagination}
              onPaginationChange={onPaginationChange}
              columnFilter={columnFilter}
              setColumnFilter={setColumnFilter}
              onSortChange={onSortChange}
              tableLoading={tableLoading}
              onClickRestoreRemarksGroup={OnClickRestoreRemarksGroup}
              onClickEditRemarksGroup={onClickEditRemarksGroup}
              rolesRestriction={rolesRestriction}
            />
          </TabPane>
          <TabPane tab="Create/Edit Pros & Cons Sub Group" key="2">
            <RemarksSubGroupFunctionalityComponent
              onCreateRemarksSubGroup={OnCreateRemarksSubGroup}
              onUpdateRemarksSubGroup={OnUpdateRemarksSubGroup}
              onClickDeleteRemarksGroup={OnClickDeleteRemarksGroup}
              selectedGroupId={selectedGroupId}
              rolesRestriction={rolesRestriction}
            />
            {selectedClassification === "advantages" ? (
              <RemarksSubGroupTransactionComponent
                form={remarksSubGroupCreateForm}
                onChangeClassification={onChangeClassification}
                selectedClassification={selectedClassification}
                selectedRemarks={selectedAdvantageRemarks}
                remarksList={advantageRemarks}
                onChangeSelectionRemarks={onChangeSelectionRemarks}
                onClickUnselectButton={onClickUnselectButton}
              />
            ) : (
              <RemarksSubGroupTransactionComponent
                form={remarksSubGroupCreateForm}
                onChangeClassification={onChangeClassification}
                selectedClassification={selectedClassification}
                selectedRemarks={selectedDisadvantageRemarks}
                remarksList={disadvantageRemarks}
                onChangeSelectionRemarks={onChangeSelectionRemarks}
                onClickUnselectButton={onClickUnselectButton}
              />
            )}
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};
