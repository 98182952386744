import { useFetch } from "../../hooks/useFetch";

export const FetchBrandTypesList = async () => {
  try {
    const data = await useFetch(`${process.env.REACT_APP_API_URL}/brand-types`);
    return data.data;
  } catch (err) {
    return null;
  }
};
