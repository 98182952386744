import axios from "axios";

export const useCreate = async (url, payload = {}) => {
  const header = {
    headers: { Authorization: localStorage.getItem("token") },
    timeout: 120000,
  };

  const dataRes = await axios.post(url, payload, header);
  return dataRes;
};
