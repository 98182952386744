import React from "react";
import { Modal, Form, Select } from "antd";
import { SelectOptionFooterExtraButtonComponent } from "../../SelectOptionFooterExtraButtonComponent";

const { Option } = Select;
export const WarehouseUpdateAdvisersComponent = ({
  specialists,
  updateAdvisersModalVisibility,
  handleUpdateAdviserModalVisibility,
  HandleUpdateWarehouseAdvisers,
  form,
  onClickShowMoreAdviser,
}) => {
  return (
    <>
      <Modal
        visible={updateAdvisersModalVisibility}
        title="Update Advisers"
        okText="Update"
        cancelText="Cancel"
        onCancel={handleUpdateAdviserModalVisibility}
        centered={true}
        width={500}
        destroyOnClose={true}
        onOk={HandleUpdateWarehouseAdvisers}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="specialists"
            label="Select Adviser(s)"
            rules={[
              {
                required: true,
                message: "Please select advisers!",
                type: "array",
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Please select advisers"
              dropdownRender={(menu) => (
                <SelectOptionFooterExtraButtonComponent
                  key="show_more"
                  menu={menu}
                  onClickShowMoreList={onClickShowMoreAdviser}
                  isClickShowMoreDisabled={
                    specialists.data.length < specialists.total ? false : true
                  }
                />
              )}
            >
              {specialists?.data?.map((item, index) => (
                <Option
                  key={index}
                  value={item.id}
                >{`${item.first_name} ${item.last_name}`}</Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
