import React from "react";
import {
  Tabs,
  Form,
  Card,
  Empty,
  Radio,
  Divider,
  Typography,
  Checkbox,
} from "antd";

const { TabPane } = Tabs;
const { Meta } = Card;
const { Text } = Typography;

export const UserPermissionsFormComponent = ({
  isHidden,
  roles = [],
  isDefaultChecked,
  onChangeDefaultPermission,
}) => {
  return (
    <div style={{ display: isHidden ? "none" : null }}>
      {roles.length === 0 ? (
        <Empty
          description={"No Roles Selected or the User type is set to User"}
        />
      ) : (
        <>
          <h4 style={{ textAlign: "center" }}>
            User Roles & Permissions{"   "}
            <div>
              <Text type="warning">
                Note: Permissions will refresh, after choosing roles
              </Text>
            </div>
          </h4>
          <Divider />
          <Form.Item
            key="is_default"
            fieldKey="is_default"
            name="is_default"
            label="Use Default Permissions?"
          >
            <Checkbox
              checked={isDefaultChecked}
              defaultChecked={true}
              onChange={onChangeDefaultPermission}
            />
          </Form.Item>
          <Tabs tabPosition={"top"}>
            {roles.map((item, index) => (
              <TabPane
                key={index}
                tab={item.name}
                forceRender={true}
                style={{ overflowY: "auto", height: 300, width: "100%" }}
              >
                {item.permissions.map((row, index) => (
                  <Card
                    key={index}
                    bordered={false}
                    size="small"
                    style={{ textAlign: "center" }}
                  >
                    <Meta
                      key={index}
                      title={row.name}
                      description={row.description}
                    />
                    <br />
                    <Form.Item
                      name={`PERMISSIONS-${row.id}-${row.module_id}`}
                      key={`${row.id}-${item.id}-${row.name}`}
                      initialValue={row.access_rights.has_access}
                    >
                      <Radio.Group
                        key={row.id}
                        name={row.id}
                        defaultValue={row.access_rights.has_access}
                        disabled={isDefaultChecked}
                      >
                        <Radio value={1}>ENABLED</Radio>
                        <Radio value={0}>DISABLED</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Card>
                ))}
              </TabPane>
            ))}
          </Tabs>
        </>
      )}
    </div>
  );
};
