import {
  createPermissionsByExistingUser,
  createPermissionsByNewUser,
} from "./permissions";
import {
  createRoleTransformer,
  createRoleTransformerByNewUser,
} from "../transformers/userAttachedRoleTransformer";

const createUserAttachedRoles = (data) => {
  const permissionArr = createPermissionsByExistingUser(data);
  data["permissions"] = permissionArr;
  return createRoleTransformer(data);
};

const createUserAttachedRoleByNewUser = (data) => {
  const permissionArr = createPermissionsByNewUser(data);
  data["permissions"] = permissionArr;
  return createRoleTransformerByNewUser(data);
};

const updateUserAttachedRolesByExistingUser = (data) => {
  const permissionArr = createPermissionsByExistingUser(data);
  data["permissions"] = permissionArr;
  return createRoleTransformer(data);
};

export {
  createUserAttachedRoles,
  createUserAttachedRoleByNewUser,
  updateUserAttachedRolesByExistingUser,
};
