import { FetchPermissionIndex } from "../../api/admin/FetchPermissionIndex";
import { message } from "antd";

const useGetPermissionIndexList = async (query) => {
  // NOTE: its faster to make message with key and not await it... just destroy it afterwards
  message.loading({ content: 'Fetching Permission Index...', key: "useGetPermissionIndexList", duration: 10 });
  // await message.loading("Fetching Permission Index", 2);

  const data = await FetchPermissionIndex(query);

  for (const item of data.data.data) {
    item["key"] = item.id;
  }

  message.destroy("useGetPermissionIndexList");
  message.success("Successfuly Permission Index", 2);
  // message.success("Successfuly Retrieved Permission Index List", 2);
  return data.data;
};

export default useGetPermissionIndexList;
