import React from "react";
import { Table } from "antd";
import useColumnFilter from "../../../hooks/useColumnFilter";

const { Column } = Table;
export const ModulesListComponent = (props) => {
  const {
    data = { data: [], total: 0 },
    onPaginationChange,
    currentPagination,
    onSortChange,
    columnFilter,
    setColumnFilter
  } = props;

  const { getColumnFilterProps, getColumnBooleanFilter } = useColumnFilter(columnFilter, setColumnFilter);

  return (
    <Table
      size="small"
      dataSource={data.data}
      pagination={{
        total: data.total,
        onChange: onPaginationChange,
        current: currentPagination,
      }}
      onChange={(pagination, filters, sorter, action) => {
        if (action.action == "sort") {
          onSortChange(sorter.field, sorter.order);
        }

      }}
      bordered={true}
      scroll={{ x: 1200 }}
    >
      <Column name="id" dataIndex="id" key="id" title="Id" sorter={true} {...getColumnFilterProps('id')} />
      <Column name="name" dataIndex="name" key="name" title="Module Name" sorter={true} {...getColumnFilterProps('name')} />
      <Column name="description" dataIndex="description" key="description" title="Description" sorter={true} {...getColumnFilterProps('description')} />
      <Column
        name="platform"
        dataIndex={["platform", "name"]}
        key={["platform", "name"]}
        title="Platform"
        sorter={true} {...getColumnFilterProps(["platform", "name"])}
      />
      <Column name="created_at" dataIndex="created_at" key="created_at" title="Date Created" sorter={true} {...getColumnFilterProps('created_at')} />
    </Table>
  );
};
