import { useState, useCallback } from "react";

const useManipulateFilterTags = () => {
  const [columnFilterTags, setColumnFilterTags] = useState([]);
  const [shouldDoFetch, setShouldDoFetch] = useState(true);
  const [columnFilter, setColumnFilter] = useState([]);
  const [columnFilterFrontend, setColumnFilterFrontend] = useState([]);
  const [isShowColumnFilter, setIsShowColumnFilter] = useState(false);

  // Helper function to filter out a tag by ID
  const filterOutTag = useCallback((prevState, id) => {
    return prevState.filter((item) =>
      Array.isArray(item.id) ? JSON.stringify(item.id) !== id : item.id !== id
    );
  }, []);

  // Function to remove a filter tag
  const onCloseColumnFilterTag = useCallback(
    (id) => {
      setColumnFilterTags((prevState) => prevState.filter((item) => item.id !== id));
      setColumnFilter((prevState) => filterOutTag(prevState, id));
      setColumnFilterFrontend((prevState) => filterOutTag(prevState, id));

      if (columnFilterTags.length === 1) {
        setIsShowColumnFilter(false);
        setShouldDoFetch(true);
      }
    },
    [filterOutTag, columnFilterTags]
  );

  // Function to generate filter tags based on current filters
  const generateFilterTags = useCallback(() => {
    const allFilters = [...columnFilter, ...columnFilterFrontend];

    const filterTags = allFilters.map((e) => {
      const isArrayId = Array.isArray(e.id);
      const tagId = isArrayId ? JSON.stringify(e.id) : e.id;
      const label = isArrayId ? e.id.join(" ") : e.id;

      return {
        color: "processing",
        closable: true,
        selection_label: label,
        value: e.value,
        id: tagId,
        onClose: () => onCloseColumnFilterTag(tagId),
      };
    });

    setColumnFilterTags(filterTags);
    setIsShowColumnFilter(filterTags.length > 0);
  }, [columnFilter, columnFilterFrontend, onCloseColumnFilterTag]);

  // Function to clear all filters
  const onClearColumnFilters = useCallback(() => {
    setColumnFilter([]);
    setColumnFilterFrontend([]);
    setColumnFilterTags([]);
    setIsShowColumnFilter(false);
    setShouldDoFetch(false);
  }, []);

  return {
    columnFilterTags,
    shouldDoFetch,
    isShowColumnFilter,
    columnFilter,
    columnFilterFrontend,
    onCloseColumnFilterTag,
    generateFilterTags,
    onClearColumnFilters,
    setColumnFilter,
    setColumnFilterFrontend,
    setShouldDoFetch,
  };
};

export default useManipulateFilterTags;
