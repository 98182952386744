import React, { useRef } from "react";
import { Form, Input, Select } from "antd";
import JoditEditor from "jodit-react";

export const PageCreationGeneralInformationComponent = (props) => {
  const ref = useRef(null);
  const { content, onBlur } = props;
  return (
    <>
      <Form.Item name="title" label="Title">
        <Input placeholder="Input Title" />
      </Form.Item>
      <Form.Item name="slug" label="Slug">
        <Input placeholder="Input Slug" />
      </Form.Item>
      <Form.Item name="post_content" label="Content">
        <JoditEditor ref={ref} value={content} onBlur={onBlur} tabIndex={1} />
      </Form.Item>
    </>
  );
};
