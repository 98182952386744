import React, { Fragment, useState, useEffect } from "react";
import { Modal, Form, Col, Select, Row, Checkbox } from "antd";
import { RolesModalFooterComponent } from "./RolesModalFooterComponent";
import { UserPermissionsFormComponent } from "./UserPermissionsFormComponent";
import useGetRolesWithPermissions from "../../../hooks/roles/useGetRoleswithPermissions";
import { createUserAttachedRoles } from "../../../dist/dao/userAttachedRoles";
import useCreateUserAttachedRoles from "../../../hooks/user_attached_roles/useCreateUserAttachedRoles";

const { Option } = Select;
export const AddingRolesFormComponent = (props) => {
  const {
    isModalVisible,
    roles,
    handleModalVisibleOnChange,
    selectedUser,
    userId,
    GetUserList,
  } = props;

  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [targetKeys, setTargetKeys] = useState([]);
  const [permissions, setPermissionRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [isDefaultChecked, setIsDefaultChecked] = useState(true);

  useEffect(() => {
    setTargetKeys(selectedUser);
  }, [selectedUser]);

  const onChangeSelection = (value) => {
    setSelectedRole(value);
  };

  useEffect(() => {
    const fetch = async () => {
      if (selectedRole) {
        const params = {
          role_id: selectedRole,
          uar_id: null,
          user_id: null,
        };
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const permissions = await useGetRolesWithPermissions(
          "for_creation",
          params
        );
        setPermissionRoles(permissions.data);
      }
    };

    fetch();
  }, [selectedRole]);

  const HandleModalOkClicked = async () => {
    const values = await form.validateFields();

    values.is_default = isDefaultChecked;
    values.user_id = userId;

    const payload = createUserAttachedRoles(values);
    const isSuccess = await useCreateUserAttachedRoles(payload);

    if (isSuccess) {
      handleModalVisibleOnChange();
      GetUserList();
    }
  };

  const onChangeCheckedValue = (e) => {
    setIsDefaultChecked(e.target.checked);
  };

  return (
    <Fragment>
      <Modal
        forceRender
        visible={isModalVisible}
        title="Add Roles"
        onOk={HandleModalOkClicked}
        onCancel={handleModalVisibleOnChange}
        width={700}
        footer={
          <RolesModalFooterComponent
            HandleModalOkClicked={HandleModalOkClicked}
            confirmLoading={confirmLoading}
            handleModalVisibleOnChange={handleModalVisibleOnChange}
          />
        }
        destroyOnClose={true}
        confirmLoading={confirmLoading}
        maskClosable={false}
        keyboard={true}
        centered
      >
        <Form form={form} layout="vertical">
          <Row>
            <Col span={24}>
              <div className="no-step-modal-body-div-layout">
                <Form.Item
                  key="roles_id"
                  fieldKey="roles_id"
                  name="roles_id"
                  label={"Roles"}
                >
                  <Select
                    placeholder="Select Role"
                    onChange={onChangeSelection}
                  >
                    {roles.map((item, index) => (
                      <Option key={index} value={item.key}>
                        {item.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                {/* <Form.Item
                  key="is_default"
                  fieldKey="is_default"
                  name="is_default"
                  label="Use Default Permissions?"
                >
                  <Checkbox
                    checked={isDefaultChecked}
                    defaultChecked={true}
                    onChange={onChangeCheckedValue}
                  />
                </Form.Item> */}
                <UserPermissionsFormComponent
                  isHidden={false}
                  roles={permissions}
                  type={"Add"}
                  isDefaultChecked={isDefaultChecked}
                  onChangeDefaultPermission={onChangeCheckedValue}
                />
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Fragment>
  );
};
