import { useCreate } from "../../hooks/useCreate";

export const InsertBulkUploaderData = async (data) => {
  try {
    return await useCreate(
      `${process.env.REACT_APP_API_URL}/bulk-uploader/insert`,
      data
    );
  } catch (err) {
    console.log("ERROR: ", err.stack);
    return null;
  }
};
