import "antd/dist/antd.css";
import "./App.css";
import React, { useEffect } from "react";
import { RoutesComponent } from "./components/RoutesComponent";

function App() {
  return (
    <>
      <RoutesComponent />
    </>
  );
}

export default App;
