// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-layer-div {
  margin-bottom: 2%;
  text-align: right;
}
`, "",{"version":3,"sources":["webpack://./src/dist/style/platform_component.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,iBAAiB;AACnB","sourcesContent":[".btn-layer-div {\n  margin-bottom: 2%;\n  text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
