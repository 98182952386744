import { useUpdate } from "../../hooks/useUpdate";

export const ArchiveWarehouseInformation = async (payload, warehouseId) => {
  try {
    return await useUpdate(
      `${process.env.REACT_APP_API_URL}/warehouses/archive/${warehouseId}`,
      payload
    );
  } catch (err) {
    console.log("ERROR: ", err.stack);
    if (err.request) return err.request;
    if (err.response) return err.response;
  }
};
