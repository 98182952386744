import { Login } from "../../api/authentication/Login";
import { message } from "antd";

const useLogin = async (data = { email_address: null, password: null }) => {
  const { email_address, password } = data;

  if (!email_address || email_address === "") {
    message.warning("Email address is required to continue logging in", 2);
    return;
  }

  if (!password || password === "") {
    message.warning("Password is required to conitnue logging in", 2);
    return;
  }

  const payload = { email_address: email_address, password: password };
  const response = await Login(payload);

  if (response.status === 200) {
    const { data } = response.data;

    if (data.status === "AUTHENTICATION_SUCCESS") {
      if (data.role.name === "User") {
        message.warning(
          "Sorry, your current account doesn't have permission to access this system",
          2
        );

        message.info(
          "If you think this is wrong, kindly contact support for this matter",
          2
        );

        message.info("Provide the email address used when logging in", 2);
        return false;
      }

      const { id, first_name, name } = data.user_info;

      localStorage.setItem("token", data.jwt.token);
      localStorage.setItem("user_id", id);
      localStorage.setItem("first_name", first_name);
      localStorage.setItem("name", name);
      localStorage.setItem("role_name", data.role.name);

      const permissions = [];
      for (const permission of data.permissions) {
        const { permission_index, has_access } = permission;
        const single_permission_object = {
          name: permission_index.name,
          has_access: has_access,
        };

        permissions.push(single_permission_object);
      }

      localStorage.setItem("permissions", JSON.stringify(permissions));
      return { status: true, permission: permissions };
    }

    return { status: false, permission: [] };
  }

  if (response.status === 401) {
    message.error(
      "invalid credentials provided, or given account is not authorized",
      2
    );
    return { status: false, permission: [] };
  }

  message.warning("Error, please try again after a minutes", 2);
  return { status: false, permission: [] };
};

export default useLogin;
