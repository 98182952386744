import React from "react";
import { Modal, Form, Button, Input, message, Upload, Avatar, Divider, Row, Col } from "antd";
import { RollbackOutlined, SaveOutlined, InboxOutlined, UserOutlined } from "@ant-design/icons";
import { FormRules } from "../../../dist/functions/formRules";

const uploader_props = {
	name: "advisers_photo",
	multiple: false,
	action: `${process.env.REACT_APP_API_URL}/uploads/advisers/profile`,
	onChange(info) {
		const { status, response } = info.file;
		if (status !== "uploading") {
		}
		if (status === "done") {
			message.success(`${info.file.name} file uploaded successfully.`);
		} else if (status === "error") {
			message.error(`${info.file.name} file upload failed.`);
			message.error(`${response.status}`);
		}
	},
};

const { Dragger } = Upload;

export const SpecialistUpdateGenModalFormComponent = (props) => {
	const { form, specialistUpdateModal, handleModalSpecialistUpdateOnClick, UpdateSpecialistInformation } = props;

	return (
		<>
			<Modal
				title="Update Advisers Information"
				visible={specialistUpdateModal}
				centered={true}
				onCancel={handleModalSpecialistUpdateOnClick}
				footer={[
					<Button type="default" icon={<RollbackOutlined />} onClick={handleModalSpecialistUpdateOnClick}>
						Return
					</Button>,
					<Button type="primary" icon={<SaveOutlined />} onClick={UpdateSpecialistInformation}>
						Save
					</Button>,
				]}
			>
				<Form form={form} layout="vertical">
					<Form.Item label="First Name" name="first_name" fieldKey="first_name" rules={[FormRules.REQUIRED_FIRSTNAME]}>
						<Input />
					</Form.Item>
					<Form.Item label="Last Name" name="last_name" fieldKey="last_name" rules={[FormRules.REQUIRED_MIDDLENAME]}>
						<Input />
					</Form.Item>
					<Form.Item
						label="Email Address"
						name="email"
						fieldKey="email"
						rules={[FormRules.EMAIL_FORMAT, FormRules.REQUIRED_EMAIL]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						label="Contact Number (Globe)"
						name="mobile_globe"
						fieldKey="mobile_globe"
						rules={[FormRules.CONTACT_CHARACTER_COUNT, FormRules.NUMBER_FORMAT_ONLY]}
					>
						<Input addonBefore={"+639"} style={{ width: "100%" }} maxLength={9} />
					</Form.Item>
					<Form.Item
						label="Contact Number (Smart)"
						name="mobile_smart"
						fieldKey="mobile_smart"
						rules={[FormRules.CONTACT_CHARACTER_COUNT, FormRules.NUMBER_FORMAT_ONLY]}
					>
						<Input addonBefore={"+639"} style={{ width: "100%" }} maxLength={9} />
					</Form.Item>

					<Row>
						<Col span={6}>
							<Form.Item name="landlinePrefix" label="Landline">
								<Input addonBefore={"+("} addonAfter={")"} style={{ width: "100%" }} maxLength={4} />
							</Form.Item>
						</Col>
						<Col span={18}>
							<Form.Item
								label=" "
								name="landline"
								fieldKey="landline"
								rules={[FormRules.REQUIRED_LANDLINE, FormRules.LANDLINE_CHARACTER_COUNT, FormRules.NUMBER_FORMAT_ONLY]}
							>
								<Input style={{ width: "100%" }} maxLength={8} />
							</Form.Item>
						</Col>
					</Row>

					<Row>
						<Col span={16}>
							<Form.Item label="Photo" name="url" fieldKey="url">
								<Dragger {...uploader_props}>
									<p className="ant-upload-drag-icon">
										<InboxOutlined />
									</p>
									<p className="ant-upload-text">Click or drag file to this area to upload</p>
								</Dragger>
							</Form.Item>
						</Col>
						<Col span={8}>
							<div style={{ textAlign: "center" }}>
								Current Photo:
								<div style={{ marginTop: "18%" }}>
									{form.getFieldValue("url") ? (
										<Avatar src={form.getFieldValue("url")} size={100} />
									) : (
										<Avatar icon={<UserOutlined />} size={100} />
									)}
								</div>
							</div>
						</Col>
					</Row>
				</Form>
			</Modal>
		</>
	);
};
