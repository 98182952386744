import React from "react";
import { Form, Input, Select, Upload, message, Button } from "antd";
import { InboxOutlined } from "@ant-design/icons";

const { Dragger } = Upload;
const { Option } = Select;

export const SpecialistGeneralInformationFormComponent = (props) => {
  const {
    warehouses,
    onClickShowMoreList,
    isClickShowMoreDisabled = true,
  } = props;

  const uploader_props = {
    name: "advisers_photo",
    multiple: false,
    action: `${process.env.REACT_APP_API_URL}/uploads/advisers/profile`,
    onChange(info) {
      const { status, response } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
        message.error(`${response.status}`);
      }
    },
  };

  return (
    <>
      <Form.Item
        label="First Name"
        name="first_name"
        fieldKey="first_name"
        rules={[
          {
            required: true,
            message: "Please Input Firstname!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Last Name"
        name="last_name"
        fieldKey="last_name"
        rules={[
          {
            required: true,
            message: "Please Input Lastname!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Photo" name="url" fieldKey="url">
        <Dragger {...uploader_props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Dragger>
      </Form.Item>
      <Form.Item
        name="warehouses"
        label="Select Warehouses(s)"
        initialValue={[]}
      >
        <Select
          mode="multiple"
          placeholder="Please select warehouses"
          virtual={true}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <div style={{ textAlign: "center", margin: "1.5%" }}>
                <Button
                  type="link"
                  size="small"
                  onClick={onClickShowMoreList}
                  disabled={isClickShowMoreDisabled}
                >
                  Show More
                </Button>
              </div>
              )
            </div>
          )}
        >
          {warehouses.map((item, index) => (
            <Option
              key={index}
              value={item.id}
            >{`${item.location} - ${item.viewing_address}`}</Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};
