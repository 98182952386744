import React from "react";
import { Tag, Button, Typography, Divider } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import useIsMobile from "../../../hooks/useIsMobile";

const { Title } = Typography;
export const WarehouseFiltersAndSelectionComponent = ({
  onClearSearcheAndSelection,
  selectedWarehouse,
  filteredDataSet,
  onClearColumnFilters,
  isShowColumnFilterTags,
  columnFilterTags = [],
}) => {
  const { isFiltered } = filteredDataSet;
  const isMobile = useIsMobile();

  return (
    <>
      {selectedWarehouse || isFiltered || isShowColumnFilterTags ? (
        <div
          style={{ marginTop: "2%", textAlign: isMobile ? "center" : "left" }}
        >
          <Divider />
          <Title level={5}>Filters:</Title>
          {selectedWarehouse && (
            <Tag color="processing">
              {`Selected Warehouse: ${
                selectedWarehouse?.viewing_address || "none"
              }`}
            </Tag>
          )}
          {isFiltered && (
            <Tag color="processing">
              {`Searched via Location: ${filteredDataSet.search_value}`}
            </Tag>
          )}
          {columnFilterTags.map((item, index) => {
            item?.value && (
              <Tag
                key={index}
                color="processing"
                closable={item.closable ? item.closable : false}
                onClose={item.closable ? () => item.onClose(item.id) : null}
              >
                {`${item.selection_label}: ${
                  !item.value ? "none" : item.value
                }`}
              </Tag>
            );
          })}
          <Button
            type="link"
            icon={<CloseOutlined />}
            size="small"
            onClick={() => {
              onClearSearcheAndSelection();
              onClearColumnFilters();
            }}
          >
            Clear Filters
          </Button>
          <Divider />
        </div>
      ) : null}
    </>
  );
};
