import { FetchRolesWithPermissions } from "../../api/admin/FetchRolesWithPermissions";
import { message } from "antd";

const useGetRolesWithPermissions = async (
  type,
  query = { uar_id: null, user_id: null, role_id: null }
) => {
  const loadingMessage =
    type === "for_creation"
      ? "loading default permissions of selected user role.."
      : "loading user current permissions..";

  await message.loading(loadingMessage, 2);

  const response = await FetchRolesWithPermissions(query);

  for (const item of response.data) {
    item["key"] = item.id;
  }

  return response;
};

export default useGetRolesWithPermissions;
