import React, { useState } from "react";
import { Row, Col, Divider, Form, message } from "antd";
import { CreatePage } from "../../../api/cms/CreatePage";
import { UpdatePost } from "../../../api/cms/UpdatePost";
import { PostCreationFunctionalitiesComponent } from "../posts/PostCreationFunctionalitiesComponent";
import { PageCreationGeneralInformationComponent } from "./PageCreationGeneralInformationComponent";
import { PageCreationSubInformationFormComponent } from "./PageCreationSubInformationFormComponent";

export const PageCreationComponent = (props) => {
  const {
    form,
    isForUpdate,
    setIsForUpdate,
    forUpdateIds,
    setForUpdateIds,
    rolesRestriction,
  } = props;
  const [content, setContent] = useState("");

  const onBlur = (newContent) => {
    setContent(newContent.target.innerHTML);
  };

  const CreateNewPage = async (payload) => {
    return await CreatePage(payload);
  };

  const handleSaveAndPublish = async () => {
    const values = await form.validateFields();
    const payload = {
      ...values,
      user_id: parseInt(localStorage.getItem("user_id")),
      status: "1",
      type: "page",
    };

    await message.loading("Creating Page...", 2);
    const response = await CreateNewPage(payload);
    if (response.status === 201) {
      message.success("Successfuly Created New Page", 2);
      resetFields();
    }
  };

  const handleSaveasDraft = async () => {
    const values = await form.validateFields();
    const payload = {
      ...values,
      user_id: parseInt(localStorage.getItem("user_id")),
      status: "0",
      type: "page",
    };

    await message.loading("Creating Page...", 2);
    const response = await CreateNewPage(payload);

    if (response.status === 201) {
      message.success("Successfuly Created New Page");
      resetFields();
    }

    if (response.status === 400) {
      for (const item of JSON.parse(response.response)) {
        message.error(item.message, 2);
      }
    }
  };

  const handleCreateNew = async () => {
    resetFields();
  };

  const HandleUpdatePostWithMetaInformation = async () => {
    const values = await form.validateFields();

    const response = await UpdatePost(forUpdateIds.post_id, values);
    await message.loading("Updating Page...", 2);
    if (response.status === 200) {
      await message.success("Successfuly Updated Page", 2);
      resetFields();
      setIsForUpdate(false);
      setForUpdateIds({ post_id: null, meta_id: null });
    }

    if (response.status === 400) {
      for (const item of response.data) {
        message.error(item.message, 2);
      }
      return;
    }
  };

  const resetFields = () => {
    form.setFieldsValue({
      post_content: "",
      title: "",
      slug: "",
      show_in_footer_menu: "Select Option",
      show_in_header_menu: "Select Option",
    });
  };

  return (
    <>
      <Row>
        <PostCreationFunctionalitiesComponent
          handleSaveAndPublish={handleSaveAndPublish}
          handleSaveasDraft={handleSaveasDraft}
          handleCreateNew={handleCreateNew}
          isForUpdate={isForUpdate}
          HandleCreateNewCopyAndSave={handleSaveasDraft}
          HandleUpdatePostWithMetaInformation={
            HandleUpdatePostWithMetaInformation
          }
          CREATE_COPY_AND_SAVE={rolesRestriction.CREATE_PAGE}
          SAVE_POST={rolesRestriction.CREATE_PAGE}
          SAVE_AS_DRAFT={rolesRestriction.CREATE_PAGE}
          UPDATE_POST={rolesRestriction.UPDATE_PAGE}
        />
      </Row>
      <Divider />
      <Form form={form} name="pages" layout="vertical">
        <Row>
          <Col span={16}>
            <PageCreationGeneralInformationComponent
              content={content}
              onBlur={onBlur}
            />
          </Col>
          <Col span={8}>
            <div className="sub-info-div-layout">
              <PageCreationSubInformationFormComponent />
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};
