import React from "react";
import { Divider, Statistic, Card, Collapse, List, Space, Tag } from "antd";
import { CompassOutlined } from "@ant-design/icons";

const { Panel } = Collapse;
export const AdsListSubInformationComponent = (props) => {
  const { sub_data = null, warehouseList } = props;

  let {
    user,
    winner_payment,
    is_negotiable,
    is_features,
    is_closed,
    price,
    buy_now_type,
    buy_now_price,
  } = sub_data;

  let { first_name, middle_name, last_name } = user;
  return (
    <>
      <h2>Other Informations</h2>
      <Divider />
      <div className="ads-sub-information-div">
        <Space direction="vertical" style={{ width: "100%" }}>
          {user !== null ? (
            <>
              <Card size="small">
                <Statistic
                  title="Winner Fullname"
                  value={`${last_name}, ${first_name} ${middle_name}`}
                />
              </Card>
              <Card size="small">
                <Statistic
                  title="Winner Payment"
                  value={winner_payment.toUpperCase()}
                />
              </Card>
              <Divider />
            </>
          ) : null}
          <Card size="small">
            <Statistic
              title="Negotiable?"
              value={is_negotiable === "0" ? "No" : "Yes"}
            />
          </Card>
          <Card size="small">
            <Statistic
              title="Features?"
              value={is_features === 0 ? "No" : "Yes"}
            />
          </Card>
          <Card size="small">
            <Statistic title="Closed?" value={is_closed === 0 ? "No" : "Yes"} />
          </Card>
          <Divider />
          <Card size="small">
            <Statistic title="Buynow Type" value={buy_now_type} />
          </Card>
          <Card size="small">
            <Statistic
              title="Buynow Price"
              value={buy_now_price}
              prefix={"PHP"}
            />
          </Card>
          <Divider />
          <Card size="small">
            <Statistic title="Price" value={price} prefix={"PHP"} />
          </Card>
          <Divider />
          <Collapse>
            <Panel header="Warehouses" key="1">
              <List
                size="small"
                bordered={false}
                dataSource={warehouseList}
                renderItem={(item) => (
                  <List.Item>
                    {<CompassOutlined />}
                    {"  "}
                    {item}
                  </List.Item>
                )}
              />
            </Panel>
          </Collapse>
        </Space>
      </div>
    </>
  );
};
