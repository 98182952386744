// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.development-notes-div-style {
  height: 350px;
  overflow: auto;
  overflow-y: scroll;
  padding-top: 3%;
  padding-left: 2%;
  padding-right: 3%;
}
`, "",{"version":3,"sources":["webpack://./src/dist/style/dashboard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".development-notes-div-style {\n  height: 350px;\n  overflow: auto;\n  overflow-y: scroll;\n  padding-top: 3%;\n  padding-left: 2%;\n  padding-right: 3%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
