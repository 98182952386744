import React from "react";
import { Form, Input, Divider, Checkbox, Space, Tag, Button } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

const { TextArea } = Input;
export const PostCreationGeneralInformationComponent = (props) => {
  const { tags, onTagsPressEnter } = props;
  return (
    <>
      <Form.Item name="title" label="Title">
        <Input placeholder="Input Title"></Input>
      </Form.Item>
      <Form.Item name="slug" label="Slug">
        <Input placeholder="Input Slug Details"></Input>
      </Form.Item>
      <Form.Item name="tags" label="Tags">
        <Input onPressEnter={onTagsPressEnter} />
      </Form.Item>
      {
        <div>
          <Space direction="horizontal" size={0} title="Tags">
            {tags.map((name, key) => (
              <Tag key={key} color="processing">
                {name}
              </Tag>
            ))}
          </Space>
          <Divider />
        </div>
      }
      <Form.Item
        name="use_legacy_content"
        valuePropName="checked"
        label="Use Legacy Content?"
      >
        <Checkbox />
      </Form.Item>
      <Form.List name="body">
        {(fields, { add, remove }) => (
          <div key={`main-div-${Math.random()}`}>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <div key={`item-field-${key}`}>
                <Form.Item
                  key={`${key}-title`}
                  {...restField}
                  label="Title"
                  name={[name, "title"]}
                  fieldKey={[fieldKey, "title"]}
                >
                  <Input
                    key={`${key}-input-sec-title`}
                    placeholder="Input Section Title"
                  />
                </Form.Item>
                <Form.Item
                  key={`${key}-is_show`}
                  name={[name, "is_show"]}
                  fieldKey={[fieldKey, "is_show"]}
                  label="Is show?"
                  valuePropName="checked"
                >
                  <Checkbox key={`${key}-is-show-checkbox`} />
                </Form.Item>
                <Form.Item
                  key={`${key}-description`}
                  {...restField}
                  label="Content"
                  name={[name, "description"]}
                  fieldKey={[fieldKey, "description"]}
                >
                  <TextArea
                    key={`${key}-tArea`}
                    placeholder="Input Content"
                    autoSize={{ minRows: 12, maxRows: 15 }}
                  />
                </Form.Item>
                <Button
                  key={`remove-btn-${key}`}
                  icon={
                    <MinusCircleOutlined key={`${key}-minus-circle-outlined`} />
                  }
                  size="middle"
                  type="default"
                  className="dynamic-delete-button"
                  onClick={() => remove(name)}
                  danger
                  block
                >
                  Remove Entry
                </Button>
                <Divider key={`divder-${key}`} />
              </div>
            ))}
            <Form.Item key={`add-field-form-item-${Math.random()}`}>
              <Button
                key={`add-field-button-${Math.random()}`}
                type="dashed"
                onClick={() => add()}
                icon={<PlusOutlined key={`plus-outlined-${Math.random()}`} />}
                block
              >
                Add field
              </Button>
            </Form.Item>
          </div>
        )}
      </Form.List>
    </>
  );
};
