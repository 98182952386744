import axios from "axios";
import { message } from "antd";
import { useState } from "react";

const useCreateWarehouse = () => {
  const [isCreationSuccess, setIsCreationSuccess] = useState(false);
  const [error, setError] = useState(null);

  const createWarehouse = async (form, resetFn, modalFn) => {
    try {
      const values = await form.validateFields();

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/warehouses`,
        values,
        {
          headers: { Authorization: localStorage.getItem("token") },
        }
      );

      if (response.status === 201) {
        form.resetFields();
        setIsCreationSuccess(true);
        resetFn();
        modalFn((prevState) => {
          return !prevState;
        });
        await message.success(
          `Successfuly Created Warehouse ${values.viewing_address}`,
          2
        );
      } else {
        setIsCreationSuccess(false);
      }
    } catch (err) {
      setIsCreationSuccess(false);
      setError(err.message);
      console.log(err, "error");
    }
  };

  return {
    isCreationSuccess,
    error,
    createWarehouse,
  };
};

export default useCreateWarehouse;
