import { BulkAdsRelist } from "../../api/bulk_uploader/BulkAdsRelist";
import { message } from "antd";

const useBulkRelistAds = async (payload) => {
  // Output loading message, when API is called
  await message.loading("Removing Previous Winner", 4);

  // Call the API function in the api/modules folder
  const response = await BulkAdsRelist(payload);
  console.log(response, "response");

  // Validate response code, and output necessary message and return data
  // Return true if the API call executed successfuly
  if (response.status === 200) {
    await message.success("Successfuly Remove Previous Winner", 2);
    return true;
  }

  // Return false if the API call failed on executing
  await message.error("Error on Removing Previous Winner", 2);
  return false;
};

export default useBulkRelistAds;
