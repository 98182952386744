import { useState, useEffect } from "react";

export default function useFetchPermissions(
  permissionsSet = [],
  initialRolesRestriction
) {
  const [rolesRestriction, setRolesRestrictions] = useState(
    initialRolesRestriction
  );

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    if (permissions) {
      const rolesRestriction = {};

      for (const permissionItem of permissionsSet) {
        const _permission = permissions.find(
          (item) => item.name === permissionItem
        );

        rolesRestriction[permissionItem] =
          typeof _permission === "undefined" ? 0 : _permission.has_access;
      }

      setRolesRestrictions(rolesRestriction);
    }
  }, []);

  return rolesRestriction;
}
