import React, { useState, useEffect } from "react";
import { PageHeader, Divider, message, Tabs, Form, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { ViewListofActiveAdsComponent } from "../../components/sub_components/auction_closer/ViewListofActiveAdsComponent";
import { AuctionCloserFunctionalityComponent } from "../../components/sub_components/auction_closer/AuctionCloserFunctionalityComponent";
import { AuctionCloserUploaderComponent } from "../../components/sub_components/auction_closer/AuctionCloserUploaderComponent";
import { AuctionCloserSearchingFormComponent } from "../../components/sub_components/auction_closer/AuctionCloserSearchingFormComponent";
import { FetchBankList } from "../../api/banks/FetchBankList";
import useUpdateAuctionStatus from "../../hooks/auctions/useUpdateAuctionStatus";
import useFetchAdsList from "../../hooks/auctions/useGetAdsList";
import useFetchPermissionSets from "../../hooks/useFetchPermissions";
import { parse } from "papaparse";

const initialTableState = {
  data: [],
  total: 0,
  perPage: 0,
  page: 0,
  lastPage: 0,
};

const { TabPane } = Tabs;
const { confirm } = Modal;

const initialRolesRestriction = {
  CLOSE_AUCTIONS_BY_FILE: 0,
  CLOSE_AUCTIONS_BY_BANK: 0,
};
const permissionSet = ["CLOSE_AUCTIONS_BY_FILE", "CLOSE_AUCTIONS_BY_BANK"];

export const AuctionCloserComponent = () => {
  const [searchForm] = Form.useForm();
  const [ads, setAds] = useState(initialTableState);
  const [banks, setBanks] = useState(initialTableState);
  const [currentTab, setCurrentTab] = useState("1");

  // Query restriction using hook
  // Then hook, to get local storage
  const rolesRestriction = useFetchPermissionSets(
    permissionSet,
    initialRolesRestriction
  );

  const fetchBanks = async (page = 1, limit = 10) => {
    const query = { page: page, limit: limit };
    const data = await FetchBankList(query);
    setBanks(data);
  };

  useEffect(() => {
    fetchBanks();
  }, []);

  const OnClickSubmit = async () => {
    const rawPayload = [];
    for (const row of ads.data) {
      const { plate_number, conduction_sticker } = row;
      const singleObject = {
        plate_number: plate_number,
        conduction_sticker: conduction_sticker,
        is_closed: 1,
        relist: 0,
      };

      rawPayload.push(singleObject);
    }

    const isUpdateSuccess = await useUpdateAuctionStatus({ ads: rawPayload });
    if (isUpdateSuccess) {
      handleResetUploader();
    }
  };

  const handleResetUploader = () => {
    setAds(initialTableState);
  };

  const executeFileUploading = async (e) => {
    const files = e;
    const rawFileContent = await files.text();
    const fileHeader = parse(rawFileContent).data[2];

    if (
      !fileHeader.includes("Conduction_sticker") &&
      !fileHeader.includes("Plate_number")
    ) {
      message.error(
        "Error column given, no Conduction_sticker or Plate_number"
      );
      return;
    }

    const fileBody = parse(rawFileContent).data;
    const fileRows = [];

    fileBody.forEach((element, index, array) => {
      if (index > 2) {
        const rowItem = {};
        element.forEach((element, index) => {
          rowItem[fileHeader[index].toLowerCase()] = element;
        });
        rowItem["key"] = index;
        fileRows.push(rowItem);
      }
    });

    const adsState = { data: fileRows, total: fileRows.length };
    setAds(adsState);
  };

  const draggerProps = {
    name: "file",
    multiple: false,
    accept: "text/csv, application/vnd.ms-excel",
    beforeUpload: async (e) => {
      executeFileUploading(e);
    },
  };

  const OnClickSubmitSearchingForm = async () => {
    const fields = await searchForm.validateFields();
    const query = { filter_type: "ACTIVE", bank_id: fields.bank_id };
    const ads = await useFetchAdsList(query);
    const finalAdsWithprops = {
      data: ads,
      total: ads.length,
    };
    setAds(finalAdsWithprops);
  };

  const onChangeCurrentTabKey = (tabKey) => {
    confirm({
      title: "Warning",
      icon: <ExclamationCircleOutlined />,
      content:
        "If you wish to change tabs, the current uploaded data will be remove",
      okText: "continue",
      cancelText: "cancel",
      async onOk() {
        setCurrentTab(tabKey);
        if (tabKey !== currentTab) {
          setAds(initialTableState);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <>
      <PageHeader
        title="AUCTION"
        subTitle="AUCTIONS MANAGEMENT"
        breadcrumbRender={() => (
          <BreadCrumbsComponent
            breadcrumbsItem={["Auctions", "Auction Closer"]}
          />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <Tabs
          defaultActiveKey={currentTab}
          activeKey={currentTab}
          onChange={onChangeCurrentTabKey}
        >
          <TabPane tab="Auction Closer By CSV file" key="1">
            <AuctionCloserFunctionalityComponent
              onClickSubmit={OnClickSubmit}
              rolesRestriction={rolesRestriction}
              ads={ads}
            />
            {ads.data.length > 0 ? (
              <ViewListofActiveAdsComponent data={ads} />
            ) : (
              <AuctionCloserUploaderComponent draggerProps={draggerProps} />
            )}
          </TabPane>
          <TabPane tab="Auction Closer By Bank" key="2" disabled>
            <AuctionCloserSearchingFormComponent
              banks={banks}
              ads={ads}
              form={searchForm}
              onSearch={OnClickSubmitSearchingForm}
              onSubmitCloseAuctions={OnClickSubmit}
              rolesRestriction={rolesRestriction}
            />
            <ViewListofActiveAdsComponent data={ads} />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};
