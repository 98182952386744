import { CreateReviewsGroup } from "../../api/reviews_group/CreateReviewsGroup";
import { message } from "antd";

const useCreateReviewsGroup = async (payload) => {
  const data = { data: payload };
  await message.loading("Creating Review Group", 2);
  const response = await CreateReviewsGroup(data);

  if (response.status === 201) {
    await message.success("Successfuly Created Review Group", 2);
    return true;
  }

  await message.error("Error Creating Review Group", 2);
  return false;
};

export default useCreateReviewsGroup;
