import React, { Fragment } from "react";
import { Table, Input, Button, Tabs, Tag, Switch, Radio, Space } from 'antd';
import useColumnFilter from "../../../hooks/useColumnFilter";

const { Column } = Table;
const { TabPane } = Tabs;
export const UserListComponent = (props) => {
  const {
    selectedRowKeys,
    onSelectRowChange,
    data,
    statusOnChange,
    statusDepositedOnChange,
    loading,
    rolesRestrictions,
    isFiltered,
    filteredData,
    onPaginationChange,
    onSortChange,
    currentPagination = 1,
    columnFilter,
    setColumnFilter
  } = props;

  const { UPDATE_DEPOSIT_STATUS, UPDATE_STATUS } = rolesRestrictions;

  const { getColumnFilterProps, getColumnBooleanFilter } = useColumnFilter(columnFilter, setColumnFilter);

  return (
    <div style={{ marginTop: "2%" }}>
      <Table
        dataSource={isFiltered ? filteredData.data : data.data}
        loading={loading}
        size="small"
        bordered={true}
        scroll={{ x: 1500 }}
        rowSelection={{
          type: "radio",
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectRowChange,
        }}
        onChange={(pagination, filters, sorter, action) => {
          if (action.action == "sort") {
            onSortChange(sorter.field, sorter.order);
          }

        }}
        pagination={{
          total: isFiltered ? filteredData.total : data.total,
          onChange: onPaginationChange,
          defaultCurrent: 1,
          current: currentPagination,
        }}
        expandable={{
          expandedRowRender: (row) => (
            <Fragment>
              <br />
              <div style={{ padding: "0% 2% 0% 2%" }}>
                <Tabs key="roles" tabPosition={"top"}>
                  <Fragment>
                    <TabPane
                      tabKey={1}
                      tab={row.user_attached_roles.roles.name}
                      className="table-user-tabpane-expendable"
                    >
                      <Table
                        size="small"
                        bordered={true}
                        dataSource={
                          row.user_attached_roles.is_default === 1
                            ? row.user_attached_roles.default_permissions
                            : row.user_attached_roles.custom_permissions
                        }
                      >
                        <Column
                          title="Permission name"
                          dataIndex={
                            row.user_attached_roles.is_default === 1
                              ? ["permission_index", "name"]
                              : ["permissions", "permission_index", "name"]
                          }
                          key={["permissions", "permission_index", "name"]}
                        />
                        <Column
                          title="Description"
                          dataIndex={
                            row.user_attached_roles.is_default === 1
                              ? ["permission_index", "description"]
                              : [
                                "permissions",
                                "permission_index",
                                "description",
                              ]
                          }
                          key={["permissions", "permission_index", "name"]}
                        />
                        <Column
                          title="Access Type"
                          dataIndex={"has_access"}
                          key={"has_access"}
                          render={(status) => (
                            <Tag color={status > 0 ? "green" : "red"}>
                              {status > 0 ? "ENABLED" : "DISABLED"}
                            </Tag>
                          )}
                        />
                        <Column
                          title="Module Name"
                          dataIndex={
                            row.user_attached_roles.is_default === 1
                              ? ["permission_index", "modules", "name"]
                              : [
                                "permissions",
                                "permission_index",
                                "modules",
                                "name",
                              ]
                          }
                          key={
                            ("permissions",
                              "permission_index",
                              "modules",
                              "name")
                          }
                        />
                      </Table>
                    </TabPane>
                  </Fragment>
                </Tabs>
              </div>
            </Fragment>
          ),
          rowExpandable: (row) => row.user_attached_roles,
        }}
      >
        <Column title="Id" dataIndex="id" key="id" sorter={true} {...getColumnFilterProps('id')} />

        <Column
          title="First Name"
          dataIndex="first_name"
          key="first_name"
          sorter={true}
          render={(first_name, record) => (
            <a
              href={`${window.location.origin}/user/edit?email=${record.email}`}
              target="_blank"
              rel="noreferrer"
            >
              {first_name}
            </a>
          )}
          {...getColumnFilterProps('first_name')}
        />

        <Column title="Last Name" dataIndex="last_name" key="last_name" sorter={true} {...getColumnFilterProps('last_name')} />
        <Column title="Middle Name" dataIndex="middle_name" key="middle_name" sorter={true} {...getColumnFilterProps('middle_name')} />
        <Column title="Email Address" dataIndex="email" key="email" sorter={true}  {...getColumnFilterProps('email')} />
        <Column title="Mobile Number" dataIndex="mobile" key="mobile" sorter={true}  {...getColumnFilterProps('mobile')} />
        <Column title="Role" dataIndex="user_type" key="user_type" sorter={true}  {...getColumnFilterProps('user_type')} />
        <Column title="Referrer" dataIndex="referrer" key="referrer" sorter={true}  {...getColumnFilterProps('referrer')} />

        <Column
          title="Has Deposited?"
          dataIndex="has_deposited"
          key="has_deposited"
          sorter={true}
          render={(active_status, record) => (
            <Switch
              disabled={UPDATE_DEPOSIT_STATUS === 1 ? false : true}
              className="user-status-switch"
              onChange={(switchValue) =>
                statusDepositedOnChange(active_status, record, switchValue)
              }
              checkedChildren="Yes"
              unCheckedChildren="No"
              defaultChecked={parseInt(active_status) === 1 ? true : false}
            />
          )}

          {...getColumnBooleanFilter("has_deposited")}
        />
        <Column
          title="Status"
          dataIndex="active_status"
          key="active_status"
          sorter={true}
          render={(active_status, record) => (
            <Switch
              disabled={UPDATE_STATUS === 1 ? false : true}
              className="user-status-switch"
              onChange={(switchValue) =>
                statusOnChange(active_status, record, switchValue)
              }
              checkedChildren="Yes"
              unCheckedChildren="No"
              defaultChecked={parseInt(active_status) === 1 ? true : false}
            />
          )}
          {...getColumnBooleanFilter("active_status")}
        />
        <Column title="Date Created" dataIndex="created_at" key="created_at" sorter={true} {...getColumnFilterProps('created_at')} />
      </Table>
    </div>
  );
};