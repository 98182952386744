const fetchPlatformTransformer = (payload) => {
    let newColFilter = payload.columnFilter.filter(e => e.value !== undefined);

    return {
        page: payload.page === undefined ? 1 : payload.page,
        limit: payload.limit === undefined ? 10 : payload.limit,
        sortColumn: payload.sortColumn === undefined ? "id" : payload.sortColumn,
        sortType: payload.sortType === undefined ? "asc" : payload.sortType,
        columnFilter: newColFilter
    };
};

export { fetchPlatformTransformer };
