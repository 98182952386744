import React from "react";
import { Tabs, Card, Form, Radio } from "antd";

const { TabPane } = Tabs;
const { Meta } = Card;
export const RolesPermissionListComponent = ({ data }) => {
  return (
    <Tabs tabPosition={"top"}>
      {data.permissions.map((item, index) => (
        <TabPane
          key={index}
          tab={item.name}
          forceRender={true}
          style={{ overflowY: "auto", height: 300, width: "100%" }}
        >
          {item.permissions.map((row, index) => (
            <Card
              key={index}
              bordered={false}
              size="small"
              style={{ textAlign: "center" }}
            >
              <Meta
                key={index}
                title={row.name}
                description={row.description}
              />
              <br />
              <Form.Item
                name={`PERMISSIONS-${row.id}-${row.module_id}`}
                key={`${row.id}-${item.id}-${row.name}`}
                initialValue={row.access_rights.has_access}
              >
                <Radio.Group
                  key={row.id}
                  name={row.id}
                  defaultValue={row.access_rights.has_access}
                >
                  <Radio value={1}>ENABLED</Radio>
                  <Radio value={0}>DISABLED</Radio>
                </Radio.Group>
              </Form.Item>
            </Card>
          ))}
        </TabPane>
      ))}
    </Tabs>
  );
};
