import React from "react";
import { Table } from "antd";
import useColumnFilter from "../../../hooks/useColumnFilter";

const { Column } = Table;
export const BanksTableListComponent = (props) => {
  const {
    tableLoading = false,
    data = { data: [], total: 0 },
    selectedRowKeys = null,
    onChange,
    onPaginationChange,
    currentPagination,
    onSortChange,
    columnFilter,
    setColumnFilter
  } = props;

  const { getColumnFilterProps } = useColumnFilter(columnFilter, setColumnFilter);

  return (
    <>
      <Table
        loading={tableLoading}
        size={"small"}
        dataSource={data.data}
        bordered={true}
        scroll={{ x: 1100 }}
        rowSelection={{
          selectedRowKeys,
          type: "radio",
          onChange: onChange,
        }}
        onChange={(pagination, filters, sorter, action) => {
          if (action.action == "sort") {
            onSortChange(sorter.field, sorter.order);
          }
        }}
        pagination={{
          total: data.total,
          onChange: onPaginationChange,
          current: currentPagination
        }}
      >
        <Column title="Id" dataIndex="id" key="id" sorter={true} {...getColumnFilterProps('id')} />
        <Column title="Bank Name" dataIndex="name" key="name" sorter={true} {...getColumnFilterProps('name')} />
        <Column
          title="Minimum Loan Amount"
          dataIndex="min_loan_amount"
          key="min_loan_amount"
          sorter={true} {...getColumnFilterProps('min_loan_amount')}
        />
        <Column title="Created Date" dataIndex="created_at" key="created_at" sorter={true}  {...getColumnFilterProps('created_at')} />
        <Column title="Updated Date" dataIndex="updated_at" key="updated_at" sorter={true} {...getColumnFilterProps('updated_at')} />
      </Table>
    </>
  );
};
