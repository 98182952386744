import { FetchFAQsIndex } from "../../api/faq_index/FetchFAQsIndex";

const useFetchFAQsIndex = async (
  isForTable = true,
  query = { page: 1, limit: 10, filter: null }
) => {
  if (isForTable) {
    const data = await FetchFAQsIndex(query);
    for (const item of data.data.data) {
      item["key"] = item.id;
    }

    return data.data;
  }

  const selectOptionQuery = {
    page: 1,
    limit: null,
    filter: "",
  };

  const data = await FetchFAQsIndex(selectOptionQuery);

  for (const item of data.data.data) {
    item["key"] = item.id;
  }

  return data.data.data;
};

export default useFetchFAQsIndex;
