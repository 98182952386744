import React from "react";
import { Space, Button, Divider, Modal } from "antd";
import {
  ExclamationCircleOutlined,
  RedoOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { BuyNowReportsComponent } from "./BuyNowReportsComponent";
import useIsMobile from "../../../hooks/useIsMobile";

const { confirm } = Modal;
export const BuyNowFunctionalityComponent = (props) => {
  const {
    isUpdateButtonHidden = true,
    OnUpdateStatus,
    data = [],
    GENERATE_BUY_NOW_WINNERS_REPORT = 0,
    UPDATE_BUY_NOW_WINNERS_STATUS = 0,
    platform = "car",
    onClickFilterBuyNow,
  } = props;

  const isMobile = useIsMobile();

  const buttonBlockStyling = {
    block: isMobile ? true : false,
  };

  const showConfirm = async () => {
    confirm({
      centered: true,
      title: "Update Status",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to update status of this unit?",
      onOk() {
        OnUpdateStatus();
      },
      onCancel() {
        return null;
      },
    });
  };

  return (
    <>
      <div style={{ marginTop: "2%" }}>
        <Space size="small" direction={isMobile ? "vertical" : "horizontal"}>
          {
            <div style={{ display: isUpdateButtonHidden ? "none" : null }}>
              <Divider type="vertical" />
              <Button
                type="primary"
                icon={<RedoOutlined />}
                hidden={UPDATE_BUY_NOW_WINNERS_STATUS === 1 ? false : true}
                onClick={showConfirm}
                danger
              >
                Cancel Buy Now
              </Button>
            </div>
          }
          <div>
            <Button
              type="primary"
              title="Filter Bids"
              icon={<FilterOutlined />}
              onClick={onClickFilterBuyNow}
              block={buttonBlockStyling}
            >
              Filter Bids
            </Button>
          </div>
          <div
            style={{
              display: GENERATE_BUY_NOW_WINNERS_REPORT === 1 ? null : "none",
            }}
          >
            <BuyNowReportsComponent dataSet={data} platform={platform} />
          </div>
        </Space>
      </div>
    </>
  );
};
