import React, { useState, useEffect, useCallback } from "react";
import { PageHeader, Divider, Tabs, Form, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { ReviewsListComponent } from "../sub_components/reviews/ReviewsListComponent";
import { ReviewsTransactionComponent } from "../sub_components/reviews/ReviewsTransactionComponent";
import moment from "moment";
import useFetchReviews from "../../hooks/reviews/useFetchReviews";
import useCreateReviews from "../../hooks/reviews/useCreateReviews";
import useUpdateReviews from "../../hooks/reviews/useUpdateReviews";
import useFetchPermissionSets from "../../hooks/useFetchPermissions";

const { TabPane } = Tabs;
const { confirm } = Modal;

const initialRolesRestriction = {
  REVIEW_INDEX_CREATE: 0,
  REVIEW_INDEX_UPDATE: 0,
  REVIEW_INDEX_DELETE: 0,
  REVIEW_INDEX_RESTORE: 0,
};

const permissionSet = [
  "REVIEW_INDEX_CREATE",
  "REVIEW_INDEX_UPDATE",
  "REVIEW_INDEX_DELETE",
  "REVIEW_INDEX_RESTORE",
];

export const ReviewsManagementComponent = () => {
  const [createReviewsForm] = Form.useForm();
  const [reviews, setReviews] = useState();
  const [currentPagination, setCurrentPagination] = useState(1);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [currentTab, setCurrentTab] = useState("1");
  const [currentImage, setCurrentImage] = useState(null);
  const [isShowSelection, setIsShowSelection] = useState(false);
  const [searchTags, setSearchTags] = useState([]);
  const [searchParameters, setSearchParameters] = useState({
    page: 1,
    limit: 10,
    is_show: null,
    filter: "",
  });

  // Query restriction using hook
  // Then hook, to get local storage
  const rolesRestriction = useFetchPermissionSets(
    permissionSet,
    initialRolesRestriction
  );

  const ExecuteFetchReviewsListHook = async (
    page = 1,
    limit = 10,
    filter = "",
    is_show = null
  ) => {
    const query = {
      page: page,
      limit: limit,
      filter: filter,
      is_show: is_show,
    };

    const data = await useFetchReviews(true, query);
    setReviews(data);
  };

  const onClickEditReviews = (id, row) => {
    setSelectedRow(row);
    setSelectedRowKey(id);
    setCurrentTab("2");
    setCurrentImage(row.image?.url || null);
    createReviewsForm.setFieldsValue({
      ...row,
      reviewed_date: moment(row.reviewed_date),
      image: row.image?.url || null,
    });
  };

  const onPaginationChange = (page, limit) => {
    setCurrentPagination(page);
    setSearchParameters((prevState) => {
      return { ...prevState, page: page, limit: limit };
    });
  };

  const onChangeTabs = (key) => {
    setCurrentTab(key);
  };

  const OnSubmitCreation = async () => {
    const values = await createReviewsForm.validateFields();

    // validate details of the payload to make sure it has default values
    // optional chaining used to validate (?) data
    values.reviewed_date = values.reviewed_date?.format("YYYY-MM-DD") || null;
    values["media_id"] = values.image?.file?.response?.data[0]?.id || null;

    const isSuccess = await useCreateReviews(values);

    if (isSuccess) {
      reset();
    }
  };

  const OnSubmitUpdate = async () => {
    const values = await createReviewsForm.validateFields();

    // validate details of the payload to make sure it has default values
    // optional chaining used to validate (?) data
    values.reviewed_date = values.reviewed_date?.format("YYYY-MM-DD") || null;
    values["media_id"] = values.image?.file?.response?.data[0]?.id || null;

    // delete image json key to make sure the payload is clean before sending
    delete values["image"];

    // remove null values
    for (const key in values) {
      if (!values[key] || values[key] === "") {
        delete values[key];
      }
    }

    const isSuccess = await useUpdateReviews(selectedRowKey, values);

    if (isSuccess) {
      reset();
    }
  };

  const OnSubmitDelete = async () => {
    const payload = { is_show: false };
    confirm({
      title: "Are you sure you want to delete this review?",
      icon: <ExclamationCircleOutlined />,
      content:
        "will remove this specific reviews on the review group after deletion",
      onOk() {
        ExecuteUpdateReviewsHook(selectedRowKey, payload);
      },
      onCancel() {},
    });
  };

  const OnClickRestoreReview = async (id, row) => {
    const payload = { is_show: true };
    confirm({
      title: "Are you sure you want to restore this review?",
      icon: <ExclamationCircleOutlined />,
      content:
        "restoring this review, user will now see this review on the selections",
      onOk() {
        ExecuteUpdateReviewsHook(id, payload);
      },
      onCancel() {},
    });
  };

  const ExecuteUpdateReviewsHook = async (id, payload) => {
    const isSuccess = await useUpdateReviews(id, payload);
    if (isSuccess) {
      reset();
    }
  };

  const reset = () => {
    setCurrentImage(null);
    createReviewsForm.resetFields();
    setSelectedRowKey(null);
    setSelectedRow(null);
    setCurrentPagination(1);
    ExecuteFetchReviewsListHook();
  };

  useEffect(() => {
    ExecuteFetchReviewsListHook();
  }, []);

  const onCloseIncludesFilterSearchTag = () => {
    setSearchTags((prevState) => {
      return prevState.filter(
        (item) => item.selection_label !== "Includes Value"
      );
    });
    setIsShowSelection(false);
  };

  const onLoadFiltersAndSelection = useCallback((filter) => {
    if (filter) {
      // set tags state for the filters
      setSearchTags(() => {
        return [
          {
            color: "blue",
            closable: true,
            selection_label: "Includes Value",
            value: filter,
            onClose: onCloseIncludesFilterSearchTag,
          },
        ];
      });

      // set is show selection tag
      setIsShowSelection(true);
    }
  }, []);

  useEffect(() => {
    const { filter, is_show, page, limit } = searchParameters;
    ExecuteFetchReviewsListHook(page, limit, filter, is_show);
    onLoadFiltersAndSelection(filter);
  }, [searchParameters, onLoadFiltersAndSelection]);

  const onSearchReviews = (search_value) => {
    setSearchParameters((prevState) => {
      return { ...prevState, filter: search_value };
    });

    // reset the search input field after submittion of search criteria
  };

  const onClearSearcheAndSelection = () => {
    setSearchTags([]);
    setIsShowSelection(false);
  };

  const onClickCreateNew = () => {
    confirm({
      title: "Are you sure you want to create new entry?",
      icon: <ExclamationCircleOutlined />,
      content: "clicking ok, will remove current progress",
      onOk() {
        reset();
      },
      onCancel() {},
    });
  };

  return (
    <>
      <PageHeader
        title="Reviews"
        subTitle="Reviews Management"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["Reviews"]} />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <Tabs
          defaultActiveKey="1"
          activeKey={currentTab}
          onChange={onChangeTabs}
        >
          <TabPane tab="List of Reviews" key="1">
            <ReviewsListComponent
              data={reviews}
              onPaginationChange={onPaginationChange}
              currentPagination={currentPagination}
              onClickEditReviews={onClickEditReviews}
              onClickRestoreReview={OnClickRestoreReview}
              onSearchReviews={onSearchReviews}
              onClearSearcheAndSelection={onClearSearcheAndSelection}
              isShowSelection={isShowSelection}
              tags={searchTags}
              searchParameters={searchParameters}
              rolesRestriction={rolesRestriction}
            />
          </TabPane>
          <TabPane
            tab="Create Reviews"
            key="2"
            disabled={rolesRestriction.REVIEW_INDEX_CREATE === 0 ? true : false}
          >
            <ReviewsTransactionComponent
              form={createReviewsForm}
              onSubmitCreation={OnSubmitCreation}
              onSubmitDelete={OnSubmitDelete}
              onSubmitUpdate={OnSubmitUpdate}
              selectedRow={selectedRow}
              currentImage={currentImage}
              onClickCreateNew={onClickCreateNew}
              rolesRestriction={rolesRestriction}
            />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};
