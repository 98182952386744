import { useUpdate } from "../../hooks/useUpdate";

export const UpdateAdsMeta = async (payload) => {
  try {
    return await useUpdate(
      `${process.env.REACT_APP_API_URL}/ads-meta`,
      payload
    );
  } catch (err) {
    console.log("ERROR: ", err);
    return [];
  }
};
