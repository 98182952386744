//this just returns the className to use based on the screen resolution

//for responsive view
import {useMediaQuery} from "react-responsive";

export default function useClassName() {
    //media queries to pass as props and use with css
    const isMobile = useMediaQuery({
        query: "(max-width: 575px)",
    });

    const getClassName = (className) => {
        return isMobile ? `${className}-mobile` : className;
    };

    return getClassName;
}
