import React from "react";
import { SaveOutlined } from "@ant-design/icons";
import { ModulesFunctionalitiesComponent } from "../../ModulesFunctionalitiesComponent";

export const RemarksSubGroupFunctionalityComponent = (props) => {
  const {
    onCreateRemarksSubGroup,
    onUpdateRemarksSubGroup,
    onClickDeleteRemarksGroup,
    selectedGroupId,
    rolesRestriction,
  } = props;

  const {
    CREATE_REMARKS_SUB_GROUP,
    UPDATE_REMARKS_SUB_GROUP,
    DELETE_REMARKS_SUB_GROUP,
  } = rolesRestriction;

  const functionalitiesComponent = [
    {
      component_type: "button",
      type: "primary",
      icon: <SaveOutlined />,
      _onClick: onCreateRemarksSubGroup,
      is_hidden: !selectedGroupId
        ? CREATE_REMARKS_SUB_GROUP === 1
          ? false
          : true
        : true,
      label: "Create Pros & Cons Sub Group",
    },
    {
      component_type: "button",
      type: "default",
      icon: <SaveOutlined />,
      _onClick: onUpdateRemarksSubGroup,
      is_hidden: selectedGroupId
        ? UPDATE_REMARKS_SUB_GROUP === 1
          ? false
          : true
        : true,
      label: "Update Pros & Cons Sub Group",
    },
    {
      component_type: "button",
      type: "default",
      icon: <SaveOutlined />,
      _onClick: onClickDeleteRemarksGroup,
      is_hidden: selectedGroupId
        ? DELETE_REMARKS_SUB_GROUP === 1
          ? false
          : true
        : true,
      label: "Delete Pros & Cons Sub Group",
      danger: true,
    },
  ];

  return (
    <ModulesFunctionalitiesComponent
      functionalitiesComponent={functionalitiesComponent}
      alignment="right"
      spaceSize="small"
    />
  );
};
