import { useEffect, useState } from "react";

const columns = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "FAQ's Count",
    dataIndex: "faq_items_count",
    key: "faq_items_count",
  },
  {
    title: "Date Creation",
    dataIndex: "created_at",
    key: "created_at",
  },
  {
    title: "Last Modified Date",
    dataIndex: "updated_at",
    key: "updated_at",
  },
];

export default function useGetFAQsColumn() {
  const [tableColumns, setColumns] = useState(columns);

  useEffect(() => {
    setColumns(columns);
  }, []);

  return tableColumns;
}
