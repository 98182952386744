import axios from "axios";

export const useDelete = async (url, payload = {}) => {
  // const [status, setStatus] = useState('Loading');
  // const [data, setData] = useState(null);
  const header = {
    headers: { Authorization: localStorage.getItem("token") },
  };

  const dataRes = await axios.delete(url, payload, header);
  return dataRes;
};
