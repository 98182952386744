import React from "react";
import { Modal, Form, Select, Input, Button, Divider } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const { TextArea } = Input;
const { Option } = Select;
export const AddNewPermissionIndexComponent = (props) => {
  const {
    visible,
    OnOk,
    onCancel,
    form,
    platforms,
    modules,
    onChangePlatform,
  } = props;
  return (
    <>
      <Modal
        title="Add Permissions"
        visible={visible}
        onOk={OnOk}
        onCancel={onCancel}
        width={"40%"}
        destroyOnClose={true}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="platform_id" label="Platform">
            <Select placeholder="Select Platform" onChange={onChangePlatform}>
              {platforms.map((platform) => (
                <Option value={platform.id}>{platform.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="module_id" label="Modules">
            <Select placeholder="Select Platform">
              {modules.map((item) => (
                <Option value={item.id}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Divider />
          <div>{"Permission Name(s)"}</div>
          <div>{"Note: permission names can be multiple"}</div>
          <Form.List name="permission_index">
            {(fields, { add, remove }) => (
              <div
                style={{
                  overflowX: "hidden",
                  overflowY: "auto",
                  height: "300px",
                }}
              >
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <div>
                    <Divider />
                    <Form.Item
                      {...restField}
                      name={[name, "name"]}
                      fieldKey={[fieldKey, "name"]}
                      label="Permission Name"
                      help="Please use this kind of format for permission name eg. CREATE_USER"
                    >
                      <Input placeholder="Permission Name" />
                    </Form.Item>
                    <Form.Item
                      name={[name, "description"]}
                      fieldKey={[fieldKey, "description"]}
                      label="Description"
                    >
                      <TextArea placeholder="Permission Description" />
                    </Form.Item>
                    <Button
                      type="default"
                      size="small"
                      icon={<MinusCircleOutlined />}
                      onClick={() => remove(name)}
                      block
                      danger
                    >
                      Delete Entry
                    </Button>
                  </div>
                ))}
                <Form.Item>
                  <Divider />
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add field
                  </Button>
                </Form.Item>
              </div>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
};
