import React from "react";
import { Modal, Form, Select, Input } from "antd";

const { Option } = Select;
const { TextArea } = Input;
export const RemarksIndexUpdateComponent = (props) => {
  const { visible, form, onSubmit, onCancel } = props;
  return (
    <Modal
      visible={visible}
      title="Update Remarks Index"
      width={700}
      onOk={onSubmit}
      onCancel={onCancel}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Content"
          name={"content"}
          fieldKey={"content"}
          rules={[{ required: true, message: "Missing content" }]}
        >
          <TextArea rows={5} />
        </Form.Item>
        <Form.Item
          label="Classification"
          name={"classification"}
          fieldKey={"classification"}
          rules={[
            {
              required: true,
              message: "Missing classification",
            },
          ]}
        >
          <Select placeholder="Select Classification">
            <Option key="advantages" value="advantages">
              Advantages
            </Option>
            <Option key="disadvantages" value="disadvantages">
              Disadvantages
            </Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
