import React, { Fragment, useState } from "react";
import { Form, Input, Modal, Button } from "antd";
import { useUpdate } from "../../../hooks/useUpdate";

const rules = {
  old_password: [
    {
      required: true,
      message: "Please input your current password",
    },
  ],
  new_password: [
    {
      required: true,
      message: "Please input new password",
    },
  ],
};

export const ChangePasswordFormComponent = (props) => {
  const { emailAddress, isModalVisible, handleModalVisibleOnChange } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();

  const HandleModalOkClicked = async () => {
    setConfirmLoading(true);
    const values = await form.validateFields();
    values["email_address"] = emailAddress;
    await useUpdate(`${process.env.REACT_APP_API_URL}/users/password`, values);
    form.resetFields();
    setConfirmLoading(false);
    handleModalVisibleOnChange();
  };

  return (
    <Fragment>
      <Modal
        visible={isModalVisible}
        title="Change Password"
        onOk={HandleModalOkClicked}
        onCancel={handleModalVisibleOnChange}
        footer={[
          <Button key="back" onClick={handleModalVisibleOnChange}>
            Back
          </Button>,
          <Button
            type="primary"
            onClick={HandleModalOkClicked}
            loading={confirmLoading}
          >
            Submit
          </Button>,
        ]}
        destroyOnClose={true}
        confirmLoading={confirmLoading}
        maskClosable={false}
        keyboard={true}
      />
    </Fragment>
  );
};
