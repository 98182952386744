//this just returns the className to use based on the screen resolution

//for responsive view
import { useMediaQuery } from "react-responsive";

export default function useIsTablet() {
  //media queries to pass as props and use with css
  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)",
  });

  return isTablet;
}
