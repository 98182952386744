import React, { useState, useEffect, useRef } from "react";
import { PageHeader, Divider, Form, message } from "antd";
import { PlatformWithRolesAndModulesTableComponent } from "../sub_components/platform/PlatformWithRolesAndModulesTableComponent";
import { PlatformFormComponent } from "../sub_components/platform/PlatformFormComponent";
import { PlatformFunctionalitiesComponent } from "../sub_components/platform/PlatformFunctionalitiesComponent";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { CreatePlatform } from "../../api/admin/CreatePlatform";
import useGetPlatforms from "../../hooks/platforms/useGetPlatforms";
import useFetchPermissionSets from "../../hooks/useFetchPermissions";
import "../../dist/style/platform_component.css";
import { useComponentDidUpdate } from "../../hooks/useComponentDidUpdate";
import { ModulesSelectAndColumnTagsComponent } from "../ModulesSelectAndColumnTagsComponent";
import { fetchPlatformDAO } from "../../dist/dao/platform";

const initialRolesRestriction = {
  CREATE_PLATFORM: 0,
};

const initialTableState = {
  data: [],
  total: 0,
};

const permissionSet = ["CREATE_PLATFORM"];

export const PlatformComponent = () => {
  const [form] = Form.useForm();
  const [isModalVisible, setModalVisibility] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [platforms, setPlatforms] = useState(initialTableState);
  const [tableLoading, setTableLoading] = useState(true);
  const [currentPagination, setCurrentPagination] = useState(1);
  const rolesRestriction = useFetchPermissionSets(
    permissionSet,
    initialRolesRestriction
  );

  const [isShowColumnFilter, setIsShowColumnFilter] = useState(false);
  const [columnFilterTags, setColumnFilterTags] = useState([]);

  const [currentCount, setCurrentCount] = useState(10);
  const [columnFilter, setColumnFilter] = useState([]);
  const sortColumn = useRef(null);
  const sortType = useRef("asc");

  const setSortColumn = (value) => {
    sortColumn.current = value;
  }

  const setSortType = (value) => {
    if (value === "descend") {
      sortType.current = "desc";
    } else if (value === "ascend") {
      sortType.current = "asc";
    } else {
      sortType.current = "none";
    }
  }

  const onSortChange = async (sortCol, sortType) => {
    setSortColumn(sortCol);
    setSortType(sortType);

    await fetchPlatforms(currentPagination, currentCount);

  };

  const fetchPlatforms = async (page, limit) => {
    setCurrentPagination(page);
    setTableLoading(true);

    const query = {
      page: page,
      limit: limit,
      sortColumn: sortColumn.current,
      sortType: sortType.current,
      columnFilter: columnFilter
    };

    const payload = fetchPlatformDAO(query);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const data = await useGetPlatforms(payload, true);
    setPlatforms(data);
    setTableLoading(false);
  };

  const onPaginationChange = (page, limit) => {
    setCurrentPagination(page);
    setCurrentCount(limit);
    fetchPlatforms(page, limit);
  };


  ///////////////////////////////
  // for column filter tags
  const onCloseColumnFilterTag = (id) => {
    setColumnFilterTags((prevState) => {
      return prevState.filter(
        (item) => item.id !== id
      );
    });

    setColumnFilter((prevState) => {
      let newData = prevState.filter(
        (item) => {

          return Array.isArray(item.id) ? JSON.stringify(item.id) != id : item.id != id;
        }
      );

      return newData;
    });

    if (!columnFilterTags.length) {
      setIsShowColumnFilter(false);
    }
  };

  const onLoadColumnFilterTags = () => {

    if (columnFilter.length) {
      let filterTags = columnFilter.map((e) => {
        let [label, tagId] = Array(2).fill(e.id);

        if (Array.isArray(e.id)) {
          tagId = JSON.stringify(tagId);
          label = "";

          for (let i = 0, n = e.id.length; i < n; i++) {
            label += `${e.id[i]} `;
          }
        }

        return {
          color: "processing",
          closable: true,
          selection_label: label,
          value: e.value,
          id: tagId,
          onClose: onCloseColumnFilterTag
        }
      })

      setColumnFilterTags(filterTags);
      setIsShowColumnFilter(true);
    } else {
      // if columnFilter is just blank, then remove the tags
      setColumnFilterTags([]);
      setIsShowColumnFilter(false);
    }
  };

  const onClearColumnFilters = () => {
    setColumnFilterTags([]);
    setIsShowColumnFilter(false);
    setColumnFilter([]);

  };


  useEffect(() => {
    fetchPlatforms();
  }, []);

  useComponentDidUpdate(() => {

    fetchPlatforms(1, currentCount);

    onLoadColumnFilterTags();
  }, [columnFilter]);

  const OnFinishFailed = (error) => { };

  const createPlatformOnClick = () => {
    setModalVisibility(true);
  };

  const onCancel = () => {
    setModalVisibility(false);
  };

  const onOk = async () => {
    try {
      const values = await form.validateFields();
      OnFinish(values);
    } catch (err) {
      OnFinishFailed(err);
    }
  };

  const OnFinish = async (values) => {
    setIsConfirmLoading(true);
    const data = await CreatePlatform(values);
    message.loading("Creating Platform", 2).then(() => {
      if (data.status !== 201) {
        message.error(
          `Error Creating New Platform, Error Message: ${data.statusText}`
        );
      }

      setTimeout(() => {
        setIsConfirmLoading(false);
        setModalVisibility(!isModalVisible);
        message.success("Successfuly Created New Platform");
        form.resetFields();
      }, 1000);
    });
  };

  return (
    <>
      <div>
        <PageHeader
          className="site-page-header"
          title="PLATFORMS"
          subTitle="PLATFORMS MANAGEMENT MODULE"
          breadcrumbRender={() => (
            <BreadCrumbsComponent
              breadcrumbsItem={["Administration", "Platforms"]}
            />
          )}
        />
        <div className="content-pane-transaction-div-style">
          <Divider />
          <PlatformFunctionalitiesComponent
            createPlatformOnClick={createPlatformOnClick}
            rolesRestrictions={rolesRestriction}
          />
          <ModulesSelectAndColumnTagsComponent
            onClearColumnFilters={onClearColumnFilters}
            isShowColumnFilterTags={isShowColumnFilter}
            columnFilterTags={columnFilterTags}
          />
          <PlatformWithRolesAndModulesTableComponent
            data={platforms}
            tableLoading={tableLoading}
            onPaginationChange={onPaginationChange}
            currentPagination={currentPagination}
            columnFilter={columnFilter}
            setColumnFilter={setColumnFilter}
            onSortChange={onSortChange}
          />
        </div>
        <PlatformFormComponent
          onOk={onOk}
          form={form}
          isConfirmLoading={isConfirmLoading}
          isModalVisible={isModalVisible}
          OnFinish={OnFinish}
          OnFinishFailed={OnFinishFailed}
          onCancel={onCancel}
        />
      </div>
    </>
  );
};
