import React from "react";
import { Table, Typography } from "antd";

const { Column } = Table;
const { Title } = Typography;
export const ActivityLogListMetaComponent = ({ meta_information = [] }) => {
  return (
    <div style={{ padding: "2%" }}>
      <Title level={5}>Meta Information: </Title>
      <Table
        dataSource={meta_information}
        size="small"
        pagination={false}
        bordered={true}
        scroll={{ x: 800, y: 750 }}
      >
        <Column name="key" dataIndex="key" title="Key" />
        <Column name="value" dataIndex="value" title="Value" />
      </Table>
    </div>
  );
};
