import React from "react";
import { Select, Form, InputNumber, Input, Radio } from "antd";

const { Option } = Select;

export const AdsCreationSubInformationFormComponent = (props) => {
  const {
    categories = [],
    brands = [],
    models = [],
    warehouses = [],
    brandTypes = [],
  } = props;
  return (
    <>
      <Form.Item name="category_id" label="Select Category">
        <Select>
          {categories.map((item, index) => (
            <Option key={index} value={item.id}>
              {item.category_name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="sub_category_id" label="Select Sub Category">
        <Select>
          {brandTypes.map((item, index) => (
            <Option key={index} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="brand_id" label="Select Brand">
        <Select>
          {brands.map((item, index) => (
            <Option key={index} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="model" label="Model">
        <Select>
          {models.map((item, index) => (
            <Option
              key={index}
              value={item.id}
            >{`${item.name}-${item.transmission_type} ${item.trim}`}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="ad_condition" label="Condition">
        <Input />
      </Form.Item>
      <Form.Item name="isForBuyNow" label="Buy now?">
        <Radio.Group>
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="buy_now_type" label="Buy Now Type">
        <Select>
          <Option value={"weekend_sale"}>Weekend Sale</Option>
          <Option value={"option_b"}>Option B</Option>
        </Select>
      </Form.Item>
      <Form.Item name="buy_now_price" label="Buy Now Price">
        <InputNumber style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name="is_negotiable" label="Negotiable?">
        <Radio.Group>
          <Radio value={"0"}>Yes</Radio>
          <Radio value={"1"}>No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="warehouse_id" label="Warehouse">
        <Select>
          {warehouses.map((item, index) => (
            <Option
              key={index}
              value={item.id}
            >{`${item.location} - ${item.viewing_address}`}</Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};
