import React from "react";
import { Form, Radio, Input, Select, InputNumber } from "antd";

const { Option } = Select;
export const CarsSubInformationComponent = (props) => {
  const { specialists } = props;
  return (
    <>
      <Form.Item name="or_available" label="OR present?">
        <Radio.Group>
          <Radio value={0}>Yes</Radio>
          <Radio value={1}>No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="cr_available" label="CR present?">
        <Radio.Group>
          <Radio value={0}>Yes</Radio>
          <Radio value={1}>No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="vehicle_condition" label="Vehicle Condition">
        <Input />
      </Form.Item>
      <Form.Item name="other_conditions" label="Other Notes">
        <Input.TextArea />
      </Form.Item>
      <Form.Item name="specialist_id" label="Specialist">
        <Select>
          {specialists.map((item) => (
            <Option
              value={item.id}
            >{`${item.first_name} ${item.last_name}`}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="specialist_notes" label="Specialist Notes">
        <Input.TextArea />
      </Form.Item>
    </>
  );
};
