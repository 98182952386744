import React from "react";
import { ModulesTableComponent } from "../../ModulesTableComponent";
import { ModulesFunctionalitiesComponent } from "../../ModulesFunctionalitiesComponent";
import { ModulesFilterAndSelectionComponent } from "../../ModulesFilterAndSelectionComponent";
import useGetReviewListColumns from "../../../hooks/reviews/useGetReviewListColumns";

export const ReviewsListComponent = (props) => {
  const {
    data = { data: [], total: 0 },
    onPaginationChange,
    currentPagination,
    selectedRowKey,
    onClickEditReviews,
    onClickRestoreReview,
    onSearchReviews,
    onClearSearcheAndSelection,
    isShowSelection = false,
    tags = [],
    rolesRestriction,
  } = props;

  const reviewsColumn = useGetReviewListColumns(
    onClickEditReviews,
    onClickRestoreReview,
    rolesRestriction
  );

  const functionalitiesComponent = [
    {
      component_type: "search_input",
      place_holder: "Search Review",
      _onSearch: onSearchReviews,
    },
  ];

  return (
    <div>
      <ModulesFunctionalitiesComponent
        functionalitiesComponent={functionalitiesComponent}
        alignment="right"
        spaceSize={0}
      />
      <ModulesFilterAndSelectionComponent
        onClearSearcheAndSelection={onClearSearcheAndSelection}
        isShowSelection={isShowSelection}
        tags={tags}
      />
      <ModulesTableComponent
        data={data}
        onPaginationChange={onPaginationChange}
        selectedRowKey={selectedRowKey}
        tableColumns={reviewsColumn || []}
        currentPagination={currentPagination}
        hasSelection={false}
      />
    </div>
  );
};
