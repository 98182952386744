import React from "react";
// import ReactExport from "react-export-excel";
import { Button } from "antd";
import { ExportOutlined } from "@ant-design/icons";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const WarehouseReportsComponent = (props) => {
  const { dataSet } = props;

  const compileAdviser = (data) => {
    const names = [];
    for (const specialist of data) {
      const { user } = specialist;
      const { first_name, last_name } = user;
      names.push(`${first_name} ${last_name}`);
    }

    return names.join();
  };

  const isReportsDisabled = () => {
    if (typeof dataSet === "undefined") {
      return true;
    }

    if (typeof dataSet === "null") {
      return true;
    }

    if (dataSet === null) {
      return true;
    }

    if (dataSet.length < 0) {
      return true;
    }

    return false;
  };

  return (
    <>
      {/* <ExcelFile
        element={
          <Button
            disabled={isReportsDisabled()}
            type="default"
            icon={<ExportOutlined />}
          >
            Generate Report
          </Button>
        }
        filename={`warehouses_${new Date()
          .toLocaleString()
          .replace("/", "")
          .replace("/", "")
          .replace(":", "")
          .replace(":", "")}`}
      >
        {!isReportsDisabled() ? (
          <ExcelSheet data={dataSet} name="Warehouses">
            <ExcelColumn label="Viewing Address" value="viewing_address" />
            <ExcelColumn label="Location" value="location" />
            <ExcelColumn label="Landmark" value="landmark" />
            <ExcelColumn label="Hours Open" value="hours_open" />
            <ExcelColumn label="Google Map" value="direction_here" />
            <ExcelColumn
              label="Bank"
              value={(col) => (!col.banks ? "" : col.banks.name)}
            />
            <ExcelColumn
              label="Adviser(s)"
              value={(col) =>
                !col.specialists ? "" : compileAdviser(col.specialists)
              }
            />
            <ExcelColumn label="Total Items" value="total_items" />
            <ExcelColumn label="Active Biddings" value="total_active_bids" />
            <ExcelColumn label="Buy Now Units" value="total_buy_now_units" />
            <ExcelColumn label="Units With Photo" value="units_with_photo" />
            <ExcelColumn
              label="Units Without Photo"
              value="units_without_photo"
            />
          </ExcelSheet>
        ) : null}
      </ExcelFile> */}
    </>
  );
};
