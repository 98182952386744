import React from "react";
import {
  Modal,
  Upload,
  message,
  Form,
  Divider,
  Button,
  Image,
  Space,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import useDeleteMedia from "../../../hooks/cms/useDeleteMedia";

export const FeatureImagesModalComponent = (props) => {
  const { setMediaIds, visible, onClickUploadButton, mediaForm, imageSet } =
    props;

  const modalWidth = 500;

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const uploadProps = {
    name: "banner_image",
    action: `${process.env.REACT_APP_API_URL}/uploads/post/banner_image`,
    multiple: false,
    maxCount: 1,
    onChange(info) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        const media_id = info.file.response.data[0].id;
        setMediaIds((prevState) => {
          return [...prevState, media_id];
        });
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const ExecuteDeleteMedia = async (payload) => {
    const isSuccessful = await useDeleteMedia(payload);
    console.log(isSuccessful);
  };

  const ExecuteDeleteBannerMedias = async (payload = []) => {
    for (const item of payload) {
      const _payload = {
        data: item,
      };

      await ExecuteDeleteMedia(_payload);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        title="Upload Images"
        onOk={onClickUploadButton}
        onCancel={onClickUploadButton}
        width={modalWidth}
        centered={false}
      >
        <div
          style={{ overflowY: "auto", overflowX: "hidden", height: "500px" }}
        >
          <Form layout="vertical" form={mediaForm}>
            <Form.Item
              name="header_image_desktop_size"
              label="Header Image Desktop (1280 x 543)"
            >
              <Upload
                {...uploadProps}
                className="post-cms-image-uploader"
                data={{
                  has_feature: false,
                  feature_image_index: 0,
                  sub_type: "header_image_desktop",
                  height: 1280,
                  width: 543,
                }}
                listType="picture-card"
                onPreview={onPreview}
              >
                {"+ Upload"}
              </Upload>
              <Space direction={"vertical"}>
                {imageSet.header_image_desktop_size ? (
                  <>
                    {"Current Image:"}
                    <Image
                      src={imageSet.header_image_desktop_size.url}
                      width={280}
                    />
                  </>
                ) : null}
                <Button
                  icon={<DeleteOutlined />}
                  size="small"
                  type="text"
                  onClick={() => {
                    const payload = {
                      data: imageSet.header_image_desktop_size,
                    };
                    ExecuteDeleteMedia(payload);
                  }}
                  danger
                />
              </Space>
            </Form.Item>
            <Form.Item
              name="header_image_mobile_size"
              label="Header Image Mobile (375 x 267)"
            >
              <Upload
                {...uploadProps}
                className="post-cms-image-uploader"
                data={{
                  has_feature: false,
                  feature_image_index: 0,
                  sub_type: "header_image_mobile",
                  height: 375,
                  width: 267,
                }}
                listType="picture-card"
                onPreview={onPreview}
              >
                {"+ Upload"}
              </Upload>
              <Space direction={"vertical"}>
                {imageSet.header_image_mobile_size ? (
                  <>
                    {"Current Image:"}
                    <Image
                      src={imageSet.header_image_mobile_size.url}
                      width={280}
                    />
                  </>
                ) : null}
                <Button
                  icon={<DeleteOutlined />}
                  size="small"
                  type="text"
                  onClick={() => {
                    const payload = {
                      data: imageSet.header_image_mobile_size,
                    };
                    ExecuteDeleteMedia(payload);
                  }}
                  danger
                />
              </Space>
            </Form.Item>
            <Form.Item
              name="header_image_tablet_size"
              label="Header Image Tablet (1024 x 420)"
            >
              <Upload
                style={{ width: "100%" }}
                {...uploadProps}
                className="post-cms-image-uploader"
                data={{
                  has_feature: false,
                  feature_image_index: 0,
                  sub_type: "header_image_tablet",
                  height: 1024,
                  width: 420,
                }}
                listType="picture-card"
                onPreview={onPreview}
              >
                {"+ Upload"}
              </Upload>
              <Space direction={"vertical"}>
                {imageSet.header_image_tablet_size ? (
                  <>
                    {"Current Image:"}
                    <Image
                      src={imageSet.header_image_tablet_size.url}
                      width={280}
                    />
                  </>
                ) : null}
                <Button
                  icon={<DeleteOutlined />}
                  size="small"
                  type="text"
                  onClick={() => {
                    const payload = {
                      data: imageSet.header_image_tablet_size,
                    };
                    ExecuteDeleteMedia(payload);
                  }}
                  danger
                />
              </Space>
            </Form.Item>
            <Button
              icon={<DeleteOutlined />}
              size="small"
              type="default"
              onClick={() => {
                const payloads = [];

                if (imageSet.header_image_tablet_size) {
                  payloads.push(imageSet.header_image_tablet_size);
                }

                if (imageSet.header_image_desktop_size) {
                  payloads.push(imageSet.header_image_desktop_size);
                }

                if (imageSet.header_image_mobile_size) {
                  payloads.push(imageSet.header_image_mobile_size);
                }

                ExecuteDeleteBannerMedias(payloads);
              }}
              danger
            >
              Delete Banner Images(s)
            </Button>
            <Divider />
            <Form.Item name="share_image" label="Share Image (1200 x 620)">
              <Upload
                {...uploadProps}
                className="post-cms-image-uploader"
                listType="picture-card"
                onPreview={onPreview}
                data={{
                  has_feature: false,
                  feature_image_index: 0,
                  sub_type: "share_image",
                  height: 1200,
                  width: 620,
                }}
              >
                {"+ Upload"}
              </Upload>
              <Space direction={"vertical"}>
                {imageSet.share_image ? (
                  <>
                    {"Current Image:"}
                    <Image src={imageSet.share_image.url} width={280} />
                  </>
                ) : null}
                <Button
                  icon={<DeleteOutlined />}
                  size="small"
                  type="text"
                  onClick={() => {
                    const payload = {
                      data: imageSet.share_image,
                    };
                    ExecuteDeleteMedia(payload);
                  }}
                  danger
                />
              </Space>
            </Form.Item>
            <Form.Item
              name="thumbnail_image"
              label="Thumbnail Image (160 x 120)"
            >
              <Upload
                {...uploadProps}
                className="post-cms-image-uploader"
                listType="picture-card"
                onPreview={onPreview}
                data={{
                  has_feature: false,
                  feature_image_index: 0,
                  sub_type: "thumbnail_image",
                  height: 160,
                  width: 120,
                }}
              >
                {"+ Upload"}
              </Upload>
              <Space direction={"vertical"}>
                {imageSet.thumbnail_image ? (
                  <>
                    {"Current Image:"}
                    <Image src={imageSet.thumbnail_image.url} width={280} />
                  </>
                ) : null}
                <Button
                  icon={<DeleteOutlined />}
                  size="small"
                  type="text"
                  onClick={() => {
                    const payload = {
                      data: imageSet.thumbnail_image,
                    };
                    ExecuteDeleteMedia(payload);
                  }}
                  danger
                />
              </Space>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};
