import React from "react";
import { ModulesFunctionalitiesComponent } from "../../ModulesFunctionalitiesComponent";
import { PlusCircleOutlined } from "@ant-design/icons";

export const CMSAssetsFunctionalityComponent = (props) => {
  const { onClickUpload } = props;

  const functionalities = [
    {
      component_type: "button",
      type: "primary",
      size: "default",
      label: "Add Asset",
      _onClick: onClickUpload,
      icon: <PlusCircleOutlined />,
    },
  ];

  return (
    <ModulesFunctionalitiesComponent
      alignment={"right"}
      functionalitiesComponent={functionalities}
      spaceSize={"small"}
    />
  );
};
