import React from "react";
import { Table, Tag } from "antd";
import { CarsSubInformationComponent } from "../cars/CarsSubInformationComponent";

const { Column } = Table;
export const CarsTableListComponent = (props) => {
  const { loading, data = [] } = props;
  return (
    <>
      <Table
        size="small"
        dataSource={data}
        bordered={true}
        loading={loading}
        expandable={{
          expandedRowRender: (record) => (
            <div style={{ padding: "2%" }}>
              <CarsSubInformationComponent
                engine_displacement={record.model.engine_displacement}
                fuel_system={record.model.fuel_system}
                fuel_type={record.model.fuel_type}
                name={record.model.name}
                starter_mechanism={record.model.starter_mechanism}
                transmission_type={record.model.transmission_type}
                trim={record.model.trim}
                make_name={record.model.make.name}
                vehicle_type={record.model.vehicleType.name}
                other_notes={record.other_notes}
                specialist_notes={record.specialist_notes}
                ads={record.ads}
              />
            </div>
          ),
        }}
      >
        <Column title="Id" dataIndex="id" key="id" />
        <Column
          title="Plate Number"
          dataIndex="plate_number"
          key="plate_number"
        />
        <Column
          title="Conduction Sticker"
          dataIndex="conduction_sticker"
          key="conduction_sticker"
          render={(record) => (!record ? "N/A" : record)}
        />
        <Column title="Color" dataIndex="color_id" key="color_id" />
        <Column title="Year Model" dataIndex="year_model" key="year_model" />
        <Column
          title="OR Available?"
          dataIndex="or_available"
          key="or_available"
          render={(record) =>
            record === 1 ? (
              <Tag color="green">Available</Tag>
            ) : (
              <Tag color="orange">Not Available</Tag>
            )
          }
        />
        <Column
          title="CR Available?"
          dataIndex="cr_available"
          key="cr_available"
          render={(record) =>
            record === 1 ? (
              <Tag color="green">Available</Tag>
            ) : (
              <Tag color="orange">Not Available</Tag>
            )
          }
        />
        <Column
          title="Vehicle Condition"
          dataIndex="vehicle_condition"
          key="vehicle_condition"
          render={(record) => (!record ? "N/A" : record)}
        />
        <Column title="Date Created" dataIndex="created_at" key="created_at" />
      </Table>
    </>
  );
};
