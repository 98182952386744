import { useUpdate } from "../../hooks/useUpdate";

export const UpdatePermissionList = async (payload, platform_id) => {
  try {
    return await useUpdate(
      `${process.env.REACT_APP_API_URL}/permissions/index/${platform_id}`,
      payload
    );
  } catch (err) {
    console.log("ERROR: ", err.stack);
    return null;
  }
};
