import React from "react";
import { Tag, Button, Typography, Divider, Space } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import useIsMobile from "../../../hooks/useIsMobile";

const { Title } = Typography;
export const BuyNowFiltersAndSelectionsComponent = (props) => {
  const {
    onRemoveAllSearches,
    onRemoveAdviserSearch,
    onRemoveBankSearch,
    onRemoveSelection,
    selectedBank = null,
    reportDateRange = null,
    selectedAdviser = null,
    selectedRow = null,
    searchKeyword = null,
    keywordType = null,
    onClearColumnFilters,
    isShowColumnFilterTags,
    columnFilterTags = [],
  } = props;

  const isMobile = useIsMobile();
  return (
    <>
      <Title level={5}>Filters:</Title>
      <Space size="small" direction={isMobile ? "vertical" : "horizontal"}>
        <Tag
          color="processing"
          closable
          onClose={onRemoveAdviserSearch}
          visible={!selectedAdviser ? false : true}
        >
          {`Selected Adviser: ${!selectedAdviser ? "none" : selectedAdviser}`}
        </Tag>
        <Tag
          color="processing"
          closable
          onClose={onRemoveBankSearch}
          visible={!selectedBank ? false : true}
        >
          {`Selected Bank: ${!selectedBank ? "none" : selectedBank}`}
        </Tag>
        <Tag color="processing" visible={!reportDateRange ? false : true}>
          {`Report Date: ${!reportDateRange ? "none" : reportDateRange}`}
        </Tag>
        <Tag
          color="processing"
          closable
          onClose={onRemoveSelection}
          visible={!selectedRow ? false : true}
        >
          {`Selected Unit: ${!selectedRow ? "none" : selectedRow.ads.slug}`}
        </Tag>
        <Tag color="processing" visible={!searchKeyword ? false : true}>
          {`Searched By ${
            keywordType ? keywordType.toUpperCase() : keywordType
          }: ${searchKeyword}`}
        </Tag>

        {isShowColumnFilterTags && columnFilterTags.length
          ? columnFilterTags.map((item, index) => (
              <Tag
                key={index}
                color="processing"
                visible={!item.value ? false : true}
                closable={item.closable ? item.closable : false}
                onClose={item.closable ? () => item.onClose(item.id) : null}
              >
                {`${item.selection_label}: ${
                  !item.value ? "none" : item.value
                }`}
              </Tag>
            ))
          : null}

        <Button
          type="link"
          icon={<CloseOutlined />}
          size="small"
          onClick={() => {
            onRemoveAllSearches();
            onClearColumnFilters();
          }}
        >
          Clear Filters
        </Button>
      </Space>
      <Divider />
    </>
  );
};
