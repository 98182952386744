import React, { useState, useEffect, useRef } from "react";
import { PageHeader, Divider, Form, message } from "antd";
import { PlusCircleOutlined, EditOutlined } from "@ant-design/icons";
import { FetchVehicleTypes } from "../../api/vehicle_type/FetchVehicleTypes";
import { CreateVehicleType } from "../../api/vehicle_type/CreateVehicleType";
import { UpdateVehicleType } from "../../api/vehicle_type/UpdateVehicleType";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { ModulesFunctionalitiesComponent } from "../ModulesFunctionalitiesComponent";
import { ModulesTableComponent } from "../ModulesTableComponent";
import { ModalwithFormComponent } from "../ModalwithFormComponent";
import useColumnFilter from "../../hooks/useColumnFilter";
import { useComponentDidUpdate } from "../../hooks/useComponentDidUpdate";
import { ModulesSelectAndColumnTagsComponent } from "../ModulesSelectAndColumnTagsComponent";

const initialRolesState = {
  CREATE_VEHICLE_TYPE: 0,
  UPDATE_VEHICLE_TYPE: 0,
};
export const ItemVehicleTypeManagementComponent = () => {
  const [updateForm] = Form.useForm();
  const [createForm] = Form.useForm();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [currentPagination, setCurrentPagination] = useState(1);
  const [data, setData] = useState({ data: [], total: 0 });
  const [rolesRestriction, setRolesRestrictions] = useState(initialRolesState);
  const [currentCount, setCurrentCount] = useState(10);

  const [isShowColumnFilter, setIsShowColumnFilter] = useState(false);
  const [columnFilterTags, setColumnFilterTags] = useState([]);

  const [columnFilter, setColumnFilter] = useState([]);
  const sortColumn = useRef(null);
  const sortType = useRef("asc");

  const setSortColumn = (value) => {
    sortColumn.current = value;
  }

  const setSortType = (value) => {
    if (value === "descend") {
      sortType.current = "desc";
    } else if (value === "ascend") {
      sortType.current = "asc";
    } else {
      sortType.current = "none";
    }
  }

  const onSortChange = async (sortCol, sortType) => {
    setSortColumn(sortCol);
    setSortType(sortType);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    await FetchVehicleTypesList(currentPagination, currentCount);

  };

  const onClickCreateModal = () => {
    setShowCreateModal(!showCreateModal);
  };

  const onClickUpdateModal = () => {
    const modalStatus = !showUpdateModal;
    if (modalStatus) {
      updateForm.setFieldsValue(selectedRow);
    }
    setShowUpdateModal(modalStatus);
  };

  const onFinishUpdate = async () => {
    const values = await updateForm.validateFields();
    await message.loading("Updating Vehicle Type", 2);
    const payload = { data: values };
    const response = await _UpdateVehicleType(selectedRowKey, payload);

    if (response.status === 200) {
      message.success("Successfuly Updated Vehicle Type");
      updateForm.resetFields();
      onClickUpdateModal();
      FetchVehicleTypesList();
      resetSelection();
      setCurrentPagination(1);
    }
  };

  const onFinishCreation = async () => {
    const values = await createForm.validateFields();
    await message.loading("Creating Vehicle Type", 2);
    const payload = { data: values };
    const response = await _CreateVehicleType(payload);

    if (response.status === 201) {
      message.success("Successfuly Created Vehicle Type");
      createForm.resetFields();
      onClickCreateModal();
      FetchVehicleTypesList();
      resetSelection();
      setCurrentPagination(1);
    }

    if (response.status === 400) {
      for (const item of response.data) {
        message.error(item.message, 2);
      }
    }
  };

  const _CreateVehicleType = async (payload) => {
    return await CreateVehicleType(payload);
  };

  const _UpdateVehicleType = async (id, payload) => {
    return await UpdateVehicleType(id, payload);
  };

  const { getColumnFilterProps, getColumnYesNoFilter } = useColumnFilter(columnFilter, setColumnFilter);

  const tableColumnsComponent = [
    { title: "Id", dataIndex: "id", key: "id", sorter: true, ...getColumnFilterProps('id') },
    { title: "Name", dataIndex: "name", key: "name", sorter: true, ...getColumnFilterProps('name') },
    { title: "Created At", dataIndex: "created_at", key: "created_at", sorter: true, ...getColumnFilterProps('created_at') },
  ];

  const formInputsComponent = [
    {
      name: "name",
      label: "Name",
      default_value: "",
      configs: {
        type: "input",
        rules: [{ required: true, message: "Please input name" }],
      },
    },
  ];

  const FetchVehicleTypesList = async (page = 1, limit = 10) => {
    setCurrentPagination(page);
    const query = {
      page: page,
      limit: limit,
      sortColumn: sortColumn.current,
      sortType: sortType.current,
      columnFilter: columnFilter
    };

    const data = await FetchVehicleTypes(query);
    setData(data);
  };

  const onPaginationChange = (page, limit) => {
    setCurrentPagination(page);
    setCurrentCount(limit);
    FetchVehicleTypesList(page, limit);
  };

  const onRowSelectionChange = (selectedRowkey, selectedRow) => {
    setSelectedRow(selectedRow[0]);
    setSelectedRowKey(selectedRowkey[0]);
  };

  const resetSelection = () => {
    setSelectedRowKey(null);
    setSelectedRow(null);
  };

  ///////////////////////////////
  // for column filter tags
  const onCloseColumnFilterTag = (id) => {
    setColumnFilterTags((prevState) => {
      return prevState.filter(
        (item) => item.id !== id
      );
    });

    setColumnFilter((prevState) => {
      let newData = prevState.filter(
        (item) => {

          return Array.isArray(item.id) ? JSON.stringify(item.id) != id : item.id != id;
        }
      );

      return newData;
    });

    if (!columnFilterTags.length) {
      setIsShowColumnFilter(false);
    }
  };

  const onLoadColumnFilterTags = () => {

    if (columnFilter.length) {
      let filterTags = columnFilter.map((e) => {
        let [label, tagId] = Array(2).fill(e.id);

        if (Array.isArray(e.id)) {
          tagId = JSON.stringify(tagId);
          label = "";

          for (let i = 0, n = e.id.length; i < n; i++) {
            label += `${e.id[i]} `;
          }
        }

        return {
          color: "processing",
          closable: true,
          selection_label: label,
          value: e.value,
          id: tagId,
          onClose: onCloseColumnFilterTag
        }
      })

      setColumnFilterTags(filterTags);
      setIsShowColumnFilter(true);
    } else {
      // if columnFilter is just blank, then remove the tags
      setColumnFilterTags([]);
      setIsShowColumnFilter(false);
    }
  };

  const onClearColumnFilters = () => {
    setColumnFilterTags([]);
    setIsShowColumnFilter(false);
    setColumnFilter([]);

  };

  useEffect(() => {
    FetchVehicleTypesList();
  }, []);

  useComponentDidUpdate(() => {
    FetchVehicleTypesList(1, currentCount);
    onLoadColumnFilterTags();
  }, [columnFilter]);

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const rolesRestriction = {};

    const CREATE_VEHICLE_TYPE = permissions.find(
      (item) => item.name === "CREATE_VEHICLE_TYPE"
    );

    rolesRestriction["CREATE_VEHICLE_TYPE"] =
      typeof CREATE_VEHICLE_TYPE === "undefined"
        ? 0
        : CREATE_VEHICLE_TYPE.has_access;

    const UPDATE_VEHICLE_TYPE = permissions.find(
      (item) => item.name === "UPDATE_VEHICLE_TYPE"
    );

    rolesRestriction["UPDATE_VEHICLE_TYPE"] =
      typeof UPDATE_VEHICLE_TYPE === "undefined"
        ? 0
        : UPDATE_VEHICLE_TYPE.has_access;

    setRolesRestrictions(rolesRestriction);
  }, []);

  return (
    <>
      <PageHeader
        title="VEHICLE TYPE"
        subTitle="VEHICLE TYPES MANAGEMENT"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["Items", "Vehicle Tyoe"]} />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <ModulesFunctionalitiesComponent
          alignment={"right"}
          functionalitiesComponent={[
            {
              component_type: "button",
              type: "primary",
              icon: <PlusCircleOutlined />,
              _onClick: onClickCreateModal,
              is_hidden:
                rolesRestriction.CREATE_VEHICLE_TYPE === 0 ? true : false,
              label: "Create Vehicle Type",
              size: "default",
            },
            {
              component_type: "button",
              type: "default",
              icon: <EditOutlined />,
              _onClick: onClickUpdateModal,
              is_hidden:
                rolesRestriction.UPDATE_VEHICLE_TYPE === 0
                  ? true
                  : selectedRowKey
                    ? false
                    : true,
              label: "Update Vehicle Type",
              size: "default",
            },
          ]}
          spaceSize={"small"}
        />
        <ModulesSelectAndColumnTagsComponent
          onClearColumnFilters={onClearColumnFilters}
          isShowColumnFilterTags={isShowColumnFilter}
          columnFilterTags={columnFilterTags}
        />
        <ModulesTableComponent
          data={data}
          onPaginationChange={onPaginationChange}
          onSelection={onRowSelectionChange}
          selectedRowKey={selectedRowKey}
          size={"small"}
          selectionType={"radio"}
          tableColumns={tableColumnsComponent}
          currentPagination={currentPagination}
          columnFilter={columnFilter}
          setColumnFilter={setColumnFilter}
          onSortChange={onSortChange}
          scrollSize={900}
        />
      </div>

      <ModalwithFormComponent
        title={"Create Vehicle Type"}
        form={createForm}
        formLayout={"vertical"}
        formInputsComponent={formInputsComponent}
        onOk={onFinishCreation}
        onCancel={onClickCreateModal}
        destroyOnClose={true}
        okText={"Save Vehicle Type"}
        okCancel={"Cancel"}
        visible={showCreateModal}
      />
      <ModalwithFormComponent
        title={"Update Vehicle Type"}
        form={updateForm}
        formLayout={"vertical"}
        formInputsComponent={formInputsComponent}
        onOk={onFinishUpdate}
        onCancel={onClickUpdateModal}
        destroyOnClose={true}
        okText={"Save Vehicle Type"}
        okCancel={"Cancel"}
        visible={showUpdateModal}
      />
    </>
  );
};
