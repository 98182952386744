import { FetchModulesByQuery } from "../../api/admin/FetchModulesByQuery";
import { message } from "antd";

const useGetModulesByQuery = async (
  platform,
  page_query,
  is_for_table = true
) => {
  await message.loading("Fetching Modules By Platform..", 2);

  const query = {
    platform_id: platform,
    page: page_query.page,
    limit: page_query.limit
  };

  const data = await FetchModulesByQuery(query);

  if (data.data.data.length === 0) {
    await message.warning("No modules created under the selected platform", 2);
    if (is_for_table) {
      return { data: [], total: 0 };
    }

    return [];
  }

  for (const item of data.data.data) {
    item["key"] = item.id;
  }

  if (is_for_table) {
    await message.success("Successfufly Retrieved Modules By Platform");
    return data.data;
  }

  return data.data.data;
};

export default useGetModulesByQuery;
