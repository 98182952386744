// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
  float: left;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}

.login-div-layout {
  margin: auto;
  max-width: 390px;
  width: 100%;
  margin-top: 20%;
}
`, "",{"version":3,"sources":["webpack://./src/dist/style/login.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,YAAY;AACd;AACA;EACE,WAAW;AACb;AACA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,WAAW;EACX,eAAe;AACjB","sourcesContent":["#components-form-demo-normal-login .login-form {\n  max-width: 300px;\n}\n#components-form-demo-normal-login .login-form-forgot {\n  float: right;\n}\n#components-form-demo-normal-login .ant-col-rtl .login-form-forgot {\n  float: left;\n}\n#components-form-demo-normal-login .login-form-button {\n  width: 100%;\n}\n\n.login-div-layout {\n  margin: auto;\n  max-width: 390px;\n  width: 100%;\n  margin-top: 20%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
