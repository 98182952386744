import React from "react";
import { Form, Input, Row, Col, Checkbox, Space, Divider, Select } from "antd";
import { SelectedRemarkItemListComponent } from "./SelectedRemarkItemListComponent";

const { Option } = Select;
export const RemarksSubGroupTransactionComponent = (props) => {
  const {
    form,
    onChangeClassification,
    selectedClassification,
    selectedRemarks,
    remarksList,
    onChangeSelectionRemarks,
    onClickUnselectButton,
  } = props;

  return (
    <>
      <Form form={form} layout="vertical">
        <Row>
          <Col span={13}>
            <Form.Item name="name" label="Name">
              <Input />
            </Form.Item>
            <Form.Item name="classification" label="Classification">
              <Select
                placeholder="Select Classification"
                onChange={onChangeClassification}
              >
                <Option value="advantages">Advantages</Option>
                <Option value="disadvantages">Disadvantages</Option>
              </Select>
            </Form.Item>
            {`Selected Remarks (${selectedClassification}): `}
            <div>
              <SelectedRemarkItemListComponent
                listData={selectedRemarks}
                onClickUnselectButton={onClickUnselectButton}
                selectedClassification={selectedClassification}
              />
            </div>
          </Col>
          <Col span={11}>
            <div style={{ textAlign: "left", paddingLeft: "10%" }}>
              <div style={{ textAlign: "right" }}>
                {`Selected Classification Group: ${selectedClassification}`}
              </div>
              <Divider />
              <Form.Item name="indices">
                <div style={{ overflowY: "auto", height: "400px" }}>
                  <Space direction={"vertical"} size="small">
                    {remarksList.map((item, index) => (
                      <Checkbox
                        checked={item.is_checked ? item.is_checked : false}
                        key={index}
                        onChange={(e) => {
                          onChangeSelectionRemarks(
                            e.target.checked,
                            selectedClassification,
                            item
                          );
                        }}
                      >
                        {item.content}
                      </Checkbox>
                    ))}
                  </Space>
                </div>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};
