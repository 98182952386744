import React, { useState, useEffect, useRef } from "react";
import { PageHeader, Divider, Form, message, Modal } from "antd";
import {
  PlusCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { ModulesFunctionalitiesComponent } from "../ModulesFunctionalitiesComponent";
import { ModulesTableComponent } from "../ModulesTableComponent";
import { ModalwithFormComponent } from "../ModalwithFormComponent";
import { ModulesFilterAndSelectionComponent } from "../ModulesFilterAndSelectionComponent";
import { FormRules } from "../../dist/functions/formRules";
import { FetchColors } from "../../api/colors/FetchColors";
import { CreateColors } from "../../api/colors/CreateColors";
import { UpdateColors } from "../../api/colors/UpdateColors";
import { DeleteColor } from "../../api/colors/DeleteColor";
import { useComponentDidUpdate } from "../../hooks/useComponentDidUpdate";
import useColumnFilter from "../../hooks/useColumnFilter";
import { ModulesSelectAndColumnTagsComponent } from "../ModulesSelectAndColumnTagsComponent";

const initialRolesState = {
  CREATE_COLORS: 0,
  UPDATE_COLORS: 0,
  DELETE_COLORS: 0,
};

const { confirm } = Modal;
export const ColorsComponent = () => {
  const [createForm] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [rolesRestriction, setRolesRestrictions] = useState(initialRolesState);
  const [showCreationModal, setShowCreationModal] = useState(false);
  const [showUpdateModel, setShowUpdateModal] = useState(false);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [colors, setColors] = useState({ data: [], total: 0 });
  const [currentPagination, setCurrentPagination] = useState(1);

  const [tableLoading, setTableLoading] = useState(false);

  const [isShowColumnFilter, setIsShowColumnFilter] = useState(false);
  const [columnFilterTags, setColumnFilterTags] = useState([]);

  const [currentCount, setCurrentCount] = useState(10);

  const [columnFilter, setColumnFilter] = useState([]);
  const sortColumn = useRef(null);
  const sortType = useRef("asc");

  const setSortColumn = (value) => {
    sortColumn.current = value;
  }

  const setSortType = (value) => {
    if (value === "descend") {
      sortType.current = "desc";
    } else if (value === "ascend") {
      sortType.current = "asc";
    } else {
      sortType.current = "none";
    }
  }

  const onSortChange = async (sortCol, sortType) => {
    setSortColumn(sortCol);
    setSortType(sortType);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    await GetAllColors(currentPagination, currentCount);

  };

  const onClickCreateModal = () => {
    setShowCreationModal(!showCreationModal);
  };
  const onClickUpdateModal = async () => {
    updateForm.setFieldsValue(selectedRow);
    setShowUpdateModal(!showUpdateModel);
  };

  const GetAllColors = async (page = 1, limit = 10) => {
    setCurrentPagination(page);
    setTableLoading(true);
    const query = {
      page: page,
      limit: limit,
      sortColumn: sortColumn.current,
      sortType: sortType.current,
      columnFilter: columnFilter
    };
    const data = await FetchColors(query);
    setColors(data);
    setTableLoading(false);
  };

  const onFinishCreation = async () => {
    const values = await createForm.validateFields();
    await message.loading("Creating Colors...", 2);
    const payload = { data: values };

    const response = await CreateColors(payload);
    if (response.status === 201) {
      message.success("Successfuly Created New Color", 2);
      onClickCreateModal();
      createForm.resetFields();
      setCurrentPagination(1);
      onClearSearcheAndSelection();
      GetAllColors();
    }
  };

  const onFinishUpdate = async () => {
    const values = await updateForm.validateFields();
    const payload = { data: values };
    const response = await UpdateColors(selectedRowKey, payload);
    if (response.status === 200) {
      message.success("Successfuly Updated Color", 2);
      onClickUpdateModal();
      updateForm.resetFields();
      setCurrentPagination(1);
      onClearSearcheAndSelection();
      GetAllColors();
    }
  };

  const ExecuteDeleteColor = async (id) => {
    return await DeleteColor(id);
  };

  const onClickDeleteModal = async () => {
    confirm({
      title: "Are you sure delete this color?",
      icon: <ExclamationCircleOutlined />,
      content: `You will delete Color: ${selectedRow.general_name} (${selectedRow.specific_name})`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        await message.loading("Deleting Selected Color..", 2);
        const response = await ExecuteDeleteColor(selectedRowKey);
        if (response.status === 200) {
          await message.success(
            `Successfuly Deleted Color: ${selectedRow.general_name} (${selectedRow.specific_name})`,
            2
          );
          onClearSearcheAndSelection();
          GetAllColors();
          setCurrentPagination(1);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const onPaginationChange = (page, limit) => {
    setCurrentPagination(page);
    setCurrentCount(limit);
    GetAllColors(page, limit);
  };

  const onSelection = (selectedRowKey, selectedRow) => {
    setSelectedRow(selectedRow[0]);
    setSelectedRowKey(selectedRowKey[0]);
  };

  ///////////////////////////////
  // for column filter tags
  const onCloseColumnFilterTag = (id) => {
    setColumnFilterTags((prevState) => {
      return prevState.filter(
        (item) => item.id !== id
      );
    });

    setColumnFilter((prevState) => {
      let newData = prevState.filter(
        (item) => {

          return Array.isArray(item.id) ? JSON.stringify(item.id) != id : item.id != id;
        }
      );

      return newData;
    });

    if (!columnFilterTags.length) {
      setIsShowColumnFilter(false);
    }
  };

  const onLoadColumnFilterTags = () => {

    if (columnFilter.length) {
      let filterTags = columnFilter.map((e) => {
        let [label, tagId] = Array(2).fill(e.id);

        if (Array.isArray(e.id)) {
          tagId = JSON.stringify(tagId);
          label = "";

          for (let i = 0, n = e.id.length; i < n; i++) {
            label += `${e.id[i]} `;
          }
        }

        return {
          color: "processing",
          closable: true,
          selection_label: label,
          value: e.value,
          id: tagId,
          onClose: onCloseColumnFilterTag
        }
      })

      setColumnFilterTags(filterTags);
      setIsShowColumnFilter(true);
    } else {
      // if columnFilter is just blank, then remove the tags
      setColumnFilterTags([]);
      setIsShowColumnFilter(false);
    }
  };

  const onClearColumnFilters = () => {
    setColumnFilterTags([]);
    setIsShowColumnFilter(false);
    setColumnFilter([]);

  };

  useEffect(() => {
    GetAllColors();
  }, []);

  useComponentDidUpdate(() => {
    GetAllColors(1, currentCount);
    onLoadColumnFilterTags();
  }, [columnFilter]);

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const rolesRestriction = {};

    const CREATE_COLORS = permissions.find(
      (item) => item.name === "CREATE_COLORS"
    );

    const UPDATE_COLORS = permissions.find(
      (item) => item.name === "UPDATE_COLORS"
    );

    const DELETE_COLORS = permissions.find(
      (item) => item.name === "DELETE_COLORS"
    );

    rolesRestriction["CREATE_COLORS"] =
      typeof CREATE_COLORS === "undefined" ? 0 : CREATE_COLORS.has_access;

    rolesRestriction["UPDATE_COLORS"] =
      typeof UPDATE_COLORS === "undefined" ? 0 : UPDATE_COLORS.has_access;

    rolesRestriction["DELETE_COLORS"] =
      typeof DELETE_COLORS === "undefined" ? 0 : DELETE_COLORS.has_access;

    setRolesRestrictions(rolesRestriction);
  }, []);

  const onClearSearcheAndSelection = () => {
    setSelectedRow(null);
    setSelectedRowKey(null);
  };

  const formInputsComponent = [
    {
      name: "general_name",
      label: "General Name",
      default_value: "",
      configs: {
        type: "input",
        rules: [FormRules.REQUIRED_GENERAL_NAME],
        list_value: "",
        place_holder: "",
      },
    },
    {
      name: "specific_name",
      label: "Specific Name",
      default_value: "",
      configs: {
        type: "input",
        rules: [FormRules.REQUIRED_SPECIFIC_NAME],
      },
    },
    {
      name: "hex_code",
      label: "Hex Code",
      default_value: "",
      configs: {
        type: "input",
        rules: [],
      },
    },
  ];

  const { getColumnFilterProps } = useColumnFilter(columnFilter, setColumnFilter);

  const tableColumnsComponent = [
    { title: "Id", dataIndex: "id", key: "id", sorter: true, ...getColumnFilterProps('id') },
    { title: "General Name", key: "general_name", dataIndex: "general_name", sorter: true, ...getColumnFilterProps('general_name') },
    { title: "Specific Name", dataIndex: "specific_name", key: "specific_name", sorter: true, ...getColumnFilterProps('specific_name') },
    { title: "Hex Code", dataIndex: "hex_code", key: "hex_code", sorter: true, ...getColumnFilterProps('hex_code') },
    {
      title: "Attached Vehicle Count", dataIndex: "total_vehicle_count_attached"
    }
  ];

  return (
    <>
      <PageHeader
        title="COLORS"
        subTitle="COLORS MANAGMENT"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["Colors"]} />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <ModulesFunctionalitiesComponent
          alignment={"right"}
          functionalitiesComponent={[
            {
              component_type: "button",
              type: "primary",
              icon: <PlusCircleOutlined />,
              _onClick: onClickCreateModal,
              is_hidden: rolesRestriction.CREATE_COLORS === 0 ? true : false,
              label: "Create Color",
              size: "default",
            },
            {
              component_type: "button",
              type: "default",
              icon: <EditOutlined />,
              _onClick: onClickUpdateModal,
              is_hidden:
                rolesRestriction.UPDATE_COLORS === 0
                  ? true
                  : selectedRowKey
                    ? false
                    : true,
              label: "Update Color",
              size: "default",
            },
            {
              component_type: "button",
              type: "danger",
              icon: <DeleteOutlined />,
              _onClick: onClickDeleteModal,
              is_hidden:
                rolesRestriction.DELETE_COLORS === 0
                  ? true
                  : selectedRow
                    ? selectedRow.total_vehicle_count_attached > 0
                      ? true
                      : false
                    : true,
              label: "Delete Color",
              size: "default",
            },
          ]}
          spaceSize={"small"}
        />

        <ModulesSelectAndColumnTagsComponent
          onClearColumnFilters={onClearColumnFilters}
          isShowColumnFilterTags={isShowColumnFilter}
          columnFilterTags={columnFilterTags}
          onClearSearcheAndSelection={onClearSearcheAndSelection}
          isShowSelection={selectedRow ? true : false}
          tags={[
            {
              color: "processing",
              selection_label: "Selected Color",
              value: selectedRow
                ? `${selectedRow.general_name} - ${selectedRow.specific_name}`
                : null,
            },
          ]}
        />
        <ModulesTableComponent
          onPaginationChange={onPaginationChange}
          onSelection={onSelection}
          selectedRowKey={selectedRowKey}
          tableColumns={tableColumnsComponent}
          size={"small"}
          selectionType={"radio"}
          data={colors}
          currentPagination={currentPagination}
          onSortChange={onSortChange}
          tableLoading={tableLoading}
        />
      </div>
      <ModalwithFormComponent
        title={"Create Color"}
        form={createForm}
        formLayout={"vertical"}
        formInputsComponent={formInputsComponent}
        onOk={onFinishCreation}
        onCancel={onClickCreateModal}
        destroyOnClose={true}
        okText={"Save Color"}
        okCancel={"Cancel"}
        visible={showCreationModal}
      />
      <ModalwithFormComponent
        title={"Update Color"}
        form={updateForm}
        formLayout={"vertical"}
        formInputsComponent={formInputsComponent}
        onOk={onFinishUpdate}
        onCancel={onClickUpdateModal}
        destroyOnClose={true}
        okText={"Save Color"}
        okCancel={"Cancel"}
        visible={showUpdateModel}
      />
    </>
  );
};
