import React, { useState, useEffect, useRef } from "react";
import { PageHeader, Divider, Form } from "antd";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { FAQIndexListComponent } from "../sub_components/faq_index/FAQIndexListComponent";
import { FAQIndexCreationComponent } from "../sub_components/faq_index/FAQIndexCreationComponent";
import { FAQIndexUpdateComponent } from "../sub_components/faq_index/FAQIndexUpdateComponent";
import { FAQIndexFunctionalityComponent } from "../sub_components/faq_index/FAQIndexFunctionalityComponent";
import useFetchFAQsIndex from "../../hooks/faq_index/useFetchFAQsIndex";
import useCreateFAQsIndex from "../../hooks/faq_index/useCreateFAQsIndex";
import useUpdateFAQsIndex from "../../hooks/faq_index/useUpdateFAQsIndex";
import { useComponentDidUpdate } from "../../hooks/useComponentDidUpdate";
import { ModulesSelectAndColumnTagsComponent } from "../ModulesSelectAndColumnTagsComponent";

const initialTableState = {
  total: 0,
  data: [],
};
export const FAQIndexManagementComponent = () => {
  const [createIndexForm] = Form.useForm();
  const [updateIndexForm] = Form.useForm();
  const [faqIndices, setFaqIndices] = useState(initialTableState);
  const [currentPagination, setCurrentPagination] = useState(1);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [faqIndexModalStatus, setfaqIndexModalStatus] = useState(false);
  const [faqIndexUpdateModalStatus, setfaqIndexUpdateModalStatus] =
    useState(false);

  const [isShowColumnFilter, setIsShowColumnFilter] = useState(false);
  const [columnFilterTags, setColumnFilterTags] = useState([]);

  const [currentCount, setCurrentCount] = useState(10);
  const [columnFilter, setColumnFilter] = useState([]);
  const sortColumn = useRef(null);
  const sortType = useRef("asc");

  const setSortColumn = (value) => {
    sortColumn.current = value;
  }

  const setSortType = (value) => {
    if (value === "descend") {
      sortType.current = "desc";
    } else if (value === "ascend") {
      sortType.current = "asc";
    } else {
      sortType.current = "none";
    }
  }

  const onSortChange = async (sortCol, sortType) => {
    setSortColumn(sortCol);
    setSortType(sortType);

    await ExecuteFetchFAQIndexHook(currentPagination, currentCount);
  };

  const ExecuteFetchFAQIndexHook = async (
    page = 1,
    limit = 10,
    filter = ""
  ) => {
    setCurrentPagination(page);
    const query = {
      page,
      limit,
      filter,
      sortColumn: sortColumn.current,
      sortType: sortType.current,
      columnFilter: columnFilter
    };
    const data = await useFetchFAQsIndex(true, query);
    setFaqIndices(data);
  };


  ///////////////////////////////
  // for column filter tags
  const onCloseColumnFilterTag = (id) => {
    setColumnFilterTags((prevState) => {
      return prevState.filter(
        (item) => item.id !== id
      );
    });

    setColumnFilter((prevState) => {
      let newData = prevState.filter(
        (item) => {

          return Array.isArray(item.id) ? JSON.stringify(item.id) != id : item.id != id;
        }
      );

      return newData;
    });

    if (!columnFilterTags.length) {
      setIsShowColumnFilter(false);
    }
  };

  const onLoadColumnFilterTags = () => {

    if (columnFilter.length) {
      let filterTags = columnFilter.map((e) => {
        let [label, tagId] = Array(2).fill(e.id);

        if (Array.isArray(e.id)) {
          tagId = JSON.stringify(tagId);
          label = "";

          for (let i = 0, n = e.id.length; i < n; i++) {
            label += `${e.id[i]} `;
          }
        }

        return {
          color: "processing",
          closable: true,
          selection_label: label,
          value: e.value,
          id: tagId,
          onClose: onCloseColumnFilterTag
        }
      })

      setColumnFilterTags(filterTags);
      setIsShowColumnFilter(true);
    } else {
      // if columnFilter is just blank, then remove the tags
      setColumnFilterTags([]);
      setIsShowColumnFilter(false);
    }
  };

  const onClearColumnFilters = () => {
    setColumnFilterTags([]);
    setIsShowColumnFilter(false);
    setColumnFilter([]);

  };

  useEffect(() => {
    ExecuteFetchFAQIndexHook();
  }, []);

  useComponentDidUpdate(() => {
    ExecuteFetchFAQIndexHook(1, currentCount);
    onLoadColumnFilterTags();

  }, [columnFilter]);

  const onPaginationChange = (page, limit) => {
    setCurrentPagination(page);
    setCurrentCount(limit);
    ExecuteFetchFAQIndexHook(page, limit, "");
  };

  const onSelection = (selectedRowKey, selectedRow) => {
    setSelectedRowKey(selectedRowKey[0]);
    setSelectedRow(selectedRow);

    if (selectedRow) {
      updateIndexForm.setFieldsValue(selectedRow[0]);
    }
  };

  const onClickFAQIndexCreationButton = () => {
    setfaqIndexModalStatus(!faqIndexModalStatus);
  };

  const onClickUpdateFAQIndexButton = () => {
    setfaqIndexUpdateModalStatus(!faqIndexUpdateModalStatus);
  };

  const OnSubmitCreation = async () => {
    const values = await createIndexForm.validateFields();
    const isCreated = await useCreateFAQsIndex(values);
    if (isCreated) {
      reset();
    }
  };

  const OnSubmitUpdate = async () => {
    const values = await updateIndexForm.validateFields();
    const faqIndexId = selectedRowKey;
    const isUpdated = await useUpdateFAQsIndex(faqIndexId, values);
    if (isUpdated) {
      reset();
    }
  };

  const reset = () => {
    createIndexForm.resetFields();
    updateIndexForm.resetFields();
    setfaqIndexModalStatus();
    setfaqIndexUpdateModalStatus();
    setCurrentPagination(1);
    ExecuteFetchFAQIndexHook();
    setSelectedRowKey(null);
    setSelectedRow(null);
  };

  const OnCancelCreate = async () => {
    onClickFAQIndexCreationButton();
  };

  const OnCancelUpdate = async () => {
    onClickUpdateFAQIndexButton();
  };

  return (
    <>
      <PageHeader
        title="FAQ Index"
        subTitle="List of Frequesntly Asked Questions"
        breadcrumbRender={() => (
          <BreadCrumbsComponent
            breadcrumbsItem={["CMS", "FAQ's", "FAQ Index"]}
          />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <FAQIndexFunctionalityComponent
          onClickCreateFAQIndexButton={onClickFAQIndexCreationButton}
          onClickUpdateFAQIndexButton={onClickUpdateFAQIndexButton}
          selectedRowKey={selectedRowKey}
        />
        <ModulesSelectAndColumnTagsComponent
          onClearColumnFilters={onClearColumnFilters}
          isShowColumnFilterTags={isShowColumnFilter}
          columnFilterTags={columnFilterTags}
        />
        <FAQIndexListComponent
          data={faqIndices}
          currentPagination={currentPagination}
          selectedRowKey={selectedRowKey}
          onSelection={onSelection}
          onPaginationChange={onPaginationChange}
          columnFilter={columnFilter}
          setColumnFilter={setColumnFilter}
          onSortChange={onSortChange}
        />
      </div>
      <FAQIndexCreationComponent
        onCancel={OnCancelCreate}
        onOk={OnSubmitCreation}
        form={createIndexForm}
        visible={faqIndexModalStatus}
      />
      <FAQIndexUpdateComponent
        onCancel={OnCancelUpdate}
        onOk={OnSubmitUpdate}
        form={updateIndexForm}
        visible={faqIndexUpdateModalStatus}
      />
    </>
  );
};
