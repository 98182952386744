import { useCreate } from "../../hooks/useCreate";

export const CreateNewModules = async (selectedRowkey, modules_body) => {
  try {
    return await useCreate(
      `${process.env.REACT_APP_API_URL}/modules/${selectedRowkey}`,
      modules_body
    );
  } catch (err) {
    console.log(err);
    return null;
  }
};
