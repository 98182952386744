import {Form, Input, Button, Space} from "antd";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {useState, useEffect} from "react";
export default function MetaDataFields({metadata, cardIndex, setMediaApi}) {
    //TODO: controlled input for metadata (DONE)
    const [inputArray, setInputArray] = useState([]);

    useEffect(() => {
        setInputArray(metadata);
    }, [metadata]);

    const updateTransactionTypeOnChange = () => {
        //for changing transaction_type to FOR_UPDATING
        setMediaApi((prevState) => {
            if (prevState[cardIndex].transaction_type !== null)
                return prevState;

            const newState = prevState.map((x, i) => ({
                ...x,
            }));
            newState[cardIndex].transaction_type =
                newState[cardIndex].transaction_type != "FOR_NEW_UPLOADING"
                    ? "FOR_UPDATING"
                    : newState[cardIndex].transaction_type;
            return newState;
        });
    };
    const handleInputOnChange = (index, key, value) => {
        updateTransactionTypeOnChange();
        //for controlled input
        setInputArray((prevState) => {
            const newState = prevState.map((x, i) => ({
                ...x,
            }));
            newState[index][key] = value;
            return newState;
        });
    };
    const handleAddOrRemoveInput = (pairIndex) => {
        //set to FOR_UPDATING if the deleted index has value
        if (
            typeof pairIndex !== "undefined" &&
            inputArray[pairIndex].name.length > 0
        ) {
            updateTransactionTypeOnChange();
        }

        setInputArray((prevState) => {
            const clonedRawState = prevState.map((x) => ({
                ...x,
            }));
            let newState;
            if (typeof pairIndex !== "undefined") {
                //remove
                newState = clonedRawState.filter((x, i) => i != pairIndex);
            } else {
                //add
                newState = clonedRawState.concat({
                    name: "",
                    value: "",
                });
            }

            return newState;
        });
    };

    return (
        <Form
            name={"form_" + cardIndex}
            className={"metadata-forms"}
            autoComplete="off"
        >
            <>
                {inputArray.map(({name, value}, i) => {
                    return (
                        <Space key={i} align="baseline">
                            <Input
                                // defaultValue={name}
                                value={name}
                                size="small"
                                placeholder="Name"
                                name="name"
                                onChange={(e) =>
                                    handleInputOnChange(
                                        i,
                                        "name",
                                        e.target.value
                                    )
                                }
                            />
                            <Input
                                // defaultValue={value}
                                value={value}
                                size="small"
                                placeholder="Value"
                                name="value"
                                onChange={(e) =>
                                    handleInputOnChange(
                                        i,
                                        "value",
                                        e.target.value
                                    )
                                }
                            />

                            <MinusCircleOutlined
                                onClick={() => handleAddOrRemoveInput(i)}
                            />
                        </Space>
                    );
                })}
                <Button
                    type="dashed"
                    onClick={() => handleAddOrRemoveInput()}
                    block
                    icon={<PlusOutlined />}
                >
                    Add Meta Data
                </Button>
            </>
        </Form>
    );
}
