import { Input, Button } from "antd";
import { RetweetOutlined } from "@ant-design/icons";

//custom hook
import useClassName from "../../../hooks/useClassName";

const { Search } = Input;

export default function SearchBar({
  hasPageData,
  textToSearch,
  searchInput,
  setSearchInput,
  setTextToSearch,
  onSearch,
  fetchListData,
  loadingList,
}) {
  //custom hooks
  const getClassName = useClassName();

  const handleChange = (e) => {
    const value = e.target.value;
    const spaceRegex = /\s/;
    !spaceRegex.test(value) && setSearchInput(value);
  };

  return (
    <div className={getClassName("ads-navbar")}>
      <Search
        value={searchInput}
        onChange={handleChange}
        onSearch={(value) => {
          value && onSearch(searchInput);
        }}
        allowClear
        placeholder="Plate Number / Conduction Sticker"
      />
      {textToSearch ? (
        <>
          <h2>Search results for "{textToSearch}"</h2>
          <Button
            icon={<RetweetOutlined />}
            onClick={async () => {
              setTextToSearch(() => "");
            }}
          >
            Back to "All Entries"
          </Button>
        </>
      ) : (
        <>
          <h2>All Entries</h2>
          <Button loading={loadingList} onClick={() => fetchListData(1, 10)}>
            Refresh List
          </Button>
        </>
      )}
    </div>
  );
}
