import React from "react";
import { Space, Button, Modal } from "antd";
import { ExclamationCircleOutlined, RedoOutlined, FilterOutlined } from "@ant-design/icons";
import BidsReportsComponent from "./BidsReportsComponent";
import useIsMobile from "../../../hooks/useIsMobile";

const { confirm } = Modal;

const usePermissionCheck = (permissions, permissionName, isHidden) => {
  const hasAccess = permissions[permissionName] === 1;
  return isHidden ? !hasAccess : hasAccess ? null : "none";
};

export const BidsFunctionalityComponent = ({
  isUpdateButtonHidden = true,
  OnUpdateStatus,
  data = [],
  bidReportsPermissions,
  onClickFilterBids,
}) => {
  const isMobile = useIsMobile();

  const showConfirm = () => {
    confirm({
      centered: true,
      title: "Update Status",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to update the status of this unit?",
      onOk: OnUpdateStatus,
    });
  };

  const cancelBidHidden = usePermissionCheck(
    bidReportsPermissions,
    "UPDATE_WINNING_BIDDERS_STATUS",
    true
  );

  const reportDisplay = usePermissionCheck(
    bidReportsPermissions,
    "GENERATE_WINNING_BIDDERS_REPORT",
    false
  );

  console.log(data, "data");

  return (
    <div style={{ marginTop: "2%", textAlign: isMobile ? "center" : "right" }}>
      <Space size="small" direction={isMobile ? "vertical" : "horizontal"}>
        {!isUpdateButtonHidden && (
          <Button
            type="outlined"
            icon={<RedoOutlined />}
            onClick={showConfirm}
            hidden={cancelBidHidden}
            block={isMobile}
            danger
          >
            Cancel Bid
          </Button>
        )}

        <Button
          type="primary"
          title="Filter Bids"
          icon={<FilterOutlined />}
          onClick={onClickFilterBids}
          block={isMobile}
        >
          Filter Bids
        </Button>

        <div style={{ display: reportDisplay }}>
          <BidsReportsComponent dataSet={data} isMobile={isMobile} />
        </div>
      </Space>
    </div>
  );
};
