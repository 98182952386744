import { useUpdate } from "../../hooks/useUpdate";

export const UpdateSpecialists = async (payload, specialist_id) => {
	try {
		return await useUpdate(`${process.env.REACT_APP_API_URL}/specialists/${specialist_id}`, payload);
	} catch (err) {
		return err;
		// if (err.response) return err.response;
		// if (err.request) return err.request;
		// return err.message;
	}
};
