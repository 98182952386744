import React from "react";
import { Empty } from "antd";
import { ModulesTableComponent } from "../../ModulesTableComponent";
import { ModulesFunctionalitiesComponent } from "../../ModulesFunctionalitiesComponent";
import { ModulesFilterAndSelectionComponent } from "../../ModulesFilterAndSelectionComponent";
import { SelectedReviewItemListComponent } from "./SelectedReviewItemListComponent";
import useGetReviewGroupListColumns from "../../../hooks/reviews_group/useGetReviewGroupListColumns";

export const ReviewsGroupListComponent = (props) => {
  const {
    data = { data: [], total: 0 },
    onPaginationChange,
    currentPagination,
    onSelection,
    selectedRowKey,
    onClickEditReviewGroup,
    onClickRestoreReviewGroup,
    onSearchReviewsGroup,
    tags = [],
    onClearSearcheAndSelection,
    isShowSelection = false,
    rolesRestriction,
    tableLoading,
  } = props;

  const reviewsGroup = useGetReviewGroupListColumns(
    onClickEditReviewGroup,
    onClickRestoreReviewGroup,
    rolesRestriction
  );

  const functionalitiesComponent = [
    {
      component_type: "search_input",
      _onSearch: onSearchReviewsGroup,
      place_holder: "Search Reviews Group",
    },
  ];

  const returnExpandableProps = () => {
    return {
      expandedRowRender: (record) => {
        const review_index_list = [];

        record.reviews_pivot.forEach((item) => {
          review_index_list.push(item.reviews_index);
        });

        return (
          <div style={{ padding: "2%" }}>
            {review_index_list.length > 0 ? (
              <SelectedReviewItemListComponent
                listData={review_index_list}
                transactionType="for_creation"
              />
            ) : (
              <Empty description={"No Data"} />
            )}
          </div>
        );
      },
    };
  };

  return (
    <div>
      <ModulesFunctionalitiesComponent
        functionalitiesComponent={functionalitiesComponent}
        alignment="right"
        spaceSize={0}
      />
      <ModulesFilterAndSelectionComponent
        isShowSelection={isShowSelection}
        onClearSearcheAndSelection={onClearSearcheAndSelection}
        tags={tags}
      />
      <ModulesTableComponent
        data={data}
        onPaginationChange={onPaginationChange}
        onSelection={onSelection}
        selectedRowKey={selectedRowKey}
        tableColumns={reviewsGroup || []}
        currentPagination={currentPagination}
        hasSelection={false}
        expandable={returnExpandableProps()}
        tableLoading={tableLoading}
      />
    </div>
  );
};
