import React from "react";
import { ModulesTableComponent } from "../../ModulesTableComponent";
import useGetFAQsColumn from "../../../hooks/faq/useGetFAQsColumn";
import useColumnFilter from "../../../hooks/useColumnFilter";

export const FAQListComponent = (props) => {
  const {
    data = { data: [], total: 0 },
    onPaginationChange,
    currentPagination,
    onSelection,
    selectedRowKey,
    onSortChange,
    columnFilter,
    setColumnFilter
  } = props;

  const faqsColumns = useGetFAQsColumn();
  const { getColumnFilterProps } = useColumnFilter(columnFilter, setColumnFilter);

  let newCols = [];

  for (let i = 0, n = faqsColumns.length; i < n; i++) {
    newCols.push({ ...faqsColumns[i], sorter: true, ...getColumnFilterProps(faqsColumns[i].dataIndex) })
  }

  return (
    <div>
      <ModulesTableComponent
        data={data}
        onPaginationChange={onPaginationChange}
        onSelection={onSelection}
        selectedRowKey={selectedRowKey}
        tableColumns={newCols || []}
        currentPagination={currentPagination}
        columnFilter={columnFilter}
        setColumnFilter={setColumnFilter}
        onSortChange={onSortChange}
      />
    </div>
  );
};
