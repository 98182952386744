import React from "react";
import { List, Button, Empty, Tag } from "antd";
import { RightCircleOutlined } from "@ant-design/icons";

export const SelectedRemarkItemListComponent = ({
  listData,
  transactionType,
  onClickUnselectButton,
  selectedClassification,
}) => {
  return (
    <>
      {listData.length > 0 ? (
        <div style={{ overflowY: "auto", height: "400px" }}>
          <List
            itemLayout="vertical"
            size="large"
            dataSource={listData}
            renderItem={(item) => (
              <List.Item
                key={item.title}
                extra={
                  <div style={{ textAlign: "center" }}>
                    <Button
                      type="default"
                      size="small"
                      icon={<RightCircleOutlined />}
                      danger
                      hidden={transactionType === "for_creation" ? true : false}
                      onClick={() =>
                        onClickUnselectButton(item, selectedClassification)
                      }
                    >
                      Un-Select Remarks
                    </Button>
                  </div>
                }
              >
                <List.Item.Meta
                  description={
                    <Tag
                      color={
                        item.classification === "advantages" ? "green" : "red"
                      }
                    >
                      {item.classification}
                    </Tag>
                  }
                />
                {item.content}
              </List.Item>
            )}
          />
        </div>
      ) : (
        <Empty />
      )}
    </>
  );
};
