import { useFetch } from "../../hooks/useFetch";

export const FetchBankList = async (query) => {
  try {
    const data = await useFetch(
      `${process.env.REACT_APP_API_URL}/banks`,
      query
    );

    for (const item of data.data.data) {
      item["key"] = item.id;
    }

    return data.data;
  } catch (err) {
    return null;
  }
};
