import React, { useState, useEffect } from "react";
import { PageHeader, Divider } from "antd";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { CMSAssetsTableComponent } from "../sub_components/cms_assets/CMSAssetsTableComponent";
import { CMSAssetsFunctionalityComponent } from "../sub_components/cms_assets/CMSAssetsFunctionalityComponent";
import fetchAssets from "../../hooks/cms/useFetchAssets";

const initialAssetState = {
  IsTruncated: false,
  Contents: [],
  Name: null,
  Prefix: null,
  MaxKeys: 0,
  CommonPrefixes: [],
  KeyCount: 0,
  NextContinuationToken: null,
};

const defaultBucketName = "automart-dev-staging-assets";

export const CMSAssetsManagementComponent = () => {
  const [assets, setAssets] = useState(initialAssetState);

  const executeFetchAssets = async (
    query = { bucket_name: defaultBucketName }
  ) => {
    const data = await fetchAssets(query);
    setAssets(data);
  };

  useEffect(() => {
    executeFetchAssets();
  }, []);

  return (
    <>
      <PageHeader
        title="ASSETS"
        subTitle="CMS ASSETS MANAGMENT"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["Manage Assets"]} />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <CMSAssetsFunctionalityComponent />
        <CMSAssetsTableComponent assets={assets} />
      </div>
    </>
  );
};
