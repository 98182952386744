import { FetchFAQs } from "../../api/faq/FetchFAQs";

const useFetchFAQs = async (isForTable = true, query = {}) => {
  const selectOptionQuery = {
    page: 1,
    limit: null,
  };

  const finalQuery = isForTable ? query : selectOptionQuery;
  const data = await FetchFAQs(finalQuery);

  for (const item of data.data.data) {
    item["key"] = item.id;
  }

  return data.data;
};

export default useFetchFAQs;
