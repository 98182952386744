const createRoleTransformer = (data) => {
  const payload = {
    roles_id: parseInt(data.roles_id),
    user_id: parseInt(data.user_id),
    is_default: data.is_default,
    permissions: data.permissions || [],
  };

  return payload;
};

const createRoleTransformerByNewUser = (data) => {
  return {
    roles_id: parseInt(data.user_attached_roles),
    is_default: data.is_default,
    permissions: data.permissions || [],
  };
};

export { createRoleTransformer, createRoleTransformerByNewUser };
