import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link,
} from "react-router-dom";
import { Button, Result } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useFetch } from "../hooks/useFetch";
import { DashboardComponent } from "./modules/DashboardComponent";
import { ModulesComponent } from "./modules/ModulesComponent";
import { PlatformComponent } from "./modules/PlatformComponent";
import { RolesAccessComponent } from "./modules/RolesAccessComponent";
import { PermissionIndexComponent } from "./modules/PermissionIndexComponent";
import { UserManagementComponent } from "./modules/UserManagementComponent";
import { WarehouseManagementComponent } from "./modules/WarehouseManagementComponent";
import { BanksManagementComponent } from "./modules/BanksManagementComponent";
import { PostManagementComponent } from "./modules/PostManagementComponent";
import { PagesManagementComponent } from "./modules/PagesManagementComponent";
import { CMSPostContentManagementComponent } from "./modules/CMSPostContentManagementComponent";
import { AdsManagementComponent } from "./modules/AdsManagementComponent";
import { CarsManagementComponent } from "./modules/CarsManagementComponent";
import { SpecialistsManagementComponent } from "./modules/SpecialistsManagementComponent";
import { UserEditPageComponent } from "./sub_components/user/UserEditPageComponent";
import { BiddingWinnersManagementComponent } from "./modules/BiddingWinnersManagementComponent";
import { BuyNowWinnersManagementComponent } from "./modules/BuyNowWinnersManagementComponent";
import { BulkUploaderManagementComponent } from "./modules/BulkUploaderManagementComponent";
import { CMSAssetsManagementComponent } from "./modules/CMSAssetsManagementComponent";
import { ActivityLogsManagementComponent } from "./modules/ActivityLogsManagementComponent";
import { FAQManagementComponent } from "./modules/FAQManagementComponent";
import { FAQIndexManagementComponent } from "./modules/FAQIndexManagementComponent";
import { ReviewsManagementComponent } from "./modules/ReviewsManagementComponent";
import { ReviewsGroupManagementComponent } from "./modules/ReviewsGroupManagementComponent";
import { RemarksSubGroupManagement } from "./modules/RemarksSubGroupManagementComponent";
import { RemarksIndexManagementComponent } from "./modules/RemarksIndexManagementComponent";
import { ForbiddenResult403Component } from "./ForbiddenResult403Component";
import { EditAdsComponent } from "./modules/EditAdsComponent";
import { OptionsComponent } from "./modules/OptionsComponent";
import { ItemMakesManagementComponent } from "./modules/ItemMakesManagementComponent";
import { ItemModelsManagementComponent } from "./modules/ItemModelsManagementComponent";
import { ItemVehicleTypeManagementComponent } from "./modules/ItemVehicleTypeManagementComponent";
import { ColorsComponent } from "./modules/ColorsComponent";
import { AuctionCloserComponent } from "./modules/AuctionCloserComponent";
import { LoginComponent } from "./LoginComponent";
import { MenuComponent } from "./MenuComponent";
import { isLogin } from "../dist/functions/auth";
import useFetchPermissionSets from "../hooks/useFetchPermissions";
import useFetchIfhasMenuAccess from "../hooks/useFetchIfhasMenuAccess";
import {
  permissionsList,
  createDefaultPermissionSet,
} from "../dist/statics/permission_directory";
import { XMLManagementComponent } from "./modules/XMLManagementComponent";
import useRevalidateSession from "../hooks/authentication/useValidateSession";
import { SessionComponent } from "./modules/SessionComponent";

const initialRolesRestriction = createDefaultPermissionSet();

const dashboardRoutes = {
  dashboard: {
    path: "/dashboard",
    component: DashboardComponent,
  },
  administration: {
    platform: {
      path: "/administration/platform",
      component: PlatformComponent,
    },
    modules: { path: "/administration/modules", component: ModulesComponent },
    permission_index: {
      path: "/administration/permission-index",
      component: PermissionIndexComponent,
    },
    roles_access: {
      path: "/administration/roles-access",
      component: RolesAccessComponent,
    },
    user: {
      path: "/administration/user-management",
      component: UserManagementComponent,
    },
    adviser: {
      path: "/administration/adviser-management",
      component: SpecialistsManagementComponent,
    },
  },
  providers: {
    path: "/providers",
    component: BanksManagementComponent,
  },
  warehouses: {
    path: "/warehouses",
    component: WarehouseManagementComponent,
  },
  cms: {
    page: {
      path: "/cms/pages",
      component: PagesManagementComponent,
    },
    post: {
      path: "/cms/posts",
      component: PostManagementComponent,
    },
    asset: {
      path: "/cms/assets",
      component: CMSAssetsManagementComponent,
    },
    sub_contents: {
      post: {
        path: "/cms/post/sub-contents",
        component: CMSPostContentManagementComponent,
      },
    },
    faqs: {
      index: {
        path: "/faq/index",
        component: FAQIndexManagementComponent,
      },
      group: {
        path: "/faq/group",
        component: FAQManagementComponent,
      },
    },
    remarks: {
      index: {
        path: "/remarks/index",
        component: RemarksIndexManagementComponent,
      },
      group: {
        path: "/remarks/group",
        component: RemarksSubGroupManagement,
      },
    },
  },
  reviews: {
    index: {
      path: "/reviews",
      component: ReviewsManagementComponent,
    },
    group: {
      path: "/reviews-group",
      component: ReviewsGroupManagementComponent,
    },
  },
  ads: {
    path: "/ads-management",
    component: AdsManagementComponent,
  },
  products: {
    cars: {
      path: "/products/cars",
      component: CarsManagementComponent,
    },
  },
  auctions: {
    bidding_winners: {
      path: "/auctions/biddings-winner",
      component: BiddingWinnersManagementComponent,
    },
    buy_now_winners: {
      path: "/auctions/buynow-winner",
      component: BuyNowWinnersManagementComponent,
    },
    auction_closer: {
      path: "/auctions/closer",
      component: AuctionCloserComponent,
    },
  },
  bulk_uploader: {
    path: "/bulk-uploader",
    component: BulkUploaderManagementComponent,
  },
  edit_ads: {
    path: "/ads-editor",
    component: EditAdsComponent,
    subPath: {
      path: "/ads-editor/:slug",
      component: EditAdsComponent,
    },
  },
  settings: {
    path: "/settings",
    component: OptionsComponent,
  },

  item_settings: {
    makes: {
      path: "/item/makes",
      component: ItemMakesManagementComponent,
    },
    models: {
      path: "/item/models",
      component: ItemModelsManagementComponent,
    },
    vehicle_types: {
      path: "/item/vehicle-types",
      component: ItemVehicleTypeManagementComponent,
    },
    colors: {
      path: "/item/colors",
      component: ColorsComponent,
    },
  },
  activity: {
    path: "/activity",
    component: ActivityLogsManagementComponent,
  },
  xml: {
    path: "/xml",
    component: XMLManagementComponent,
  },
  session: {
    path: "/active-sessions",
    component: SessionComponent,
  },
};

export const RoutesComponent = () => {
  const { checkSession } = useRevalidateSession();
  const [permissions, setPermissions] = useState([]);

  checkSession();

  const rolesRestriction = useFetchPermissionSets(
    permissionsList,
    initialRolesRestriction
  );

  const {
    CLOSE_AUCTIONS_BY_BANK,
    CLOSE_AUCTIONS_BY_FILE,
    REVIEW_GROUP_CREATE,
    REVIEW_GROUP_UPDATE,
    REVIEW_GROUP_DELETE,
    REVIEW_GROUP_RESTORE,
    REVIEW_INDEX_CREATE,
    REVIEW_INDEX_UPDATE,
    REVIEW_INDEX_DELETE,
    REVIEW_INDEX_RESTORE,
    CREATE_REMARKS_SUB_GROUP,
    UPDATE_REMARKS_SUB_GROUP,
    DELETE_REMARKS_SUB_GROUP,
    RESTORE_REMARKS_SUB_GROUP,
    CREATE_REMARKS_INDEX,
    UPDATE_REMARKS_INDEX,
    DELETE_REMARKS_INDEX,
    RESTORE_REMARKS_INDEX,
  } = rolesRestriction;

  const reviewGroupPermissionSet = [
    REVIEW_GROUP_CREATE,
    REVIEW_GROUP_UPDATE,
    REVIEW_GROUP_DELETE,
    REVIEW_GROUP_RESTORE,
  ];

  const reviewGroupHasAccess = useFetchIfhasMenuAccess(
    reviewGroupPermissionSet
  );

  const reviewIndexPermissionSet = [
    REVIEW_INDEX_CREATE,
    REVIEW_INDEX_UPDATE,
    REVIEW_INDEX_DELETE,
    REVIEW_INDEX_RESTORE,
  ];

  const reviewIndexHasAccess = useFetchIfhasMenuAccess(
    reviewIndexPermissionSet
  );

  const remarksSubGroupPermissionSet = [
    CREATE_REMARKS_SUB_GROUP,
    UPDATE_REMARKS_SUB_GROUP,
    DELETE_REMARKS_SUB_GROUP,
    RESTORE_REMARKS_SUB_GROUP,
  ];

  const remarksSubGroupHasAccess = useFetchIfhasMenuAccess(
    remarksSubGroupPermissionSet
  );

  const remarksIndexPermissionSet = [
    CREATE_REMARKS_INDEX,
    UPDATE_REMARKS_INDEX,
    DELETE_REMARKS_INDEX,
    RESTORE_REMARKS_INDEX,
  ];

  const remarksIndexHasAccess = useFetchIfhasMenuAccess(
    remarksIndexPermissionSet
  );

  const FetchUser = async (token) => {
    return await useFetch(
      `${process.env.REACT_APP_API_URL}/authentications/tokens/${token}`
    );
  };

  const ReturnAuthenticationStatus = async (token) => {
    const result = await FetchUser(token);
    if (result.data[0].user.user_type !== "admin") {
      return false;
    }

    if (result.data[0].is_revoked === 0) {
      localStorage.setItem("first_name", result.data[0].user.first_name);
      return true;
    }

    return false;
  };

  return (
    <Router>
      <Switch>
        <Route exact path="/login">
          {!isLogin() ? (
            <LoginComponent
              setPermissions={setPermissions}
              ReturnAuthenticationStatus={ReturnAuthenticationStatus}
            />
          ) : (
            <Redirect to={{ pathname: "/dashboard" }} />
          )}
        </Route>

        <Route exact path="/">
          {isLogin() ? (
            <Redirect to={{ pathname: "/dashboard" }} />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        <Route exact path="/dashboard">
          {isLogin() ? (
            <MenuComponent
              permissions={permissions}
              setPermissions={setPermissions}
              dashboardRoutes={dashboardRoutes.dashboard}
            />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        <Route exact path="/administration/platform">
          {isLogin() ? (
            <MenuComponent
              permissions={permissions}
              setPermissions={setPermissions}
              dashboardRoutes={dashboardRoutes.administration.platform}
            />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        <Route exact path="/administration/modules">
          {isLogin() ? (
            <MenuComponent
              permissions={permissions}
              setPermissions={setPermissions}
              dashboardRoutes={dashboardRoutes.administration.modules}
            />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        <Route exact path="/administration/permission-index">
          {isLogin() ? (
            <MenuComponent
              permissions={permissions}
              setPermissions={setPermissions}
              dashboardRoutes={dashboardRoutes.administration.permission_index}
            />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        <Route exact path="/administration/roles-access">
          {isLogin() ? (
            <MenuComponent
              permissions={permissions}
              setPermissions={setPermissions}
              dashboardRoutes={dashboardRoutes.administration.roles_access}
            />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        <Route exact path="/administration/user-management">
          {isLogin() ? (
            <MenuComponent
              permissions={permissions}
              setPermissions={setPermissions}
              dashboardRoutes={dashboardRoutes.administration.user}
            />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        <Route exact path="/administration/adviser-management">
          {isLogin() ? (
            <MenuComponent
              permissions={permissions}
              setPermissions={setPermissions}
              dashboardRoutes={dashboardRoutes.administration.adviser}
            />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        <Route exact path="/providers">
          {isLogin() ? (
            <MenuComponent
              permissions={permissions}
              setPermissions={setPermissions}
              dashboardRoutes={dashboardRoutes.providers}
            />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        <Route exact path="/warehouses">
          {isLogin() ? (
            <MenuComponent
              permissions={permissions}
              setPermissions={setPermissions}
              dashboardRoutes={dashboardRoutes.warehouses}
            />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        <Route exact path="/cms/pages">
          {isLogin() ? (
            <MenuComponent
              permissions={permissions}
              setPermissions={setPermissions}
              dashboardRoutes={dashboardRoutes.cms.page}
            />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        <Route exact path="/cms/posts">
          {isLogin() ? (
            <MenuComponent
              permissions={permissions}
              setPermissions={setPermissions}
              dashboardRoutes={dashboardRoutes.cms.post}
            />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        <Route exact path={dashboardRoutes.cms.asset.path}>
          {isLogin() ? (
            <MenuComponent
              permissions={permissions}
              setPermissions={setPermissions}
              dashboardRoutes={dashboardRoutes.cms.asset}
            />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        <Route exact path={dashboardRoutes.cms.sub_contents.post.path}>
          {isLogin() ? (
            <MenuComponent
              permissions={permissions}
              setPermissions={setPermissions}
              dashboardRoutes={dashboardRoutes.cms.sub_contents.post}
            />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        <Route exact path={dashboardRoutes.cms.faqs.group.path}>
          {isLogin() ? (
            <MenuComponent
              permissions={permissions}
              setPermissions={setPermissions}
              dashboardRoutes={dashboardRoutes.cms.faqs.group}
            />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        <Route exact path={dashboardRoutes.cms.faqs.index.path}>
          {isLogin() ? (
            <MenuComponent
              permissions={permissions}
              setPermissions={setPermissions}
              dashboardRoutes={dashboardRoutes.cms.faqs.index}
            />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        <Route exact path={dashboardRoutes.cms.remarks.index.path}>
          {isLogin() ? (
            remarksIndexHasAccess ? (
              <MenuComponent
                permissions={permissions}
                setPermissions={setPermissions}
                dashboardRoutes={dashboardRoutes.cms.remarks.index}
              />
            ) : (
              <ForbiddenResult403Component />
            )
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        <Route exact path={dashboardRoutes.cms.remarks.group.path}>
          {isLogin() ? (
            remarksSubGroupHasAccess ? (
              <MenuComponent
                permissions={permissions}
                setPermissions={setPermissions}
                dashboardRoutes={dashboardRoutes.cms.remarks.group}
              />
            ) : (
              <ForbiddenResult403Component />
            )
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        <Route exact path={dashboardRoutes.reviews.index.path}>
          {isLogin() ? (
            reviewIndexHasAccess ? (
              <MenuComponent
                permissions={permissions}
                setPermissions={setPermissions}
                dashboardRoutes={dashboardRoutes.reviews.index}
              />
            ) : (
              <ForbiddenResult403Component />
            )
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        <Route exact path={dashboardRoutes.reviews.group.path}>
          {isLogin() ? (
            reviewGroupHasAccess ? (
              <MenuComponent
                permissions={permissions}
                setPermissions={setPermissions}
                dashboardRoutes={dashboardRoutes.reviews.group}
              />
            ) : (
              <ForbiddenResult403Component />
            )
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        <Route exact path="/ads-management">
          {isLogin() ? (
            <MenuComponent
              permissions={permissions}
              setPermissions={setPermissions}
              dashboardRoutes={dashboardRoutes.ads}
            />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        <Route exact path="/products/cars">
          {isLogin() ? (
            <MenuComponent
              permissions={permissions}
              setPermissions={setPermissions}
              dashboardRoutes={dashboardRoutes.products.cars}
            />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        <Route exact path="/auctions/biddings-winner">
          {isLogin() ? (
            <MenuComponent
              permissions={permissions}
              setPermissions={setPermissions}
              dashboardRoutes={dashboardRoutes.auctions.bidding_winners}
            />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        <Route exact path="/auctions/buynow-winner">
          {isLogin() ? (
            <MenuComponent
              permissions={permissions}
              setPermissions={setPermissions}
              dashboardRoutes={dashboardRoutes.auctions.buy_now_winners}
            />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        <Route exact path={dashboardRoutes.auctions.auction_closer.path}>
          {isLogin() ? (
            CLOSE_AUCTIONS_BY_BANK === 0 && CLOSE_AUCTIONS_BY_FILE === 0 ? (
              <Result
                status="403"
                title="403"
                subTitle="Sorry, you are not authorized to access this page."
                extra={
                  <Link to="/dashboard">
                    <Button type="primary" icon={<LeftOutlined />}>
                      Back to Dashboard
                    </Button>
                  </Link>
                }
              />
            ) : (
              <MenuComponent
                permissions={permissions}
                setPermissions={setPermissions}
                dashboardRoutes={dashboardRoutes.auctions.auction_closer}
              />
            )
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        <Route exact path={dashboardRoutes.bulk_uploader.path}>
          {isLogin() ? (
            <MenuComponent
              permissions={permissions}
              setPermissions={setPermissions}
              dashboardRoutes={dashboardRoutes.bulk_uploader}
            />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        {/* Edit Ads */}
        <Route exact path={dashboardRoutes.edit_ads.path}>
          {isLogin() ? (
            <MenuComponent
              permissions={permissions}
              setPermissions={setPermissions}
              dashboardRoutes={dashboardRoutes.edit_ads}
            />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        <Route exact path={dashboardRoutes.edit_ads.subPath.path}>
          {isLogin() ? (
            <MenuComponent
              permissions={permissions}
              setPermissions={setPermissions}
              dashboardRoutes={dashboardRoutes.edit_ads.subPath}
            />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>

        <Route exact path={dashboardRoutes.settings.path}>
          {isLogin() ? (
            <MenuComponent
              permissions={permissions}
              setPermissions={setPermissions}
              dashboardRoutes={dashboardRoutes.settings}
            />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        <Route exact path={dashboardRoutes.item_settings.makes.path}>
          {isLogin() ? (
            <MenuComponent
              permissions={permissions}
              setPermissions={setPermissions}
              dashboardRoutes={dashboardRoutes.item_settings.makes}
            />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        <Route exact path={dashboardRoutes.item_settings.models.path}>
          {isLogin() ? (
            <MenuComponent
              permissions={permissions}
              setPermissions={setPermissions}
              dashboardRoutes={dashboardRoutes.item_settings.models}
            />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        <Route exact path={dashboardRoutes.item_settings.vehicle_types.path}>
          {isLogin() ? (
            <MenuComponent
              permissions={permissions}
              setPermissions={setPermissions}
              dashboardRoutes={dashboardRoutes.item_settings.vehicle_types}
            />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        <Route exact path={dashboardRoutes.item_settings.colors.path}>
          {isLogin() ? (
            <MenuComponent
              permissions={permissions}
              setPermissions={setPermissions}
              dashboardRoutes={dashboardRoutes.item_settings.colors}
            />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        {/* <Route exact path={dashboardRoutes.cities.path}>
          {isLogin() ? (
            <MenuComponent
              permissions={permissions}
              setPermissions={setPermissions}
              dashboardRoutes={dashboardRoutes.cities}
            />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route> */}
        <Route exact path={dashboardRoutes.activity.path}>
          {isLogin() ? (
            <MenuComponent
              permissions={permissions}
              setPermissions={setPermissions}
              dashboardRoutes={dashboardRoutes.activity}
            />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        <Route exact path={dashboardRoutes.xml.path}>
          {isLogin() ? (
            <MenuComponent
              permissions={permissions}
              setPermissions={setPermissions}
              dashboardRoutes={dashboardRoutes.xml}
            />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        <Route exact path={dashboardRoutes.session.path}>
          {isLogin() ? (
            <MenuComponent
              permissions={permissions}
              setPermissions={setPermissions}
              dashboardRoutes={dashboardRoutes.session}
            />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        <Route exact path="/user/edit">
          {isLogin() ? (
            <UserEditPageComponent />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )}
        </Route>
        <Route path="*">
          <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={
              <Link to="/dashboard">
                <Button type="primary" icon={<LeftOutlined />}>
                  Back to Dashboard
                </Button>
              </Link>
            }
          />
        </Route>
      </Switch>
    </Router>
  );
};
