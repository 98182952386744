import { useEffect, useState } from "react";

const columns = [
  {
    title: "Plate Number",
    dataIndex: "plate_number",
    key: "plate_number",
  },
  {
    title: "Conduction Sticker",
    dataIndex: "conduction_sticker",
    key: "conduction_sticker",
  },
  {
    title: "Vehicle Location",
    dataIndex: "veh_location",
    key: "veh_location",
  },
  {
    title: "Date Updated",
    dataIndex: "date_updated",
    key: "date_updated",
  },
];

export default function useGetAuctionTableCloserColumns() {
  const [tableColumns, setColumns] = useState(columns);

  useEffect(() => {
    setColumns(columns);
  }, []);

  return tableColumns;
}
