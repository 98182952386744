import { useCreate } from "../../hooks/useCreate";

export const SendEmailWarehouse = async () => {
    try {
        return await useCreate(
            `${process.env.REACT_APP_API_URL}/warehouses/email/notification`
        );
    } catch (err) {
        if (err.response) return err.response;
        if (err.request) return err.request;
        return err.message;
    }
};
