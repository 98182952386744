import React from "react";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";

const generateRow = (item) => {
  const {
    ads,
    users,
    bid_amount,
    payment_method,
    created_at,
    user_bid_status,
    auction_status,
    is_accepted,
  } = item;
  const { title, slug, warehouses, price_order, car, buy_now_type } = ads;

  const model = title.split(" ")[0];
  const trans_type = car?.model?.transmission_type;
  const new_adviser = car?.new_adviser || car?.adviser;
  const middle_initial =
    users?.middle_name && users?.middle_name.toLowerCase() !== "n/a"
      ? users?.middle_name.charAt(0)
      : "";
  const adviserName = new_adviser
    ? `${new_adviser.first_name || ""} ${new_adviser.last_name || ""}`
    : "";

  let formattedTitle = title.replace(model, "");
  if (trans_type) formattedTitle += ` ${trans_type}`;

  return [
    model,
    formattedTitle,
    car?.conduction_sticker || "",
    slug,
    users?.first_name || "",
    users?.last_name || "",
    middle_initial,
    users?.email || "",
    users?.mobile || "",
    price_order || "",
    bid_amount || "",
    payment_method || "",
    warehouses?.location || "",
    adviserName,
    created_at,
    user_bid_status || "",
    payment_method || "",
    is_accepted === 1 ? "No" : "Yes",
    auction_status || "",
    users?.referrer || "AutomartPH",
    car?.color?.specific_name || "",
    buy_now_type || "",
  ];
};

const generateData = (rows) => {
  const header = [
    "Year Model",
    "Car Title",
    "Conduction Sticker",
    "Plate Number",
    "Winner Firstname",
    "Winner Lastname",
    "Middle Initial",
    "Winner Email",
    "Winner Phone",
    "Starting Bid Price",
    "Final Bid",
    "Payment Method",
    "Location",
    "Seller Name",
    "Bid Date",
    "Bid Status",
    "Payment Method",
    "Previous Bids",
    "Auction Status",
    "Referrer",
    "Unit Color",
    "Bidding Type",
  ];
  return [header, ...rows.map(generateRow)];
};

const exportToExcel = (rows) => {
  const data = generateData(rows);
  const ws = XLSX.utils.aoa_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  const fileName = `bids_${new Date()
    .toISOString()
    .replace(/[-T:.Z]/g, "")
    .substring(0, 14)}.xlsx`;
  XLSX.writeFile(wb, fileName);
};

const BidsReportsComponent = ({ dataSet, isMobile }) => (
  <Button icon={<DownloadOutlined />} onClick={() => exportToExcel(dataSet)} block={isMobile}>
    Generate Report
  </Button>
);

export default BidsReportsComponent;
