import React, { useState, useEffect, useRef } from "react";
import { PageHeader, Divider, Form, message } from "antd";
import { BidsTableComponent } from "../sub_components/bids/BidsTableListComponent";
import { BidsFunctionalityComponent } from "../sub_components/bids/BidsFunctionalityComponent";
import { BuyNowFiltersAndSelectionsComponent } from "../sub_components/buy_now/BuyNowFiltersAndSelectionsComponent";
import { FetchBankList } from "../../api/banks/FetchBankList";
import { FetchWarehouseSpecialistsList } from "../../api/warehouse/FetchWarehouseSpecialistsList";
import { FetchBiddingWinnersList } from "../../api/auctions/FetchBiddingWinnersList";
import { UpdateBiddingStatus } from "../../api/auctions/UpdateBiddingStatus";
import { BidsSearchFunctionalityComponent } from "../sub_components/bids/BidsSearchFunctionalityComponent";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { EmailBiddingStatusUpdate } from "../../api/auctions/EmailBiddingStatusUpdate";
import { ModalwithFormComponent } from "../ModalwithFormComponent";
import { FormRules } from "../../dist/functions/formRules";
import returnLastWednesdayDate from "../../dist/functions/returnLastWednesdayDate";
import moment from "moment";
import useGetPlatform from "../../hooks/useGetPlatform";
import useIsMobile from "../../hooks/useIsMobile";
import { useComponentDidUpdate } from "../../hooks/useComponentDidUpdate";
import { ConsoleSqlOutlined, ContactsOutlined } from "@ant-design/icons";

const initialRolesRestriction = {
  GENERATE_WINNING_BIDDERS_REPORT: 0,
  UPDATE_WINNING_BIDDERS_STATUS: 0,
};

export const BiddingWinnersManagementComponent = () => {
  const [form] = Form.useForm();
  const [remarksForm] = Form.useForm();
  const [data, setData] = useState([]);
  const [advisers, setAdvisers] = useState({ data: [], total: 0 });
  const [banks, setBanks] = useState({ data: [], total: 0 });
  const [tableLoading, setTableLoading] = useState(false);
  const [selectedAdviser, setSelectedAdviser] = useState(null);
  const [selectedBank, setSelectedBank] = useState(null);
  const [reportDateRange, setReportDateRange] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isFiltered, setIsFiltered] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [isUpdateButtonHidden, setIsUpdateButtonHidden] = useState(true);
  const [rolesRestrictions, setRolesRestrictions] = useState(
    initialRolesRestriction
  );
  const [searchedKeyword, setSearchedKeyword] = useState(null);
  const [keywordType, setKeywordType] = useState(null);
  const [filterType, setFilterType] = useState("active");
  const [isShowRemarksModal, setIShowRemarksModal] = useState(false);
  const [isShowBidsFilterModal, setIsBidsFilterModal] = useState(false);
  const currentPlatform = useGetPlatform();
  const isMobile = useIsMobile();
  const [isFilteredFrontend, setIsFilteredFrontend] = useState(false);
  const [filteredDataFrontend, setFilteredDataFrontend] = useState([]);
  const [columnFilterFrontend, setColumnFilterFrontend] = useState([]);
  const [isShowColumnFilter, setIsShowColumnFilter] = useState(false);
  const [columnFilterTags, setColumnFilterTags] = useState([]);
  const [shouldDoFetch, setShouldDoFetch] = useState(true);
  const [currentPagination, setCurrentPagination] = useState(1);
  const [currentCount, setCurrentCount] = useState(10);
  const [columnFilter, setColumnFilter] = useState([]);

  const sortColumn = useRef(null);
  const sortType = useRef("asc");

  const { UPDATE_WINNING_BIDDERS_STATUS, GENERATE_WINNING_BIDDERS_REPORT } =
    rolesRestrictions;

  const setSortColumn = (value) => {
    sortColumn.current = value;
  };

  const setSortType = (value) => {
    if (value === "descend") {
      sortType.current = "desc";
    } else if (value === "ascend") {
      sortType.current = "asc";
    } else {
      sortType.current = "none";
    }
  };

  const onSortChange = async (sortCol, sortType) => {
    setSortColumn(sortCol);
    setSortType(sortType);

    await onSubmitSearch(currentPagination, currentCount);
  };

  const handleseShowRemarksModal = async () => {
    setIShowRemarksModal(!isShowRemarksModal);
  };

  const handleShowFilterBidsModal = () => {
    setIsBidsFilterModal(!isShowBidsFilterModal);
  };

  const onSubmitSearch = async (page, limit) => {
    setCurrentPagination(page);

    const values = await form.validateFields();

    const {
      report_type,
      adviser_id,
      bank_id,
      report_date_range,
      keyword,
      type,
      isIncludePreviousBids,
    } = values;

    const specialist_id =
      typeof adviser_id !== "undefined" ? adviser_id : "none";

    for (const adviser of advisers.data) {
      if (adviser.id === specialist_id) {
        setSelectedAdviser(`${adviser.first_name} ${adviser.last_name}`);
      }
    }

    const date_from =
      typeof report_date_range === "undefined"
        ? returnLastWednesdayDate()
        : report_date_range[0].format("YYYY-MM-DD hh:mm:ss");

    const date_to =
      typeof report_date_range === "undefined"
        ? moment().format("YYYY-MM-DD hh:mm:ss")
        : report_date_range[1].format("YYYY-MM-DD hh:mm:ss");

    const _bank_id = typeof bank_id !== "undefined" ? bank_id : "none";

    for (const bank of banks.data) {
      if (bank.id === _bank_id) {
        setSelectedBank(bank.name);
      }
    }
    const keyword_type = typeof keyword !== "undefined" ? type : "none";
    const search_value = typeof keyword !== "undefined" ? keyword : "none";

    const data = await fetchBiddingWinnersList(
      page,
      limit,
      report_type,
      date_from,
      date_to,
      _bank_id,
      specialist_id,
      keyword_type,
      search_value,
      sortColumn.current,
      sortType.current,
      columnFilter,
      isIncludePreviousBids
    );

    console.log(data, "data");

    setIsFiltered(true);
    setReportDateRange(`${date_from} - ${date_to}`);

    if (typeof keyword !== "undefined") {
      setKeywordType(keyword_type);
      setSearchedKeyword(search_value);
    }

    setFilterType(report_type);
    handleShowFilterBidsModal();

    // filter data if there is a parameter on filter frontend side
    if (isFilteredFrontend && columnFilterFrontend.length) {
      setFilteredDataFrontend(
        data.filter(function (el) {
          let isMatched;
          columnFilterFrontend.map((e) => {
            isMatched = el[e.id] === e.value;
          });
          return isMatched;
        })
      );
      setIsFilteredFrontend(true);
    } else {
      setFilteredDataFrontend([]);
      setIsFilteredFrontend(false);
      setData(data);
    }
  };

  ///////////////////////////////
  // for column filter tags
  const onCloseColumnFilterTag = (id) => {
    setColumnFilterTags((prevState) => {
      return prevState.filter((item) => item.id !== id);
    });

    setColumnFilter((prevState) => {
      let newData = prevState.filter((item) => {
        return Array.isArray(item.id)
          ? JSON.stringify(item.id) != id
          : item.id !== id;
      });

      return newData;
    });

    setColumnFilterFrontend((prevState) => {
      let newData = prevState.filter((item) => {
        return Array.isArray(item.id)
          ? JSON.stringify(item.id) !== id
          : item.id !== id;
      });

      return newData;
    });

    if (!columnFilterTags.length) {
      setIsShowColumnFilter(false);
      setShouldDoFetch(true);
    }
  };

  const onLoadColumnFilterTags = () => {
    let allFilters = [...columnFilter, ...columnFilterFrontend];

    if (allFilters.length) {
      let filterTags = allFilters.map((e) => {
        let [label, tagId] = Array(2).fill(e.id);

        if (Array.isArray(e.id)) {
          tagId = JSON.stringify(tagId);
          label = "";

          for (let i = 0, n = e.id.length; i < n; i++) {
            label += `${e.id[i]} `;
          }
        }

        return {
          color: "processing",
          closable: true,
          selection_label: label,
          value: e.value,
          id: tagId,
          onClose: onCloseColumnFilterTag,
        };
      });

      setColumnFilterTags(filterTags);
      setIsShowColumnFilter(true);
    } else {
      // if columnFilter is just blank, then remove the tags
      setColumnFilterTags([]);
      setIsShowColumnFilter(false);
    }
  };

  const onClearColumnFilters = () => {
    setColumnFilter([]);
    setColumnFilterTags([]);
    setIsShowColumnFilter(false);
    setShouldDoFetch(false);
  };

  useComponentDidUpdate(() => {
    if (shouldDoFetch) {
      onSubmitSearch(1, currentCount);
    }

    onLoadColumnFilterTags();
  }, [columnFilter]);

  useComponentDidUpdate(() => {
    if (columnFilterFrontend.length) {
      setFilteredDataFrontend(
        data.filter(function (el) {
          let isMatched;
          columnFilterFrontend.map((e) => {
            isMatched = el[e.id] == e.value;
          });
          return isMatched;
        })
      );
      setIsFilteredFrontend(true);
    } else {
      setFilteredDataFrontend([]);
      setIsFilteredFrontend(false);
    }

    onLoadColumnFilterTags();
  }, [columnFilterFrontend]);

  useEffect(() => {
    const fetchBanksLists = async () => {
      const banksData = await FetchBankList({ page: 1, limit: 50 });
      setBanks(banksData);
    };

    fetchBanksLists();
  }, []);

  useEffect(() => {
    const fetchWarehouseSpecialists_ = async () => {
      const advisersData = await FetchWarehouseSpecialistsList({
        page: 1,
        limit: 500,
      });
      setAdvisers(advisersData);
    };

    fetchWarehouseSpecialists_();
  }, []);

  const fetchBiddingWinnersList = async (
    page = 1,
    limit = 10,
    filter_type = "active",
    date_from = returnLastWednesdayDate(),
    date_to = moment().format("YYYY-MM-DD hh:mm:ss"),
    bank_id = "none",
    specialist_id = "none",
    keyword_type,
    search_value,
    sortColumn,
    sortType,
    columnFilter,
    isIncludePreviousBids
  ) => {
    setTableLoading(true);

    const query = {
      page: page,
      limit: limit,
      filter_type: filter_type,
      date_from: date_from,
      date_to: date_to,
      bank_id: bank_id,
      specialist_id: specialist_id,
      keyword_type: keyword_type,
      search_value: search_value,
      sortColumn: sortColumn,
      sortType: sortType,
      columnFilter: columnFilter,
      is_include_previous_bids: isIncludePreviousBids,
    };

    const data = await FetchBiddingWinnersList(query);
    setData(data);
    setTableLoading(false);
    setFilterType(query.filter_type);
    return data;
  };

  const onRemoveAdviserSearch = () => {
    setSelectedAdviser(null);
    form.setFieldsValue({
      adviser_id: undefined,
    });
    onSubmitSearch();
  };

  const onRemoveBankSearch = () => {
    setSelectedBank(null);
    form.setFieldsValue({
      bank_id: undefined,
    });
    onSubmitSearch();
  };

  const onRemoveSelection = () => {
    setSelectedRowKeys(null);
    setSelectedRow(null);
    setIsUpdateButtonHidden(true);
  };

  const onRemoveAllSearches = () => {
    form.resetFields();
    setSelectedBank(null);
    setSelectedAdviser(null);
    setIsFiltered(false);
    setFilteredData([]);

    setData([]);
    setSearchedKeyword(null);
    setKeywordType(null);
    onRemoveSelection();
  };

  const onPaginationChange = async (value, currentCount) => {
    setCurrentCount(currentCount);
    setCurrentPagination(value);
    onSubmitSearch(value, currentCount);
  };

  const onSelectRowChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);

    setSelectedRow(selectedRows[0]);

    setIsUpdateButtonHidden(false);
  };

  const OnUpdateStatus = async () => {
    await message.info("To proceed kindly provide reason for cancelling", 4);
    handleseShowRemarksModal();
  };

  const OnFinishCancelReason = async () => {
    const values = await remarksForm.validateFields();

    await message.loading("Updating Status...", 2);
    const payload = { is_accepted: 2 };

    const data = await UpdateBiddingStatus(payload, selectedRowKeys);

    if (data.status === 200) {
      onRemoveAllSearches();
      message.success("Successfuly Updated Status");
      await fetchBiddingWinnersList();

      const email_payload = {
        user_id: parseInt(localStorage.getItem("user_id")),
        title: "BID STATUS UPDATE NOTIFICATION",
        remarks: values.reason_for_cancellation,
        amount: selectedRow.bid_amount,
      };

      EmailBiddingStatusUpdate(email_payload);
      remarksForm.resetFields();
      handleseShowRemarksModal();

      return;
    }

    message.error("Error updating status");
    message.info("Please seek help from support");
  };

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const GENERATE_WINNING_BIDDERS_REPORT = permissions.find(
      (item) => item.name === "GENERATE_WINNING_BIDDERS_REPORT"
    );

    const UPDATE_WINNING_BIDDERS_STATUS = permissions.find(
      (item) => item.name === "UPDATE_WINNING_BIDDERS_STATUS"
    );

    const restrictions = {};
    restrictions["GENERATE_WINNING_BIDDERS_REPORT"] =
      typeof GENERATE_WINNING_BIDDERS_REPORT === "undefined"
        ? 0
        : GENERATE_WINNING_BIDDERS_REPORT.has_access;

    restrictions["UPDATE_WINNING_BIDDERS_STATUS"] =
      typeof UPDATE_WINNING_BIDDERS_STATUS === "undefined"
        ? 0
        : UPDATE_WINNING_BIDDERS_STATUS.has_access;

    setRolesRestrictions(restrictions);
  }, []);

  const formInputsComponent = [
    {
      name: "reason_for_cancellation",
      label: "Reason For Cancellation",
      default_value: "",
      configs: {
        type: "text_area",
        rules: [FormRules.REQUIRED_CANCELLATION_REASON],
        text_area_row: 5,
      },
    },
  ];

  return (
    <>
      <PageHeader
        title="WINNING BIDDERS"
        subTitle="WINNING BIDDERS REPORT MANAGEMENT"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["Auctions", "Bidders"]} />
        )}
      />

      <div className="content-pane-transaction-div-style">
        <Divider />
        <div style={{ textAlign: isMobile ? "center" : "left" }}>
          <BidsSearchFunctionalityComponent
            advisers={advisers}
            banks={banks}
            onSubmitSearch={onSubmitSearch}
            visible={isShowBidsFilterModal}
            onCancel={handleShowFilterBidsModal}
            form={form}
          />
        </div>
        <div style={{ textAlign: isMobile ? "center" : "right" }}>
          <BidsFunctionalityComponent
            isUpdateButtonHidden={isUpdateButtonHidden}
            OnUpdateStatus={OnUpdateStatus}
            data={data}
            filteredDataFrontend={filteredDataFrontend}
            isFilteredFrontend={isFilteredFrontend}
            GENERATE_WINNING_BIDDERS_REPORT={GENERATE_WINNING_BIDDERS_REPORT}
            UPDATE_WINNING_BIDDERS_STATUS={UPDATE_WINNING_BIDDERS_STATUS}
            platform={currentPlatform}
            onClickFilterBids={handleShowFilterBidsModal}
          />
        </div>
        <div
          style={{
            marginTop: "1%",
            display: isFiltered || selectedRow ? null : "none",
            textAlign: isMobile ? "center" : "left",
          }}
        >
          <BuyNowFiltersAndSelectionsComponent
            onRemoveAllSearches={onRemoveAllSearches}
            onRemoveAdviserSearch={onRemoveAdviserSearch}
            onRemoveBankSearch={onRemoveBankSearch}
            onRemoveSelection={onRemoveSelection}
            selectedBank={selectedBank}
            reportDateRange={reportDateRange}
            selectedAdviser={selectedAdviser}
            selectedRow={selectedRow}
            searchKeyword={searchedKeyword}
            keywordType={keywordType}
            onClearColumnFilters={onClearColumnFilters}
            isShowColumnFilterTags={isShowColumnFilter}
            columnFilterTags={columnFilterTags}
          />
        </div>
        <div style={{ marginTop: "2%" }}>
          <BidsTableComponent
            data={data}
            filteredDataFrontend={filteredDataFrontend}
            isFilteredFrontend={isFilteredFrontend}
            tableLoading={tableLoading}
            onPaginationChange={onPaginationChange}
            isFiltered={isFiltered}
            filteredData={filteredData}
            onSelectRowChange={onSelectRowChange}
            selectedRowKeys={selectedRowKeys}
            filterType={filterType}
            platform={currentPlatform}
            columnFilter={columnFilter}
            setColumnFilter={(obj) => {
              setColumnFilter(obj);
              setShouldDoFetch(true);
            }}
            onSortChange={onSortChange}
            columnFilterFrontend={columnFilterFrontend}
            setColumnFilterFrontend={(obj) => {
              setColumnFilterFrontend(obj);
              setShouldDoFetch(true);
            }}
          />
        </div>
        <ModalwithFormComponent
          title={"INPUT REASON FOR CANCELLING"}
          form={remarksForm}
          formLayout={"vertical"}
          formInputsComponent={formInputsComponent}
          onOk={OnFinishCancelReason}
          onCancel={handleseShowRemarksModal}
          destroyOnClose={true}
          okText={"Save Remarks"}
          cancelText={"Cancel"}
          visible={isShowRemarksModal}
        />
      </div>
    </>
  );
};
