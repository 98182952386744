import React from "react";
import { Form, Button, Input, Divider } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";

export const AddNewModulesToPlatformFormComponent = (props) => {
  return (
    <>
      <div
        style={{
          overflowX: "hidden",
          overflowY: "auto",
          height: "300px",
        }}
      >
        <Form.List name="modules">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <>
                  <div style={{ marginTop: "2%" }}>
                    <Form.Item
                      {...field}
                      name={[field.name, "name"]}
                      key={[field.fieldKey, "name"]}
                      fieldKey={[field.fieldKey, "name"]}
                      rules={[
                        { required: true, message: "Missing module name" },
                      ]}
                    >
                      <Input placeholder="module Name" />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, "description"]}
                      fieldKey={[field.fieldKey, "description"]}
                      key={[field.fieldKey, "description"]}
                    >
                      <Input.TextArea placeholder="Module Description" />
                    </Form.Item>
                    <Button
                      key={`delete-${index}`}
                      type="default"
                      size="small"
                      icon={<DeleteOutlined />}
                      onClick={() => remove(field.name)}
                      danger
                      block
                    >
                      Remove Entry
                    </Button>
                  </div>
                  <Divider />
                </>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Modules Entry
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>
    </>
  );
};
