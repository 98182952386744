import React from "react";
import { Descriptions, Tag } from "antd";

export const AdsDescriptionComponent = (props) => {
  const { title, description, type, expired_at, price_order, price } = props;
  return (
    <>
      <Descriptions title="Ads Information" size="small" bordered>
        <Descriptions.Item label="Title">{title}</Descriptions.Item>
        <Descriptions.Item label="Description">{description}</Descriptions.Item>
        <Descriptions.Item label="Type">
          {!type ? ReturnNoInformationTag() : type}
        </Descriptions.Item>
        <Descriptions.Item label="Price Order">{price_order}</Descriptions.Item>
        <Descriptions.Item label="Price">{price}</Descriptions.Item>
        <Descriptions.Item label="Expiration Date">
          {!expired_at ? ReturnNoInformationTag() : expired_at}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

const ReturnNoInformationTag = () => {
  return <Tag color="default">Not Available</Tag>;
};
