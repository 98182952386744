import { DeleteRemarksSubGroup } from "../../api/remarks_sub_group/DeleteRemarksSubGroup";
import { message } from "antd";

const useDeleteRemarksSubGroup = async (id, payload) => {
  await message.loading("Archiving Remarks Sub Group", 2);
  const response = await DeleteRemarksSubGroup(id, payload);
  if (response.status === 200) {
    await message.success("Successfuly Archived Remarks Sub Group", 2);
    return true;
  }
  await message.error("Error Archiving Remarks Sub Group", 2);
  return false;
};

export default useDeleteRemarksSubGroup;
