import React from "react";
import { Table } from "antd";
import useColumnFilter from "../../../hooks/useColumnFilter";

const { Column } = Table;
export const OptionsTableComponent = (props) => {
  const {
    data = { data: [], total: 0 },
    onPaginateChange,
    onRowSelectionChange,
    selectedRowKeys,
    currentPagination,
    onSortChange,
    columnFilter,
    setColumnFilter
  } = props;

  const { getColumnFilterProps } = useColumnFilter(columnFilter, setColumnFilter);

  return (
    <Table
      dataSource={data.data}
      size="small"
      rowSelection={{
        type: "radio",
        onChange: onRowSelectionChange,
        selectedRowKeys: [selectedRowKeys],
      }}
      onChange={(pagination, filters, sorter, action) => {
        if (action.action == "sort") {
          onSortChange(sorter.field, sorter.order);
        }
      }}
      scroll={{ x: 1000 }}
      bordered={true}
      pagination={{ total: data.total, onChange: onPaginateChange, current: currentPagination }}
    >
      <Column title="Id" dataIndex="id" key="id" sorter={true} {...getColumnFilterProps('id')} />
      <Column title="Option Key" dataIndex="option_key" key="option_key" sorter={true} {...getColumnFilterProps('option_key')} />
      <Column
        title="Option Value"
        dataIndex="option_value"
        key="option_value"
        sorter={true} {...getColumnFilterProps('option_value')}
      />
    </Table>
  );
};
