import { FetchRemarksIndex } from "../../api/remarks_index/FetchRemarksIndex";

const useFetchRemarksIndex = async (
  isForTable,
  query = { page: 1, limit: 10, filter: "", classification: "" }
) => {
  if (isForTable) {
    const data = await FetchRemarksIndex(query);
    console.log(data, "data");
    for (const item of data.data.data) {
      item["key"] = item.id;
    }

    return data.data;
  }

  const selectOptionQuery = {
    page: 1,
    limit: null,
    filter: "",
    classification: query.classification,
  };

  const data = await FetchRemarksIndex(selectOptionQuery);
  for (const item of data.data.data) {
    item["key"] = item.id;
  }

  return data.data.data;
};

export default useFetchRemarksIndex;
