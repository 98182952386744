import React from "react";
import { Modal, Form, Input, InputNumber } from "antd";

export const BankDataActionsComponent = (props) => {
  const { OnHandleOk, OnHandleCancel, isModalVisible, form } = props;

  return (
    <>
      <Modal
        title="Add Bank"
        centered={true}
        okText="Submit"
        visible={isModalVisible}
        onOk={OnHandleOk}
        onCancel={OnHandleCancel}
        destroyOnClose={true}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Bank Name"
            rules={[{ required: true, message: "Please add Bank Name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="min_loan_amount"
            label="Minimum Loan Amount"
            rules={[
              { required: true, message: "Please add Minimum Loan Amount" },
            ]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
