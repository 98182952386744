import React from "react";
import { Tag, Button, Typography, Divider } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const { Title } = Typography;
export const ModulesFiltersAndSelectionsComponent = (props) => {
  const { onClearSearcheAndSelection, selectedPlatform } = props;
  return (
    <>
      {selectedPlatform ? (
        <div style={{ marginTop: "2%" }}>
          <Divider />
          <Title level={5}>Filters:</Title>
          <Tag color="processing" visible={!selectedPlatform ? false : true}>
            {`Selected Platform: ${
              !selectedPlatform ? "none" : selectedPlatform.name
            }`}
          </Tag>
          <Button
            type="link"
            icon={<CloseOutlined />}
            size="small"
            onClick={onClearSearcheAndSelection}
          >
            Clear Filters
          </Button>
          <Divider />
        </div>
      ) : null}
    </>
  );
};
