import { UpdateRemarksIndex } from "../../api/remarks_index/UpdateRemarksIndex";
import { message } from "antd";

const useUpdateRemarksIndex = async (id, payload) => {
  const response = await UpdateRemarksIndex(id, payload);
  if (response.status === 200) {
    await message.success("Remarks Index Successfuly Updated", 2);
    return true;
  }

  await message.error("Error Updating Remarks Index");
  return false;
};

export default useUpdateRemarksIndex;
