import React from "react";
import { Row, Col, Button, Card, Empty, Space } from "antd";
import {
  CompassOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { SpecialistUpdateModalFormComponent } from "./SpecialistUpdateModalFormComponent";

const { Meta } = Card;

const gridStyle = {
  width: "33.3%",
  textAlign: "left",
};

export const SpecialistTableExpandedWarehouseComponent = (props) => {
  const {
    record,
    specialistUpdateModal,
    handleModalSpecialistUpdateOnClick,
    HandleUpdateWarehousesOnFinish,
    form,
    warehouses,
    HandleClearWarehouse,
    userPermissions,
    onClickShowMoreList,
    isClickShowMoreDisabled,
  } = props;

  const { UPDATE_ADVISER_WAREHOUSE, CLEAR_WAREHOUSE } = userPermissions;

  return (
    <>
      <div style={{ padding: "4%" }}>
        <Row>
          <Col span={24}>
            <Space size="small">
              <Button
                hidden={UPDATE_ADVISER_WAREHOUSE === 1 ? false : true}
                type="primary"
                icon={<EditOutlined />}
                onClick={() =>
                  handleModalSpecialistUpdateOnClick(record.warehouses)
                }
              >
                {record.warehouses.length !== 0 ? "Update" : "Add"} Warehouse
              </Button>
              {record.warehouses.length !== 0 ? (
                <Button
                  hidden={CLEAR_WAREHOUSE === 1 ? false : true}
                  hidden={true}
                  type="default"
                  icon={<DeleteOutlined />}
                  onClick={HandleClearWarehouse}
                  danger
                >
                  Clear Warehouse
                </Button>
              ) : null}
            </Space>
          </Col>
        </Row>
        <Row>
          <Card className="specialist-warehouses-card-div">
            {record.warehouses.length !== 0 ? (
              <div className="card-div-content-scrollable">
                {record.warehouses.map((data, index) => (
                  <Card.Grid key={index} style={gridStyle}>
                    <Meta
                      key={index}
                      title={data.warehouse.location}
                      description={
                        <>
                          <CompassOutlined />
                          {"  "}
                          {data.warehouse.viewing_address}
                        </>
                      }
                    />
                  </Card.Grid>
                ))}
              </div>
            ) : (
              <Empty description="No Warehouse Attached To this Adviser" />
            )}
          </Card>
        </Row>
      </div>
      <SpecialistUpdateModalFormComponent
        specialistUpdateModal={specialistUpdateModal}
        handleModalSpecialistUpdateOnClick={handleModalSpecialistUpdateOnClick}
        HandleUpdateWarehousesOnFinish={HandleUpdateWarehousesOnFinish}
        form={form}
        warehouses={warehouses}
        onClickShowMoreList={onClickShowMoreList}
        isClickShowMoreDisabled={isClickShowMoreDisabled}
      />
    </>
  );
};
