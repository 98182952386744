import React, { Fragment, useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { PageHeader, Divider, Modal, message, Form, Tag } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useFetch } from "../../hooks/useFetch";
import { useUpdate } from "../../hooks/useUpdate";
import { MultiStepFormComponent } from "../sub_components/user/MultiStepFormComponent";
import { ChangePasswordFormComponent } from "../sub_components/user/ChangePasswordFormComponent";
import { ChangePasswordviaInputsComponent } from "../sub_components/user/ChangePasswordviaInputsComponent";
import { UserListComponent } from "../sub_components/user/UserListComponent";
import { AddingRolesFormComponent } from "../sub_components/user/AddingRolesFormComponent";
import { UserManagementFunctionsComponent } from "../sub_components/user/UserManagementFunctionsComponent";
import { UserFiltersAndSelectionComponent } from "../sub_components/user/UserFiltersAndSelectionComponent";
import { UpdateUserInformationComponent } from "../sub_components/user/UpdateUserInformationComponent";
import { SearchAndFilterUser } from "../../api/admin/SearchAndFilterUser";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { FetchPermissionsByRolesId } from "../../api/admin/FetchPermissionsByRolesId";
import { UpdateUserInformation } from "../../api/admin/UpdateUseInformation";
import { ChangePasswordAdmin } from "../../api/admin/ChangePasswordAdmin";
import { FormRules } from "../../dist/functions/formRules";
import { updateUserAttachedRolesByExistingUser } from "../../dist/dao/userAttachedRoles";
import { UpdateRolesFormComponent } from "../sub_components/user/UpdateRolesFormComponent";
import useGetRolesWithPermissions from "../../hooks/roles/useGetRoleswithPermissions";
import useUpdateUserAttachedRoles from "../../hooks/user_attached_roles/useUpdateUserAttachedRoles";
import useFetchPermissionSets from "../../hooks/useFetchPermissions";
import "../../dist/style/user_component.css";
import { useComponentDidUpdate } from "../../hooks/useComponentDidUpdate";
import { UserReportsModal } from "../sub_components/user/UserReportsModal";
import { UserReportsComponent } from "../sub_components/user/UserReportsComponent";
import useRemoveAdminAccess from "../../hooks/roles/useRemoveAdminAccess";

import fileDownload from "js-file-download";
import axios from "axios";

const { confirm } = Modal;
const initialRolesRestriction = {
  CREATE_USER: 0,
  UPDATE_USER: 0,
  CHANGE_PASSWORD: 0,
  ADD_UPDATE_USER_ROLE: 0,
  UPDATE_DEPOSIT_STATUS: 0,
  UPDATE_STATUS: 0,
  GENERATE_USER_REPORT: 0,
};

const permissionSet = [
  "CREATE_USER",
  "UPDATE_USER",
  "CHANGE_PASSWORD",
  "ADD_UPDATE_USER_ROLE",
  "UPDATE_DEPOSIT_STATUS",
  "UPDATE_STATUS",
  "GENERATE_USER_REPORT",
];

const initialQuerySearch = {
  search_value: null,
  date_from: null,
  date_to: null,
};

export const UserManagementComponent = () => {
  const [updateRolesForm] = Form.useForm();
  const [changePasswordForm] = Form.useForm();
  const [searchUserForm] = Form.useForm();
  const [updateUserForm] = Form.useForm();
  const { removeAdminAccess } = useRemoveAdminAccess();
  const [isUserCreationModalVisible, setUserCreationModalVisible] =
    useState(false);
  const [isChangePasswordModalVisible, setChangePasswordModalVisible] =
    useState(false);
  const [isUpdateRolesModalVisible, setUpdateRolesModalVisible] =
    useState(false);
  const [isChangeAdminPasswordVisible, setIsChangeAdminPasswordVisible] =
    useState(false);
  const [isAddRolesModalVisible, setAddRolesModalVisible] = useState(false);
  const [isUpdateUserModalVisible, setUpdateUserModalVisible] = useState(false);
  const [roles, setRoles] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedUserRow, setSelectedUserRowInfo] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isForUpdate, setIsForUpdate] = useState(null);
  const [selectedUserInfo, setSelectedUserInfo] = useState({
    id: null,
    email: null,
    user_type: null,
  });
  const [data, setData] = useState({});
  const [permissionRoles, setPermissionRoles] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [searchType, setSearchType] = useState(null);
  const [isFiltered, setIsFiltered] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [userType, setUserType] = useState("User");
  const [querySearch, setQuerySearch] = useState(initialQuerySearch);
  const [currentPagination, setCurrentPagination] = useState(1);
  const [currentCount, setCurrentCount] = useState(10);
  const [isDefaultPermission, setIsDefaultPermission] = useState(true);
  const [permissions, setPermissions] = useState([]);

  const [isShowColumnFilter, setIsShowColumnFilter] = useState(false);
  const [columnFilterTags, setColumnFilterTags] = useState([]);

  const [columnFilter, setColumnFilter] = useState([]);
  const sortColumn = useRef(null);
  const sortType = useRef("asc");

  // Query restriction using hook
  // Then hook, to get local storage
  const rolesRestriction = useFetchPermissionSets(
    permissionSet,
    initialRolesRestriction
  );
  const [reportsForm] = Form.useForm();
  const [isReportsVisible, setIsReportsVisible] = useState(false);

  const [reportsData, setReportsData] = useState([]);
  const reportRef = useRef(null);
  const [referrerData, setReferrerData] = useState([]);

  const setSortColumn = (value) => {
    sortColumn.current = value;
  };

  const setSortType = (value) => {
    if (value === "descend") {
      sortType.current = "desc";
    } else if (value === "ascend") {
      sortType.current = "asc";
    } else {
      sortType.current = "none";
    }
  };

  const onSelectRowChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedUserRowInfo(selectedRows[0]);
    setIsForUpdate(true);
    if (selectedRows.length !== 0) {
      const selectedUserRole = [];
      var user_attached_roles;

      setSelectedUserInfo({
        id: selectedRows[0].id,
        email: selectedRows[0].email,
        user_type: selectedRows[0].user_type,
        ...selectedRows[0],
      });

      user_attached_roles = selectedRows[0].user_attached_roles;

      if (user_attached_roles) {
        const roles_id = user_attached_roles.roles.id;
        selectedUserRole.push(roles_id);
      }

      setSelectedRows(selectedUserRole);
    }

    if (!user_attached_roles) {
      const { id, email, user_type } = selectedRows[0];
      setIsForUpdate(false);
      setSelectedUserInfo({
        id: id,
        email: email,
        user_type: user_type,
        ...selectedRows[0],
      });
    }
  };

  const resetSelections = () => {
    setColumnFilterTags([]);
    setIsShowColumnFilter(false);
    setColumnFilter([]);
    GetUserList(1, currentCount);
    setIsForUpdate(null);
    setSelectedUserInfo({ id: null, email: null });
    setSelectedRowKeys([]);
    setPermissionRoles([]);
    setTargetKeys([]);
    setIsFiltered(false);
    setQuerySearch(initialQuerySearch);
    searchUserForm.resetFields();
  };

  const handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const handleChange = async (nextTargetKeys) => {
    setTargetKeys(nextTargetKeys);
  };

  const handleCreateUserModalVisibilityOnClick = () => {
    setUserCreationModalVisible(!isUserCreationModalVisible);
  };

  const handleUpdateUserModalVisibilityOnClick = () => {
    const isVisible = !isUpdateUserModalVisible;
    setUpdateUserModalVisible(!isUpdateUserModalVisible);
    if (isVisible) {
      updateUserForm.setFieldsValue(selectedUserInfo);
    }
  };

  const handleUpdateuserInformation = async () => {
    const values = await updateUserForm.validateFields();
    const result = await UpdateUserInformation(values, selectedRowKeys[0]);
    if (result.status === 200) {
      await message.success("Successfully Updated User Information");
      resetSelections();
      updateUserForm.resetFields();
      handleUpdateUserModalVisibilityOnClick();
    }
  };

  const handleChangePasswordModalVisibilityOnClick = async () => {
    confirm({
      title: "Remove Admin Access",
      icon: <ExclamationCircleOutlined />,
      content: "Do you want to remove the admin access on this specific user?",
      async onOk() {
        await removeAdminAccess(selectedUserInfo?.id);
        GetUserList();
      },
      onCancel() {},
    });
  };

  const handleUpdateRolesVisibilityOnClick = () => {
    const updateRolesModalStatus = !isUpdateRolesModalVisible;
    setUpdateRolesModalVisible(updateRolesModalStatus);
    if (updateRolesModalStatus) {
      const { roles_id, is_default } = selectedUserRow.user_attached_roles;
      const isDefaultChecked = is_default === 1 ? true : false;
      setIsDefaultPermission(isDefaultChecked);
      updateRolesForm.setFieldsValue({
        roles_id: roles_id,
      });
    }
  };

  const HandleUpdateRolesFormComponentSubmit = async () => {
    const values = await updateRolesForm.validateFields();
    values.is_default = isDefaultPermission;
    values.user_id = selectedUserRow.id;
    const payload = updateUserAttachedRolesByExistingUser(values);

    const isSuccess = await useUpdateUserAttachedRoles(payload);

    if (isSuccess) {
      setPermissions([]);
      handleUpdateRolesVisibilityOnClick();
      GetUserList();
      updateRolesForm.resetFields();
    }
  };

  const handleAddRolesVisibilityOnClick = () => {
    setAddRolesModalVisible(!isAddRolesModalVisible);
  };

  useEffect(() => {
    FetchPermissions();
  }, [targetKeys]);

  const FetchPermissions = async () => {
    if (targetKeys.length > 0) {
      const params = { role_ids: targetKeys };
      const permissions = await FetchPermissionsByRolesId(params);
      setPermissionRoles(permissions.data);
    }
  };

  const SearchAndFilterUserList = async (
    page,
    limit,
    search_value,
    date_from,
    date_to
  ) => {
    const query = {
      page: page,
      limit: limit,
    };

    if (search_value !== "") {
      query["search_value"] = search_value;
    }

    if (date_from) {
      query["date_from"] = date_from;
    }

    if (date_to) {
      query["date_to"] = date_to;
    }

    if (sortColumn) {
      query["sort_column"] = sortColumn.current;
    }

    if (sortType) {
      query["sort_type"] = sortType.current;
    }

    if (columnFilter && columnFilter.length) {
      query["columnFilter"] = columnFilter;
    }

    return await SearchAndFilterUser(query);
  };

  ///////////////////////////////
  // for column filter tags
  const onCloseColumnFilterTag = (id) => {
    setColumnFilterTags((prevState) => {
      return prevState.filter((item) => item.id !== id);
    });

    setColumnFilter((prevState) => {
      let newData = prevState.filter((item) => {
        return Array.isArray(item.id)
          ? JSON.stringify(item.id) != id
          : item.id != id;
      });

      return newData;
    });

    if (!columnFilterTags.length) {
      setIsShowColumnFilter(false);
    }
  };

  const onLoadColumnFilterTags = () => {
    if (columnFilter.length) {
      let filterTags = columnFilter.map((e) => {
        let [label, tagId] = Array(2).fill(e.id);

        if (Array.isArray(e.id)) {
          tagId = JSON.stringify(tagId);
          label = "";

          for (let i = 0, n = e.id.length; i < n; i++) {
            label += `${e.id[i]} `;
          }
        }

        return {
          color: "processing",
          closable: true,
          selection_label: label,
          value: e.value,
          id: tagId,
          onClose: onCloseColumnFilterTag,
        };
      });

      setColumnFilterTags(filterTags);
      setIsShowColumnFilter(true);
    } else {
      // if columnFilter is just blank, then remove the tags
      setColumnFilterTags([]);
      setIsShowColumnFilter(false);
    }
  };

  const onClearColumnFilters = async () => {
    setColumnFilterTags([]);
    setIsShowColumnFilter(false);
    setColumnFilter([]);
  };

  useEffect(() => {
    const GetRolesList = async () => {
      const data = await useFetch(`${process.env.REACT_APP_API_URL}/roles`);
      const roles = [];

      for (const role of data.data) {
        const { id, name, description } = role;
        roles.push({
          key: id,
          title: name,
          description: description,
        });
      }

      setRoles(roles);
    };

    GetRolesList();
  }, []);

  const GetUserList = async (page = 1, limit = 10) => {
    setTableLoading(true);

    const query = {
      page: page,
      limit: limit,
      sortColumn: sortColumn.current,
      sortType: sortType.current,
      columnFilter: columnFilter,
    };

    const data = await useFetch(
      `${process.env.REACT_APP_API_URL}/users`,
      query
    );
    for (const row of data.data.data) {
      row["key"] = row.id;
    }
    setCurrentPagination(page);
    setData(data.data);
    setTableLoading(false);
  };

  const FetchReferrer = async () => {
    const data = await useFetch(
      `${process.env.REACT_APP_API_URL}/users/referrer`
    );

    setReferrerData(data.data);
  };

  useEffect(() => {
    FetchReferrer();
  }, []);
  useEffect(() => {
    (async function () {
      if (!isFiltered) {
        await GetUserList();
      } else if (isFiltered) {
        const { search_value, date_from, date_to } = querySearch;
        setCurrentPagination(1);

        const data = await SearchAndFilterUserList(
          1,
          currentCount,
          search_value,
          date_from,
          date_to
        );

        setFilteredData(data);
      }
    })();
  }, []);

  useComponentDidUpdate(() => {
    (async function () {
      if (!isFiltered) {
        await GetUserList(1, currentCount);
      } else if (isFiltered) {
        const { search_value, date_from, date_to } = querySearch;
        setCurrentPagination(1);

        const data = await SearchAndFilterUserList(
          1,
          currentCount,
          search_value,
          date_from,
          date_to
        );

        setFilteredData(data);
      }
    })();

    onLoadColumnFilterTags();
  }, [columnFilter]);

  const statusOnChange = (active_status, record, switchValue) => {
    const isActiveStatus = parseInt(active_status) === 1 ? true : false;
    if (isActiveStatus !== switchValue) {
      showConfirm(record, isActiveStatus, switchValue, "Account");
    }
  };

  const statusDepositedOnChange = (active_status, record, switchValue) => {
    const isActiveStatus = parseInt(active_status) === 1 ? true : false;
    if (isActiveStatus !== switchValue) {
      showConfirm(record, isActiveStatus, switchValue, "Deposit");
    }
  };

  const showConfirm = (record, isActiveStatus, switchValue, type) => {
    confirm({
      title: `Do you want to update account status of ${record.first_name} ${record.middle_name} ${record.last_name}?`,
      icon: <ExclamationCircleOutlined />,
      content: `You are going to update the ${type} status of this user`,
      async onOk() {
        const payload = {};

        if (type === "Deposit") {
          payload["has_deposited"] = switchValue ? "1" : "0";
        }

        if (type === "Account") {
          payload["active_status"] = switchValue ? "1" : "0";
        }

        message.loading("Updating User Status..", 2.5).then(async () => {
          await UpdateUserStatus(payload, record.id);
          message
            .success("Successfuly Updated User Status", 2)
            .then(() => GetUserList());
        });
      },
      onCancel() {},
    });
  };

  const UpdateUserStatus = async (payload, user_id) => {
    return await UpdateUserInformation(payload, user_id);
  };

  const executeUserSearch = async (query) => {
    message.loading(`Searching User`, 1).then(async () => {
      const { search_value, date_from, date_to } = query;
      setQuerySearch(query);
      setIsFiltered(true);
      setCurrentPagination(1);
      const data = await SearchAndFilterUserList(
        1,
        10,
        search_value,
        date_from,
        date_to
      );
      setFilteredData(data);
    });
  };

  const onSearch = async () => {
    const values = await searchUserForm.validateFields();
    const { created_date_range, search_value } = values;

    var query = {};

    // Add default values to the json query
    query["date_from"] = null;
    query["date_to"] = null;
    query["search_value"] = null;

    if (created_date_range) {
      query["date_from"] = created_date_range[0].format("YYYY-MM-DD");
      query["date_to"] = created_date_range[1].format("YYYY-MM-DD");
    }

    if (search_value) {
      query["search_value"] = search_value;
    }

    if (!query.date_from && !query.date_to) {
      confirm({
        title: "Search with Default Dates?",
        icon: <ExclamationCircleOutlined />,
        content:
          "Do you want to search users with default date created dates, if yes click OK then click NO if not",
        okText: "OK",
        cancelText: "NO",
        async onOk() {
          var currentDate = new Date();
          currentDate.setMonth(currentDate.getMonth() - 1);
          const dateLastMonth = currentDate
            .toLocaleString()
            .split(",")[0]
            .replace(",", "-");

          query["date_from"] = dateLastMonth;
          query["date_to"] = new Date()
            .toLocaleString()
            .split(",")[0]
            .replace(",", "-");

          executeUserSearch(query);
        },
        async onCancel() {
          executeUserSearch(query);
        },
      });

      return;
    }

    executeUserSearch(query);
  };

  const searchTypeOnChange = (value) => {
    setSearchType(value);
  };

  const onPaginationChange = async (value, currentCount) => {
    if (!isFiltered) {
      setCurrentPagination(value);
      setCurrentCount(currentCount);
      await GetUserList(value, currentCount);
    } else if (isFiltered) {
      setCurrentPagination(value);
      setCurrentCount(currentCount);

      const { search_value, date_from, date_to } = querySearch;
      const data = await SearchAndFilterUserList(
        value,
        currentCount,
        search_value,
        date_from,
        date_to
      );
      setFilteredData(data);
    }
  };

  const onSortChange = async (sortCol, sortType) => {
    setSortColumn(sortCol);
    setSortType(sortType);

    if (!isFiltered) {
      await GetUserList(currentPagination, currentCount);
    } else if (isFiltered) {
      const { search_value, date_from, date_to } = querySearch;
      const data = await SearchAndFilterUserList(
        currentPagination,
        currentCount,
        search_value,
        date_from,
        date_to
      );
      setFilteredData(data);
    }
  };

  const handleUserTypeOnChange = (value) => {
    setUserType(value);
  };

  const changePasswordAdminFormInputs = [
    {
      name: "new_password",
      label: "New Password",
      default_value: "",
      configs: {
        type: "input_password",
        rules: [FormRules.REQUIRED_PASSWORD],
        list_value: "",
        place_holder: "",
      },
    },
  ];

  const handleAdminChangePasswordModal = () => {
    setIsChangeAdminPasswordVisible(!isChangeAdminPasswordVisible);
  };

  const handleReportsModal = () => {
    setIsReportsVisible(!isReportsVisible);
  };

  const OnFinishChangePassword = async () => {
    const value = await changePasswordForm.validateFields();
    const payload = {
      email_address: selectedUserInfo.email,
      new_password: value.new_password,
    };

    await message.loading("Updating User Password", 2);
    const response = await ChangePasswordAdmin(payload);
    if (response.status === 200) {
      message.success("Successfuly Updated Password", 2);
      resetSelections();
      handleAdminChangePasswordModal();
      changePasswordForm.resetFields();
    }
  };

  const confirmReports = async () => {
    const value = await reportsForm.validateFields();
    let referString = [];

    for (let i = 0, n = value.referrer.length; i < n; i++) {
      let refer = value.referrer[i];
      referString.push(<Tag key={value.referrer[i]}>{refer}</Tag>);
    }

    confirm({
      title: "Generate User Reports",
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <p>Do you want to generate user reports with referrers: </p>
          {referString}
        </>
      ),
      okText: "OK",
      cancelText: "Cancel",
      async onOk() {
        await OnFinishReports(value.referrer);
      },
      async onCancel() {
        return;
      },
    });
  };

  const OnFinishReports = async (referrers) => {
    const payload = {
      referrer: referrers,
    };

    message.loading({
      content: "Generating User Reports",
      key: "report",
      duration: 20,
    });

    const header = {
      headers: { Authorization: localStorage.getItem("token") },
    };

    axios({
      url: `${process.env.REACT_APP_API_URL}/users/report`,
      method: "GET",
      headers: header.headers,
      params: payload,
      responseType: "blob", // Important
    }).then((response) => {
      fileDownload(
        response.data,
        `users${new Date()
          .toLocaleString()
          .replace("/", "")
          .replace("/", "")
          .replace(":", "")
          .replace(":", "")}.xlsx`
      );

      message.destroy("report");
      message.success("Successfuly Generated Reports");
    });
  };

  const handleDefaultPermissionChecked = (e) => {
    setIsDefaultPermission(e.target.checked);
  };

  useEffect(() => {
    const fetchPermissions = async () => {
      if (selectedUserRow) {
        const { user_attached_roles, id } = selectedUserRow;
        if (user_attached_roles) {
          const { is_default, roles_id } = user_attached_roles;

          var permissionQueryParameters = {};

          if (is_default) {
            permissionQueryParameters["user_id"] = null;
            permissionQueryParameters["uar_id"] = null;
            permissionQueryParameters["role_id"] = roles_id;
          }

          if (!is_default) {
            permissionQueryParameters["user_id"] = id;
            permissionQueryParameters["uar_id"] = user_attached_roles.id;
            permissionQueryParameters["role_id"] = null;
          }

          // eslint-disable-next-line react-hooks/rules-of-hooks
          const permissions = await useGetRolesWithPermissions(
            "for_update",
            permissionQueryParameters
          );

          setPermissions(permissions.data);
        }
      }
    };

    fetchPermissions();
  }, [selectedUserRow]);

  return (
    <Fragment>
      <PageHeader
        className="site-page-header"
        title="USER MANAGEMENT"
        subTitle="USER MANAGEMENT MODULE"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["Administration", "Users"]} />
        )}
      />

      {/* User Management Functionalities Button Component */}
      <div className="content-pane-transaction-div-style">
        <Divider />
        <div style={{ textAlign: "left" }}>
          <UserManagementFunctionsComponent
            changePasswordModal={handleChangePasswordModalVisibilityOnClick}
            createUserModal={handleCreateUserModalVisibilityOnClick}
            updateUserRoleModal={handleUpdateRolesVisibilityOnClick}
            addRolesModal={handleAddRolesVisibilityOnClick}
            handleOnClickUpdateUserModal={
              handleUpdateUserModalVisibilityOnClick
            }
            isForUpdate={isForUpdate}
            userType={selectedUserInfo.user_type}
            selectedUserInfo={selectedUserInfo}
            rolesRestrictions={rolesRestriction}
            onSearch={onSearch}
            searchTypeOnChange={searchTypeOnChange}
            dataLength={data.length}
            filteredDataLength={filteredData.length}
            isFiltered={isFiltered}
            handleAdminChangePasswordModal={handleAdminChangePasswordModal}
            handleReportsModal={handleReportsModal}
            form={searchUserForm}
          />
        </div>
        <UserFiltersAndSelectionComponent
          onClearSearcheAndSelection={resetSelections}
          selectedUser={selectedUserInfo}
          isFiltered={isFiltered}
          isShowColumnFilterTags={isShowColumnFilter}
          columnFilterTags={columnFilterTags}
          querySearch={querySearch}
        />
        <UserListComponent
          loading={tableLoading}
          onSelectRowChange={onSelectRowChange}
          selectedRowKeys={selectedRowKeys}
          data={data}
          statusOnChange={statusOnChange}
          statusDepositedOnChange={statusDepositedOnChange}
          rolesRestrictions={rolesRestriction}
          isFiltered={isFiltered}
          filteredData={filteredData}
          onPaginationChange={onPaginationChange}
          onSortChange={onSortChange}
          currentPagination={currentPagination}
          columnFilter={columnFilter}
          setColumnFilter={setColumnFilter}
        />
      </div>
      {/* Modal Component Containing User Creation Form */}
      <MultiStepFormComponent
        data={data}
        roles={roles}
        targetKeys={targetKeys}
        selectedKeys={selectedKeys}
        handleChange={handleChange}
        handleSelectChange={handleSelectChange}
        isModalVisible={isUserCreationModalVisible}
        handleModalVisibleOnChange={handleCreateUserModalVisibilityOnClick}
        permissionRoles={permissionRoles}
        GetUserList={GetUserList}
        resetSelections={resetSelections}
        handleUserTypeOnChange={handleUserTypeOnChange}
        userType={userType}
        rolesRestrictions={rolesRestriction}
      />
      {/* Modal Component for Change Password Form */}
      <ChangePasswordFormComponent
        emailAddress={selectedUserInfo.email}
        isModalVisible={isChangePasswordModalVisible}
        handleModalVisibleOnChange={handleChangePasswordModalVisibilityOnClick}
      />
      {/* Modal Component for Updating Roles For User */}
      {selectedUserRow ? (
        <UpdateRolesFormComponent
          isModalVisible={isUpdateRolesModalVisible}
          form={updateRolesForm}
          roles={roles}
          permissions={permissions}
          selectedUser={selectedRows}
          handleModalVisibleOnChange={handleUpdateRolesVisibilityOnClick}
          userId={selectedUserInfo.id}
          GetUserList={GetUserList}
          isDefaultPermission={isDefaultPermission}
          selectedUserRow={selectedUserRow}
          handleDefaultPermissionChecked={handleDefaultPermissionChecked}
          HandleUpdateRolesFormComponentSubmit={
            HandleUpdateRolesFormComponentSubmit
          }
        />
      ) : null}
      {/*Modal Component for Adding Roles For User */}
      <AddingRolesFormComponent
        isModalVisible={isAddRolesModalVisible}
        roles={roles}
        selectedUser={[]}
        handleModalVisibleOnChange={handleAddRolesVisibilityOnClick}
        userId={selectedUserInfo.id}
        GetUserList={GetUserList}
      />
      {/*Modal Component for Generating User Reports */}
      <UserReportsModal
        title={"Generate User Reports"}
        form={reportsForm}
        referrerData={referrerData}
        onOk={confirmReports}
        onCancel={handleReportsModal}
        destroyOnClose={true}
        okText={"Generate Reports"}
        cancelText={"Cancel"}
        visible={isReportsVisible}
      />
      {/*Modal for Changing Password, via input */}
      <ChangePasswordviaInputsComponent
        title={"Change Password"}
        form={changePasswordForm}
        formLayout={"vertical"}
        formInputsComponent={changePasswordAdminFormInputs}
        onOk={OnFinishChangePassword}
        onCancel={handleAdminChangePasswordModal}
        destroyOnClose={true}
        okText={"Change Password"}
        cancelText={"Cancel"}
        visible={isChangeAdminPasswordVisible}
      />
      {/*Modal for Updating User Information */}
      <UpdateUserInformationComponent
        visible={isUpdateUserModalVisible}
        form={updateUserForm}
        onCancel={handleUpdateUserModalVisibilityOnClick}
        onSubmit={handleUpdateuserInformation}
      />
      <UserReportsComponent dataSet={reportsData} reportRef={reportRef} />
    </Fragment>
  );
};
