import React from "react";
import { Modal, Form } from "antd";
import { SaveOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { WarehouseGenralInformationFormComponent } from "./WarehouseGenralInformationFormComponent";
import useFetchWarehouses from "../../../hooks/warehouses/useFetchWarehouses";
import useUpdateWarehouse from "../../../hooks/warehouses/useUpdateWarehouse";

export const WarehouseUpdateComponent = ({
  warehouseId,
  updateModalVisibility,
  handleUpdateModalVisibility,
  specialists,
  banks,
  onClickShowMoreCity,
  onClickShowMoreBanks,
  setCurrentPagination,
  onClearSelection,
  form,
  onChangeCity,
  onChangeProvince,
  onChangeBarangay,
  provinces,
  isProvinceFetchingLoading,
  cities,
  isCityFetchingLoading,
  barangays,
  isBarangayFetchLoading,
  onClickShowMoreAdviser,
  oldCities = [],
  providers = [],
}) => {
  const { onPaginationChange, query } = useFetchWarehouses();
  const { updateWarehouse, warehouseUpdateLoading } = useUpdateWarehouse();

  return (
    <Modal
      open={updateModalVisibility}
      title="Update Warehouse"
      okText="Update Warehouse"
      okButtonProps={{
        icon: <SaveOutlined />,
        loading: warehouseUpdateLoading,
      }}
      cancelButtonProps={{
        icon: <ArrowLeftOutlined />,
        disabled: warehouseUpdateLoading,
      }}
      cancelText="Cancel"
      onCancel={handleUpdateModalVisibility}
      centered={true}
      width={"60%"}
      destroyOnClose={true}
      onOk={async () => {
        const isSuccess = await updateWarehouse(form, warehouseId);
        if (isSuccess) {
          handleUpdateModalVisibility();
          setCurrentPagination(1);
          onClearSelection();
          onPaginationChange(query?.page, query?.total);
        }
      }}
    >
      <Form form={form} layout="vertical" name="warehouses">
        <WarehouseGenralInformationFormComponent
          banks={banks}
          provinces={provinces}
          isProvinceFetchingLoading={isProvinceFetchingLoading}
          isCityFetchingLoading={isCityFetchingLoading}
          isBarangayFetchingLoading={isBarangayFetchLoading}
          cities={cities}
          oldCities={oldCities}
          barangays={barangays}
          specialists={specialists}
          onClickShowMoreCity={onClickShowMoreCity}
          onClickShowMoreBanks={onClickShowMoreBanks}
          onClickShowMoreAdviser={onClickShowMoreAdviser}
          onChangeProvince={onChangeProvince}
          onChangeCity={onChangeCity}
          onChangeBarangay={onChangeBarangay}
          providers={providers}
        />
      </Form>
    </Modal>
  );
};
