import React, { useState } from "react";
import { Drawer, Result } from "antd";

const DescriptionItem = ({ title, content }) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">{title}:</p>
    {content}
  </div>
);

const initialUserInfo = {
  id: null,
  first_name: null,
  middle_name: null,
  last_name: null,
  email: null,
  mobile: null,
  user_type: null,
  created_at: null,
};

export const ProfileDrawerComponent = (props) => {
  const { onClose, visible } = props;

  return (
    <>
      <Drawer
        width={640}
        placement="right"
        closable={true}
        onClose={onClose}
        visible={visible}
      >
        <Result
          status="404"
          title="Service Unavailable"
          subTitle="Sorry, this part is under development"
        />
      </Drawer>
    </>
  );
};
