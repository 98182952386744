const createPermissionTransformer = (data) => {
  const payload = {
    permission_index_id: data.permission_index_id,
    has_access: data.has_access || 0,
    roles_id: data.roles_id,
    user_id: data.user_id,
  };

  return payload;
};

const createPermissionByNewUserTransformer = (data) => {
  const payload = {
    permission_index_id: data.permission_index_id,
    has_access: data.has_access || 0,
    roles_id: data.roles_id,
  };

  return payload;
};

export { createPermissionTransformer, createPermissionByNewUserTransformer };
