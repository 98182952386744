import { UpdateRolesPermissionsDefault } from "../../api/admin/UpdateRolesPermissionDefault";
import { message } from "antd";

const useGetUpdateRolesPermissions = async (data) => {
  await message.loading("Updating Permissions...", 2);
  const response = await UpdateRolesPermissionsDefault(data);

  if (response.status === 200) {
    await message.success("Successfuly Updated Permissions");
    return true;
  }

  await message.error("Error updating permissions", 2);
  await message.warning("Please try refreshing the page", 2);
  await message.warning(
    "If the error still exists, please ask for support from dev team"
  );
  return false;
};

export default useGetUpdateRolesPermissions;
